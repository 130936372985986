import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormCheck, Table } from 'react-bootstrap-5';
import { setOffMarketPropertyId } from '../search/helpers/navigation_helper';
import { formatWithCommas, USD } from '../../../../helpers/formatters/number_formats';
import { PROPERTY_MODEL } from '../../constants';
import ConnectTagManager from '../tags/connect_tag_manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/pro-solid-svg-icons';

export default function MyPropertiesTable({ properties, selectedProperties, onSelect, onSelectAll }) {
  return (
    <Table bordered className="table-fixed my-0">
      <thead className="bg-light">
        <tr className="align-middle">
          <th>
            <div className="d-flex gap-3">
              <FormCheck
                checked={selectedProperties.length === properties.length}
                width={24}
                onChange={() => onSelectAll()}
              />
              <div>Address</div>
            </div>
          </th>
          <th>Property Type</th>
          <th>SF</th>
          <th>Price</th>
          <th>Tags</th>
        </tr>
      </thead>
      <tbody>
        {properties.map((property => (
          <tr className="align-middle hover-toggle-parent" key={property.propertyId}>
            <td>
              <div className="d-flex gap-3">
                <FormCheck
                  checked={
                    selectedProperties.some(selectedProperty => selectedProperty.propertyId === property.propertyId)
                  }
                  onChange={() => onSelect(property)}
                />
                <div style={{ lineHeight: '1.25' }}>
                  <span
                    className="clickable text-primary fw-bolder"
                    onClick={() => setOffMarketPropertyId(property.propertyId)}
                  >
                    {property.address}
                  </span>
                  <div className="text-muted">
                    <small>{property.city}, {property.state}</small>
                  </div>
                </div>
              </div>
            </td>
            <td className="text-muted">{property.propertyType}</td>
            <td className="text-muted">{property.buildingSizeSf && formatWithCommas(property.buildingSizeSf)}</td>
            <td className="text-muted">
              {
                (property.lastSalePrice !== null && property.lastSalePrice !== 0)
                && USD.format(property.lastSalePrice, { round: true })
              }
            </td>
            <td>
              <ConnectTagManager
                emptyState={
                  <div className="hover-d-inline">
                    <Button variant="text-secondary"><FontAwesomeIcon icon={faTag} />Add Tag</Button>
                  </div>
                }
                initialTags={property.tags}
                recordId={property.id}
                recordType={PROPERTY_MODEL}
              />
            </td>
          </tr>
        )))}
      </tbody>
    </Table>
  );
}

MyPropertiesTable.propTypes = {
  properties: PropTypes.array.isRequired,
  selectedProperties: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired
};

