import React from 'react';
import PropTypes from 'prop-types';
import { setOffMarketPropertyId } from '../search/helpers/navigation_helper';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseBuilding, faTrash } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from '../empty_state';


export default function OwnedPropertiesList({ ownedProperties, onDeleteOwnedProperty }) {
  if (ownedProperties.length === 0) {
    return (
      <EmptyState
        icon={faHouseBuilding}
        text="This contact has no properties"
      />
    );
  }

  return (
    <React.Fragment>
      {ownedProperties.map(ownedProperty => (
        <div className="d-flex border-bottom justify-content-between p-2" key={ownedProperty.propertyId}>
          <span
            className="text-truncate fw-bolder clickable text-primary"
            onClick={() => setOffMarketPropertyId(ownedProperty.propertyId)}
          >
            {ownedProperty.address}, {ownedProperty.city}, {ownedProperty.state}
          </span>
          <Button
            variant="link"
            onClick={() => onDeleteOwnedProperty(ownedProperty)}
          >
            <FontAwesomeIcon className="text-danger" icon={faTrash} />
          </Button>
        </div>
      ))}
    </React.Fragment>
  );
}

OwnedPropertiesList.propTypes = {
  ownedProperties: PropTypes.array.isRequired,
  onDeleteOwnedProperty: PropTypes.func.isRequired
};
