import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Dropdown } from 'react-bootstrap-5';

import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import EditModal from './edit_modal';
import { deleteOwnership } from '../store/actions/owners';
import { connect } from 'react-redux';

function ActionsDropdown({ owner, deleteOwnership }) {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
          <FontAwesomeIcon className="clickable px-2" icon={faEllipsisVertical}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-0">
          <Dropdown.Item className="border-top-0" onClick={() => setShowEditModal(true)}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faPencil}/>
            Edit
          </Dropdown.Item>
          {owner.id && (
            <Dropdown.Item className="border-bottom-0" onClick={() => deleteOwnership(owner.id)}>
              <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <EditModal
        initialContact={owner.contact}
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
      />
    </div>
  );
}

ActionsDropdown.propTypes = {
  deleteOwnership: PropTypes.func.isRequired,
  owner: PropTypes.object.isRequired
};

export default connect(null, { deleteOwnership })(ActionsDropdown);
