import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get as objectGet } from 'lodash';
import {
  GoogleMap, Polygon, DrawingManager
} from '@react-google-maps/api';

import {
  boundsFromMultiplePolygons,
  fetchGeoJSONFromNominatim,
  getDetailsOfPlace,
  placeBounds,
  polygonFromBounds,
  splitFullAddress
} from '../helpers/map_helpers';
import { addBound, addGeoJson, replaceMapBounds, clearBounds } from '../store/actions/criteria';
import { mapLoaded, setMapZoomReadonly } from '../store/actions/ui';
import { propertyIdOffMarketPropertySearch } from 'bundles/connect/helpers/prospect_api';
import { USA_CENTER, USA_BOUNDING_BOX } from 'bundles/connect/constants';
import MapMarkers from './map/map_markers';
import InfowWindow from './map/info_window';
import CustomDrawingControls from './map/custom_drawing_controls';
import { setOffMarketPropertyId } from '../helpers/navigation_helper';

const DEFAULT_LOCATIONS_ARRAY = [];
const POLYGON_STYLES = {
  fillOpacity: 0,
  strokeColor: '#8a1bd9',
  strokeOpacity: 0.9,
  strokeWeight: 3
};

function Map({
  addBound,
  addGeoJson,
  clearBounds,
  defaultMapType,
  locations,
  mapBounds,
  mapLoaded,
  nominatimUrl,
  replaceMapBounds,
  setMapZoomReadonly,
  showMap
}) {
  const [drawingMode, setDrawingMode] = useState(null);
  const [map, setMap] = useState();
  const [placesService, setPlacesService] = useState();

  const polygons = useMemo(() => {
    const polygonsArray = [];
    locations.forEach((location) => {
      if (!location.polygonGeojson) return;

      if (location.polygonGeojson.type == 'Polygon') {
        const coords = location.polygonGeojson.coordinates;
        if (coords[0]) polygonsArray.push(coords[0].map(point => ({ lat: point[1], lng: point[0] })));
      } else if (location.polygonGeojson.type == 'MultiPolygon') {
        location.polygonGeojson.coordinates.forEach((coords) => {
          if (coords[0]) polygonsArray.push(coords[0].map(point => ({ lat: point[1], lng: point[0] })));
        });
      }
    });

    return polygonsArray;
  }, [locations]);

  const centerMap = useCallback(() => {
    if (!map) return;

    const { latMin, latMax, lngMin, lngMax } = USA_BOUNDING_BOX;
    const continentalUs = new google.maps.LatLngBounds(
      new google.maps.LatLng(latMin, lngMin),
      new google.maps.LatLng(latMax, lngMax)
    );
    map.fitBounds(continentalUs);
  }, [map]);

  // When a street address is selected, use the trySetPropertyFromAddress action to try and open
  // the property modal directly
  const trySetPropertyFromAddress = useCallback(async (place) => {
    if (!['premise', 'subpremise', 'street_address'].includes(place.types[0])) return false;

    try {
      const { id, latitude, longitude } = await propertyIdOffMarketPropertySearch(
        ...splitFullAddress(place.address).slice(0, 3)
      );

      setOffMarketPropertyId(id);
      map.panTo({ lat: latitude, lng: longitude });
      map.setZoom(18);

      return true;
    } catch (e) {
      return false;
    }
  }, [map]);

  // When a place is selected, try to lookup the details from nominatim.
  // 1. Boundary from nominatim is found
  //   a. Replace the search bounds with the new bounds and fit the map to the bounds
  // 2. Boundary from nominatim IS NOT found
  //   a. Google Place result contains a viewport
  //      i. If using the map view - fit the map to the place viewport and let it auto update the search bounds
  //      ii. If not using the map view - replace the search bounds with the place viewport
  //   b. Google Place result does not contain a viewport
  //      i. If using the map view - pan the map to the place location and let it auto update the search bounds
  //      ii. If not using the map view - set the search bounds to a 10square mile bounding box around the location
  useEffect(() => {
    const placeSelected = async (e) => {
      if (await trySetPropertyFromAddress(e.detail))  return;

      if (e.detail.types[0] === 'postal_code') {
        getDetailsOfPlace(
          {
            placeId: e.detail.placeId,
            fields: ['name', 'address_components', 'geometry']
          },
          new google.maps.places.PlacesService(map)
        ).then(place =>  addBound(placeBounds(place), { name: place.name }));
      } else {
        fetchGeoJSONFromNominatim(nominatimUrl, e.detail, {
          successCallback: (geoJson) => {
            addGeoJson(geoJson, { name: e.detail.address });
          },
          noResultCallback: () => {
            placesService.getDetails(
              { placeId: e.detail.placeId },
              place => addBound(placeBounds(place), { name: e.detail.address })
            );
          }
        });
      }
    };

    window.addEventListener('placeSelect', placeSelected);

    return () => {
      window.removeEventListener('placeSelect', placeSelected);
    };
  }, [placesService, map, showMap, polygons]);

  // Handle when the clearLocation event is sent from elsewhere in the map.
  // The map has to perform this logic because only it knows it's current bounds and visibility state
  useEffect(() => {
    window.addEventListener('clearLocation', clearBounds);

    return () => {
      window.removeEventListener('clearLocation', clearBounds);
    };
  }, [showMap, map]);

  // On mobile, when flipping between list and map view, recenter the map on any existing bounds
  // On polygon set (region), pan the map to fit
  useEffect(() => {
    if (!showMap || !map) return;

    if (polygons.length > 0) {
      map.fitBounds(boundsFromMultiplePolygons(polygons));
    }
  }, [showMap]);

  useEffect(() => {
    if (showMap && map && polygons.length > 0) {
      map.fitBounds(boundsFromMultiplePolygons(polygons));
    }
  }, [JSON.stringify(polygons.at(-1))]);

  const onLoad = useCallback((map) => {
    mapLoaded();
    map.mapTypeId = defaultMapType || 'roadmap';
    setMap(map);
    setPlacesService(new google.maps.places.PlacesService(map));

    if (mapBounds) {
      const sw = new google.maps.LatLng(mapBounds.south, mapBounds.west);
      const ne = new google.maps.LatLng(mapBounds.north, mapBounds.east);
      map.fitBounds(new google.maps.LatLngBounds(sw, ne), 0);
    } else if (polygons.length > 0) {
      map.fitBounds(boundsFromMultiplePolygons(polygons));
    } else centerMap();
  }, []);

  const onZoomChanged = useCallback(() => {
    if (map) setMapZoomReadonly(map.getZoom());
  }, [map]);

  const boundsUpdated = useCallback(() => {
    if (!showMap) return;

    replaceMapBounds(
      polygonFromBounds(map.getBounds())
    );
  }, [showMap, map]);

  const handlePolygonComplete = useCallback((polygon) => {
    setDrawingMode(null);

    const coordinates = [];
    const points = polygon.getPath().getArray();

    // We only care about polygons with area. Discard single or double point polygons
    if (points.length < 3) {
      polygon.setMap(null);
      return;
    }

    points.forEach((point) => {
      const lat = point.lat();
      const lng = point.lng();

      coordinates.push({ lat, lng });
    });

    addBound(coordinates);

    polygon.setMap(null);
  }, [addBound]);

  const handleRectangleComplete = useCallback((rectangle) => {
    setDrawingMode(null);

    const bounds = rectangle.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();
    const north = northEast.lat();
    const east = northEast.lng();
    const south = southWest.lat();
    const west = southWest.lng();

    addBound([
      { lat: north, lng: west },
      { lat: north, lng: east },
      { lat: south, lng: east },
      { lat: south, lng: west },
      { lat: north, lng: west }
    ]);

    rectangle.setMap(null);
  }, [addBound]);

  return (
    <GoogleMap
      center={USA_CENTER}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      options={{
        clickableIcons: false,
        fullscreenControl: false,
        mapId: '5de8e726806581b',
        mapTypeControl: true,
        mapTypeControlOptions: {
          styles: ['roadmap', 'satellite'],
          position: google.maps.ControlPosition.LEFT_TOP
        },
        minZoom: 4,
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      }}
      zoom={3}
      onIdle={boundsUpdated}
      onLoad={onLoad}
      onZoomChanged={onZoomChanged}
    >
      <CustomDrawingControls
        drawingMode={drawingMode}
        map={map}
        position={google.maps.ControlPosition.TOP_RIGHT}
        onClear={clearBounds}
        onDragClick={() => setDrawingMode(null)}
        onPolygonClick={() => setDrawingMode(google.maps.drawing.OverlayType.POLYGON)}
        onRectangleClick={() => setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE)}
      />
      {showMap && <InfowWindow />}
      {showMap && <MapMarkers />}
      {polygons.map((polygon, idx) => (
        <Polygon
          key={idx}
          options={{
            ...POLYGON_STYLES,
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: false,
            zIndex: 1
          }}
          paths={polygon}
        />
      ))}
      {/* <DrawingManager onPolygonComplete={handlePolygonComplete}/> */}
      <DrawingManager
        drawingMode={drawingMode}
        options={{
          drawingControl: false,
          rectangleOptions: POLYGON_STYLES,
          polygonOptions: POLYGON_STYLES
        }}
        onPolygonComplete={handlePolygonComplete}
        onRectangleComplete={handleRectangleComplete}
      />
    </GoogleMap>
  );
}

Map.propTypes = {
  addBound: PropTypes.func.isRequired,
  addGeoJson: PropTypes.func.isRequired,
  clearBounds: PropTypes.func.isRequired,
  defaultMapType: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({
    polygonGeojson: PropTypes.shape({
      coordinates: PropTypes.array
    })
  })),
  mapBounds: PropTypes.shape({
    north: PropTypes.number.isRequired,
    south: PropTypes.number.isRequired,
    east: PropTypes.number.isRequired,
    west: PropTypes.number.isRequired
  }),
  mapLoaded: PropTypes.func.isRequired,
  nominatimUrl: PropTypes.string.isRequired,
  replaceMapBounds: PropTypes.func.isRequired,
  setMapZoomReadonly: PropTypes.func.isRequired,
  showMap: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ criteria, ui: { profile: { defaultMapType } } }) => {
  const { mapBounds } = criteria;
  const locations = objectGet(criteria, 'locationsAttributes') || DEFAULT_LOCATIONS_ARRAY;

  // In the case that there are no locations, use DEFAULT_LOCATIONS_ARRAY. By using the same object we can avoid
  // re-renders. Without using DEFAULT_LOCATIONS_ARRAY, react would see [] as a new object each time and rerender
  // the map everytime the redux store is updated.
  return { locations, mapBounds, defaultMapType };
};

export default connect(
  mapStateToProps,
  {
    addBound,
    addGeoJson,
    clearBounds,
    mapLoaded,
    replaceMapBounds,
    setMapZoomReadonly,
  }
)(Map);
