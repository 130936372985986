import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap-5';
import CallForm from './activity_forms/call_form';
import OtherForm from './activity_forms/other_form';
import MeetingForm from './activity_forms/meeting_form';
import { activityTypeLabel } from './activity_history_item';
import { ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_MEETING, ACTIVITY_TYPE_OTHER } from '../../constants';
import ContactInput from './activity_forms/contact_input';
import EmailForm from './activity_forms/email_form';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';

export default function ActivityEdit({ initialActivity, show, onClose, onUpdate }) {
  const [activity, setActivity] = useState(initialActivity);
  const [saving, setSaving] = useState(false);

  const handleSave = useCallback(() => {
    setSaving(true);
    onUpdate(activity).then(onClose).finally(() => setSaving(false));
  }, [activity]);

  useEffect(() => {
    setActivity(initialActivity);
  }, [initialActivity]);

  if (!activity) return null;

  let form;
  if (activity.type === ACTIVITY_TYPE_CALL) {
    form = <CallForm activity={activity} onUpdate={setActivity}/>;
  }
  if (activity.type === ACTIVITY_TYPE_OTHER) form = <OtherForm activity={activity} onUpdate={setActivity} />;
  if (activity.type === ACTIVITY_TYPE_MEETING) form = <MeetingForm activity={activity} onUpdate={setActivity} />;
  if (activity.type === ACTIVITY_TYPE_EMAIL) form = <EmailForm activity={activity} onUpdate={setActivity} />;

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <div>
          <Modal.Title as="h5">{activityTypeLabel(activity)}</Modal.Title>
          {activity.phoneNumber && <div>{formatPhoneNumber(activity.phoneNumber)}</div>}
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        {form}
        <ContactInput activity={activity} onChange={setActivity} />
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving} variant="secondary" onClick={handleSave}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ActivityEdit.propTypes = {
  initialActivity: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
