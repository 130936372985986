import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Overlay, Popover } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { map } from 'lodash';
import HideShowPassword from './hide_show_password';

const TEST_TEXT = {
  length: 'Minimum length of 10',
  uppercase: 'At least one upper case letter',
  lowercase: 'At least one lower case letter',
  number: 'At least one number',
  special: 'At least one special character',
};

export default function PasswordInput({
  inputName,
  inputProps = {},
  pattern,
  requiredElement,
  value,
  onChange
}) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [containerRef, setContainerRef] = useState(null);
  const targetRef = useRef(null);

  let tests = {};

  if (value) {
    tests = {
      length: value.length >= 10,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /\d/.test(value),
      special: /[-!@#$%^&*()_=+{};:'",./<>?`~[\]]/.test(value),
    };
  }

  const isPasswordValid = Object.values(tests).every(val => val);

  useEffect(() => {
    if (!containerRef) return;

    function listenIfElementIsInsideDiv(e) {
      setIsInputFocused(containerRef.contains(e.target));
    }

    document.body.addEventListener('click', listenIfElementIsInsideDiv);
    document.body.addEventListener('focusin', listenIfElementIsInsideDiv);

    return () => {
      document.body.removeEventListener('click', listenIfElementIsInsideDiv);
      document.body.removeEventListener('focusin', listenIfElementIsInsideDiv);
    };
  }, [containerRef]);

  return (
    <div ref={setContainerRef}>
      <Form.Group>
        <Form.Label htmlFor="password-input">
          Password
          {requiredElement || <span className="text-danger"> *</span>}
        </Form.Label>
        <div ref={targetRef}>
          <HideShowPassword
            id="password-input"
            maxLength={256}
            minLength={10}
            name={inputName || 'password'}
            pattern={pattern}
            placeholder="Enter password"
            required
            value={value}
            onChange={e => onChange(e.target.value)}
            {...inputProps}
          />
        </div>
        {!isInputFocused && value && !isPasswordValid && (
          <div className="text-danger mt-2">Password does not meet the requirements.</div>
        )}
      </Form.Group>
      <Overlay
        placement="top"
        show={Object.keys(tests).length > 0 && isInputFocused}
        target={targetRef.current}
      >
        <Popover>
          <Popover.Body>
            {map(tests, (val, key) => (
              <div className={val ? 'text-success' : 'text-danger'} key={key}>
                <FontAwesomeIcon className="me-2" icon={val ? faCheck : faTimes} />
                {TEST_TEXT[key]}
              </div>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

PasswordInput.propTypes = {
  inputName: PropTypes.string,
  inputProps: PropTypes.object,
  pattern: PropTypes.string,
  requiredElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
