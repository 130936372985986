import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles/checkbox.scss';

class ManagedCheckbox extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.any,
    indeterminate: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired
  }

  checkRef = React.createRef();

  componentDidMount() {
    if (this.checkRef.current) {
      this.checkRef.current.indeterminate = this.props.indeterminate;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.indeterminate !== prevProps.indeterminate) {
      this.checkRef.current.indeterminate = this.props.indeterminate;
    }
  }

  handleChange = (event) => {
    const { checked } = event.target;
    this.props.onChange({ [this.props.name]: checked }, event);
    this.props.onValueChange(checked, event);
  }

  render() {
    const {
      checked, children, indeterminate, label, value, wrapperClassName, onChange, onValueChange, ...props
    } = this.props;

    return (
      <div className={classNames('checkbox managed-checkbox', wrapperClassName)}>
        <label>
          <input
            {...props}
            checked={checked || value || false}
            ref={this.checkRef}
            type="checkbox"
            onChange={this.handleChange}
          />

          {children || label}
        </label>
      </div>
    );
  }
}

export default ManagedCheckbox;
