import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SidebarSearch from 'components/search_sidebar/sidebar_search';
import { closeFileSearch, setFileSearchQuery, openFileSearch, submitSearch } from '../actions';

const mapDispatchToProps = {
  closeFileSearch,
  setFileSearchQuery,
  submitSearch,
  openFileSearch
};

const mapStateToProps = ({ fileSearch: { query } }) => {
  return { searchQuery: query };
};

class FileSearchBar extends React.Component {
  static propTypes = {
    closeFileSearch: PropTypes.func.isRequired,
    openFileSearch: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    setFileSearchQuery: PropTypes.func.isRequired,
    submitSearch: PropTypes.func.isRequired
  }

  handleInputChange = (e) => {
    if (e.target.value.length > 0) {
      this.props.openFileSearch();
      this.props.setFileSearchQuery(e.target.value);
      this.submitSearch();
    } else {
      this.props.closeFileSearch();
    }
  }

  submitSearch = _.debounce(() => {
    this.props.submitSearch(this.props.searchQuery);
  }, 250)

  render() {
    return (
      <div>
        <SidebarSearch
          name="search_text"
          placeholder="File Name..."
          value={this.props.searchQuery}
          onChange={this.handleInputChange}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileSearchBar);
