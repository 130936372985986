import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FileIcon from './file_icon';

class ListItem extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      folder: PropTypes.bool,
      icon: PropTypes.string,
      iconType: PropTypes.string,
      name: PropTypes.string,
      updatedAtLabel: PropTypes.string
    }),
    selected: PropTypes.bool,
    wrapper: PropTypes.node,
    onNavigation: PropTypes.func,
    onPreview: PropTypes.func
  }

  folder = () => {
    const { file } = this.props;
    return this.iconType() == 'folder' || file.folder;
  }

  iconType = () => this.props.file.icon || this.props.file.iconType;

  handleFileClick = () => {
    const { file, onNavigation, onPreview } = this.props;
    this.folder() ? onNavigation(file) : onPreview(file);
  }

  renderFileName() {
    return (
      <span name="static-name">
        {this.props.file.name}
      </span>
    );
  }

  renderRow() {
    const { selected, wrapper } = this.props;

    return (
      <div
        className={classNames('list-item', {
          'selected': selected,
          'clickable': !selected
        })}
        onClick={wrapper ? null : this.handleFileClick}
      >
        <div className="row gx-2">
          <div className="col-auto">
            <FileIcon colorless={selected} type={this.iconType()}/>
          </div>
          <div className="col text-truncate">
            <div className={classNames('text-truncate', { 'fw-bold': selected })} name="title">
              {this.renderFileName()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.wrapper) {
      return React.cloneElement(this.props.wrapper, { onClick: this.handleFileClick }, this.renderRow());
    }

    return this.renderRow();
  }
}

export default ListItem;
