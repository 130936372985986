import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles/radio_group.scss';

class ManagedRadioGroup extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any.isRequired
    })).isRequired,
    required: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired
  }

  static defaultProps = {
    inline: true,
  };

  handleChange = (event) => {
    const { value } = event.target;
    this.props.onChange({ [this.props.name]: value }, event);
    this.props.onValueChange(value, event);
  }

  render() {
    return (
      <div className={classNames('radio-fields', this.props.className, { 'form-inline': this.props.inline })}>
        {this.props.options.map(this.renderOption)}
      </div>
    );
  }

  renderOption = ({ label, value }) => {
    const input = this.renderInput({ label, value });

    return this.props.inline
      ? input
      : <div key={value}>{input}</div>;
  }

  renderInput = ({ label, value }) => {
    return (
      <label className="radio radio-inline" key={value}>
        <input
          checked={this.props.value === value}
          disabled={this.props.disabled}
          name={this.props.name}
          required={this.props.required}
          type="radio"
          value={value}
          onChange={this.handleChange}
        />
        {label || value}
      </label>
    );
  }
}

export default ManagedRadioGroup;
