import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function ProductFeature({ children, product, products }) {
  if (products[product]) return children;

  return null;
}

ProductFeature.propTypes = {
  children: PropTypes.node.isRequired,
  product: PropTypes.string.isRequired,
  products: PropTypes.object.isRequired
};

const mapStateToProps = ({ ui: { products } }) => ({ products });
export default connect(mapStateToProps, {})(ProductFeature);
