import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons';
import { formatDate, SHORT_DATE_FORMAT } from '../../../../helpers/formatters/date_time_formats';
import EmptyState from '../empty_state';
import Paginator from '../paginator';

const PER_PAGE = 5;
export default function TemplateList({ templates, onDeleteTemplate, onEditTemplate }) {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSeachResults] = useState(templates);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!searchText) {
      setSeachResults(templates);
    } else {
      setSeachResults(
        templates.filter(t => t.name.toLowerCase().includes(searchText))
      );
    }
  }, [templates, searchText]);

  const paginatedResults = useMemo(() => {
    return searchResults.slice((page - 1) * PER_PAGE, page * PER_PAGE);
  }, [searchResults, page]);

  return (
    <div>
      {templates.length > 0 ? (
        <React.Fragment>
          <Form.Label className="mb-0">Search Templates</Form.Label>
          <InputGroup className="my-2">
            <InputGroup.Text>
              <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search by template name"
              type="text"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </InputGroup>
          {paginatedResults.map(template => (
            <div className="d-flex gap-2 border-bottom py-2" key={template.id}>
              <FontAwesomeIcon className="py-1" icon={faFileSpreadsheet} size="lg"/>
              <div className="flex-grow-1">
                <div className="fw-bold">
                  {template.name}
                </div>
                <div className="text-muted small">
                  Created {formatDate(template.createdAt, SHORT_DATE_FORMAT)}
                </div>
              </div>
              <div className="d-flex gap-2">
                <Button variant="link" onClick={() => onEditTemplate(template)}>
                  <FontAwesomeIcon icon={faPencil}/>
                  Edit
                </Button>
                <Button className="text-danger" variant="link" onClick={() => onDeleteTemplate(template)}>
                  <FontAwesomeIcon icon={faTrash}/>
                  Delete
                </Button>
              </div>
            </div>
          ))}
          {searchResults.length > PER_PAGE && (
            <div className="d-flex justify-content-center">
              <Paginator currentPage={page} perPage={PER_PAGE} total={searchResults.length} onSelect={setPage}/>
            </div>
          )}
        </React.Fragment>
      ) : (
        <EmptyState icon={faFileSpreadsheet} title="You don't have any data export templates" />
      )}
    </div>
  );
}

TemplateList.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  onDeleteTemplate: PropTypes.func.isRequired,
  onEditTemplate: PropTypes.func.isRequired
};
