import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileWord, faFileExcel, faFilePowerpoint,
  faFileVideo, faFileCsv, faFolder, faFileArchive, faFileAlt, faTrash, faLock } from '@fortawesome/pro-solid-svg-icons';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';

import classNames from 'classnames';

const ICON_MAP = {
  'zip': { icon: faFileArchive, color: 'text-muted' },
  'doc': { icon: faFileWord, color: 'icon-blue' },
  'xls': { icon: faFileExcel, color: 'icon-green' },
  'jpg': { icon: faFileImage, color: 'icon-blue' },
  'ppt': { icon: faFilePowerpoint, color: 'icon-ppt' },
  'vid': { icon: faFileVideo, color: 'icon-blue' },
  'csv': { icon: faFileCsv, color: 'icon-green' },
  'pdf': { icon: faFilePdf, color: 'icon-red' },
  'folder': { icon: faFolder, color: 'text-muted' },
  'misc': { icon: faFileAlt, color: 'icon-blue' },
  'trash': { icon: faTrash, color: 'text-muted' },
  'lock': { icon: faLock, color: 'text-muted' },
  default: { icon: faFileAlt, color: 'icon-blue' }
};

export default class FileIcon extends React.Component {
  static propTypes = {
    colorless: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string
  }

  getIcon = () => ICON_MAP[this.props.type] || ICON_MAP.default;

  render() {
    const { icon, color } = this.getIcon();
    return (
      <FontAwesomeIcon
        className={classNames('icon', { [color]: !this.props.colorless })}
        fixedWidth
        icon={icon}
        size={this.props.size || 'lg'}
      />
    );
  }
}
