import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content']

  toggleTargets(e) {
    const value = e.target.value;
    const field = e.target.dataset.field;

    this.contentTargets.forEach((target) => {
      if (field == target.dataset.field) {
        if (target.dataset.showFor == value) {
          target.classList.remove('d-none');
        } else {
          target.classList.add('d-none');
        }
      }
    });
  }
}
