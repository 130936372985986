import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConnectListingOverlayCard from './connect_listing_overlay_card';
import OffMarketOverlayCard from './off_market_overlay_card';

function InfowWindow({ offMarketSearch }) {
  if (offMarketSearch) {
    return <OffMarketOverlayCard />;
  } else {
    return <ConnectListingOverlayCard />;
  }
}

InfowWindow.propTypes = {
  offMarketSearch: PropTypes.bool.isRequired
};

const mapStateToProps = ({ ui: { offMarketSearch } }) => ({ offMarketSearch });

export default connect(mapStateToProps)(InfowWindow);
