import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

import DatePicker from 'react-datepicker';
import Emails from './emails';
import PhoneNumbers from './phone_numbers';
import Addresses from './addresses';
import { contactTypeOptions } from '../../call_list/owners/constants';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { searchContacts } from '../../../helpers/prospect_api';

export default function PersonForm({
  formData,
  addresses,
  emails,
  phoneNumbers,
  onFormChange,
  onSetAddresses,
  onSetEmails,
  onSetPhoneNumbers
}) {
  const promiseOptions = useCallback(debounce((inputValue, callback) => {
    searchContacts(inputValue || '*').then((results) => {
      const companies = [...results.companies.hits];
      const options = companies.map(result => (
        { id: result.id, label: result.name }
      ));
      callback(options);
    });
  }, 300), []);

  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>First Name</Form.Label>
        <Form.Control name="firstName" value={formData.firstName || ''} onChange={onFormChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Last Name</Form.Label>
        <Form.Control name="lastName" value={formData.lastName || ''} onChange={onFormChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Job Title</Form.Label>
        <Form.Control name="jobTitle" value={formData.jobTitle || ''} onChange={onFormChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Contact Type</Form.Label>
        <Form.Select
          className="form-select"
          name="contactType"
          value={formData.contactType || ''}
          onChange={onFormChange}
        >
          <option value=""/>
          {contactTypeOptions.map(({ key, label }) => (
            <option key={key} value={key}>{label}</option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>Company</Form.Label>
        <AsyncSelect
          cacheOptions
          defaultOptions
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          loadOptions={promiseOptions}
          value={formData.company}
          onChange={(company) => {
            onFormChange({ target: { name: 'company', value: company } });
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Birthday</Form.Label>
        <div>
          <DatePicker
            className="form-control"
            selected={formData.birthday ? (new Date(formData.birthday)) : null}
            wrapperClassName="w-100"
            onChange={date => onFormChange({ target: { name: 'birthday', value: date } })}
          />
        </div>
      </Form.Group>
      <Emails
        emails={emails}
        onSet={onSetEmails}
      />
      <PhoneNumbers
        phoneNumbers={phoneNumbers}
        onSet={onSetPhoneNumbers}
      />
      <Addresses
        addresses={addresses}
        onSet={onSetAddresses}
      />
    </React.Fragment>
  );
}

PersonForm.propTypes = {
  addresses: PropTypes.array,
  emails: PropTypes.array,
  formData: PropTypes.object,
  phoneNumbers: PropTypes.array,
  onFormChange: PropTypes.func.isRequired,
  onSetAddresses: PropTypes.func.isRequired,
  onSetEmails: PropTypes.func.isRequired,
  onSetPhoneNumbers: PropTypes.func.isRequired
};
