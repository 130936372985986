import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function Options({ options, labelName = 'label', valueName = 'value' }) {
  const normalizedOptions = useMemo(() => {
    if (!(options instanceof Array)) {
      options = Object.keys(options).map(value => ({
        [labelName]: options[value],
        [valueName]: value,
      }));
    }

    return options;
  }, [options, labelName, valueName]);

  return <React.Fragment>
    {normalizedOptions.map((option, i) => (
      <option
        aria-label={option['aria-label'] || option[labelName]}
        key={i}
        value={option[valueName]}
      >
        {option[labelName]}
      </option>
    ))}
  </React.Fragment>;
}

Options.propTypes = {
  labelName: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  valueName: PropTypes.string,
};
