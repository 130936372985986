import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap-5';
import { OverlayView } from '@react-google-maps/api';

export default function OverlayCard({ children, lat, lng, heightOffset }) {
  if (!children) return null;

  const setOffset = (width, height) => {
    return { x: -(width / 2), y: -(height + heightOffset) };
  };

  return (
    <OverlayView
      getPixelPositionOffset={setOffset}
      mapPaneName={OverlayView.FLOAT_PANE}
      position={{ lat, lng }}
    >
      <Card className="fs-base shadow-lg overflow-hidden" style={{ minWidth: '275px' }}>
        {children}
      </Card>
    </OverlayView>
  );
}

OverlayCard.propTypes = {
  children: PropTypes.node,
  heightOffset: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
};
