import React, { useState } from 'react';
import PropTypes from 'prop-types';

import officePNG from 'connect/search/office_grayscale.png';
import retailPNG from 'connect/search/retail_grayscale.png';
import industrialPNG from 'connect/search/industrial_grayscale.png';
import landPNG from 'connect/search/land_grayscale.png';
import multiFamilyPNG from 'connect/search/multifamily_grayscale.png';
import specialPurposePNG from 'connect/search/special_purpose_grayscale.png';
import hospitalityPNG from 'connect/search/hospitality_grayscale.png';
import residentialPNG from 'connect/search/residential_grayscale.png';

const THUMBNAILS = {
  1: officePNG,
  2: retailPNG,
  3: industrialPNG,
  5: landPNG,
  6: multiFamilyPNG,
  7: specialPurposePNG,
  8: hospitalityPNG,
  10: residentialPNG
};

export default function OffMarketPropertyImage({ result }) {
  const [fallback, setFallback] = useState(false);

  if (!result.streetviewUrl) setFallback(true);

  const placeholderImg = THUMBNAILS[result.propertyTypeId] || THUMBNAILS[8];
  const imageSrc = fallback ? placeholderImg : result.streetviewUrl;
  return (
    <React.Fragment>
      <img
        className="d-md-none placeholder-thumb object-fit-contain"
        src={imageSrc}
        onError={() => setFallback(true)}
      />
      <img
        className="d-none d-md-block placeholder-thumb"
        src={imageSrc}
        onError={() => setFallback(true)}
      />
    </React.Fragment>
  );
}

OffMarketPropertyImage.propTypes = {
  result: PropTypes.shape({
    propertyTypeId: PropTypes.number.isRequired,
    streetviewUrl: PropTypes.string
  })
};
