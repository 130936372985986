import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nanoid } from 'nanoid';
import { OverlayTrigger, Popover, Tooltip as TooltipReact } from 'react-bootstrap';

import './styles.scss';

// TODO: Clean this up (or make a clean wrapper component) - see VoucherEditorTableTD as an example
export default class Tooltip extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    childrenRef: PropTypes.object,
    containerRef: PropTypes.object,
    content: PropTypes.node,
    dark: PropTypes.bool,
    icon: PropTypes.object,
    iconClassName: PropTypes.string,
    overlayClassName: PropTypes.string,

    // When onlyOnOverflow is false, this component is just a dumb wrapper around the OverlayTrigger
    // and popover components. It could use the same rendering path, but this way avoids interacting
    // with the DOM when not necessary, and should therefore also help prevent bugs.
    //
    // When onlyOnOverflow is true, however:
    //   - childrenRef is required
    //   - The OverflowTrigger is only rendered on mouseover _and_ when children are overflowing
    //   - The children will be wrapped in a container and be given a ref for overflow detection,
    //     unless a containerRef is provided
    // eslint-disable-next-line react/sort-prop-types
    onlyOnOverflow: PropTypes.bool
  }

  static defaultProps = {
    placement: 'bottom',
  }

  id = nanoid(32);
  containerRef = this.props.containerRef || React.createRef();

  state = {};

  componentDidMount() {
    this.initializeRefs();
  }

  getSnapshotBeforeUpdate() {
    if (this.props.childrenRef && this.props.childrenRef.current) {
      this.props.childrenRef.current.removeEventListener('mouseenter', this.handleChildMouseEnter);
    }

    return null;
  }

  componentDidUpdate() {
    this.initializeRefs();
  }

  initializeRefs() {
    if (this.props.onlyOnOverflow && this.props.childrenRef.current) {
      this.props.childrenRef.current.addEventListener('mouseenter', this.handleChildMouseEnter);
    }
  }

  handleChildMouseEnter = () => {
    const childrenWidth = this.props.childrenRef.current.getBoundingClientRect().width;
    const containerWidth = this.containerRef.current.getBoundingClientRect().width;
    this.setState({ show: childrenWidth > containerWidth });
  }

  render() {
    if (!this.props.onlyOnOverflow || this.state.show) {
      return this.renderOverlayTrigger();
    } else {
      return this.renderChildren();
    }
  }

  renderOverlayTrigger() {
    const { children, content, dark, onlyOnOverflow, overlayClassName, ...props } = this.props;

    const overlay = dark ? (
      <TooltipReact className={overlayClassName} id={this.id}>
        {this.props.content}
      </TooltipReact>
    ) : (
      <Popover className={overlayClassName} id={this.id}>
        {this.props.content}
      </Popover>
    );

    return (
      <OverlayTrigger
        overlay={overlay}
        {...props}
      >
        {this.renderChildren()}
      </OverlayTrigger>
    );
  }

  renderChildren() {
    if (!this.props.onlyOnOverflow || this.props.containerRef) {
      if (this.props.icon) {
        return <FontAwesomeIcon className={classNames(this.props.iconClassName)} icon={this.props.icon} />;
      } else {
        return this.props.children;
      }
    } else {
      return (
        <div className="tooltip-overflow-container" ref={this.containerRef}>
          {this.props.children}
        </div>
      );
    }
  }
}
