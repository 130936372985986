import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Form, Row, Col } from 'react-bootstrap-5';
import classNames from 'classnames';

import ProductFeature from '../product_feature';
import { setOffMarketHoverPropertyId } from '../../store/actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import OffMarketPropertyImage from './off_market_property_image';
import LikelyToSellBar from './likely_to_sell_bar';
import OffMarketSummary from './off_market_summary';
import { setOffMarketPropertyId } from '../../helpers/navigation_helper';
import PropertyBookmark from './property_bookmark';
import AddToList from '../../../properties/add_to_list';
import { PROPERTY_MODEL } from '../../../../constants';

function OffMarketResultCard({
  isSelected,
  offMarketHoverPropertyId,
  result,
  setOffMarketHoverPropertyId,
  onSelectToggle
}) {
  const isHovering = result.propertyId == offMarketHoverPropertyId;

  const onMouseEnter = () => {
    setOffMarketHoverPropertyId(result.propertyId);
  };

  const onMouseLeave = () => {
    setOffMarketHoverPropertyId();
  };

  const onClick = () => {
    onMouseLeave();
    setOffMarketPropertyId(result.propertyId);
  };

  const handleSelectToggle = () => onSelectToggle(result.propertyId);

  return (
    <Card
      className={classNames('mb-3 overflow-hidden', {
        shadow: isHovering,
        'border-primary': isHovering,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Row className="g-0 align-items-stretch">
        <Col className="d-flex flex-column" onClick={onClick}>
          <div className="d-flex flex-grow-1">
            <ProductFeature product="prospecting">
              <div className="py-2 ps-2" onClick={e => e.stopPropagation()}>
                <Form.Check
                  checked={isSelected}
                  data-pendo-tag="select_property_result_card"
                  id={`property_${result.propertyId}_checkbox`}
                  type="checkbox"
                  onChange={handleSelectToggle}
                />
              </div>
            </ProductFeature>
            <Card.Body className="p-2 clickable">
              <OffMarketSummary property={result} />
            </Card.Body>
          </div>
          {!result?.listings?.forSale && (
            <div className="clickable">
              <LikelyToSellBar likelyToSell={result?.scores?.likelyToSell} />
            </div>
          )}
        </Col>
        <Col
          className="map-result-thumb off-market-result-thumb d-none d-md-block clickable"
          md={{ order: 'last', span: 'auto' }}
          xs={{ order: 'first', span: '12' }}
          onClick={onClick}
        >
          <OffMarketPropertyImage result={result} />
        </Col>
        <ProductFeature product="prospecting">
          <Col
            className="d-flex flex-column border-start"
            xs={{ order: 'last', span: 'auto' }}
          >
            <div className="flex-fill clickable border-bottom" data-pendo-tag="add_to_list_result_card">
              <AddToList itemCount={1} itemIds={new Set([result.propertyId])} itemType={PROPERTY_MODEL}>
                <div className="align-content-center text-center p-2 h-100">
                  <FontAwesomeIcon
                    className="text-primary"
                    icon={faRectangleHistoryCirclePlus}
                  />
                </div>
              </AddToList>
            </div>
            <div className="flex-fill clickable" data-pendo-tag="bookmark_property_result_card">
              <PropertyBookmark bookmarked={result.bookmarked} buttonClass="h-100" id={result.propertyId} />
            </div>
          </Col>
        </ProductFeature>
      </Row>
    </Card>
  );
}

OffMarketResultCard.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  offMarketHoverPropertyId: PropTypes.string,
  result: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    favorited: PropTypes.bool,
    state: PropTypes.string.isRequired,
    listings: PropTypes.shape({
      forSale: PropTypes.bool,
      salePrice: PropTypes.number,
    }),
    propertyId: PropTypes.string.isRequired,
    valuation: PropTypes.shape({
      estimate: PropTypes.number,
    }),
  }).isRequired,
  setOffMarketHoverPropertyId: PropTypes.func.isRequired,
  onSelectToggle: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ui: { offMarketHoverPropertyId } }) => ({
  offMarketHoverPropertyId
});
export default connect(mapStateToProps, {
  setOffMarketHoverPropertyId,
})(OffMarketResultCard);
