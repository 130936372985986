import { SET_PROFILE_DATA } from '../actions/preview';

export default (state = {}, action) => {
  switch (action.type) {
  case SET_PROFILE_DATA:
    return { ...state, profile: action.payload };
  default:
    return state;
  }
};
