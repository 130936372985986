import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Modal, Spinner } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faTimes } from '@fortawesome/pro-solid-svg-icons';
import TagPicker from './tag_picker';
import { addTagToRecord, getTagsForRecord, removeTagFromRecord } from '../../helpers/prospect_api';
import { isEmpty, uniq } from 'lodash';
import { displayToast } from '../../helpers/display_toast';

export default function ConnectTagManager({ emptyState, recordType, recordId, insightsPropertyId, initialTags }) {
  const [tags, setTags] = useState(initialTags);

  // Use two states here to allow the tagForModal to linger. This makes closing the tag modal less jarring
  const [tagForModal, setTagForModal] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (tags === undefined) getTagsForRecord(recordType, recordId, insightsPropertyId).then(setTags);
  }, []);

  const handleTagSelect = (newTag) => {
    // The network request shouldn't ever really fail so opting to just update state immediately
    setTags(prevTags => uniq([...(prevTags || []), newTag]));

    addTagToRecord(recordType, recordId, insightsPropertyId, newTag)
      .then(() => displayToast('Tag has been added', 'success'));
  };

  const handleTagDelete = (tag) => {
    // The network request shouldn't ever really fail so opting to just update state immediately
    setTags(prevTags => prevTags.filter(existingTag => existingTag !== tag));

    removeTagFromRecord(recordType, recordId, insightsPropertyId, tag);
  };

  const handleOpenModal = useCallback((tag) => {
    setShowModal(true);
    setTagForModal(tag);
  }, []);

  return (
    <div className="d-flex gap-2 align-items-center flex-wrap">
      {!(emptyState && isEmpty(tags)) && <FontAwesomeIcon icon={faTag}/>}
      {(tags || []).map(tag => (
        <div className="d-flex align-items-stretch" key={tag}>
          <Badge
            bg=""
            className="bg-connect-tag text-body rounded-end-0 clickable"
            onClick={() => handleOpenModal(tag)}
          >
            <small className="fw-normal">{tag}</small>
          </Badge>
          <Badge
            bg=""
            className="bg-connect-tag text-body rounded-start-0 clickable"
            onClick={() => handleTagDelete(tag)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Badge>
        </div>
      ))}
      <TagPicker emptyState={emptyState} excludeNames={tags || []} onTagSelected={handleTagSelect}/>
      <Modal contentClassName="overflow-hidden" show={showModal} onHide={() => setShowModal(false)}>
        <turbo-frame class="h-100" id="tag-details" src={`/prospect/tags/${encodeURIComponent(tagForModal)}`}>
          <Modal.Body>
            <div style={{ minHeight: '300px' }}>
              <div className="px-3 py-5">
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </turbo-frame>
      </Modal>
    </div>
  );
}

ConnectTagManager.propTypes = {
  emptyState: PropTypes.node,
  initialTags: PropTypes.arrayOf(PropTypes.string),
  insightsPropertyId: PropTypes.string,
  recordId: PropTypes.number,
  recordType: PropTypes.string
};
