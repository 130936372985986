import PropTypes from 'prop-types';

import { DragSource, DropTarget } from 'react-dnd';
import withDragDropContext from './with_drag_drop_context';

export const listItemPropTypes = {
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool,
  listName: PropTypes.string,
  onReorder: PropTypes.func,
  targetGroup: PropTypes.string
};

export const listTargetPropTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  listName: PropTypes.string,
  onReorder: PropTypes.func,
  targetGroup: PropTypes.string
};

const dragSourceCollector = (connect, monitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

// https://react-dnd.github.io/react-dnd/examples/sortable/simple
const dropTargetBase = {
  canDrop(props, monitor) {
    return props.targetGroup === monitor.getItem().targetGroup;
  },

  hover(props, monitor, component) {
    if (!component) {
      return null;
    }

    const dragIndex = monitor.getItem().index;
    const dragList = monitor.getItem().listName;
    const hoverIndex = props.index;
    const hoverList = props.listName;
    const dragTarget = monitor.getItem().targetGroup;
    const dropTarget = props.targetGroup;

    // Only allow dragging to/from the same group
    if (dragTarget !== dropTarget) {
      return;
    }

    if (dragList === hoverList && dragIndex === hoverIndex) {
      // Don't replace items with themselves
      return;
    }

    props.onReorder(dragIndex, hoverIndex, dragList, hoverList);

    monitor.getItem().index = hoverIndex < 0 ? 0 : hoverIndex;
    monitor.getItem().listName = hoverList;
  }
};

const dropTargetCollector = connect => ({
  connectDropTarget: connect.dropTarget()
});

export function reorderableListTarget(component, type, dropTarget) {
  dropTarget = { ...dropTargetBase, ...dropTarget };
  const droppable = DropTarget(type, dropTarget, dropTargetCollector);
  return droppable(component);
}

export function reorderableListItem(component, type, dragSource, dropTarget) {
  dropTarget = { ...dropTargetBase, ...dropTarget };
  const draggable = DragSource(type, dragSource, dragSourceCollector);
  const droppable = DropTarget(type, dropTarget, dropTargetCollector);
  return draggable(droppable(component));
}

export const reorderableList = withDragDropContext;
