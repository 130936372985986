import React, { useState } from 'react';
import PropTypes from 'prop-types';


import { Accordion, Form } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROPERTY_TYPE_ICON_MAP } from '../../../../constants';



export default function PropertyTypeFilters({ assetClasses, selectedAssetClasses, onAssetClassChange }) {
  const [activeKeys, setActiveKeys] = useState(selectedAssetClasses);

  const checkAllIfNone = (assetClass) => {
    const selectedSubclasses = assetClass.subclasses.filter(sc => selectedAssetClasses.includes(sc.value));
    return selectedAssetClasses.includes(assetClass.value) && selectedSubclasses.length === 0;
  };

  const handleAccordionButtonToggle = (assetClass) => {
    if (!activeKeys.includes(assetClass.value)) {
      setActiveKeys([...activeKeys, assetClass.value]);
    } else {
      setActiveKeys(activeKeys.filter(key => key !== assetClass.value));
    }
  };

  const handleAssetClassChange = (e, assetClass) => {
    const selectedSubclasses = assetClass.subclasses.filter(sc => selectedAssetClasses.includes(sc.value));

    const checked = e.target.checked;

    if (checked && !activeKeys.includes(assetClass.value)) {
      setActiveKeys([...activeKeys, assetClass.value]);
    } else if (!checked && activeKeys.includes(assetClass.value)) {
      setActiveKeys(activeKeys.filter(key => key !== assetClass.value));
    }

    // clear subclasses
    if (selectedSubclasses.length > 0) {
      if (!checked) {
        selectedSubclasses.forEach((subclass) => {
          onAssetClassChange({ target: { value: subclass.value, checked: false } });
        });
      }
    }

    onAssetClassChange(e);
  };

  const handleSubAssetClassChange = (e, assetClass) => {
    const selectedSubclasses = assetClass.subclasses.filter(sc => selectedAssetClasses.includes(sc.value));
    if (selectedAssetClasses.includes(assetClass.value) && selectedSubclasses.length === 0) {
      e.target.checked = true;
    }

    onAssetClassChange(e);
  };

  const renderAccordionLabel = (assetClass) => {
    return (
      <div className="d-flex flex-column align-items-start">
        <div className="d-flex align-items-center gap-2">
          <FontAwesomeIcon className="text-primary" icon={PROPERTY_TYPE_ICON_MAP[assetClass.value]} />
          {assetClass.label}
        </div>
        {assetClass.hint && <small className="text-muted fw-normal">{assetClass.hint}</small>}
      </div>
    );
  };

  const renderCheckboxGroup = (assetClass) => {
    const selectedSubclasses = assetClass.subclasses.filter(sc => selectedAssetClasses.includes(sc.value));

    return (
      <Accordion.Item eventKey={assetClass.value} key={assetClass.value}>
        <div className="d-flex gap-2">
          <div className="filter-checkbox flex-grow-1 p-3">
            <Form.Check
              className="mb-0 d-flex gap-2"
              id={`${assetClass.value}_check`}
            >
              <Form.Check.Input
                checked={selectedAssetClasses.includes(assetClass.value) || selectedSubclasses.length > 0}
                className="mt-1"
                type="checkbox"
                value={assetClass.value}
                onChange={e => handleAssetClassChange(e, assetClass)}
              />
              <Form.Check.Label>{renderAccordionLabel(assetClass)}</Form.Check.Label>
            </Form.Check>
          </div>
          <Accordion.Button
            className="bg-white py-3 shadow-none w-auto"
            onClick={() => handleAccordionButtonToggle(assetClass)} 
          />
        </div>
        {assetClass.subclasses.length > 0 && (
          <Accordion.Body className="ps-9 bg-light">
            {assetClass.subclasses.map(subclass => (
              <div key={subclass.value}>
                <Form.Check
                  checked={checkAllIfNone(assetClass) || selectedSubclasses.includes(subclass)}
                  id={`${subclass.value}_check`}
                  label={subclass.label}
                  type="checkbox"
                  value={subclass.value}
                  onChange={e => handleSubAssetClassChange(e, assetClass)}
                />
              </div>
            ))}
          </Accordion.Body>
        )}
      </Accordion.Item>
    );
  };

  return (
    <Accordion activeKey={activeKeys} alwaysOpen flush style={{ minWidth: '270px' }}>
      {assetClasses.map(renderCheckboxGroup)}
    </Accordion>
  );
}

PropertyTypeFilters.propTypes = {
  assetClasses: PropTypes.array.isRequired,
  selectedAssetClasses: PropTypes.array.isRequired,
  onAssetClassChange: PropTypes.func.isRequired
};
