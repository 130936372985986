import React, { PureComponent, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis 
} from 'recharts';
import { formatWithCommas } from '../../../../../helpers/formatters/number_formats';
import useOnScreen from '../../../../../hooks/use_on_screen';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="card shadow border rounded p-2">
        <div className="fw-bold">{payload[0].payload.name}</div>
        <div className="fs-large">{`$${formatWithCommas(payload[0].value)}`}</div>
      </div>
    );
  }

  return null;
};

class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    let offsetY;
    if (payload.index % 2 === 0) {
      offsetY = y + 12;
    } else {
      offsetY = y;
    }

    return (
      <g transform={`translate(${x},${offsetY})`}>
        <text dy={12} fill="#666" style={{ fontSize: 10 }} textAnchor="middle" x={0} y={0}>
          {payload.value}
        </text>
      </g>
    );
  }
}

class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text dx={-16} fill="#666" style={{ fontSize: 10 }} textAnchor="middle" x={0} y={0}>
          {formatter(payload.value)}
        </text>
      </g>
    );
  }
}

const formatter = (value) => {
  return new Intl.NumberFormat(
    'en-US',
    { style: 'currency', currency: 'USD', notation: 'compact', compactDisplay: 'short' }
  ).format(value);
};

const XaxisLabel = (value) => {
  const limit = 7;
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};

export default function OffMarketCompChart({ data, avgLine }) {
  const viewRef = useRef();
  const isInView = useOnScreen(viewRef);

  return (
    <React.Fragment>
      <div className="w-100" ref={viewRef} style= {{ height: '228px' }}>
        { isInView && (
          <ResponsiveContainer maxHeight="228px" width="100%" >
            <BarChart
              data={data}
              height={228}
            >
              <CartesianGrid vertical={false}/>
              <XAxis dataKey="name" tick={<CustomizedXAxisTick/>} tickFormatter={XaxisLabel}/>
              <YAxis tick={<CustomizedYAxisTick/>} tickFormatter={formatter} width={34}/>
              <Tooltip content={CustomTooltip} cursor={{ fill: '#f7f8f8', opacity: 0.5 }}/>
              <Bar
                activeBar={<Rectangle fill="#7422CE" radius={[4, 4, 0, 0]}/>}
                dataKey="sale_price"
                radius={[4, 4, 0, 0]}
              >
                {
                  data.map((entry, index) => (
                    // The first data entry should always be the subject property
                    <Cell fill={index === 0 ? '#7422CE' : '#1D357E'} key={`cell-${index}`}/>
                  ))
                }
              </Bar>
              <ReferenceLine stroke="pink" strokeWidth={2} y={avgLine} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </React.Fragment>
  );
}

OffMarketCompChart.propTypes = {
  avgLine: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array
};

CustomizedXAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
};

CustomizedYAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
};
