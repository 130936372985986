import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export function ContactSearch({ handleSearch, totalContactsAndLeads }) {
  const onChange = (e) => {
    handleSearch(e.target.value);
  };

  return (
    <FormGroup>
      <InputGroup>
        <InputGroup.Addon>
          <FontAwesomeIcon icon={faSearch} />
        </InputGroup.Addon>
        <FormControl
          name="search-input"
          placeholder={`Search ${totalContactsAndLeads} project contacts and leads`}
          type="text"
          onChange={onChange}
        />
      </InputGroup>
    </FormGroup>
  );
}

ContactSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  totalContactsAndLeads: PropTypes.number.isRequired
};

export default ContactSearch;
