import PdfCa from '../pdf_ca';
import React from 'react';
import CaText from '../ca_text';
import { Form } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

export default function CaContainer({ caData, caFields, setCaFields }) {
  function changeCaChecked(newValue) {
    setCaFields({ ...caFields, checked: newValue });
  }

  function fileUploaded(uploadedFile) {
    setCaFields({ ...caFields, uploadedFile: uploadedFile });
  }

  if (caData.filePath) {
    return (
      <PdfCa
        caFilePath={caData.filePath}
        caType={caData.type}
        uploadedFile={caFields.uploadedFile}
        onFileUploaded={fileUploaded}
      />
    );
  }

  return <TextCa caChecked={caFields.checked} caText={caData.text} onCaChecked={changeCaChecked}/>;
}

function TextCa({ caChecked, caText, onCaChecked }) {
  return (
    <React.Fragment>
      <CaText caText={caText}/>
      <Form.Group className="mb-4">
        <Form.Check
          checked={caChecked}
          id="email-ca"
          label="Email me a copy of this CA"
          name="email_ca"
          type="checkbox"
          onChange={e => onCaChecked(e.target.checked)}
        />
      </Form.Group>
    </React.Fragment>
  );
}

CaContainer.propTypes = {
  caData: PropTypes.object.isRequired,
  caFields: PropTypes.object.isRequired,
  setCaFields: PropTypes.func.isRequired
};

TextCa.propTypes = {
  caChecked: PropTypes.bool.isRequired,
  caText: PropTypes.string.isRequired,
  onCaChecked: PropTypes.func.isRequired,
};