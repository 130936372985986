import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { hideAlert, moveEntryRename, moveEntryReplace, uploadFileReplace, uploadFileRename,
  cancelAlert, restoreEntryRename } from '../actions';

class AlertModal extends React.Component {
  static propTypes = {
    buttons: PropTypes.array,
    cancelAlert: PropTypes.func,
    closeDealRoom: PropTypes.func.isRequired,
    hideAlert: PropTypes.func,
    message: PropTypes.string,
    moveEntryRename: PropTypes.func,
    moveEntryReplace: PropTypes.func,
    radioButtons: PropTypes.array,
    restoreEntryRename: PropTypes.func,
    show: PropTypes.bool,
    title: PropTypes.string,
    uploadFileRename: PropTypes.func,
    uploadFileReplace: PropTypes.func
  }

  state = {
    checkedRadio: 0
  }

  onCancel = () => {
    this.props.cancelAlert();
  }

  onRestoreEntryRename = () => this.props.restoreEntryRename();

  onMoveEntryReplace = () => this.props.moveEntryReplace();
  onMoveEntryRename = () => this.props.moveEntryRename();
  onCloseDealRoom = () => this.props.closeDealRoom();

  onUploadFileReplace = () => {
    this.props.uploadFileReplace();
  }

  onUploadFileRename = () => {
    this.props.uploadFileRename();
  }

  renderButtons = () => {
    if ((this.props.buttons || []).length === 0) {
      return (
        <button className="btn btn-sm btn-dark" onClick={this.submit}>
          OK
        </button>
      );
    }
    const buttons = this.props.buttons.reverse().map((button, i) => {
      return (
        <button
          className={`btn pull-right mr-2 ${button.className ? button.className : 'btn-dark'}`}
          key={i}
          onClick={() => {
            this.props.hideAlert();
            this[button.onClick]();
          }}
        >
          {button.name}
        </button>
      );
    });
    return (
      <div className="clearfix">
        {buttons}
      </div>
    );
  }

  submit = () => {
    this.props.hideAlert();
    const funcName = this.props.radioButtons[this.state.checkedRadio].onSubmit;
    this[funcName]();
  }

  renderRadioButtons = () => {
    if ((this.props.radioButtons  || []).length === 0) {
      return null;
    }
    const radios = [];
    this.props.radioButtons.forEach((radioButton, i) => {
      radios.push(
        <div className="radio">
          <label onClick={() => this.setState({ checkedRadio: i })}>
            <input checked={this.state.checkedRadio === i} readOnly type="radio"/>
            {radioButton.text}
          </label>
        </div>
      );
    });
    return radios;
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <Modal show={this.props.show}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{this.props.message}</p>
          {this.renderRadioButtons()}
        </Modal.Body>
        <Modal.Footer>
          {this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    show: state.alert.show,
    title: state.alert.title,
    message: state.alert.message,
    buttons: state.alert.buttons,
    radioButtons: state.alert.radioButtons
  };
}

const mapDispatchToProps = {
  hideAlert,
  moveEntryReplace,
  moveEntryRename,
  restoreEntryRename,
  uploadFileReplace,
  uploadFileRename,
  cancelAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);