/* eslint-disable */
// Vendored from https://github.com/jsillitoe/react-currency-input to incorporate unpublished fixes for Safari
import PropTypes from 'prop-types';
import React, { Component }  from 'react';
import ReactDOM from 'react-dom';
import mask from './mask';

// IE* parseFloat polyfill
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/parseFloat#Polyfill
if (Number.parseFloat === undefined) {
  Number.parseFloat = parseFloat;
}

export default class CurrencyInput extends Component {
  constructor(props) {
    super(props);

    this.prepareProps = this.prepareProps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.setSelectionRange = this.setSelectionRange.bind(this);
    this.state = this.prepareProps(this.props);

    this.currentSelectionStart = 0;
    this.previousSelectionStart = 0;
    this.previousSelectionEnd = 0;
  }

  /**
   * Exposes the current masked value.
   *
   * @returns {String}
   */
  getMaskedValue() {
    return this.state.maskedValue;
  }

  /**
   * General function used to cleanup and define the final props used for rendering
   * @returns {{ maskedValue: {String}, value: {Number}, customProps: {Object} }}
   */
  prepareProps(props) {
    const customProps = { ...props };
    delete customProps.onChange;
    delete customProps.onChangeEvent;
    delete customProps.value;
    delete customProps.decimalSeparator;
    delete customProps.thousandSeparator;
    delete customProps.precision;
    delete customProps.inputType;
    delete customProps.allowNegative;
    delete customProps.allowEmpty;
    delete customProps.prefix;
    delete customProps.suffix;
    delete customProps.selectAllOnFocus;
    delete customProps.autoFocus;

    let initialValue = props.value;

    if (initialValue === null) {
      initialValue = props.allowEmpty ? null : '';
    } else {
      if (typeof initialValue == 'string') {
        if (props.thousandSeparator === ".") {
          initialValue = initialValue.replace(/\./g, '');
        }

        if (props.decimalSeparator != ".") {
          initialValue = initialValue.replace(new RegExp(props.decimalSeparator, 'g'), '.');
        }

        initialValue = initialValue.replace(/[^0-9-.]/g, '');
        initialValue = Number.parseFloat(initialValue);
      }

      initialValue = Number(initialValue).toLocaleString(undefined, {
        style: 'decimal',
        minimumFractionDigits: props.precision,
        maximumFractionDigits: props.precision
      });
    }

    const { maskedValue, value } = mask(
      initialValue,
      props.precision,
      props.decimalSeparator,
      props.thousandSeparator,
      props.allowNegative,
      props.prefix,
      props.suffix
    );

    return { maskedValue, value, customProps };
  }


  /**
   * Component lifecycle function.
   * Invoked when a component is receiving new props. This method is not called for the initial render.
   *
   * @param nextProps
   * @see https://facebook.github.io/react/docs/component-specs.html#updating-componentwillreceiveprops
   */
  componentWillReceiveProps(nextProps) {
    this.setState(this.prepareProps(nextProps));
  }

  /**
   * Component lifecycle function.
   * @returns {XML}
   * @see https://facebook.github.io/react/docs/react-component.html#componentdidmount
   */
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.theInput);
    let selectionStart, selectionEnd;

    if (this.props.autoFocus) {
      this.theInput.focus();
      selectionEnd = this.state.maskedValue.length - this.props.suffix.length;
      selectionStart = selectionEnd;
    } else {
      selectionEnd = Math.min(node.selectionEnd, this.theInput.value.length - this.props.suffix.length);
      selectionStart = Math.min(node.selectionStart, selectionEnd);
    }

    this.setSelectionRange(node, selectionStart, selectionEnd);

    node.addEventListener('keydown', (event) => {
      this.previousSelectionStart = node.selectionStart;
      this.previousSelectionEnd = node.selectionEnd;
      this.previousKey = event.key;
    })
  }


  /**
   * Component lifecycle function
   * @returns {XML}
   * @see https://facebook.github.io/react/docs/react-component.html#componentwillupdate
   */
  componentWillUpdate() {
    const node = ReactDOM.findDOMNode(this.theInput);
    this.currentSelectionStart = node.selectionStart;
  }

  componentDidUpdate(prevProps, prevState) {
    const node = ReactDOM.findDOMNode(this.theInput);
    const previousValue = prevState.maskedValue;
    const currentValue = this.state.maskedValue;

    let newCaretPosition;
    if (this.previousSelectionEnd === previousValue.length) {
      // At the end, or replacing everything (or blank) - the caret should move to/stay at the end
      newCaretPosition = currentValue.length;
    } else if (previousValue.length === currentValue.length) {
      // No meaningful change - put the cursor back where it was (or at the end of the range)
      newCaretPosition = this.previousSelectionEnd;

      if (previousValue === currentValue) {
        // ...unless deleting with no change, meaning it was a delimiter deletion attempt, which no-ops
        // Move the caret to prevent it from getting "stuck"
        if (this.previousKey === 'Backspace') {
          newCaretPosition--;
        } else if (this.previousKey === 'Delete') {
          newCaretPosition++;
        }
      }
    } else {
      // Actual change - adjust the caret position by the number of added/removed leading delimeters
      const previousLeadingDelimiterCount = this.countLeadingDelimiters(previousValue, this.previousSelectionStart);
      const currentLeadingDelimiterCount = this.countLeadingDelimiters(currentValue, this.currentSelectionStart);
      newCaretPosition = this.currentSelectionStart + currentLeadingDelimiterCount - previousLeadingDelimiterCount;
    }

    this.setSelectionRange(node, newCaretPosition, newCaretPosition);

    // When this component is controlled, inputs may cause multiple updates - reset for idempotency
    this.currentSelectionStart = newCaretPosition;
    this.previousSelectionStart = newCaretPosition;
    this.previousSelectionEnd = newCaretPosition;
    this.previousKey = null;
  }

  countLeadingDelimiters(string, upToIndex) {
    let count = 0;

    for (let i = 0; i < upToIndex; i++) {
      if (!/\d/.test(string[i])) {
        count++;
      }
    }

    return count;
  }

  /**
   * Set selection range only if input is in focused state
   * @param node DOMElement
   * @param start number
   * @param end number
   */
  setSelectionRange(node, start, end) {
    if (document.activeElement === node) {
      node.setSelectionRange(start, end);
    }
  }

  /**
   * onChange Event Handler
   * @param event
   */
  handleChange(event) {
    event.preventDefault();
    const { maskedValue, value } = mask(
      event.target.value,
      this.props.precision,
      this.props.decimalSeparator,
      this.props.thousandSeparator,
      this.props.allowNegative,
      this.props.prefix,
      this.props.suffix
    );

    event.persist();  // fixes issue #23

    this.setState({ maskedValue, value }, () => {
      this.props.onChange(maskedValue, value, event);
      this.props.onChangeEvent(event, maskedValue, value);
    });
  }

  handleFocus(event) {
    if (!this.theInput) return;

    //Whenever we receive focus check to see if the position is before the suffix, if not, move it.
    const selectionEnd = this.theInput.value.length - this.props.suffix.length;
    const isNegative = (this.theInput.value.match(/-/g) || []).length % 2 === 1;
    const selectionStart = this.props.prefix.length + (isNegative ? 1 : 0);
    this.props.selectAllOnFocus && event.target.setSelectionRange(selectionStart, selectionEnd);
  }

  render() {
    return (
      <input
        ref={input => this.theInput = input}
        type={this.props.inputType}
        value={this.state.maskedValue}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onMouseUp={this.handleFocus}
        {...this.state.customProps}
      />
    );
  }
}

CurrencyInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputType: PropTypes.string,
  allowNegative: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  selectAllOnFocus: PropTypes.bool
};

CurrencyInput.defaultProps = {
  onChange: function(maskValue, value, event) {/*no-op*/},
  onChangeEvent: function(event, maskValue, value) {/*no-op*/},
  autoFocus: false,
  value: '0',
  decimalSeparator: '.',
  thousandSeparator: ',',
  precision: '2',
  inputType: 'text',
  allowNegative: false,
  prefix: '',
  suffix: '',
  selectAllOnFocus: false
};
