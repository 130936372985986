import { ALERT, HIDE_ALERT } from "../actions";

export default (state = {}, action) => {
  switch (action.type) {
  
  case ALERT: {
    return {
      buttons: action.buttons,
      radioButtons: action.radioButtons,
      message: action.message,
      pending: action.pending,
      show: true,
      title: action.title
    };
  }
  case HIDE_ALERT: {
    return { ...state, show: false };
  }
  default:
    return state;
  }
};