import React from 'react';
import PropTypes from 'prop-types';

export default function PdfPreview({ file }) {
  return (
    <iframe className="w-100 h-100 pdf-iframe-viewer" src={file.url} title="Pdf Viewer" />
  );
}

PdfPreview.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string
  })
};
