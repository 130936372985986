import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import ActionCable from 'actioncable';
import { connect } from 'react-redux';
import { get } from 'helpers/api';
import { findEntryById, findParentMostFolder } from '../helpers/folder_helpers';
import { getQueryParameter } from 'helpers/browser_helpers';
import Snackbar from 'shared/snackbar';
import { updateRootFolder } from '../actions';
import { noop } from 'lodash';

function RootFolderChangeNotifier(props) {
  const cableConsumer = useRef(ActionCable.createConsumer(props.cablePath));

  useEffect(() => {
    return () => cableConsumer.current.disconnect;
  }, []);

  return <Functionality cableConsumer={cableConsumer.current} {...props}/>;
}

function Functionality({ cableConsumer, cableData, fileSharingUrl, rootFolder, updateRootFolder }) {
  const [showModal, setShowModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [entriesNames, setEntriesNames] = useState('');
  const openModal = () => setShowModal(true);
  const openSnackbar = () => setShowSnackbar(true);
  const closeSnackbar = () => setShowSnackbar(false);

  function didEntryChangeAccessLevel(entryId, isFolder, updatedRootFolder) {
    const updatedDoc = findEntryById(updatedRootFolder, entryId, isFolder);
    const originalDoc = findEntryById(rootFolder, entryId, isFolder);
    // if we cant find the doc from before, we can assume it was private therefore it was locked
    // so if it goes from private -> high and the user cant see it before and after, we dont want to
    // say it changed since the user still cannot access it
    const updatedDocLocked = updatedDoc?.hasOwnProperty('locked') ? updatedDoc.locked : true;
    const originalDocLocked = originalDoc?.hasOwnProperty('locked') ? originalDoc.locked : true;

    return updatedDocLocked !== originalDocLocked;
  }

  function needsToReload(entries, updatedRootFolder) {
    // we need to check if either the file the user is viewing got altered, or if they are in a folder
    // the file wont get altered so we have to find the top most parent that isnt the rootFolder
    const fileId = Number(getQueryParameter('file'));
    const fileEntryIds = entries.filter(entry => !entry.folder).map(entry => entry.id);
    if (fileEntryIds.includes(fileId)) return didEntryChangeAccessLevel(fileId, false, updatedRootFolder);

    const currentFolderId = Number(document.querySelector('body').dataset.currentFolderId);
    if (isNaN(currentFolderId) || currentFolderId === 0) return false;

    const parentMostFolder = findParentMostFolder(rootFolder, currentFolderId);
    if (didEntryChangeAccessLevel(parentMostFolder.id, true, updatedRootFolder)) return true;
  }

  function accessGainedEntries(entries, updatedRootFolder) {
    return entries.reduce((accumulator, entry) => {
      const updatedEntry = findEntryById(updatedRootFolder, entry.id, entry.folder);
      const isUnlocked = (updatedEntry && !updatedEntry.locked);
      if (didEntryChangeAccessLevel(entry.id, entry.folder, updatedRootFolder) && isUnlocked) {
        accumulator.push(updatedEntry);
      }

      return accumulator;
    }, []);
  }

  function accessLostEntries(entries, updatedRootFolder) {
    return entries.filter((entry) => {
      const updatedEntry = findEntryById(updatedRootFolder, entry.id, entry.folder);
      const isLocked = (!updatedEntry || updatedEntry.locked);

      return didEntryChangeAccessLevel(entry.id, entry.folder, updatedRootFolder) && isLocked;
    });
  }

  async function handleDocAccessChanged(entries) {
    const data = await get(fileSharingUrl);

    const updatedRootFolder = data?.rootFolder;
    if (needsToReload(entries, updatedRootFolder)) {
      openModal();
      return;
    }

    let shouldUpdateRootFolder = false;
    const changedEntries = entries.filter(entry => (
      didEntryChangeAccessLevel(entry.id, entry.folder, updatedRootFolder))
    );
    const newUnlockedEntries = accessGainedEntries(changedEntries, updatedRootFolder);
    const newLockedEntries = accessLostEntries(changedEntries, updatedRootFolder);
    if (newUnlockedEntries.length > 0) {
      const unlockedEntriesNames = newUnlockedEntries.map(entry => entry.name);
      setEntriesNames(namesToSentence(unlockedEntriesNames));
      shouldUpdateRootFolder = true;
      openSnackbar();
    }

    if (newLockedEntries.length > 0) {
      shouldUpdateRootFolder = true;
      newLockedEntries.forEach((entry) => {
        const originalEntry = findEntryById(rootFolder, entry.id, entry.folder);
        const rootFolderChild = data.rootFolder.children.find(e => e.id === entry.id && e.folder === entry.folder);
        if (rootFolderChild) {
          rootFolderChild.lostAccess = true;
          rootFolderChild.name = originalEntry.name;
        } else {
          const updatedEntry = {
            ...originalEntry,
            icon: 'lock',
            lostAccess: true,
            locked: true,
            removed: true
          };
          data.rootFolder.children.push(updatedEntry);
        }
      });
    }

    const missingLostAccess = missingEntries(data);
    if (missingLostAccess.length > 0) data.rootFolder.children.push(...missingLostAccess);

    if (shouldUpdateRootFolder) updateRootFolder(data);
  }

  function missingEntries(data) {
    // we need to carry over all of the lostAccess entries, but need to make sure the user has not regained access
    // to the lostAccess entries
    const originalLostAccess = rootFolder.children.filter(entry => entry.lostAccess);
    const newLostAccessIds = data.rootFolder.children.filter(entry => entry.lostAccess).map(entry => entry.id);

    return originalLostAccess.filter((entry) => {
      const newEntry = findEntryById(data.rootFolder, entry.id, entry.folder);
      const unlockedInNewRoot = newEntry && !newEntry.locked;
      return !newLostAccessIds.includes(entry.id) && !unlockedInNewRoot;
    });
  }

  function namesToSentence(array) {
    if (array.length === 2) {
      return array.join(' and ');
    } else if (array.length >= 3) {
      const firstPartOfSentence = array.slice(0, array.length - 1).join(', ');
      return `${firstPartOfSentence}, and ${array[array.length - 1]}`;
    }

    return array[0];
  }

  function handleMessageReceived(message) {
    if (message.event === 'DOC_ACCESS_CHANGED') {
      handleDocAccessChanged(message.entries);
    }
  }

  useEffect(() => {
    const { cableChannel, propertyPluginListingId } = cableData;
    const sharingCable = cableConsumer.subscriptions.create(
      {
        channel: cableChannel,
        property_plugin_listing_id: propertyPluginListingId
      },
      { received: handleMessageReceived }
    );

    return () => {
      sharingCable.unsubscribe();
    };
  }, [rootFolder]);

  function reloadPage() {
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Modal show={showModal} onHide={noop}>
        <Modal.Body className="text-center">
          <FontAwesomeIcon className="text-branding" icon={faLock} size="2x"/>
          <h5 className="my-5"><strong>Access to document has changed</strong></h5>
          <p className="mb-5">Access to this document has been changed. Please reload the page to continue viewing</p>
          <button className="btn btn-primary" onClick={reloadPage}>Reload Page</button>
        </Modal.Body>
      </Modal>
      <Snackbar
        show={showSnackbar}
        text={`You have been given access to view ${entriesNames}`}
        variant="info"
        onClose={closeSnackbar}
      />
    </React.Fragment>
  );
}

const props = {
  cableData: PropTypes.shape({
    cableChannel: PropTypes.string.isRequired,
    cablePath: PropTypes.string.isRequired,
    propertyPluginListingId: PropTypes.number.isRequired,
  }).isRequired,
  fileSharingUrl: PropTypes.string.isRequired,
  rootFolder: PropTypes.object,
  updateRootFolder: PropTypes.func.isRequired
};

RootFolderChangeNotifier.propTypes = props;
Functionality.propTypes = { ...props, cableConsumer: PropTypes.object.isRequired };

const mapStateToProps = ({ navigation, vault }) => (
  { fileSharingUrl: vault.fileSharingUrl, rootFolder: navigation?.rootFolder }
);

const mapDispatchToProps = { updateRootFolder };

export default connect(mapStateToProps, mapDispatchToProps)(RootFolderChangeNotifier);
