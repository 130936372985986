import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { applyMiddleware } from 'helpers/redux_dev_tools';
import thunk from 'redux-thunk';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import MediaQuery from 'react-responsive';

import reducers from './reducers';
import DocumentNavigation from './components/document_navigation';
import MobileDocumentNavigation from './components/mobile_document_navigation';

import { setShowLogin } from './actions/ui';
import RootFolderChangeNotifier from './components/root_folder_change_notifier';
import { setQueryParameter } from '../../helpers/browser_helpers';

class SharingConnect extends Component {
  constructor(props) {
    super(props);
    const vault = {
      companyName: this.props.companyName,
      path: this.props.vaultAccessUrl,
      fileSharingUrl: this.props.sharingUrl,
      profileUrl: this.props.profileUrl,
      oauthUrls: this.props.oauthUrls,
      propertyId: this.props.propertyId,
      sharingId: this.props.sharingId,
      hasVaultGates: this.props.hasVaultGates
    };

    const ui = {
      isEuRequest: props.isEuRequest,
      lastRequested: { url: props.remindBrokerPath, timestamp: props.lastRequestedAccess },
      listingUrl: props.listingUrl,
      userType: props.userType
    };
    const preview = { readonly: props.readonly };
    this.store = createStore(reducers, { navigation: null, file: null, preview, ui, vault }, applyMiddleware(thunk));
  }

  currentFolderLoaded(atRoot, accessibleChildren, inaccessibleChildren, currentFile) {
    if (!this.props.currentFolderLoadedEvent) return;

    const oneAvailableEntryIsFile = accessibleChildren.length == 1 && !accessibleChildren[0].folder;
    const noInaccessibleChildren = inaccessibleChildren.length == 0;
    const availableEntryIsSelected = accessibleChildren[0]?.id === currentFile?.id;

    const oneEntryIsSelectedFile =
      atRoot && oneAvailableEntryIsFile && noInaccessibleChildren && availableEntryIsSelected;

    window.dispatchEvent(
      new CustomEvent(
        this.props.currentFolderLoadedEvent, { detail: { oneEntryIsSelectedFile: oneEntryIsSelectedFile } }
      )
    );
  }

  componentDidMount() {
    setQueryParameter('session_token');

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-login')) {
        e.preventDefault();
        this.store.dispatch(setShowLogin(true));
      }
    });

    if (this.isDeviceIpad()) {
      document.querySelectorAll('.js-hide-on-ipad').forEach((domNode) => {
        domNode.setAttribute('style', 'display:none !important');
      });
    }
  }

  isDeviceIpad() {
    // ipads can be wide enough to show DocumentNavigation but cannot show the full pdf in the viewer
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';
  }

  render() {
    const { fileNavigationContainerSelector, recaptchaKey } = this.props;

    return (
      <Provider store={this.store}>
        <React.Fragment>
          <RootFolderChangeNotifier cableData={this.props.cableData}/>
          {this.isDeviceIpad() ? (
            <MobileDocumentNavigation/>
          ) : (
            <React.Fragment>
              <MediaQuery maxWidth={991}>
                <MobileDocumentNavigation/>
              </MediaQuery>
              <MediaQuery minWidth={992}>
                <DocumentNavigation
                  currentFolderLoaded={this.currentFolderLoaded.bind(this)}
                  fileNavigationContainerSelector={fileNavigationContainerSelector}
                  recaptchaKey={recaptchaKey}
                />
              </MediaQuery>
            </React.Fragment>
          )}
        </React.Fragment>
      </Provider>
    );
  }
}

SharingConnect.propTypes = {
  cableData: PropTypes.shape({
    cableChannel: PropTypes.string.isRequired,
    cablePath: PropTypes.string.isRequired,
    propertyPluginListingId: PropTypes.number.isRequired,
  }).isRequired,
  companyName: PropTypes.string.isRequired,
  currentFolderLoadedEvent: PropTypes.string,
  fileNavigationContainerSelector: PropTypes.string.isRequired,
  hasVaultGates: PropTypes.bool.isRequired,
  isEuRequest: PropTypes.bool.isRequired,
  lastRequestedAccess: PropTypes.number,
  listingUrl: PropTypes.string.isRequired,
  oauthUrls: PropTypes.shape({
    google: PropTypes.string.isRequired,
    linkedIn: PropTypes.string.isRequired
  }).isRequired,
  profileUrl: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
  readonly: PropTypes.bool.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  remindBrokerPath: PropTypes.string.isRequired,
  sharingId: PropTypes.string.isRequired,
  sharingUrl: PropTypes.string.isRequired,
  userType: PropTypes.string,
  vaultAccessUrl: PropTypes.string.isRequired
};

export default SharingConnect;
