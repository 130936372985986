import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap-5';

import { formatDate } from 'helpers/formatters/date_time_formats';

export default function LlcTitleCard({ llcData, button = <React.Fragment/> }) {
  return (
    <div className="bg-light p-3 rounded">
      <Row>
        <Col>
          <div><strong>{llcData.name}</strong></div>
          <small className="text-muted">{llcData.companyType && <div>{llcData.companyType}</div>}</small>
          {llcData.currentStatus && (
            <div className="mt-1">
              <small><strong>Status:</strong> {llcData.currentStatus}</small>
            </div>
          )}
          {llcData.agentName && (
            <div>
              <small><strong>Agent:</strong> {llcData.agentName}</small>
            </div>
          )}
        </Col>
        <Col xs="auto">
          {llcData.incorporationDate && (
            <small className="text-muted">Incorporated {formatDate(llcData.incorporationDate)}</small>
          )}
          <div className="text-end">
            {button}
          </div>
        </Col>
      </Row>
    </div>
  );
}

LlcTitleCard.propTypes = {
  button: PropTypes.node,
  llcData: PropTypes.shape({
    agentName: PropTypes.string,
    companyType: PropTypes.string,
    currentStatus: PropTypes.string,
    incorporationDate: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired
};
