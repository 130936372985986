import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap-5';
import { brokerRemindedAt, brokerRemindedData } from '../../actions';
import { connect } from 'react-redux';
import { get } from 'helpers/api';
import PropTypes from 'prop-types';
import Snackbar from 'shared/snackbar';

const REMIND_BROKER_STATES = {
  DEFAULT: 'default',
  SENDING: 'sending',
  SENT: 'sent'
};

function RemindBroker({
  brokerRemindedAt,
  brokerRemindedData,
  lastRequestedAccess,
  readonly = false,
  remindBrokerUrl,
  vaultPath
}) {
  const [remindBrokerState, setRemindBrokerState] = useState(REMIND_BROKER_STATES.DEFAULT);
  const [checkedLastRequestedAccess, setCheckedLastRequestedAccess] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const canRemindBroker = checkedLastRequestedAccess && remindBrokerUrl && (!lastRequestedAccess ||
    (lastRequestedAccess && moment(Date.now()).diff(lastRequestedAccess, 'hours') >= 24));

  const validLastRequestedAccess = !!lastRequestedAccess && lastRequestedAccess !== 0;

  function showRemindBroker() {
    return (canRemindBroker && remindBrokerState === REMIND_BROKER_STATES.DEFAULT) ||
      remindBrokerState === REMIND_BROKER_STATES.SENDING || remindBrokerState === REMIND_BROKER_STATES.SENT;
  }

  async function sendBrokerReminder() {
    setRemindBrokerState(REMIND_BROKER_STATES.SENDING);
    try {
      await get(remindBrokerUrl);
      setRemindBrokerState(REMIND_BROKER_STATES.SENT);
      brokerRemindedAt(Date.now());
      setShowSnackbar(true);
    } catch {
      setRemindBrokerState(REMIND_BROKER_STATES.DEFAULT);
    }
  }

  useEffect(() => {
    if (lastRequestedAccess) {
      setCheckedLastRequestedAccess(true);
    } else {
      get(`${vaultPath}/broker_remind_data`).then((data) => {
        brokerRemindedData(data.lastRequestedAccess, data.remindBrokerPath);
        setCheckedLastRequestedAccess(true);
      });
    }
  }, []);

  useEffect(() => {
    if (checkedLastRequestedAccess && !validLastRequestedAccess) {
      // initial request should be sent automatically
      sendBrokerReminder();
    }
  }, [checkedLastRequestedAccess, validLastRequestedAccess]);

  function formatDate(dateInMs) {
    if (!validLastRequestedAccess) return;

    const date = new Date(dateInMs);
    const datePart = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });
    const timePart = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return `${datePart} ${timePart}`;
  }

  if (!checkedLastRequestedAccess) {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} size="2x" spin/>
      </div>
    );
  }

  return (
    <div className="text-center mt-6">
      <RemindBrokerButton
        readonly={readonly}
        remindBrokerState={remindBrokerState}
        showRemindBroker={showRemindBroker()}
        validLastRequestedAccess={validLastRequestedAccess}
        onRemindBroker={sendBrokerReminder}
      />
      {validLastRequestedAccess ? (
        <div className="text-muted mt-3"><small>Sent {formatDate(lastRequestedAccess)}</small></div>
      ) : null}
      <Snackbar
        icon={faCheckCircle}
        show={showSnackbar}
        text="Successfully sent request"
        variant="success"
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  );
}

function RemindBrokerButton({
  readonly,
  remindBrokerState,
  showRemindBroker,
  validLastRequestedAccess,
  onRemindBroker
}) {
  if (!showRemindBroker) {
    return null;
  }

  switch (remindBrokerState) {
  case REMIND_BROKER_STATES.DEFAULT: {
    const buttonText = validLastRequestedAccess ? 'Remind Broker' : 'Send Request';
    return <Button className="primary" disabled={readonly} onClick={onRemindBroker}>{buttonText}</Button>;
  }
  case REMIND_BROKER_STATES.SENDING: {
    const buttonText = validLastRequestedAccess ? 'Reminding Broker...' : 'Sending Request...';
    return (
      <Button className="primary" disabled>
        <FontAwesomeIcon className="me-2" icon={faSpinner} spin/>
        {buttonText}
      </Button>
    );
  }
  case REMIND_BROKER_STATES.SENT: {
    const buttonText = 'Message has been sent.';
    return <Button className="primary" disabled>{buttonText}</Button>;
  }
  }
}

RemindBrokerButton.propTypes = {
  readonly: PropTypes.bool.isRequired,
  remindBrokerState: PropTypes.string.isRequired,
  showRemindBroker: PropTypes.bool.isRequired,
  validLastRequestedAccess: PropTypes.bool.isRequired,
  onRemindBroker: PropTypes.func.isRequired
};

RemindBroker.propTypes = {
  brokerRemindedAt: PropTypes.func,
  brokerRemindedData: PropTypes.func,
  lastRequestedAccess: PropTypes.number,
  readonly: PropTypes.bool,
  remindBrokerUrl: PropTypes.string,
  vaultPath: PropTypes.string
};

export const mapStateToProps = ({ ui: { lastRequested }, vault: { path } }) => {
  return {
    lastRequestedAccess: lastRequested.timestamp,
    remindBrokerUrl: lastRequested.url,
    vaultPath: path
  };
};
const mapDispatchToProps = { brokerRemindedAt, brokerRemindedData };
export default connect(mapStateToProps, mapDispatchToProps)(RemindBroker);
