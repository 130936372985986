import React from 'react';
import PropTypes from 'prop-types';

import { USD } from 'helpers/formatters/number_formats';
import { useAdjustedValuation } from '../../../helpers/hooks';

export default function PropertyPrice({
  estimatedValue,
  hideSubtitle,
  listingValue,
  propertyId,
  shortSubtitle,
  valueClass,
}) {
  const [valuationAdjustment] = useAdjustedValuation(propertyId);
  const useEstimated = !(valuationAdjustment?.estimate || (listingValue && listingValue !== 0));
  const valueForDisplay = valuationAdjustment?.estimate || listingValue || estimatedValue;

  if (!valueForDisplay) return null;

  return (
    <div className="d-flex align-items-baseline gap-1">
      <span className={`my-0 ${valueClass}`}>
        {USD.format(valueForDisplay, { round: true })}
      </span>
      {!hideSubtitle && useEstimated && (
        <div className="text-xs text-muted">
          Est. {!shortSubtitle && 'Value'}
        </div>
      )}
    </div>
  );
}

PropertyPrice.propTypes = {
  estimatedValue: PropTypes.number,
  hideSubtitle: PropTypes.bool,
  listingValue: PropTypes.number,
  propertyId: PropTypes.string,
  shortSubtitle: PropTypes.bool,
  valueClass: PropTypes.string,
};
