import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap-5';

export default function LlcPeopleList({ llcData }) {
  if (llcData.officers.length == 0) return null;

  return (
    <div className="bg-light my-1 p-3 rounded">
      <Row className="g-3">
        <Col xs={12}>
          <div><strong>Officers</strong></div>
        </Col>
        {llcData.officers.map((person, i) => (
          <Col key={i} md={4} sm={6}>
            <div className="h-100">
              <div>{person.name}</div>
              <div><small className="text-muted">{person.position || 'Officer'}</small></div>
              {person.address && <div><small>{person.address}</small></div>}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

LlcPeopleList.propTypes = {
  llcData: PropTypes.shape({
    agentAddress: PropTypes.string,
    agentName: PropTypes.string,
    officers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string
    }))
  })
};
