import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';
import { linkFromRecord, searchContacts } from '../../../helpers/prospect_api';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { debounce, find } from 'lodash';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { CONTACT_MODEL } from '../../../constants';

const MultiValueLabel = (props) => {
  const link = linkFromRecord(props.data.connectedRecordType, props.data.connectedRecordId);
  return (
    <div className="d-flex align-items-center gap-1 ps-2 privacy-mask">
      {link && (
        <a href={link} rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
        </a>
      )}
      <components.MultiValueLabel {...props} />
    </div>
  );
};

MultiValueLabel.propTypes = {
  data: PropTypes.shape({
    connectedRecordType: PropTypes.string.isRequired,
    connectedRecordId: PropTypes.number.isRequired
  })
};

const ownerToSelectOption = owner => (
  { connectedRecordId: owner.contact.id, label: owner.contact.name, connectedRecordType: CONTACT_MODEL }
);
function ContactInput({ activity, owners, onChange }) {
  const connectedRecords = activity.connectedRecordsAttributes.filter(record => !record._destroy);
  const handleContactChange = (values) => {
    onChange((oldActivity) => {
      // On update, we get a list of all values. To determine deletes, mark everything as deleted and then
      // find all matches between the old and new values and mark the matches as not destroyed.

      const connectedRecords = oldActivity.connectedRecordsAttributes.map(cr => ({ ...cr, '_destroy': true }));
      values.forEach((v) => {
        const matchingRecord = find(
          connectedRecords,
          { connectedRecordId: v.connectedRecordId, connectedRecordType: v.connectedRecordType }
        );

        if (matchingRecord) {
          delete matchingRecord._destroy;
        } else {
          connectedRecords.push(v);
        }
      });

      return { ...activity, connectedRecordsAttributes: connectedRecords };
    });
  };

  const promiseOptions = useCallback(debounce((inputValue, callback) => {
    searchContacts(inputValue).then((results) => {
      const contactsAndCompanies = [...results.contacts.hits, ...results.companies.hits];
      const options = contactsAndCompanies.map(result => (
        { connectedRecordId: result.id, label: result.name, connectedRecordType: result.type }
      ));
      callback(options);
    });
  }, 300), []);

  const defaultOptions = useMemo(() => (owners || []).map(ownerToSelectOption), [owners]);

  return (
    <Form.Group>
      <Form.Label>Contact</Form.Label>
      <AsyncSelect
        cacheOptions
        components={{ MultiValueLabel }}
        defaultOptions={defaultOptions}
        getOptionValue={option => option.connectedRecordId}
        isMulti
        loadOptions={promiseOptions}
        value={connectedRecords}
        onChange={handleContactChange}
      />
    </Form.Group>
  );
}

ContactInput.propTypes = {
  activity: PropTypes.object.isRequired,
  owners: PropTypes.arrayOf(PropTypes.shape({
    contact: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  })),
  onChange: PropTypes.func.isRequired
};

export default connect(({ owners }) => ({ owners }), {})(ContactInput);
