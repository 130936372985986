import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Card, Row, Col } from 'react-bootstrap-5';

import { findListing } from 'bundles/connect/helpers/prospect_api';

import OverlayCard from './overlay_card';
import ResultSummary from '../results/result_summary';

function ConnectListingOverlayCard({ hoverSaleListingId }) {
  const [listing, setListing] = useState();

  const fetchListing = useCallback(
    debounce((id) => {
      findListing(id).then(setListing);
    }, 500),
    []
  );

  useEffect(() => {
    if (hoverSaleListingId && hoverSaleListingId !== listing?.saleListingId) {
      fetchListing(hoverSaleListingId);
    }
  }, [hoverSaleListingId]);

  if (!hoverSaleListingId || !listing || hoverSaleListingId !== listing.saleListingId) return null;

  return (
    <OverlayCard heightOffset={30} lat={listing.location.lat} lng={listing.location.lng}>
      <Row className="g-0" style={{ maxWidth: '320px' }}>
        <Col>
          <Card.Body className="p-2">
            <ResultSummary property={listing} />
          </Card.Body>
        </Col>
        <Col xs={4}>
          <img
            className="rounded-end w-100 h-100"
            src={listing.thumbnail}
            style={{ objectFit: 'cover' }}
          />
        </Col>
      </Row>
    </OverlayCard>
  );
}

ConnectListingOverlayCard.propTypes = {
  hoverSaleListingId: PropTypes.number
};

const mapStateToProps = ({ ui: { hoverSaleListingId } }) => ({ hoverSaleListingId });

export default connect(mapStateToProps)(ConnectListingOverlayCard);
