import { combineReducers } from 'redux';

import activities from './reducers/activities';
import owners from './reducers/owners';
import insightsPropertyId from './reducers/insights_property_id';
import ownedProperties from './reducers/owned_properties';

export default combineReducers({
  activities,
  ownedProperties,
  owners,
  insightsPropertyId
});
