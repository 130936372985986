import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap-5';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from 'helpers/api';
import DatePicker from 'react-datepicker';

export default function ActivityExport({ exportPath, show, templates, onHide }) {
  const [loading, setLoading] = useState(false);
  const [exportComplete, setExportComplete] = useState(false);
  const [exportName, setExportName] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [templateId, setTemplateId] = useState();
  const [includeCalls, setIncludeCalls] = useState(true);
  const [includeEmails, setIncludeEmails] = useState(true);
  const [includeOther, setIncludeOther] = useState(true);
  const [emailNotification, setEmailNotification] = useState(true);

  const handleExport = () => {
    setLoading(true);
    const dataExport = {
      name: exportName,
      startDate,
      endDate,
      connectDataExportTemplateId: templateId,
      includeCalls,
      includeEmails,
      includeOther,
      emailNotification
    };
    post(exportPath, { type: 'activity', dataExport }).then(() => {
      setLoading(false);
      setExportComplete(true);
    });
  };

  if (!show) return null;

  return (
    <React.Fragment>
      <Modal.Body>
        {exportComplete ? (
          <div>
            Activity export queued!&nbsp;
            Check your inbox or navigate to your <a href="/prospect/profile#exports">Profile Settings</a>&nbsp;
            to download your export
          </div>
        ) : (
          <React.Fragment>
            <Form.Group className="mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Export Name"
                value={exportName}
                onChange={e => setExportName(e.target.value)}
              />
            </Form.Group>
            <h6 className="mb-1 fw-bold">Export Options</h6>
            <Form.Group className="d-flex align-items-center gap-2">
              <div className="mb-2">
                <Form.Label className="mb-0">From</Form.Label>
                <div>
                  <DatePicker
                    className="form-control"
                    dateFormat="Pp"
                    maxDate={endDate}
                    selected={startDate}
                    onChange={setStartDate}
                  />
                </div>
              </div>
              <div>
                <Form.Label className="mb-0">To</Form.Label>
                <div>
                  <DatePicker
                    className="form-control"
                    dateFormat="Pp"
                    minDate={startDate}
                    selected={endDate}
                    onChange={setEndDate}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Type of Activity</Form.Label>
              <Form.Check
                checked={includeCalls}
                label="Export Phone Calls"
                type="checkbox"
                onChange={e => setIncludeCalls(e.target.checked)}
              />
              <Form.Check
                checked={includeEmails}
                label="Export Emails"
                type="checkbox"
                onChange={e => setIncludeEmails(e.target.checked)}
              />
              <Form.Check
                checked={includeOther}
                label="Export Other Activities"
                type="checkbox"
                onChange={e => setIncludeOther(e.target.checked)}
              />
            </Form.Group>
            {templates.length > 0 && (
              <Form.Group className="mb-2">
                <Form.Label>Export Template</Form.Label>
                <Form.Select value={templateId} onChange={e => setTemplateId(e.target.value)}>
                  <option value="">Select a template</option>
                  {templates.map(template => (
                    <option key={template.id} value={template.id}>{template.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            <Form.Group>
              <Form.Check
                checked={emailNotification}
                label="Email me when the export completes"
                type="checkbox"
                onChange={e => setEmailNotification(e.target.checked)}
              />
            </Form.Group>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        {exportComplete ? (
          <Button variant="success" onClick={onHide}>
            Done!
          </Button>
        ) : (
          <React.Fragment>
            <Button disabled={loading} variant="link" onClick={onHide}>
              Cancel
            </Button>
            <Button disabled={loading} variant="secondary" onClick={handleExport}>
              <FontAwesomeIcon icon={faFileExport}/>
              Begin Export
            </Button>
          </React.Fragment>
        )}
      </Modal.Footer>
    </React.Fragment>
  );
}

ActivityExport.propTypes = {
  exportPath: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  onHide: PropTypes.func.isRequired
};
