export const faBuildoutLogo = {
  icon: [
    // SVG viewbox width (in pixels)
    50,

    // SVG viewbox height (in pixels)
    50,

    // Aliases (not needed)
    [],

    // Unicode as hex value (not needed)
    '',

    // SVG path data
    'm26.445 49.867 10.479-6.048a3.845 3.845 0 0 0 1.921-3.328V22.509a3.832 3.832 0 0 0-1.921-3.328L26.92 13.409a3.865 3.865 0 0 0-3.851 0L12.59 19.457a.963.963 0 0 0 0 1.664l10.479 6.048a3.845 3.845 0 0 1 1.921 3.328V49.04a.964.964 0 0 0 1.445.837Zm20.769-8.768c1.236.713 2.786-.181 2.786-1.607V16.061c0-.685-.181-1.35-.514-1.921s-.818-1.065-1.407-1.407L37.505 6.628 26.93.514C26.331.171 25.666 0 25 0s-1.331.171-1.921.514L12.505 6.619 1.921 12.733A3.836 3.836 0 0 0 0 16.061v23.431c0 1.426 1.55 2.33 2.786 1.607s1.826-1.056 1.826-1.056c.599-.342.96-.979.96-1.664V19.284c0-.685.181-1.35.514-1.921s.818-1.065 1.407-1.407l7.788-4.498 7.788-4.498a3.856 3.856 0 0 1 3.842 0l7.788 4.498 7.788 4.498a3.836 3.836 0 0 1 1.921 3.328v19.095c0 .685.371 1.322.96 1.664l1.826 1.056Zm-30-7-1.037-.599a1.912 1.912 0 0 1-.96-1.664v-1.607a.964.964 0 0 1 1.445-.837l2.434 1.407c.599.342.96.979.96 1.664 0 1.455-1.579 2.368-2.843 1.636Zm0 7.293-1.037-.599a1.912 1.912 0 0 1-.96-1.664v-1.617a.964.964 0 0 1 1.445-.837l2.434 1.407c.599.342.96.979.96 1.674 0 1.455-1.579 2.368-2.843 1.636Z'
  ],
  iconName: 'buildout-logo',
  prefix: 'fak'
};

export const faConnectLogo = {
  icon: [
    // SVG viewbox width (in pixels)
    512,

    // SVG viewbox height (in pixels)
    512,

    // Aliases (not needed)
    [],

    // Unicode as hex value (not needed)
    '',

    // SVG path data
    'M372.8 355.3c-8.7-8.4-23.2-11.2-34.7-2.8c-8.7 5.6-17.4 11.2-26 16.9c-17.4 8.4-37.6 11.2-57.9 11.2c-34.7 0-66.6-14.1-86.8-39.4c-11.6-11.2-17.4-25.3-23.2-42.2c-5.8-14.1-8.7-30.9-8.7-47.8s2.9-33.7 8.7-47.8s14.5-28.1 23.2-42.2c11.6-11.2 23.2-22.5 37.6-28.1c17.4-5.6 34.7-8.4 52.1-8.4c20.3 0 37.6 2.8 55 11.2c8.7 2.8 17.4 8.4 23.2 16.9c0 0 0 0 2.9 0V130.4c0-14.1 5.8-28.1 20.3-39.4c-8.7-2.8-14.5-5.6-20.3-8.4C288.8 62.9 231 60 178.9 82.5C132.6 99.4 97.8 136 80.5 181C71.8 203.5 66 228.8 66 254.1s2.9 50.6 14.5 75.9c17.4 45 52.1 81.6 98.4 98.4c23.2 5.6 49.2 11.2 78.1 11.2c43.4 0 86.8-14.1 121.5-42.2c11.6-8.4 11.6-22.5 2.9-33.7l-8.7-8.4zm-8.7-225c0-14.1 11.6-25.3 26-25.3H422c14.5 0 26 11.2 26 25.3v30.9c0 14.1-11.6 25.3-26 25.3H390.1c-14.5 0-26-11.2-26-25.3V130.4z'
  ],
  iconName: 'connect-logo',
  prefix: 'fak'
};
