import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faBell } from '@fortawesome/free-solid-svg-icons';

export default function WaitForDocs() {
  return (
    <div className="text-center d-flex flex-column gap-6 my-2">
      <FontAwesomeIcon className="text-branding" icon={faBell} size="2x"/>
      <h5 className="mb-0">
        <strong>You'll be notified when documents are available</strong>
      </h5>
      <p className="mb-0">
        You're all set to view documents.
        You'll receive an email notification as soon as the documents are available for viewing.
      </p>
    </div>
  );
}
