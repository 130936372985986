import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';

import { setFilterAttribute } from '../../../store/actions/criteria';


function BuildingClassInput({
  criteria,
  setFilterAttribute
}) {
  return (
    <div>
      <Form.Check
        checked={criteria.buildingClassA || false}
        id="class_a"
        label="Class A"
        type="checkbox"
        onChange={e => setFilterAttribute('buildingClassA', e.target.checked)}
      />
      <Form.Check
        checked={criteria.buildingClassB || false}
        id="class_b"
        label="Class B"
        type="checkbox"
        onChange={e => setFilterAttribute('buildingClassB', e.target.checked)}
      />
      <Form.Check
        checked={criteria.buildingClassC || false}
        id="class_c"
        label="Class C"
        type="checkbox"
        onChange={e => setFilterAttribute('buildingClassC', e.target.checked)}
      />
    </div>
  );
}

BuildingClassInput.propTypes = {
  criteria: PropTypes.object.isRequired,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(BuildingClassInput);
