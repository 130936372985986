import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, InputGroup } from 'react-bootstrap-5';
import { setFilterAttribute } from '../../../store/actions/criteria';
import CriteriaDecorator from '../../../helpers/criteria_decorator';

function RangeInput({
  criteria,
  maxAttribute,
  minAttribute,
  percent,
  placeholder,
  addOn,
  setFilterAttribute
}) {
  const decoratedCriteria = new CriteriaDecorator(criteria);
  const minValue = decoratedCriteria[minAttribute] ? decoratedCriteria[minAttribute]() : criteria[minAttribute];
  const maxValue = decoratedCriteria[maxAttribute] ? decoratedCriteria[maxAttribute]() : criteria[maxAttribute];

  return (
    <div className="d-flex align-items-center">
      <InputGroup>
        { addOn && <InputGroup.Text>{addOn}</InputGroup.Text> }
        <Form.Control
          className={minValue ? 'active' : ''}
          placeholder={`Min ${placeholder}`}
          step={percent ? '0.1' : undefined}
          type={percent ? 'number' : undefined}
          value={minValue || ''}
          onChange={e => setFilterAttribute(minAttribute, e.target.value, { int: !percent, percent, debounce: true })}
        />
      </InputGroup>
      <div className="px-1">-</div>
      <InputGroup>
        { addOn && <InputGroup.Text>{addOn}</InputGroup.Text> }
        <Form.Control
          className={maxValue ? 'active' : ''}
          placeholder={`Max ${placeholder}`}
          step={percent ? '0.1' : undefined}
          type={percent ? 'number' : undefined}
          value={maxValue || ''}
          onChange={e => setFilterAttribute(maxAttribute, e.target.value, { int: !percent, percent, debounce: true  })}
        />
      </InputGroup>
    </div>
  );
}

RangeInput.propTypes = {
  addOn: PropTypes.element,
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  maxAttribute: PropTypes.string.isRequired,
  minAttribute: PropTypes.string.isRequired,
  percent: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(RangeInput);
