import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown } from '@fortawesome/pro-solid-svg-icons';
import ResultCardHighlights from './result_card_highlights';

export default function ResultSummary({ property }) {
  return (
    <div className="d-flex flex-column justify-content-md-between gap-3 gap-md-2 h-100">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex align-items-center flex-wrap">
          <span className="me-2 fs-base fw-bolder">
            {property.priceSummary.value}
          </span>
          {property.priceSummary.difference && (
            <span className="text-success fw-normal text-nowrap small">
              <FontAwesomeIcon className="me-1" icon={faArrowAltCircleDown}/>
              {property.priceSummary.difference}
            </span>
          )}
        </div>
        <div className="lh-sm">
          <div className="small text-muted">{property.addressLineOne}</div>
          <div className="small text-muted">{property.addressLineTwo}</div>
        </div>
      </div>
      <div className="small text-muted lh-sm">
        <ResultCardHighlights highlights={Object.values(property.attributesTable)} />
      </div>
    </div>
  );
}

ResultSummary.propTypes = {
  property: PropTypes.shape({
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string.isRequired,
    attributesTable: PropTypes.array.isRequired,
    priceSummary: PropTypes.shape({
      value: PropTypes.number.isRequired,
      difference: PropTypes.number,
    })
  }).isRequired,
};
