import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons';
import { Card } from 'react-bootstrap-5';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

export const STEP_TEXTS = {
  'custom_word': {
    step2: 'Annotate the CA using the document editor of your choice',
    step3: 'Upload your edited document here',
    step3Dragging: 'Drop your edited document here'
  },
  'custom_pdf': {
    step2: 'Sign the CA electronically with Adobe or your PDF software of choice',
    step3: 'Upload your signed document here',
    step3Dragging: 'Drop your signed document here'
  }
};

export default function PdfCa({ caFilePath, caType, uploadedFile, onFileUploaded }) {
  const onDrop = useCallback((acceptedFiles) => {
    onFileUploaded(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: caType === 'custom_word' ? ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
      : ['application/pdf'],
    maxFiles: 1
  });

  const parseStepText = step => STEP_TEXTS[caType]?.[step];

  return (
    <React.Fragment>
      <div className="mb-4 text-center">
        <h5 className="mb-2">
          <strong>Step 1</strong>
        </h5>
        <div>Download the CA using the following link</div>
        <a className="btn btn-primary mt-1" href={caFilePath} rel="noopener noreferrer" target="_blank">
          <FontAwesomeIcon className="me-1" icon={faCloudDownload}/> Download CA
        </a>
      </div>
      <div className="mb-4 text-center">
        <h5 className="mb-2">
          <strong>Step 2</strong>
        </h5>
        <div name="step2">{parseStepText('step2')}</div>
      </div>
      <div className="mb-4 text-center">
        <h5 className="mb-2">
          <strong>Step 3</strong>
        </h5>
        <Card className="clickable" {...getRootProps()} style={{ borderStyle: 'dashed' }}>
          <Card.Body className="p-4">
            <input {...getInputProps()} />
            {isDragActive && <div name="step3Dragging">{parseStepText('step3Dragging')}</div>}
            {!isDragActive && !uploadedFile && <div name="step3">{parseStepText('step3')}</div>}
            {!isDragActive && uploadedFile && (
              <div>
                <div>Uploaded <strong>{uploadedFile.name}</strong></div>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}

PdfCa.propTypes = {
  caFilePath: PropTypes.string.isRequired,
  caType: PropTypes.string.isRequired,
  uploadedFile: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired
};
