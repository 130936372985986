// Do not load this file as a separate bundle via `javascript_packs_with_chunks_tag` until the TODO below is resolved.
// Instead, import this file from other packs - but this isn't ideal because we need to remember to do that for new
// packs.
//
// TODO: Apparently only the first bundle to `javascript_packs_with_chunks_tag` can contain components that use hooks,
// so appending "global" will cause CookieManager to fail. We can't make "global" first, because it will break bundles
// like "application" (e.g. on pages: pipeline, database properties).

// Work around for https://github.com/shakacode/react_on_rails/issues/991
window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

import EuPrivacyPolicy from 'components/eu_privacy_policy';

try {
  ReactOnRails.getComponent('EuPrivacyPolicy');
} catch {
  // only if not registered yet
  ReactOnRails.register({ EuPrivacyPolicy });
}