import { ADD_ACTIVITY, REMOVE_ACTIVITY, SET_ACTIVITIES, UPDATE_ACTIVITY } from '../actions/activities';

export default function(state = [], action) {
  switch (action.type) {
  case ADD_ACTIVITY:
    return [...state, action.payload];
  case REMOVE_ACTIVITY:
    return state.filter(activity => activity.id !== action.payload);
  case UPDATE_ACTIVITY:
    return state.map(activity => activity.id === action.payload.id ? action.payload : activity);
  case SET_ACTIVITIES:
    return [...action.payload];
  default:
    return state;
  }
}
