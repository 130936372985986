import React from 'react';
import PropTypes from 'prop-types';

import PWInput from 'components/form/password_input';

export default function PasswordInput({ initialValue, inputName, pattern }) {
  const [val, setVal] = React.useState(initialValue || '');

  return (
    <PWInput inputName={inputName} pattern={pattern} requiredElement=" *" value={val} onChange={setVal}/>
  );
}

PasswordInput.propTypes = {
  initialValue: PropTypes.string,
  inputName: PropTypes.string,
  pattern: PropTypes.string
};
