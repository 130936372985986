import { UPLOADING_FILES, UPLOAD_COMPLETED, ERROR_UPLOADING_FILE } from "../actions";

export default (state = [], action) => {
  switch (action.type) {
  case UPLOADING_FILES: {
    return [...state, ...(action.fileUuids.map(uuid => ({ uuid, parentId: action.parentId })))];
  }
  case ERROR_UPLOADING_FILE:
  case UPLOAD_COMPLETED:
    return [...state].splice(1);
  default:
    return state;
  }
};