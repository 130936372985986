import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function EmptyState({ icon, title, text, iconSize }) {
  return (
    <div className="p-4 text-center">
      {icon && (
        <div className="my-4">
          <FontAwesomeIcon className="icon-gradient" icon={icon} size={iconSize || 'lg'} />
        </div>
      )}
      {title && <h6 className="mb-4 fw-bold">{title}</h6>}
      {text && <p className="mb-4 text-neutral">{text}</p>}
    </div>
  );
}

EmptyState.propTypes = {
  icon: PropTypes.object.isRequired,
  iconSize: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};
