import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

export function getNumber(str) {
  if (str == '') return null;

  const output = new Array();
  [...str].forEach((char) => {
    if (!isNaN(char)) output.push(char);
  });

  return Number(output.join(''));
}

function NumberWithCommasInput({ minValue, name, value, onChange, onValueChange, ...props }) {
  const handleChange = (event) => {
    const value = getNumber(event.target.value);
    event.target.value = value;

    if (!isUndefined(minValue) && value < minValue) return;

    onChange({ [name]: value }, event);
    onValueChange(value, event);
  };

  const handleFocus = (event) => {
    if (value === 0) {
      event.target.select();
    }
  };

  const valueWithCommas = () => {
    if (value == null) return '';

    return value.toLocaleString();
  };

  return (
    <input
      {...props}
      autoComplete="off"
      type="text"
      value={valueWithCommas()}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
}

NumberWithCommasInput.propTypes = {
  minValue: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};

export default NumberWithCommasInput;
