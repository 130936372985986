import ReactDOM from 'react-dom';

// For a given turbo frame, find all react on rails components and unmount them.
export function handleFrameUnload(frameElement) {
  const existingReactOnRailsComponents = frameElement.querySelectorAll('.js-react-on-rails-component');
  existingReactOnRailsComponents.forEach((element) => {
    const rootElement = frameElement.querySelector(`#${element.dataset.domId}`);

    if (rootElement) ReactDOM.unmountComponentAtNode(rootElement);
  });
}
