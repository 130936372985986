import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { get } from 'helpers/api';

export default class TextPreview extends Component {
  static propTypes = {
    file: PropTypes.shape({
      url: PropTypes.string
    })
  };

  state = {
    text: null
  };

  componentDidMount() {
    get(this.props.file.url).then(text => this.setState({ text: text }));
  }

  render() {
    const { text } = this.state;
    return (
      <div>
        {text ? text : "Loading..." }
        {/* <FontAwesomeIcon icon={faSpinner} size='2x' spin /> */}
      </div>
    );
  }
}
