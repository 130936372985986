import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap-5/Modal';

const CreateLeadErrorModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Oops!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          There was an error when sharing your information with the Brokerage.
          We have some rules that your profile might not fully comply.
        </p>
        <p></p>
        <p>Please contact support for more information</p>
      </Modal.Body>
    </Modal>
  );
};

CreateLeadErrorModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};

export default CreateLeadErrorModal;