import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faSpinner, faTrash, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import Dropzone from 'react-dropzone';
import {  Panel } from 'react-bootstrap';
import _ from 'lodash';

import FileSystem from 'components/file_system';
import UserIconList from 'components/user_icon_list';

import './deal_room.scss';
import EntryRow from './entry_row';
import RecycleBinRow from './recycle_bin_row';
import Breadcrumbs from './breadcrumbs';
import {
  uploadFiles,
  deleteEntries,
  loadDealRoom,
  openDealRoomSettings,
  openFileSearch,
  toggleSelectAllEntries,
  setSortOrder,
  closeFilePreview,
  moveEntries
} from '../actions/index';
import { undeletedChildren } from '../helpers/file_system';
import ActionsDropdown from './actions_dropdown';
import NewDropdown from './new_dropdown';
import { sortEntries, SORT_LOOKUP } from '../helpers/sorting';
import NameEditor from './name_editor';
import UsersModal from './users_modal';
import FileSearchBar from './file_search_bar';
import { currentDirAllSelected } from '../helpers/file_system';
import RenameDealRoomModal from './rename_deal_room_modal';

const mapDispatchToProps = {
  uploadFiles,
  deleteEntries,
  loadDealRoom,
  openDealRoomSettings,
  openFileSearch,
  toggleSelectAllEntries,
  setSortOrder,
  closeFilePreview,
  moveEntries
};

export class DealRoomContent extends React.Component {
  static propTypes = {
    closeFilePreview: PropTypes.func.isRequired,
    currentFolderId: PropTypes.number,
    dealRoom: PropTypes.shape({
      access: PropTypes.shape({
        contributor: PropTypes.bool.isRequired
      }).isRequired,
      editable: PropTypes.bool,
      name: PropTypes.string.isRequired
    }).isRequired,
    dealRoomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    deleteEntries: PropTypes.func.isRequired,
    dropdownButtonComponent: PropTypes.elementType.isRequired,
    dropdownComponent: PropTypes.elementType.isRequired,
    dropdownMenuItemComponent: PropTypes.elementType.isRequired,
    entriesById: PropTypes.object.isRequired,
    filePreviewOpen: PropTypes.bool,
    fileSearchEntryIds: PropTypes.array,
    fileSearchOpen: PropTypes.bool,
    inRecycleBin: PropTypes.bool,
    loadDealRoom: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    membersById: PropTypes.object.isRequired,
    moveEntries: PropTypes.func.isRequired,
    openDealRoomSettings: PropTypes.func.isRequired,
    openFileSearch: PropTypes.func.isRequired,
    previewFile: PropTypes.object,
    recycleBinRootEntries: PropTypes.array.isRequired,
    searchMatches: PropTypes.array,
    searchPerformed: PropTypes.bool,
    selected: PropTypes.array,
    setSortOrder: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    toggleSelectAllEntries: PropTypes.func.isRequired,
    uploadFiles: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }

  state = {
    dragSourceIndex: null,
    dropTargetIndex: null,
    showRenameModal: false
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyListener);
    this.props.loadDealRoom(this.props.dealRoomId);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyListener);
  }

  showRenameModal = () => this.setState({ showRenameModal: true })
  hideRenameModal = () => this.setState({ showRenameModal: false })

  contributorAccess = () => this.props.dealRoom.access.contributor

  sortedEntries = () => {
    const {
      entriesById,
      currentFolderId,
      inRecycleBin,
      recycleBinRootEntries,
      fileSearchOpen,
      searchMatches,
      sortOrder
    } = this.props;

    let entries;
    if (inRecycleBin) {
      entries = recycleBinRootEntries;
    } else if (fileSearchOpen) {
      entries = searchMatches;
    } else {
      entries = undeletedChildren(entriesById[currentFolderId], entriesById);
    }

    return sortEntries(entries, sortOrder);
  }

  handleBeginDrag = (dragSourceIndex) => {
    this.setState({ dragSourceIndex });
  }

  handleReorder = (_dragSourceIndex, dropTargetIndex) => {
    this.setState({ dropTargetIndex });
  }

  handleEndDrag = () => {
    if (this.props.inRecycleBin) return;

    const sorted = this.sortedEntries();
    const source = sorted[this.state.dragSourceIndex];
    const destination = sorted[this.state.dropTargetIndex];

    if (!destination && this.state.dropTargetIndex === sorted.length) {
      this.props.deleteEntries([source], !this.props.inRecycleBin);
    } else if (source && destination) this.props.moveEntries([source], destination.id);
  }

  sortOrder = () => {
    return SORT_LOOKUP[this.props.sortOrder];
  }

  previewFile = () => {
    const { previewFile } = this.props;
    const downloadUrl = `/deal_rooms/entries/${previewFile.id}`;
    return {
      ...previewFile,
      downloadUrl,
      url: `${downloadUrl}?preview=true`,
    };
  }

  setSortOrder = (key) => {
    if (!key) return;

    const sort = this.sortOrder();
    const reverse = sort.key == key ? !sort.reverse : false;

    this.props.setSortOrder({ key, reverse });
  }

  allSelected = () => {
    if (this.props.fileSearchOpen) {
      return _.every(this.props.selected, item => this.props.fileSearchEntryIds.includes(item.id)) &&
      _.every(this.props.fileSearchEntryIds, id => this.props.selected.map(item => item.id).includes(id));
    }
    return currentDirAllSelected(
      this.props.entriesById, this.props.currentFolderId, this.props.selected.map(item => item.id),
      this.props.recycleBinRootEntries.map(entry => entry.id), this.props.inRecycleBin
    );
  }

  keyListener = (e) => {
    if (e.key === 'Delete' && this.props.selected.length > 0) {
      this.props.deleteEntries(this.props.selected, !this.props.inRecycleBin);
    }
  }

  handleDrop = (files) => {
    this.props.uploadFiles(files, this.props.currentFolderId);
  }

  renderEmptyRecycleBin = () => {
    return (
      <div className="empty-recycle-bin">
        <FontAwesomeIcon icon={faTrash} size="2x" />
        <br />
        <span className="recycle-title">Recycle Bin is Empty</span>
        <br/>
        <span className="text">
          If you delete a file it will show up here instead of being permanently deleted
        </span>
      </div>
    );
  }

  renderEmptyFolderView = () => {
    const content = (
      <div className="empty-folder-view">
        <div>
          <FontAwesomeIcon icon={faFolderOpen} size="3x" />
        </div>
        This folder is currently empty
        {this.contributorAccess() && (
          <div className="small">
            Drag or click the upload button to add files
          </div>
        )}
      </div>
    );

    if (!this.contributorAccess()) return content;

    return (
      <Dropzone onDrop={this.handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section {...getRootProps()}>
            {content}
            <input {...getInputProps()} />
          </section>
        )}
      </Dropzone>
    );
  }

  renderEmptySearchView = () => {
    if (!this.props.searchPerformed) {
      return null;
    }
    return (
      <div className="text-muted w-100">
        Your search didn't match any entries
      </div>
    );
  }

  renderFileTable = () => {
    const {
      filePreviewOpen,
      entriesById,
      currentFolderId,
      inRecycleBin,
      recycleBinRootEntries,
      fileSearchOpen,
      dealRoom
    } = this.props;

    if (filePreviewOpen) return;
    const currentFolder = entriesById[currentFolderId];

    if (!inRecycleBin && undeletedChildren(currentFolder, entriesById).length === 0 &&
        (recycleBinRootEntries.length === 0 || currentFolderId !== dealRoom.rootEntryId)) {
      return this.renderEmptyFolderView();
    }

    if (inRecycleBin && recycleBinRootEntries.length === 0) return this.renderEmptyRecycleBin();

    const entries = this.sortedEntries();

    if (entries.length === 0 && fileSearchOpen) return this.renderEmptySearchView();

    const moveToParentId = currentFolder.parentId;
    const tableRows = this.sortedEntries().map((entry, idx) => {
      return (
        <EntryRow
          dropdownComponent={this.props.dropdownComponent}
          excludedColumns={dealRoom.editable ? [] : ['lastAccessed']}
          id={entry.id}
          index={idx}
          key={`entry-${entry.id}`}
          moveToParentId={moveToParentId}
          onBeginDrag={this.handleBeginDrag}
          onEndDrag={this.handleEndDrag}
          onReorder={this.handleReorder}
        />
      );
    });

    return (
      <React.Fragment>
        <FileSystem.Table
          allSelected={this.allSelected()}
          excludedColumns={dealRoom.editable ? [] : ['lastAccessed']}
          sort={this.sortOrder()}
          onHeaderClick={this.setSortOrder}
          onSelectAll={this.props.toggleSelectAllEntries}
        >
          {tableRows}
          {this.contributorAccess() && !inRecycleBin && recycleBinRootEntries.length > 0 &&
            currentFolderId === dealRoom.rootEntryId && !fileSearchOpen && (
            <RecycleBinRow
              dropdownComponent={this.props.dropdownComponent}
              excludedColumns={dealRoom.editable ? [] : ['lastAccessed']}
              index={tableRows.length}
              key="recycle-bin"
              recycleBinRootEntries={recycleBinRootEntries}
              onBeginDrag={this.handleBeginDrag}
              onEndDrag={this.handleEndDrag}
              onReorder={this.handleReorder}
            />
          )}
        </FileSystem.Table>
        {this.contributorAccess() && !inRecycleBin && (
          <Dropzone onDrop={this.handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <section {...getRootProps()}>
                <div className="p-3 file-dropzone">
                  <h4 className="text-center dz-message my-3">
                    <span className="text-muted">
                      Drag or click to add files
                    </span>
                  </h4>
                </div>
                <input {...getInputProps()} />
              </section>
            )}
          </Dropzone>
        )}
      </React.Fragment>
    );
  }

  renderBackButton = () => {
    if (this.props.filePreviewOpen) return;
    return (
      <strong className="clickable">
        <a href="#" onClick={this.props.onClose}>
          <FontAwesomeIcon className="mr-2 me-2" icon={faArrowLeft} />
          Back to All Deal Rooms
        </a>
      </strong>
    );
  }

  renderNavBar() {
    const { filePreviewOpen } = this.props;
    if (filePreviewOpen) return;

    return (
      <React.Fragment>
        <div className="py-0 deal-room-header row-eq-height">
          <Breadcrumbs />
        </div>
      </React.Fragment>
    );
  }

  renderTitle() {
    const { dealRoom } = this.props;

    if (!dealRoom.editable) return <NameEditor/>;
    return <div className="deal-room-name">{dealRoom.name}</div>;
  }

  renderHead = () => {
    const { filePreviewOpen, membersById } = this.props;
    const members = Object.values(membersById) || [];

    return (
      <div className="">
        <div className="py-0">
          {this.renderBackButton()}
          {!filePreviewOpen && (<React.Fragment>
            <div className="d-flex row-eq-height align-items-center deal-room-name-bar">
              <div className="grow flex-grow-1">
                {this.renderTitle()}
              </div>

              {members.length > 0 && (
                <div>
                  <UserIconList size="35px" users={members} />
                </div>
              )}
              {this.props.dealRoom.editable && (
                <div
                  className={`btn btn-link bold pr-0 ml-4 ${members.length > 0 ? 'border-left' : null}`}
                  name="manage-invite-link"
                  onClick={this.props.openDealRoomSettings}
                >
                  {members.length > 0 ? 'Manage Users' : 'Invite Users'}
                </div>
              )}
            </div>
          </React.Fragment>)}
        </div>
        <div className="container-fluid">
          <div className="py-3 row align-items-center row-eq-height">
            <div className="col-md-4 p-0">
              <FileSearchBar />
            </div>
            <div className="text-right text-end col-md-8">
              <ActionsDropdown onDealRoomClose={this.props.onClose} onRenameDealRoom={this.showRenameModal} />
              <NewDropdown
                dropdownButtonComponent={this.props.dropdownButtonComponent}
                dropdownMenuItemComponent={this.props.dropdownMenuItemComponent}
              />
            </div>
          </div>
        </div>
        {this.renderNavBar()}
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="text-center my-5">
          <FontAwesomeIcon icon={faSpinner} size="2x" spin />
        </div>
      );
    }
    if (this.props.filePreviewOpen) {
      return (
        <Panel>
          <Panel.Body>
            <FileSystem.Preview file={this.previewFile()} onExit={this.props.closeFilePreview} />
          </Panel.Body>
        </Panel>
      );
    }
    return (
      <div className="deal-room" id="deal-room">
        {this.renderHead()}
        {this.renderFileTable()}
        {this.props.dealRoom.editable && <UsersModal/>}
        <RenameDealRoomModal
          shown={this.state.showRenameModal}
          onHide={this.hideRenameModal}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  filePreview: { open, entryId },
  fileSearch,
  ui: { loading, currentFolderId, sortOrder, recycleSortOrder, inRecycleBin },
  recycleBin: { entryIds },
  dealRoom,
  membersById,
  entriesById,
  selected
}) => ({
  currentFolderId,
  dealRoom,
  membersById,
  entriesById,
  loading,
  recycleBinRootEntries: entryIds.map(id => entriesById[id]),
  sortOrder: inRecycleBin ? recycleSortOrder : sortOrder,
  inRecycleBin,
  fileSearchOpen: fileSearch.open,
  fileSearchEntryIds: fileSearch.entryIds,
  searchMatches: fileSearch.entryIds.map(id => entriesById[id]),
  selected: selected.entryIds.map(id => entriesById[id]),
  filePreviewOpen: open,
  previewFile: entriesById[entryId],
  searchPerformed: fileSearch.searchPerformed
});

export default connect(mapStateToProps, mapDispatchToProps)(DealRoomContent);
