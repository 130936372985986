import { keyBy } from 'lodash';

import {
  SET_VALUATION_ADJUSTMENTS,
  RESET_VALUATION_ADJUSTMENTS,
  LOAD_VALUATION_ADJUSTMENTS,
} from '../actions/valuation_adjustments';

export default function (state = {}, action) {
  switch (action.type) {
  case LOAD_VALUATION_ADJUSTMENTS: {
    const valuationAdjustments = keyBy(action.payload, 'insightsPropertyId');
    window.CONNECT_VALUATION_ADJUSTMENTS = valuationAdjustments;

    return valuationAdjustments;
  }
  case SET_VALUATION_ADJUSTMENTS: {
    const newState = {
      ...state,
      [action.payload.insightsPropertyId]: action.payload,
    };
    window.CONNECT_VALUATION_ADJUSTMENTS = newState;

    return newState;
  }
  case RESET_VALUATION_ADJUSTMENTS: {
    const newState = { ...state };
    delete newState[action.payload];
    window.CONNECT_VALUATION_ADJUSTMENTS = newState;

    return newState;
  }
  default:
    return state;
  }
}
