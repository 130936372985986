import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['element', 'popper'];
  static values = {
    placement: { type: String, default: 'bottom' },
    offset: { type: Array, default: [0, 8] },
    animateIn: String
  };

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.popperTarget, this.withEventListenerModifier({
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offsetValue,
          }
        }
      ]
    }, false));

    document.addEventListener('click', this.handleClickOutside.bind(this));
  }

  hide() {
    this.popperTarget.classList.add('d-none');
    this.popperInstance.setOptions(options => this.withEventListenerModifier(options, false));
  }

  show() {
    this.popperTarget.classList.remove('d-none');
    if (this.animateInValue) {
      this.popperTarget.classList.add('animate__animated', 'animate__faster', this.animateInValue);
    }
    this.popperInstance.setOptions(options => this.withEventListenerModifier(options, true));

  }

  toggle() {
    this.popperTarget.classList.contains('d-none') ? this.show() : this.hide();
  }

  handleClickOutside(e) {
    if (!this.popperTarget.classList.contains('d-none') && !this.element.contains(e.target)) {
      this.hide();
    }
  }

  withEventListenerModifier(options, enabled) {
    return {
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled },
      ],
    };
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }
}
