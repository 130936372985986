import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import { connect } from 'react-redux';
import PreviewPortal from './index';

function VerifyEmailPortal({ nextStep, profile, setProfileData, onNext }) {
  function VerifiedEmail() {
    const updatedProfile = { ...profile, [PROFILE_OPTIONS.EMAIL_VERIFIED]: true };

    setProfileData(updatedProfile);
    onNext(nextStep, false, { previewProfile: updatedProfile });
  }

  return (
    <PreviewPortal>
      <Button variant="primary" onClick={VerifiedEmail}>Verify Email</Button>
    </PreviewPortal>
  );
}


VerifyEmailPortal.propTypes = {
  nextStep: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPortal);
