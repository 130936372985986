import React from 'react';
import PropTypes from 'prop-types';
import ContactSection from './owners/contact_section';

export default function ContactInformation({ contact }) {
  return (
    <div className="d-flex flex-column gap-3">
      {contact.phoneNumbers.length > 0 && (
        <ContactSection defaultOpen={true} title="Phone Number">
          {contact.phoneNumbers.map(phoneNumber => (
            <div className="d-flex align-items-center flex-wrap" key={`phone_number_${phoneNumber.id}`}>
              <a className="privacy-mask" href={`tel:${phoneNumber.number}`} target="_top">{phoneNumber.formatted}</a>
              <small className="ms-1 text-muted">{phoneNumber.label}</small>
            </div>
          ))}
        </ContactSection>
      )}
      {contact.emails.length > 0 && (
        <ContactSection defaultOpen={true} title="Email Address">
          {contact.emails.map(email => (
            <div key={`email_${email.id}`}>
              <a
                className="text-truncate privacy-mask"
                href={`mailto:${email.email}`}
                target="_top"
              >
                {email.email}
              </a>
            </div>
          ))}
        </ContactSection>
      )}
      {contact.addresses.length > 0 && (
        <ContactSection defaultOpen={false} title="Address">
          {contact.addresses.map(address => (
            <div className="privacy-mask" key={`address_${address.id}`}>
              <div>{address.twoLine[0]}</div>
              <div>{address.twoLine[1]}</div>
            </div>
          ))}
        </ContactSection>
      )}
    </div>
  );
}

ContactInformation.propTypes = {
  contact: PropTypes.object.isRequired
};


