import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Dropdown } from 'react-bootstrap-5';

import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import { EditModal } from '../call_list/owners/edit_modal';

export default function ActionsDropdown({ contact, onDelete, onUpdate }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const target = useRef(null);

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted" ref={target}>
          <FontAwesomeIcon className="clickable ps-4 pe-2 py-1" icon={faEllipsisVertical}/>
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-0">
          <Dropdown.Item className="border-top-0" onClick={() => setShowEditModal(true)}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faPencil}/>
            Edit
          </Dropdown.Item>
          <Dropdown.Item className="border-bottom-0" onClick={onDelete}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {contact && (
        <EditModal
          initialContact={contact}
          show={showEditModal}
          updateContact={onUpdate}
          onHide={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}

ActionsDropdown.propTypes = {
  contact: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
