import _ from 'lodash';

export const ENTRY_TYPES = {
  FOLDER: 'folder',
  FILE: 'file'
};

export const isFolder = (entry) => {
  return entry.entryType === ENTRY_TYPES.FOLDER;
};

export const currentDirAllSelected = (
  entriesById, currentDirId, selectedEntryIds, recycleBinRootEntryIds, inRecycleBin
) => {
  if (inRecycleBin) {
    return selectedEntryIds.length === recycleBinRootEntryIds.length;
  }
  const currDirEntries = undeletedChildren(entriesById[currentDirId], entriesById);
  return currDirEntries.length === selectedEntryIds.length;
};

export const findUniqueName = (originalName, siblingNames) => {
  siblingNames = siblingNames.map(x => x.toLowerCase());
  const extensionMatch = originalName.match(/^(.*)(\.[^.]*)$/); // originalName -> (fullName)(extension)
  const buildName = (i) => {
    if (i === 0) {
      return originalName;
    }
    const addExtension = (str) => {
      if (extensionMatch) {
        return str + extensionMatch[2];
      }
      return str;
    };
    const noExtensionName = extensionMatch ? extensionMatch[1] : originalName;
    const subMatch = noExtensionName.match(/^(.*)\([0-9]+\)$/); // noExtensionName -> name(duplicate number)
    if (!subMatch) {
      return addExtension(`${noExtensionName} (${i})`);
    }
    return addExtension(`${subMatch[1]}(${i})`);
  };
  let i = 0;
  while (siblingNames.includes(buildName(i).toLowerCase())) {
    i++;
  }
  return buildName(i);
};

export const allUploadsCompleted = (uploads) => {
  return _.every(uploads, upload => upload.uploadCompleted || upload.uploadFailed);
};

export const allChildren = (folder, entriesById) => {
  return folder.entryIds.map(id => entriesById[id]);
};

export const deletedChildren = (folder, entriesById) => {
  return allChildren(folder, entriesById).filter(entry => entry.recycledAt);
};

export const undeletedChildren = (folder, entriesById) => {
  return allChildren(folder, entriesById).filter(entry => !entry.recycledAt);
};
