import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pluralize from 'pluralize';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

import { loadValuationAdjustments } from '../store/actions/valuation_adjustments';
import { formatWithCommas } from 'helpers/formatters/number_formats';

import OffMarketResultCard from './results/off_market_result_card';
import { EmptyState } from './results/empty_state';
import Paginator from './results/paginator';
import EmptyStateBadges from './results/empty_state_badges';
import ProductFeature from './product_feature';
import AddToList from '../../properties/add_to_list';
import { PROPERTY_MODEL } from '../../../constants';

function OffMarketResults({
  allOffMarketResults,
  allResults,
  containerRef,
  criteria,
  loading,
  loadValuationAdjustments,
  total
}) {
  const [selectedPropertyIds, setSelectedPropertyIds] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedPropertyIds(new Set());
    setSelectAll(false);
  }, [allOffMarketResults]);

  useEffect(() => {
    loadValuationAdjustments();
  }, []);

  const handleSelectToggle = (propertyId) => {
    if (selectAll) {
      setSelectAll(false);
      const ids = new Set();
      allOffMarketResults.forEach((result) => {
        if (propertyId !== result.propertyId) ids.add(result.propertyId);
      });

      setSelectedPropertyIds(ids);
    } else if (selectedPropertyIds.has(propertyId)) {
      setSelectedPropertyIds(prev => new Set([...prev].filter(id => id !== propertyId)));
    } else {
      setSelectedPropertyIds(previousState => new Set([...previousState, propertyId]));
    }
  };

  const handleToggleAllSelected = () => {
    setSelectedPropertyIds(new Set());
    setSelectAll(!selectAll);
  };

  if (!allResults) {
    return (
      <div className="text-center py-4">
        <FontAwesomeIcon beatFade className="text-primary" icon={faBuildoutLogo} size="3x" />
      </div>
    );
  }

  if (allResults.length === 0) {
    return (
      <div>
        <EmptyState subtitle="Expand your search to find matching, off market properties" title="No matches" />
        <EmptyStateBadges />
      </div>
    );
  }

  return (
    <div>
      {total && (
        <div className="p-3 d-flex justify-content-between position-sticky top-0 z-1 bg-white shadow-md">
          <div className="fw-semibold">{formatWithCommas(total)} {pluralize('property', total)}</div>
          <ProductFeature product="prospecting">
            <div className="d-flex gap-4 align-items-center">
              <label>
                <div className="d-flex gap-2">
                  <input
                    checked={selectAll}
                    data-pendo-tag="results_select_all_checkbox"
                    type="checkbox"
                    onChange={handleToggleAllSelected}
                  />
                  <div>Select All</div>
                </div>
              </label>
              {(selectedPropertyIds.size > 0 || selectAll) && (
                <AddToList
                  criteria={criteria}
                  itemCount={selectAll ? total : selectedPropertyIds.size}
                  itemIds={selectedPropertyIds}
                  itemType={PROPERTY_MODEL}
                  selectAll={selectAll}
                >
                  <FontAwesomeIcon className="text-primary clickable" icon={faRectangleHistoryCirclePlus} />
                </AddToList>
              )}
              {selectedPropertyIds.size === 0 && !selectAll && (
                <FontAwesomeIcon className="text-muted" icon={faRectangleHistoryCirclePlus} />
              )}
            </div>
          </ProductFeature>
        </div>
      )}
      <div className={`mt-1 p-3 transition-opacity ${loading ? 'opacity-75' : 'opacity-100'}`}>
        {allResults.map(result => (
          <OffMarketResultCard
            isSelected={selectAll || selectedPropertyIds.has(result.propertyId)}
            key={result.propertyId}
            result={result}
            onSelectToggle={handleSelectToggle}
          />
        ))}
      </div>

      <div className="d-flex justify-content-center mb-4">
        <Paginator containerRef={containerRef} />
      </div>
    </div>
  );
}

OffMarketResults.propTypes = {
  allOffMarketResults: PropTypes.arrayOf(PropTypes.shape({
    propertyId: PropTypes.string.isRequired
  })),
  allResults: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  containerRef: PropTypes.object,
  criteria: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  loadValuationAdjustments: PropTypes.func.isRequired,
  total: PropTypes.number
};

const mapStateToProps = ({
  criteria,
  results: {
    offMarketResults,
    totalOffMarketResults,
    allOffMarketResults
  },
  ui: { loading }
}) => {
  return {
    allOffMarketResults,
    allResults: offMarketResults,
    criteria,
    total: totalOffMarketResults,
    loading
  };
};
export default connect(mapStateToProps, { loadValuationAdjustments })(OffMarketResults);
