import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function InvalidRegion({ listingUrl }) {
  return (
    <div className="d-flex flex-column gap-3 align-items-center py-2">
      <div className="text-branding">
        <FontAwesomeIcon icon={faGlobe} size="2x"/>
      </div>
      <h5 className="m-0">
        <strong>Unable to access locked documents</strong>
      </h5>
      <p className="m-0">We are unable to show you this document based on your region.</p>
      <a href={listingUrl}>View Property</a>
    </div>
  );
}

const mapStateToProps = ({ ui: { listingUrl } }) => ({ listingUrl });

InvalidRegion.propTypes = {
  listingUrl: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(InvalidRegion);
