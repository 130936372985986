import React from 'react';
import PropTypes from 'prop-types';

import DealRoom from 'bundles/deal_rooms/components/deal_room';
import { getProjectDetails } from 'bundles/deal_rooms/api';
import GenericDropdown from 'components/bootstrap_extensions/bs5/dropdown';

import { DropdownButton, Dropdown } from 'react-bootstrap-5';

export default class ShowDealRoom extends React.Component {
  static propTypes = {
    closePath: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    profileId: PropTypes.number.isRequired,
    rootPath: PropTypes.string.isRequired
  }

  state = {}

  closeDealRoom = () => {
    window.location.href = this.props.closePath;
  }

  componentDidMount() {
    getProjectDetails(this.props.id).then(project => this.setState({ project }));
  }

  render() {
    return (
      <DealRoom
        dropdownButtonComponent={DropdownButton}
        dropdownComponent={GenericDropdown}
        dropdownMenuItemComponent={Dropdown.Item}
        id={this.props.id}
        profileId={this.props.profileId}
        onClose={this.closeDealRoom}
      />
    );
  }
}
