import { get } from 'helpers/api';

const DEFAULT_SEARCH_PARAMS = {
  'polygon_geojson': '1',
  'polygon_threshold': '0.001',
  'format': 'json',
  'limit': '1'
};

export function boundsFromMultiplePolygons(polygons, bounds = new google.maps.LatLngBounds()) {
  polygons.forEach((polygon) => {
    bounds.union(boundsFromPolygon(polygon));
  });

  return bounds;
}

export function boundsFromPolygon(coords, bounds = new google.maps.LatLngBounds()) {
  coords.forEach(coord => bounds.extend(coord));

  return bounds;
}

export function boundsFromGeoJson(geoJson, bounds = new google.maps.LatLngBounds()) {
  if (geoJson.type == 'Polygon') {
    geoJson.coordinates.forEach(coords => coords.forEach(([lng, lat]) => bounds.extend({ lat, lng })));
  } else if (geoJson.type == 'MultiPolygon') {
    geoJson.coordinates.forEach((shape) => {
      shape.forEach(coords => coords.forEach(([lng, lat]) => bounds.extend({ lat, lng })));
    });
  }

  return bounds;
}

export function nominatimGetUrl(url, params) {
  const searchParams = new URLSearchParams({
    ...DEFAULT_SEARCH_PARAMS,
    ...params
  }).toString();

  return `${url}search?${searchParams}`;
}

function nominatimQueryParams({ types, address }) {
  if (types.includes('administrative_area_level_1')) {
    // State level area
    return { state: address.replace(', USA', '') };
  } else {
    return { q: address };
  }
}

export function fetchGeoJSONFromNominatim(url, details, options) {
  // console.log({ url, details, options });
  get(nominatimGetUrl(url, nominatimQueryParams(details))).then((data) => {
    if (data && data.length > 0 && data[0].geojson && ['Polygon', 'MultiPolygon'].includes(data[0].geojson.type)) {
      return options.successCallback(data[0].geojson);
    }

    options.noResultCallback();
  });
}

export function placeBounds(place) {
  if (place.geometry.viewport) {
    return polygonFromBounds(place.geometry.viewport);
  } else {
    const circle = new google.maps.Circle({
      center: place.geometry.location,
      radius: 8046.72 // 5 miles
    });

    return polygonFromBounds(circle.getBounds());
  }
}

export const polygonFromBounds = (bounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return [
    { lat: ne.lat(), lng: ne.lng() },
    { lat: sw.lat(), lng: ne.lng() },
    { lat: sw.lat(), lng: sw.lng() },
    { lat: ne.lat(), lng: sw.lng() }
  ];
};

export function getDetailsOfPlace(
  query,
  placesService = new google.maps.places.PlacesService(document.createElement('div'))
) {
  return new Promise((resolve, reject) => {
    if (!query.placeId) reject('Property ID required for details');
    placesService.getDetails(query, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(place);
      } else {
        reject(status);
      }
    });
  });
}

// fullAddress is place name from google autocomplete, usually in the format of
// ADDRESS, CITY, STATE, COUNTRY
export function splitFullAddress(fullAddress) {
  return fullAddress.split(',').map(text => text.trim());
}
