import React from 'react';
import PropTypes from 'prop-types';

import CurrencyInput from 'components/form/currency_input_wrapper';

class ManagedCurrencyInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired
  }

  handleChange = (event, _, value) => {
    this.props.onChange({ [this.props.name]: value }, event);
    this.props.onValueChange(value, event);
  }

  render() {
    const { onChange, onValueChange, ...props } = this.props;

    return (
      <CurrencyInput
        {...props}
        onChangeEvent={this.handleChange}
      />
    );
  }
}

export default ManagedCurrencyInput;
