import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { closeFlashMessage } from '../actions';

// how many ms to show the flash message before automatically hiding it
const AUTO_HIDE_DELAY = 6000;

class FlashMessage extends React.Component {
  static propTypes = {
    closeFlashMessage: PropTypes.func.isRequired,
    id: PropTypes.number,
    isErrorMessage: PropTypes.bool,
    message: PropTypes.string,
    show: PropTypes.bool
  }

  state = {
    fading: false
  }

  resetCloseTimeout = () => {
    this.setState({ fading: false });
    this.fadeTimeout = null;
    const currId = this.props.id;
    setTimeout(() => this.close(currId), AUTO_HIDE_DELAY);
  }

  componentDidMount() {
    if (this.props.show) {
      this.resetCloseTimeout();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.id !== this.props.id) {
      this.resetCloseTimeout();
    }
  }

  close = (id) => {
    if (id !== this.props.id) {
      return;
    }
    const ANIMATION_DURATION = 200;
    this.setState({ fading: true });
    setTimeout(() => {
      this.props.closeFlashMessage(id);
      this.setState({ fading: false });
    }, ANIMATION_DURATION);
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div
        className={classNames(
          "deal-room-flash p-3",
          { 'fading': this.state.fading },
          { 'error-flash': this.props.isErrorMessage }
        )}
      >
        <div className="flash-text">
          {this.props.message}
        </div>
        <FontAwesomeIcon className="close-flash-icon ml-3" icon={faTimes}
          onClick={() => this.close(this.props.id)}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ flashMessage: { show, message, id, isErrorMessage } }) => {
  return {
    show,
    message,
    id,
    isErrorMessage
  };
};
const mapDispatchToProps = { closeFlashMessage };
export default connect(mapStateToProps, mapDispatchToProps)(FlashMessage);
