export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const PROFILE_OPTIONS = {
  ACCESS_LEVEL: 'access_level',
  WAITLISTED: 'waitlisted',
  EMAIL_VERIFIED: 'email_verified',
  CA_SIGNED: 'ca_signed',
  PROFILE_PRESENT: 'profile_present',
  VALID_LOGIN: 'valid_login'
};

export function setProfileData(profileData) {
  return {
    type: SET_PROFILE_DATA,
    payload: profileData
  };
}
