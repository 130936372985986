import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  followLink(event) {
    const url = this.element.dataset.url;

    if (event.metaKey) {
      window.open(url);
    } else {
      if (typeof Turbo !== 'undefined') {
        Turbo.visit(url);
      } else {
        window.document.location = url;
      }
    }
  }
}
