import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class VideoPreview extends Component {
  static propTypes = {
    file: PropTypes.shape({
      url: PropTypes.string
    })
  };

  render() {
    return (
      <div>
        <video className="w-100 bg-white shadow-sm" controls>
          <source src={this.props.file.url}/>
        </video>
      </div>
    );
  }
}
