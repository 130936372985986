import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';

import { setFilterAttribute } from '../../../store/actions/criteria';

function TextInput({ criteria, attribute, placeholder, setFilterAttribute, type }) {
  return (
    <Form.Control
      className={criteria[attribute] ? 'active' : ''}
      placeholder={placeholder}
      type={type ? type : 'text'}
      value={criteria[attribute] || ''}
      onChange={e => setFilterAttribute(attribute, e.target.value)}
    />
  );
}

TextInput.propTypes = {
  attribute: PropTypes.string.isRequired,
  criteria: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  setFilterAttribute: PropTypes.func.isRequired,
  type: PropTypes.string
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(TextInput);
