import PropTypes from 'prop-types';
import React from 'react';

export function getErrorsForInput(errors, errorString) {
  const filteredErrors = Array.isArray(errorString)
    ? errors.filter(error => errorString.some(str => error.includes(str)))
    : errors.filter(error => error.includes(errorString));

  return filteredErrors;
}

export default function ErrorsForInput({ errors, errorString }) {
  const errorsForInput = getErrorsForInput(errors, errorString);
  if (errorsForInput.length === 0) return null;

  return (
    <div className="mt-2 d-flex flex-column gap-1">
      {errorsForInput.map(error => (
        <small className="text-danger" key={error}>{error}</small>
      ))}
    </div>
  );
}

ErrorsForInput.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  errorString: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired
};
