import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';

import LlcTitleCard from '../llc_title_card';
import { fetchCompanyDetails } from '../api';
import LlcDetails from '../llc_details';

export default function LlcSearchResult({ owner }) {
  const [llcData, setLlcData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDetails = () => {
    setLoading(true);
    fetchCompanyDetails(owner.companyNumber, owner.jurisdictionCode).then((data) => {
      setLlcData(data);
      setLoading(false);
    });
  };

  const button = (
    <Button disabled={loading} variant="outline-primary" onClick={handleDetails}>Get Details</Button>
  );

  return (
    <div className="py-2">
      {llcData ? (
        <LlcDetails llcData={llcData} />
      ) : (
        <LlcTitleCard button={button} llcData={owner} />
      )}
    </div>
  );
}

LlcSearchResult.propTypes = {
  owner: PropTypes.shape({
    companyNumber: PropTypes.string.isRequired,
    jurisdictionCode: PropTypes.string.isRequired
  })
};
