import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setHoverListingId } from '../../store/actions/ui';

import { useGoogleMap } from '@react-google-maps/api';
import { PROPERTY_TYPE_ICON } from 'helpers/icon_helpers';
import { setExclusiveListingId } from '../../helpers/navigation_helper';

function highlight(markerView) {
  markerView.content.classList.add('highlight');
  markerView.element.style.zIndex = 1;
}

function unhighlight(markerView) {
  markerView.content.classList.remove('highlight');
  markerView.element.style.zIndex = 0;
}

function ConnectListingMapMarker({
  isHovering,
  largeIcon,
  result,
  saleListingId,
  setHoverListingId
}) {
  const map = useGoogleMap();
  const marker = useRef();

  useEffect(() => {
    const markerElement = document.createElement('div');
    markerElement.classList.add('property-type-marker', 'property-type-marker-primary', largeIcon ? 'large' : null);

    const icon = PROPERTY_TYPE_ICON[result.propertyTypeId] || PROPERTY_TYPE_ICON[0];

    markerElement.innerHTML = largeIcon && icon ? `<i aria-hidden="true" class="fa-solid ${icon} icon"></i>` : '';
    markerElement.innerHTML += `<div class="marker-shadow"></div>`;

    const position = { lat: parseFloat(result.location.lat), lng: parseFloat(result.location.lon) };

    const advancedMarkerView = new google.maps.marker.AdvancedMarkerElement({
      map,
      position: position,
      content: markerElement
    });
    marker.current = advancedMarkerView;
    const element = advancedMarkerView.element;

    ['focus', 'pointerenter'].forEach((eventName) => {
      element.addEventListener(eventName, () => {
        setHoverListingId(saleListingId);
      });
    });

    ['blur', 'pointerleave'].forEach((eventName) => {
      element.addEventListener(eventName, () => {
        setHoverListingId();
      });
    });

    advancedMarkerView.addListener('click', () => {
      setHoverListingId();
      setExclusiveListingId(saleListingId);
    });

    return () => {
      marker.current.map = null;
    };
  }, [map]);

  useEffect(() => {
    if (!marker.current) return;

    if (isHovering) {
      highlight(marker.current);
    } else {
      unhighlight(marker.current);
    }

  }, [isHovering]);

  return null;
}

ConnectListingMapMarker.propTypes = {
  isHovering: PropTypes.bool.isRequired,
  largeIcon: PropTypes.bool.isRequired,
  saleListingId: PropTypes.number.isRequired,
  setHoverListingId: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  results: { allResults },
  ui: { hoverSaleListingId, mapZoomReadonly }
}, ownProps) => ({
  isHovering: hoverSaleListingId === ownProps.saleListingId,
  largeIcon: mapZoomReadonly >= 15 || (allResults || []).length <= 250
});
const mapDispatchToProps = { setHoverListingId };

export default connect(mapStateToProps, mapDispatchToProps)(ConnectListingMapMarker);
