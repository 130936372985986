import { combineReducers } from 'redux';

import navigation from './navigation';
import file from './file';
import ui from './ui';
import preview from './preview';

const reducers = combineReducers({
  navigation,
  file,
  preview,
  vault: state => state || {},
  ui
});

export default reducers;
