import { isArray, isEmpty } from 'lodash';
import { EXCLUSIVE_LISTING_FILTERS, OFF_MARKET_FILTERS } from '../components/filters/constants';

export const trackEvent = (eventName, params = {}) => {
  if (window.gtag) window.gtag('event', eventName, params);
};

export const trackFilters = (offMarketSearch, criteria) => {
  const filters = offMarketSearch ? OFF_MARKET_FILTERS : EXCLUSIVE_LISTING_FILTERS;

  filters.forEach((filter) => {
    filter.keys.forEach((filterKey) => {
      if (criteria.hasOwnProperty(filterKey)) {
        trackFilter(offMarketSearch, filterKey, criteria[filterKey]);
      }
    });
  });
};

const trackFilter = (offMarketSearch, key, value) => {
  const prefix = offMarketSearch ? 'connect_off_market_filter_' : 'connect_exclusive_listings_filter';
  if (isArray(value)) {
    if (!isEmpty(value)) {
      trackEvent((prefix + key), { value });
    }
  } else if (value) {
    trackEvent((prefix + key), { value });
  }
};
