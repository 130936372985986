import { PROPERTY_TYPE_ICON } from 'helpers/icon_helpers';
import { numberShorthand } from 'helpers/formatters/number_formats';

export const customPropertyMarkerContent = (
  propertyTypeId,
  largeIcon,
  restricted = false,
  adjustedValuation = null,
  valuationEstimate = null
) => {
  const markerElement = document.createElement('div');
  markerElement.classList.add(
    'property-type-marker',
    largeIcon ? 'large' : null,
    restricted ? 'property-type-marker-restricted' : null
  );

  const icon = restricted ?
    'fa-lock' :
    propertyTypeId ? PROPERTY_TYPE_ICON[propertyTypeId] : PROPERTY_TYPE_ICON[0];

  const valuationEst = adjustedValuation || valuationEstimate;

  if (largeIcon) {
    markerElement.innerHTML = `
      <div class="d-flex gap-1 align-items-center text-white">
        ${icon ? `<i aria-hidden="true" class="fa-solid ${icon} icon"></i>` : ''}
        ${valuationEst ? `$${numberShorthand(valuationEst)}` : ''}
      </div>
    `;
  }

  markerElement.innerHTML += `<div class="marker-shadow"></div>`;

  return markerElement;
};
