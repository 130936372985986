import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Modal } from 'react-bootstrap';
import Button from '../shared/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

export default function EuPrivacyPolicy({ companyName, cookieName }) {
  const [isModalOpen, setIsModalOpen] = useState(hasAcceptedPrivacyPolicy);

  function hasAcceptedPrivacyPolicy() {
    const cookie = Cookies.get(cookieName);
    return !cookie;
  }

  function acceptPrivacyPolicy() {
    setIsModalOpen(false);
    Cookies.set(cookieName, 'true', { expires: 365 });
  }

  return (
    <Modal dialogClassName="modal-sm" show={isModalOpen}>
      <Modal.Body className="p-5">
        <div className="text-center">
          <FontAwesomeIcon className="icon-gradient" icon={faCircleInfo} size="4x"/>
        </div>
        <h2 className="text-center my-5"><b>Your Privacy is important to us</b></h2>
        <p style={{ fontSize: '15px' }}>
          Buildout is committed to protecting and respecting your privacy in the US. We will only use your
          personal information to administer your account and to provide services you request from us.
          For more information on our privacy practices and how we are committed to protecting your
          personal information, please review our {' '}
          <a href="https://www.buildout.com/terms-of-use" rel="noopener noreferrer" target="_blank">privacy policy</a>
        </p>
        <p className="my-5" style={{ fontSize: '15px' }}>Contact {companyName} if you have any concerns</p>
        <div className="text-center my-5">
          <Button.Primary
            name="privacy-agree"
            type="button"
            onClick={acceptPrivacyPolicy}
          >
            I Agree and accept
          </Button.Primary>
        </div>
        <a
          className="d-block text-center"
          href="https://share.hsforms.com/1Dkqlus8cQv20XNq6q5obAA4rjlf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Do not sell or share my personal information
        </a>
      </Modal.Body>
    </Modal>
  );
}

EuPrivacyPolicy.propTypes = {
  companyName: PropTypes.string.isRequired,
  cookieName: PropTypes.string.isRequired
};
