import { useState, useEffect, useMemo } from 'react';

const useOnScreen = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)));

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.unobserve(ref.current);
  }, []);

  return isIntersecting;
};

export default useOnScreen;
