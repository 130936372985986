import { combineReducers } from 'redux';
import actionQueues from './action_queues';
import alert from './alert';
import contactsAndLeads from './contacts_and_leads';
import dealRoom from './deal_room';
import entriesById from './entries_by_id';
import filePreview from './file_preview';
import fileSearch from './file_search';
import flashMessage from './flash_message';
import membersById from './members_by_id';
import recycleBin from './recycle_bin';
import selected from './selected';
import transactionUuids from './transaction_uuids';
import ui from './ui';
import uploadQueue from './upload_queue';

const reducers = combineReducers(
  {
    actionQueues,
    alert,
    contactsAndLeads,
    dealRoom,
    entriesById,
    filePreview,
    fileSearch,
    flashMessage,
    globalProps: state => ({ ...state }),
    membersById,
    recycleBin,
    selected,
    transactionUuids,
    ui,
    uploadQueue
  }
);
export default reducers;
