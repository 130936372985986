import React from 'react';
import PreviewPortal from './index';
import { Button } from 'react-bootstrap-5';
import PropTypes from 'prop-types';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import { connect } from 'react-redux';
import { COMPLETE } from '../../../helpers/vault_access_helper';

export const ACCESS_LEVEL_OPTIONS = ['public_access', 'low', 'medium', 'high'];

export function accessLevelString(accessLevel) {
  let accessLevelWithSpaces = accessLevel.replace('_', ' ');
  if (!accessLevelWithSpaces.endsWith('access')) {
    accessLevelWithSpaces += ' access';
  }
  return accessLevelWithSpaces;
}

function ChangeDocAccessPortal({ profile, setProfileData, onNext }) {
  function changeLeadAccessLevel(accessLevel) {
    const updatedProfile = { ...profile, [PROFILE_OPTIONS.ACCESS_LEVEL]: accessLevel, };
    setProfileData(updatedProfile);
    onNext(COMPLETE, false, { previewProfile: updatedProfile });
  }

  const accessLevelOptions = ACCESS_LEVEL_OPTIONS.filter(option => option !== profile[PROFILE_OPTIONS.ACCESS_LEVEL]);

  return (
    <PreviewPortal>
      {accessLevelOptions.map(accessLevel => (
        <Button key={accessLevel} variant="primary" onClick={() => changeLeadAccessLevel(accessLevel)}>
          <span>Change Access to <span className="text-capitalize">{accessLevelString(accessLevel)}</span></span>
        </Button>
      ))}
    </PreviewPortal>
  );
}

ChangeDocAccessPortal.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocAccessPortal);
