import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';
import classNames from 'classnames';

import { setFilterAttribute } from '../../../store/actions/criteria';

function Select({ criteria, attribute, includeBlank, options, setFilterAttribute }) {
  return (
    <Form.Select
      className={classNames('as-button', criteria[attribute] ? 'active' : 'text-muted')}
      value={criteria[attribute] || ''}
      onChange={e => setFilterAttribute(attribute, e.target.value)}
    >
      {includeBlank &&
        <option value="">
          {typeof includeBlank === 'string' ? includeBlank : 'Select...'}
        </option>
      }
      {options.map(option => (<option key={option.value} value={option.value}>{option.label}</option>))}
    </Form.Select>
  );
}

Select.propTypes = {
  attribute: PropTypes.string.isRequired,
  criteria: PropTypes.object.isRequired,
  includeBlank: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any
  })),
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(Select);
