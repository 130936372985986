import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchOwners } from '../../../helpers/prospect_api';
import { Button, Col, Row, Spinner } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import pluralize from 'pluralize';

export default function UnlockOwners({ insightsPropertyId, trialUser, onUnlock }) {
  const [loading, setLoading] = useState(true);
  const [remainingCredits, setRemainingCredits] = useState();
  const [restrictedProperty, setRestrictedProperty] = useState(false);

  const setOwners = useCallback((useCredit) => {
    setLoading(true);
    fetchOwners(insightsPropertyId, useCredit)
      .then(results => onUnlock(results['connect/ownerships']))
      .catch((error) => {
        setLoading(false);

        if (error.response && error.response.status === 422) {
          if (error.response.data['remaining_credits'] !== undefined) {
            setRemainingCredits(error.response.data['remaining_credits']);
          } else {
            setRestrictedProperty(true);
          }
        }
      });
  }, [insightsPropertyId]);

  useEffect(() => {
    setOwners();
  }, [insightsPropertyId]);

  if (loading) {
    return (
      <div className="text-center py-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  const messageBody = () => {
    if (restrictedProperty) return (
      <Row className="flex-column gap-3">
        <Col>
          <div className="fs-large fw-bolder">Owner contacts for this property are not included in your plan.</div>
          Looks like you don't have access to this property.
          View and manage your subscription details from your account settings.
        </Col>
        <Col>
          <Button href="/prospect/profile#subscription" variant="secondary">View Your Plan Details</Button>
        </Col>
      </Row>
    );

    if (remainingCredits > 0) return (
      <Row className="flex-column gap-3">
        <Col>
          <div className="fs-large fw-bolder">Use a credit to unlock the owner information of this property.</div>
          You have {remainingCredits} {pluralize('credit', remainingCredits)} remaining
          {trialUser ? ' during your trial' : ''}.&nbsp;
          Unlocking this owner information will use 1 credit.
        </Col>
        <Col>
          <Button variant="secondary" onClick={() => setOwners(true)}>Use Credit</Button>
        </Col>
      </Row>
    );

    return (
      <Row className="flex-column gap-3">
        <Col>
          <div className="fs-large fw-bolder">You have no credits remaining</div>
        </Col>
      </Row>
    );
  };

  return (
    <Row>
      <Col xs="auto">
        <FontAwesomeIcon className="p-4 bg-light border rounded" icon={faLock} />
      </Col>
      <Col>
        {messageBody()}
      </Col>
    </Row>
  );
}

UnlockOwners.propTypes = {
  insightsPropertyId: PropTypes.string.isRequired,
  trialUser: PropTypes.bool.isRequired,
  onUnlock: PropTypes.func.isRequired
};
