import React from 'react';
import PropTypes from 'prop-types';

export default function UserIcon({ admin, size = 36, user }) {
  const adminImageUrl = '/images/default-profile-photo.png';

  return (
    <img
      className="img-circle rounded-circle bs5-img-thumbnail"
      src={admin ? adminImageUrl : user.imageUrl}
      style={{ width: size, height: size }}
      title={admin ? 'Buildout' : user.name || user.email}
    />
  );
}

UserIcon.propTypes = {
  admin: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  user: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
};
