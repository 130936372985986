import React from 'react';
import PropTypes from 'prop-types';

// Renders the property card highlights so that the line breaks will happen between each string
export default function ResultCardHighlights({ highlights }) {
  return highlights.map((text, i) => (
    <React.Fragment key={i}>
      <span className="d-inline-block">{text}</span>
      {i < highlights.length - 1 && (<span> • </span>)}
    </React.Fragment>
  ));
}

ResultCardHighlights.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired
};
