import React from 'react';
import RemindBroker from './remind_broker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

export default function NotApproved({ readonly = false }) {
  return (
    <div className="text-center my-2">
      <FontAwesomeIcon className="text-branding" icon={faClock} size="2x"/>
      <h5 className="my-6"><strong>Broker Confirmation Required</strong></h5>
      <p className="mb-0">
        This document requires broker approval.
        You will receive an email once your account has been approved.
      </p>
      <RemindBroker readonly={readonly}/>
    </div>
  );
}

NotApproved.propTypes = { readonly: PropTypes.bool };
