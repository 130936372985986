import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// We have to include all libraries that are used throughout Connect because we do not reload the map api
// between page loads (due to using turbo)
const LIBRARIES = ['drawing', 'places', 'marker'];

export default function MapScriptLoader({ children }) {
  const [librariesLoaded, setLibrariesLoaded] = useState(false);

  useEffect(() => {
    Promise.all(
      LIBRARIES.map(library => google.maps.importLibrary(library))
    ).then(() => {
      setLibrariesLoaded(true);
    });
  }, []);


  if (librariesLoaded) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  } else {
    return null;
  }
}

MapScriptLoader.propTypes = {
  children: PropTypes.node.isRequired
};
