import { useEffect, useState } from 'react';

export function useAdjustedValuation(propertyId) {
  const [valuationAdjustment, setValuationAdjustment] = useState(
    (window.CONNECT_VALUATION_ADJUSTMENTS || {})[propertyId]
  );

  const loadAdjustment = () => {
    const valuationAdjustments = window.CONNECT_VALUATION_ADJUSTMENTS || {};

    if (valuationAdjustments[propertyId] !== valuationAdjustment) {
      setValuationAdjustment(valuationAdjustments[propertyId]);
    }
  };

  const handleChange = (adjustment) => {
    window.dispatchEvent(
      new CustomEvent('setValuationAdjustments', {
        detail: {
          ...valuationAdjustment,
          ...adjustment,
          insights_property_id: propertyId,
        },
      })
    );
  };

  const handleReset = () => {
    window.dispatchEvent(
      new CustomEvent('resetValuationAdjustments', {
        detail: {
          id: valuationAdjustment.id,
          insightsPropertyId: valuationAdjustment.insightsPropertyId,
        },
      })
    );
  };

  useEffect(() => {
    window.addEventListener('valuationAdjustmentsChanged', loadAdjustment);

    return () => {
      window.removeEventListener('valuationAdjustmentsChanged', loadAdjustment);
    };
  }, [valuationAdjustment]);

  return [valuationAdjustment, handleChange, handleReset];
}
