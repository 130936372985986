import axios from 'axios';
import { post } from 'helpers/api';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { isFolder, ENTRY_TYPES, currentDirAllSelected,
  findUniqueName,
  undeletedChildren,
  allChildren } from '../helpers/file_system';
import { SORT_LOOKUP } from '../helpers/sorting';
import deepMapKeys from 'deep-map-keys';
import levenshtein from 'js-levenshtein';
import TinyQueue from 'tinyqueue';
import snakecaseKeys from 'snakecase-keys';

const toCamelCase = obj => deepMapKeys(obj, _.camelCase);

export const REMOVE_TRANSACTION_UUID = 'REMOVE_TRANSACTION_UUID';
export const ADD_TRANSACTION_UUID = 'ADD_TRANSACTION_UUID';
const transactionUuid = (dispatch) => {
  const transactionUuid = uuid();
  dispatch({ type: ADD_TRANSACTION_UUID, uuid: transactionUuid });
  return transactionUuid;
};

export const LOAD_DEAL_ROOM = 'LOAD_DEAL_ROOM';
export const LOAD_RECYCLE_BIN = 'LOAD_RECYCLE_BIN';
export const loadDealRoom = dealRoomId => (dispatch, getState) => {
  const { profileId } = getState().globalProps || {};

  let url = `/deal_rooms/deal_rooms/${dealRoomId}.json`;
  if (profileId) url = `${url}?profile_id=${profileId}`;

  return axios.get(url).then((response) => {
    const dealRoom = toCamelCase(response.data);
    const allEntries = dealRoom.allEntries;
    delete dealRoom.allEntries;

    const allMembers = dealRoom.members;
    dealRoom.memberIds = dealRoom.members.map(member => member.id);

    delete dealRoom.members;
    const entriesById = {};
    allEntries.map(entry => entriesById[entry.id] = entry);
    dispatch({ type: LOAD_DEAL_ROOM, dealRoom, entriesById, allMembers });

    const rootRecycledEntries = [];
    rootRecycleFinding(entriesById[dealRoom.rootEntryId], rootRecycledEntries, entriesById);
    dispatch({ type: LOAD_RECYCLE_BIN, entries: rootRecycledEntries });
  });
};

export const CLEAR_FORCE_RENAME_FOCUS = 'CLEAR_FORCE_RENAME_FOCUS';
export const clearForceRenameFocus = () => {
  return {
    type: CLEAR_FORCE_RENAME_FOCUS,
  };
};

export const RENAMED_DEAL_ROOM = 'RENAMED_DEAL_ROOM';
export const renameDealRoom = (dealRoom, name) => (dispatch) => {
  return axios.put(`/deal_rooms/deal_rooms/${dealRoom.id}`,
    { deal_room: { name }, transaction_uuid: transactionUuid(dispatch) }
  ).then((response) => {
    dispatch({
      type: RENAMED_DEAL_ROOM,
      dealRoom: toCamelCase(response.data)
    });
  });
};

export const CREATING_FOLDER = 'CREATING_FOLDER';
export const DONE_CREATING_FOLDER = 'DONE_CREATING_FOLDER';
export const ERROR_CREATING_FOLDER = 'ERROR_CREATING_FOLDER';
export const CREATED_ENTRY = 'CREATED_ENTRY';
export const createFolder = () => (dispatch, getState) => {
  const parentId = getState().ui.currentFolderId;
  const name = uniqueName("New Folder", parentId, getState);
  const data = {
    entry: { parent_id: parentId, name, entry_type: ENTRY_TYPES.FOLDER },
    transaction_uuid: transactionUuid(dispatch)
  };
  dispatch({ type: CREATING_FOLDER });
  axios.post('/deal_rooms/entries', data).then((response) => {
    dispatch({
      type: CREATED_ENTRY,
      entry: toCamelCase(response.data)
    });
    dispatch({ type: DONE_CREATING_FOLDER });
  }).catch(() => {
    dispatch({ type: ERROR_CREATING_FOLDER });
  });
};

export const SET_CURRENT_FOLDER_ID = 'SET_CURRENT_FOLDER_ID';
export const setCurrentFolderId = (folderId, remoteForcedBy) => {
  return {
    type: SET_CURRENT_FOLDER_ID,
    folderId: folderId,
    remoteForcedBy
  };
};

export const RENAMED_ENTRY = 'RENAMED_ENTRY';
export const RENAMING_ENTRY = 'RENAMING_ENTRY';
export const ERROR_RENAMING_ENTRY = 'ERROR_RENAMING_ENTRY';
export const renameEntry = (entry, name) => (dispatch, getState) => {
  const entriesById = getState().entriesById;
  const siblingNames = undeletedChildren(entriesById[entry.parentId], entriesById).
    filter(sibling => sibling.id !== entry.id).
    map(sibling => sibling.name);
  name = findUniqueName(name, siblingNames);
  dispatch({ type: RENAMING_ENTRY, entry });
  const path = `/deal_rooms/entries/${entry.id}`;
  return axios.put(path, { entry: { name }, transaction_uuid: transactionUuid(dispatch) }).then((response) => {
    dispatch({
      type: RENAMED_ENTRY,
      entry: toCamelCase(response.data),
    });
  }).catch(() => {
    dispatch({ type: ERROR_RENAMING_ENTRY, entry });
  });
};

export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

function formDataGenerator(file, name, parentId, overwriteEntryId, dispatch) {
  const formData = new FormData();
  formData.append('entry[file]', file);
  formData.append('entry[entry_type]', ENTRY_TYPES.FILE);
  if (name) {
    formData.append('entry[name]', name);
  }
  formData.append('entry[parent_id]', parentId);
  if (overwriteEntryId) {
    formData.append('overwrite_entry_id', overwriteEntryId);
  }
  formData.append('transaction_uuid', transactionUuid(dispatch));
  return formData;
}

export const UPLOADING_FILES = "UPLOADING_FILES";
export const uploadFiles = (files, parentId) => (dispatch, getState) => {
  window.fileData = window.fileData || {};
  const fileUuids = [];
  files.forEach((file) => {
    const fileUuid = uuid();
    window.fileData[fileUuid] = file;
    fileUuids.push(fileUuid);
  });
  dispatch({
    type: UPLOADING_FILES,
    fileUuids,
    parentId
  });
  uploadFile(fileUuids[0], parentId)(dispatch, getState);
};

const getConflictingEntry = (name, parentId, getState) => {
  const entriesById = getState().entriesById;
  const entries = undeletedChildren(entriesById[parentId], entriesById);
  let conflictingEntry;
  entries.forEach((entry) => {
    if (entry.name.toLowerCase() === name.toLowerCase()) {
      conflictingEntry = entry;
    }
  });
  return conflictingEntry;
};

export const ERROR_UPLOADING_FILE = 'ERROR_UPLOADING_FILE';
export const UPLOAD_COMPLETED = "UPLOAD_COMPLETED";
// The name argument is only present if the file is to be uploaded with a custom name.
// It is necessary because file.name is read-only
export const uploadFile = (uuid, parentId, shouldReplace, name) => (dispatch, getState) => {
  const file = window.fileData[uuid];
  const fileName = name || file.name;
  const conflictingEntry = getConflictingEntry(fileName, parentId, getState);
  if (conflictingEntry && isFolder(conflictingEntry)) {
    uploadFileRename();
  } else if (conflictingEntry && !shouldReplace) {
    dispatch({
      type: ALERT,
      title: "Replace existing file?",
      message: `A file named ${fileName} already exists. What would you like to do?`,
      radioButtons: [
        { text: "Upload and replace", onSubmit: "onUploadFileReplace" },
        { text: "Upload and rename automatically", onSubmit: "onUploadFileRename" }
      ]
    });
  } else {
    const overwriteEntryId = (conflictingEntry || {}).id;
    const onUploadProgress = (event) => {
      dispatch({
        type: SET_UPLOAD_PROGRESS,
        progress: Math.floor(event.loaded * 100 / event.total),
        uuid
      });
    };
    const config = { onUploadProgress };
    return axios.post('/deal_rooms/entries',
      formDataGenerator(file, fileName, parentId, overwriteEntryId, dispatch), config
    ).then((response) => {
      dispatch({
        type: CREATED_ENTRY,
        overwriteEntry: conflictingEntry,
        entry: toCamelCase(response.data)
      });
      dispatch({ type: UPLOAD_COMPLETED, uuid });
    }).catch(() => {
      dispatch({ type: ERROR_UPLOADING_FILE, uuid });
    }).finally(() => {
      const queue = getState().uploadQueue;
      if (queue.length > 0) {
        uploadFile(queue[0].uuid, queue[0].parentId)(dispatch, getState);
      }
    });
  }
};

const uniqueName = (originalName, parentId, getState) => {
  const entriesById = getState().entriesById;
  let siblingNames = undeletedChildren(entriesById[parentId], entriesById).map(x => x.name);
  siblingNames = siblingNames.map(x => x.toLowerCase());
  return findUniqueName(originalName, siblingNames);
};

export const uploadFileReplace = () => (dispatch, getState) => {
  const fileQueueEntry = getState().uploadQueue[0];
  uploadFile(fileQueueEntry.uuid, fileQueueEntry.parentId, true)(dispatch, getState);
};

export const uploadFileRename = () => (dispatch, getState) => {
  const fileQueueEntry = getState().uploadQueue[0];
  const file = window.fileData[fileQueueEntry.uuid];
  const newName = uniqueName(file.name, fileQueueEntry.parentId, getState);
  // it would be nice to do "file.name = newName", but file.name is read-only
  // so newName gets passed to uploadFile, which resultingly needs slightly messier code
  uploadFile(fileQueueEntry.uuid, fileQueueEntry.parentId, false, newName)(dispatch, getState);
};

export const DELETED_ENTRY = "DELETED_ENTRY";
const deleteEntryPayload = (entry) => {
  return {
    type: DELETED_ENTRY,
    entry
  };
};

export const ERROR_RECYCLING_ENTRY = 'ERROR_RECYCLING_ENTRY';
export const ERROR_DELETING_ENTRY = 'ERROR_DELETING_ENTRY';
export const RECYCLED_ENTRY = 'RECYCLED_ENTRY';
const recycleEntryPayload = entry => ({ type: RECYCLED_ENTRY, entry });

const deleteEntry = (entry, recycle) => (dispatch) => {
  if (recycle) {
    return axios.put(`/deal_rooms/entries/${entry.id}`,
      { recycle: true, transaction_uuid: transactionUuid(dispatch) }
    ).then((response) => {
      dispatch(recycleEntryPayload(toCamelCase(response.data)));
    }).catch(() => {
      dispatch({ type: ERROR_RECYCLING_ENTRY, entry });
    });
  }
  return axios.delete(`/deal_rooms/entries/${entry.id}?transaction_uuid=${transactionUuid(dispatch)}`).then(() => {
    dispatch(deleteEntryPayload(entry));
  }).catch(() => {
    dispatch({ type: ERROR_DELETING_ENTRY, entry });
  });
};

export const DONE_DELETING_ENTRIES = 'DONE_DELETING_ENTRIES';
export const DELETING_ENTRIES = 'DELETING_ENTRIES';
export const deleteEntries = (entries, recycle) => (dispatch) => {
  dispatch({
    type: DELETING_ENTRIES,
    entries
  });
  entries.forEach((entry) => {
    deleteEntry(entry, recycle)(dispatch);
  });
  dispatch({
    type: DONE_DELETING_ENTRIES,
    entries
  });
};

export const ERROR_RESTORING_ENTRY = 'ERROR_RESTORING_ENTRY';
export const RESTORED_ENTRY = 'RESTORED_ENTRY';
export const DONE_RESTORING_ENTRIES = 'DONE_RESTORING_ENTRIES';
const restoreEntryPayload = entry => ({ type: RESTORED_ENTRY, entry });

const restoreEntry = (entry, newName) => (dispatch, getState) => {
  const conflictingEntry = getConflictingEntry(newName || entry.name, entry.parentId, getState);
  if (conflictingEntry) {
    const fileOrFolderText = entry => isFolder(entry) ? 'folder' : 'file';
    dispatch({
      type: ALERT,
      title: `Rename ${fileOrFolderText(entry)}?`,
      message: `The destination has a ${fileOrFolderText(conflictingEntry)} with the same name, \
          "${conflictingEntry.name}". Would you like to rename the ${fileOrFolderText(entry)}?`,
      buttons: [
        { name: "Rename", onClick: "onRestoreEntryRename" },
        { name: "Cancel", onClick: "onCancel" }
      ],
      pending: { entryId: entry.id, type: RESTORED_ENTRY }
    });
  } else {
    const data = { restore: true };
    if (newName) {
      data.entry = { name: newName };
    }
    data.transaction_uuid = transactionUuid(dispatch);
    return axios.put(`/deal_rooms/entries/${entry.id}`, data).then((response) => {
      dispatch(restoreEntryPayload(toCamelCase(response.data)));
    }).catch(() => {
      dispatch({ type: ERROR_RESTORING_ENTRY, entry });
    }).finally(() => {
      continueRestoring()(dispatch, getState);
    });
  }
};

export const RESTORING_ENTRIES = 'RESTORING_ENTRIES';
export const restoreEntries = entries => (dispatch, getState) => {
  dispatch({ type: RESTORING_ENTRIES, entries });
  restoreEntry(entries[0])(dispatch, getState);
};


export const MOVED_ENTRY = "MOVED_ENTRY";
const moveEntryPayload = (entry, overwriteEntry) => {
  return {
    type: MOVED_ENTRY,
    entry,
    overwriteEntry
  };
};

export const restoreEntryRename = () => (dispatch, getState) => {
  const entryId = getState().alert.pending.entryId;
  const entry = getState().entriesById[entryId];
  const newName = uniqueName(entry.name, entry.parentId, getState);
  restoreEntry(entry, newName)(dispatch, getState);
};

export const moveEntryRename = () => (dispatch, getState) => {
  const entryId = getState().alert.pending.entryId;
  const entry = getState().entriesById[entryId];
  const destId = getState().alert.pending.destId;
  const newName = uniqueName(entry.name, destId, getState);
  moveEntry(entry, destId, false, newName)(dispatch, getState);
};

export const moveEntryReplace = () => (dispatch, getState) => {
  const entryId = getState().alert.pending.entryId;
  const entry = getState().entriesById[entryId];
  const destId = getState().alert.pending.destId;
  moveEntry(entry, destId, true)(dispatch, getState);
};

export const HIDE_ALERT = "HIDE_ALERT";
export const hideAlert = () => {
  return { type: HIDE_ALERT };
};

const continueMoving = () => (dispatch, getState) => {
  const queue = getState().actionQueues.pendingMoves;
  const entriesById = getState().entriesById;
  if (queue.length > 0) {
    moveEntry(entriesById[queue[0].id], queue[0].destId)(dispatch, getState);
  } else {
    const completedMoves = getState().actionQueues.completedMoves;
    if (completedMoves.length > 0) {
      dispatch({
        type: DONE_MOVING_ENTRIES,
        completedMoves
      });
    }
  }
};

const continueRestoring = () => (dispatch, getState) => {
  const queue = getState().actionQueues.pendingRestores;
  const entriesById = getState().entriesById;
  if (queue.length > 0) {
    restoreEntry(entriesById[queue[0].id])(dispatch, getState);
  } else {
    const completedRestores = getState().actionQueues.completedRestores;
    if (completedRestores.length > 0) {
      dispatch({
        type: DONE_RESTORING_ENTRIES,
        completedRestores
      });
    }
  }
};

export const MOVE_CANCELLED = 'MOVE_CANCELLED';
export const RESTORE_CANCELLED = 'RESTORE_CANCELLED';
export const cancelAlert = () => (dispatch, getState) => {
  switch (getState().alert.pending.type) {

  case MOVED_ENTRY: {
    const entry = getState().entriesById[getState().alert.pending.entryId];
    dispatch({ type: MOVE_CANCELLED, entry });
    continueMoving()(dispatch, getState);
    break;
  }
  case RESTORED_ENTRY: {
    const entry = getState().entriesById[getState().alert.pending.entryId];
    dispatch({ type: RESTORE_CANCELLED, entry });
    continueRestoring()(dispatch, getState);
  }
  }
};

export const ALERT = "ALERT";
export const ERROR_MOVING_ENTRY = 'ERROR_MOVING_ENTRY';
export const DONE_MOVING_ENTRIES = 'DONE_MOVING_ENTRIES';
export const moveEntry = (entry, destId, shouldReplace, newName) => (dispatch, getState) => {
  const conflictingEntry = getConflictingEntry(newName || entry.name, destId, getState);
  if (conflictingEntry && !shouldReplace) {
    if (isFolder(entry)) {
      dispatch({
        type: ALERT,
        title: "Rename file?",
        message: `The destination has a ${isFolder(conflictingEntry) ? "folder" : "file"} with the same name, \
          "${conflictingEntry.name}". Would you like to rename the folder being moved?`,
        buttons: [
          { name: "Rename", onClick: "onMoveEntryRename", className: 'btn-save' },
          { name: "Cancel", onClick: "onCancel", className: 'btn-dark' }
        ],
        pending: { entryId: entry.id, destId, type: MOVED_ENTRY }
      });
    } else {
      if (isFolder(conflictingEntry)) {
        dispatch({
          type: ALERT,
          title: "Rename file?",
          message: `The destination has a folder with the same name, "${conflictingEntry.name}". \
            Would you like to rename the file being moved?`,
          buttons: [
            { name: "Rename", onClick: "onMoveEntryRename", className: 'btn-save' },
            { name: "Cancel", onClick: "onCancel", className: "btn-dark" }
          ],
          pending: { entryId: entry.id, destId, type: MOVED_ENTRY }
        });
      } else {
        dispatch({
          type: ALERT,
          title: "Replace existing file?",
          message: `The destination already has a file named "${conflictingEntry.name}". \
            What would you like to do?`,
          radioButtons: [
            { text: "Replace existing file", onSubmit: "onMoveEntryReplace" },
            { text: "Rename automatically", onSubmit: "onMoveEntryRename" },
            { text: "Cancel", onSubmit: "onCancel" }
          ],
          pending: { entryId: entry.id, destId, type: MOVED_ENTRY }
        });
      }
    }
  } else {
    const data = { entry: { parent_id: destId } };
    if (newName) {
      data.entry.name = newName;
    }
    if (shouldReplace) {
      data.overwrite_entry_id = conflictingEntry.id;
    }
    data.transaction_uuid = transactionUuid(dispatch);
    return axios.put(`/deal_rooms/entries/${entry.id}`, data).then((response) => {
      dispatch(moveEntryPayload(toCamelCase(response.data), conflictingEntry));
    }).catch(() => {
      dispatch({ type: ERROR_MOVING_ENTRY, entry });
    }).finally(() => {
      continueMoving()(dispatch, getState);
    });
  }
};

export const MOVING_ENTRIES = 'MOVING_ENTRIES';
export const moveEntries = (entries, destId) => (dispatch, getState) => {
  if (_.some(entries, { id: destId })) {
    return;
  }
  dispatch({
    type: MOVING_ENTRIES,
    entries,
    destination: getState().entriesById[destId]
  });
  moveEntry(entries[0], destId)(dispatch, getState);
};

// the drop handlers for both FolderRow and BreadCrumbEntry rely
// on this code to distinguish between a FileRow, FolderRow, or newly uploaded file,
// and dispatch the correct action
export const onEntryDrop = (event, destId) => (dispatch, getState) => {
  event.preventDefault();
  if ((event.dataTransfer.files || []).length > 0) {
    // convert from FileList to Array
    const files = [...event.dataTransfer.files];
    uploadFiles(files, destId)(dispatch, getState);
  }
};

export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_RECYCLE_SORT_ORDER = "SET_RECYCLE_SORT_ORDER";

export const setSortOrder = sort => (dispatch, getState) =>  {
  const order = _.findKey(SORT_LOOKUP, value => _.isEqual(value, sort));
  const type = getState().ui.inRecycleBin ? SET_RECYCLE_SORT_ORDER : SET_SORT_ORDER;
  dispatch({ type, order });
};

export const SELECT_ENTRIES = "SELECT_ENTRIES";
const selectEntries = (entries) => {
  return {
    type: SELECT_ENTRIES,
    entries
  };
};

export const SELECT_ENTRY = "SELECT_ENTRY";
export const DESELECT_ENTRY = "DESELECT_ENTRY";
export const toggleSelectEntry = (entry, checkboxClicked) => (dispatch, getState) => {
  const alreadySelected = getState().selected.entryIds.includes(entry.id);
  const type = alreadySelected ? DESELECT_ENTRY : SELECT_ENTRY;
  dispatch({ type, entry, checkboxClicked });
};

export const toggleSelectAllEntries = () => (dispatch, getState) => {
  const state = getState();
  let allSelected = false;
  if (state.fileSearch.open) {
    allSelected = _.every(state.selected.entryIds, id => state.fileSearch.entryIds.includes(id)) &&
      _.every(state.fileSearch.entryIds, id => state.selected.entryIds.includes(id));
  } else {
    allSelected = currentDirAllSelected(
      state.entriesById, state.ui.currentFolderId, state.selected.entryIds,
      state.recycleBin.entryIds, state.ui.inRecycleBin, state.fileSearch.open
    );
  }
  if (allSelected) {
    dispatch(selectEntries([]));
  } else {
    let entries;
    if (state.fileSearch.open) {
      entries = state.fileSearch.entryIds.map(id => state.entriesById[id]);
    } else if (state.ui.inRecycleBin) {
      entries = state.recycleBin.entryIds.map(id => state.entriesById[id]);
    } else {
      entries = undeletedChildren(state.entriesById[state.ui.currentFolderId], state.entriesById);
    }
    dispatch(selectEntries(entries));
  }
};

export const START_DRAGGING_ENTRIES = "START_DRAGGING_ENTRIES";
export const startDraggingEntries = (draggedEntry, position) => (dispatch, getState) => {
  if (!getState().selected.entryIds.includes(draggedEntry.id)) {
    toggleSelectEntry(draggedEntry, false, false)(dispatch, getState);
  }
  const entryIds = getState().selected.entryIds;
  dispatch({
    type: START_DRAGGING_ENTRIES,
    entryIds,
    position
  });
};

export const DRAG_ENTRIES = "DRAG_ENTRIES";
export const dragEntries = (position) => {
  return {
    type: DRAG_ENTRIES,
    position
  };
};

export const STOP_DRAGGING_ENTRIES = "STOP_DRAGGING_ENTRIES";
export const stopDraggingEntries = () => {
  return { type: STOP_DRAGGING_ENTRIES };
};

export const UPLOAD_WINDOW_STATUS = {
  OPEN: "UPLOAD_WINDOW_OPEN",
  CLOSED: "UPLOAD_WINDOW_CLOSED",
  COLLAPSED: "UPLOAD_WINDOW_COLLAPSED"
};
export const SET_UPLOAD_WINDOW_STATUS = 'SET_UPLOAD_WINDOW_STATUS';
export const closeUploadProgress = () => {
  return { type: SET_UPLOAD_WINDOW_STATUS, status: UPLOAD_WINDOW_STATUS.CLOSED };
};
export const openUploadProgress = () => {
  return { type: SET_UPLOAD_WINDOW_STATUS, status: UPLOAD_WINDOW_STATUS.OPEN };
};
export const collapseUploadProgress = () => {
  return { type: SET_UPLOAD_WINDOW_STATUS, status: UPLOAD_WINDOW_STATUS.COLLAPSED };
};

export const CLOSE_FLASH_MESSAGE = 'CLOSE_FLASH_MESSAGE';
export const closeFlashMessage = (id) => {
  return { type: CLOSE_FLASH_MESSAGE, id };
};

export const SHOW_RIGHT_CLICK_MENU = "SHOW_RIGHT_CLICK_MENU";
export const showRightClickMenu = (position) => {
  return { type: SHOW_RIGHT_CLICK_MENU, position };
};

export const CLOSE_RIGHT_CLICK_MENU = "CLOSE_RIGHT_CLICK_MENU";
export const closeRightClickMenu = () => {
  return { type: CLOSE_RIGHT_CLICK_MENU };
};

export const SHOW_RENAME_ENTRY_INPUT = 'SHOW_RENAME_ENTRY_INPUT';
export const showRenameEntryInput = () => (dispatch, getState) => {
  if (getState().selected.entryIds.length !== 1) {
    return;
  }
  dispatch({ type: SHOW_RENAME_ENTRY_INPUT });
};

export const HIDE_RENAMED_ENTRY_INPUT = 'HIDE_RENAMED_ENTRY_INPUT';
export const hideRenameEntryInput = () => {
  return { type: HIDE_RENAMED_ENTRY_INPUT };
};

// DFS to find entries in the "root" of the recycle bin
const rootRecycleFinding = (entry, arr, entriesById) => {
  if (entry.recycledAt) {
    arr.push(entry);
    return;
  }
  if (isFolder(entry)) {
    allChildren(entry, entriesById).forEach(child => rootRecycleFinding(child, arr, entriesById));
  }
};

export const OPEN_RECYCLE_BIN = 'OPEN_RECYCLE_BIN';
export const openRecycleBin = () => (dispatch, getState) => {
  const state = getState();
  const dealRoom = state.dealRoom;
  const rootEntry = state.entriesById[dealRoom.rootEntryId];
  const rootRecycledEntries  = [];
  rootRecycleFinding(rootEntry, rootRecycledEntries, state.entriesById);
  dispatch({ type: OPEN_RECYCLE_BIN, entries: rootRecycledEntries });
};

export const CLOSE_RECYCLE_BIN = 'CLOSE_RECYCLE_BIN';
export const closeRecycleBin = () => {
  return { type: CLOSE_RECYCLE_BIN };
};

const downloadFromPath = (path, errorMessage) => {
  const link = document.createElement('a');
  link.href = path;
  link.download = errorMessage;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DOWNLOAD_ENTRIES = 'DOWNLOAD_ENTRIES';
export const DOWNLOADING_ENTRIES = 'DOWNLOADING_ENTRIES';
export const downloadEntries = entries => (dispatch) => {
  dispatch({ type: DOWNLOADING_ENTRIES, entries });
  if (entries.length === 1) {
    downloadFromPath(`/deal_rooms/entries/${entries[0].id}`, entries[0].name);
  } else {
    const idsParam = entries.map(entry => `ids[]=${entry.id}`);
    downloadFromPath(`/deal_rooms/batch_download_entries?${idsParam.join('&')}`, `${entries.length} entries.zip`);
  }

};

export const OPEN_DEAL_ROOM_SETTINGS = 'OPEN_DEAL_ROOM_SETTINGS';
export const openDealRoomSettings = () => {
  return {
    type: OPEN_DEAL_ROOM_SETTINGS,
  };
};
export const CLOSE_DEAL_ROOM_SETTINGS = 'CLOSE_DEAL_ROOM_SETTINGS';
export const closeDealRoomSettings = () => ({ type: CLOSE_DEAL_ROOM_SETTINGS });

export const ADD_MEMBERS = 'ADD_MEMBERS';
const addMembersPayload = (members) => {
  return {
    type: ADD_MEMBERS,
    members
  };
};

export const ERROR_ADDING_MEMBERS = "ERROR_ADDING_MEMBERS";
export const sendInvites = (invites, dealRoom, inviteMessage) => (dispatch) => {
  const data = {
    member: {
      invite_message: inviteMessage
    },
    deal_room_id: dealRoom.id,
    invites
  };

  data.transaction_uuid = transactionUuid(dispatch);

  return post('/deal_rooms/invites', data).then((data) => {
    dispatch(addMembersPayload(data));
    return {};
  }).catch(({ data }) => ({ errors: data.errors }));
};

export const UPDATING_MEMBER = 'UPDATING_MEMBER';
export const ERROR_UPDATING_MEMBER = 'ERROR_UPDATING_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const updateMember = member => (dispatch) => {
  dispatch({ type: UPDATING_MEMBER, member });
  const params = { transaction_uuid: transactionUuid(dispatch), member };
  axios.put(`/deal_rooms/members/${member.id}`, snakecaseKeys(params)).then(() => {
    dispatch({ type: UPDATE_MEMBER, member });
  }).catch(() => {
    dispatch({ type: ERROR_UPDATING_MEMBER, member });
  });
};

export const DELETING_MEMBER = 'DELETING_MEMBER';
export const ERROR_DELETING_MEMBER = 'ERROR_DELETING_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const deleteMember = member => (dispatch) => {
  dispatch({ type: DELETING_MEMBER, member });
  axios.delete(`/deal_rooms/members/${member.id}?transaction_uuid=${transactionUuid(dispatch)}`).then(() => {
    dispatch({ type: DELETE_MEMBER, member });
  }).catch(() => {
    dispatch({ type: ERROR_DELETING_MEMBER, member });
  });
};

export const ERROR_DELETING_DEAL_ROOM = 'ERROR_DELETING_DEAL_ROOM';
export const deleteDealRoom = (dealRoom, successCallback, failCallback) => (dispatch) => {
  axios.delete(
    `/deal_rooms/deal_rooms/${dealRoom.id}?transaction_uuid=${transactionUuid(dispatch)}`
  ).then(successCallback).catch(() => {
    dispatch({ type: ERROR_DELETING_DEAL_ROOM });
    failCallback();
  });
};

export const OPEN_FILE_PREVIEW = 'OPEN_FILE_PREVIEW';
export const openFilePreview = (entry) => {
  return {
    type: OPEN_FILE_PREVIEW,
    entry
  };
};

export const CLOSE_FILE_PREVIEW = 'CLOSE_FILE_PREVIEW';
export const closeFilePreview = () => ({ type: CLOSE_FILE_PREVIEW });

export const SET_DEAL_ROOM_EDITING_NAME = 'SET_DEAL_ROOM_EDITING_NAME';
export const setDealRoomEditingName = editingName => (dispatch) => {
  dispatch({ type: SET_DEAL_ROOM_EDITING_NAME, editingName });
  return Promise.resolve();
};

const DELETED_DEAL_ROOM = 'DELETED_DEAL_ROOM';
export const handleRemoteAction = remoteMessage => (dispatch, getState) => {
  const myTransactionUuids = getState().transactionUuids;
  // filter out socket messages that are duplicates from http responses
  if (myTransactionUuids[remoteMessage.transaction_uuid]) {
    dispatch({ type: REMOVE_TRANSACTION_UUID, uuid: remoteMessage.transaction_uuid });
    return;
  }
  switch (remoteMessage.type) {
  case RECYCLED_ENTRY:
  case DELETED_ENTRY: {
    const action = toCamelCase(remoteMessage);
    action.fromRemote = true;
    if (!isFolder(action.entry)) { // check if currently previewed file was deleted
      if (getState().filePreview.entryId === action.entry.id) {
        dispatch({ type: CLOSE_FILE_PREVIEW, remoteForcedBy: action.performedBy });
      }
    } else { // check if a parent folder of the current open directory was deleted
      const entriesById = getState().entriesById;
      const currentFolderId = getState().ui.currentFolderId;
      let folderPtr = entriesById[currentFolderId];
      while (folderPtr && folderPtr.id !== action.entry.id) {
        folderPtr = folderPtr.parentId ? entriesById[folderPtr.parentId] : null;
      }
      if (folderPtr) { // the folder the user is currently in has been recycled or deleted
        dispatch(setCurrentFolderId(folderPtr.parentId, action.performedBy));
      }
    }
    dispatch(action);
    break;
  }
  case RENAMED_ENTRY:
  case MOVED_ENTRY:
  case RESTORED_ENTRY:
  case RENAMED_DEAL_ROOM:
  case ADD_MEMBERS:
  case UPDATE_MEMBER:
  case DELETE_MEMBER:
  case CREATED_ENTRY: {
    const action = toCamelCase(remoteMessage);
    action.fromRemote = true;
    dispatch(action);
    break;
  }
  case DELETED_DEAL_ROOM: {
    dispatch({
      type: ALERT,
      title: "Deal Room Deleted",
      message: `${remoteMessage.performed_by} deleted this deal room`,
      buttons: [
        { name: "Leave Deal Room", onClick: "onCloseDealRoom" }
      ]
    });
  }
  }
};

export const OPEN_FILE_SEARCH = 'OPEN_FILE_SEARCH';
export const openFileSearch = () => ({
  type: OPEN_FILE_SEARCH
});

export const CLOSE_FILE_SEARCH = 'CLOSE_FILE_SEARCH';
export const closeFileSearch = () => ({
  type: CLOSE_FILE_SEARCH
});

export const SET_FILE_SEARCH_QUERY = 'SET_FILE_SEARCH_QUERY';
export const setFileSearchQuery = query => ({
  type: SET_FILE_SEARCH_QUERY,
  query
});

export const SET_SEARCH_MATCHES = 'SET_SEARCH_MATCHES';
const MATCH_EDIT_DISTANCE_THRESHOLD = 5;
const MAX_MATCHES = 50;
export const submitSearch = query => (dispatch, getState) => {
  if (!query) {
    dispatch({
      type: SET_SEARCH_MATCHES,
      entryIds: []
    });
    return;
  }
  query = query.toLowerCase();
  const state = getState();
  const editDistById = {};
  const topMatches = new TinyQueue([], (a, b) => b.editDist - a.editDist); // priority queue
  Object.keys(state.entriesById).forEach((id) => {
    id = parseInt(id);
    const entry = state.entriesById[id];
    // TODO: allow searching recycled entries
    if (!entry.name || entry.id === state.dealRoom.rootEntryId || entry.recycledAt) {
      return;
    }
    const editDist = levenshtein(query, entry.name.toLowerCase());
    editDistById[id] = editDist;
    if (editDist <= MATCH_EDIT_DISTANCE_THRESHOLD || entry.name.toLowerCase().includes(query)) {
      if (topMatches.length < MAX_MATCHES || editDist < topMatches.peek().editDist) {
        if (topMatches.length >= MAX_MATCHES) {
          topMatches.pop();
        }
        topMatches.push({ editDist, entryId: id });
      }
    }
  });
  const entryIds = [];
  while (topMatches.length > 0) {
    entryIds.push(topMatches.pop().entryId);
  }
  entryIds.sort((idA, idB) => {
    return editDistById[idA] - editDistById[idB];
  });
  dispatch({
    type: SET_SEARCH_MATCHES,
    entryIds
  });
};
