import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap-5';
import MapScriptLoader from 'bundles/connect/helpers/map_script_loader';
import {
  GoogleMap,
  Circle
} from '@react-google-maps/api';

const defaultMinHeight = '300px';
const defaultZoom = 11;

export default function MatchPhotoAndMap({
  approximateLatLng,
  googleMapsApiKey,
  maxHeight,
  minHeight,
  photoUrl,
  zoom
}) {
  return (
    <Row className="mb-4 d-md-flex.align-items-stretch g-0 rounded-3 overflow-hidden">
      <Col className="pb-1 pb-lg-0 pe-lg-1" lg={7} xl={8}>
        <img
          className="w-100 match-review-photo"
          src={photoUrl}
          style={{ minHeight: minHeight || defaultMinHeight, maxHeight }}
        />
      </Col>
      <Col lg={5} xl={4}>
        <MapScriptLoader googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            center={approximateLatLng}
            mapContainerStyle={{ width: '100%', height: '100%', minHeight: minHeight || defaultMinHeight }}
            options={{ mapTypeControl: false, streetViewControl: false }}
            zoom={zoom || defaultZoom}
          >
            <Circle
              center={approximateLatLng}
              options={{
                strokeColor: '#000000',
                strokeOpacity: 0.2,
                strokeWeight: 2,
                fillColor: '#54a7e7',
                fillOpacity: 0.35
              }}
              radius={1609.34 * 3}
            />
          </GoogleMap>
        </MapScriptLoader>
      </Col>
    </Row>
  );
}

MatchPhotoAndMap.propTypes = {
  approximateLatLng: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired
  }).isRequired,
  googleMapsApiKey: PropTypes.string.isRequired,
  maxHeight: PropTypes.string,
  minHeight: PropTypes.string,
  photoUrl: PropTypes.string.isRequired,
  zoom: PropTypes.number
};
