import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { getDateByYearsOffset, getDaysOptions, monthOptions } from './helpers';
import { formatDate } from 'helpers/formatters/date_time_formats';
import ManagedInput from 'components/form/managed_input';
import Options from 'components/form/managed_input/options';
import YearInput from './year_input';

const ManagedDateDropDownInput = ({ disabled, name, required, value, yearsOffset, onChange }) => {
  // value can be a string when loaded from the server and needs to be formatted to local time
  const formattedValue = new Date(formatDate(value));
  const [selectedMonth, setSelectedMonth] = useState(value ? formattedValue.getMonth() : 0);
  const [selectedDate, setSelectedDate] = useState(value ? formattedValue.getDate() : 1);
  const [selectedYear, setSelectedYear] = useState(value ? formattedValue.getFullYear() : new Date().getFullYear());

  const handleChange = () => {
    const date = getDateByYearsOffset(selectedYear, selectedMonth, selectedDate, yearsOffset);
    onChange({ [name]: date });
  };

  React.useEffect(() => {
    if (yearsOffset === 1) {
      setSelectedYear(new Date().getFullYear());
    }

    handleChange();
  }, [selectedMonth, selectedDate, selectedYear, yearsOffset]);

  const commonProps = {
    className: 'form-control',
    disabled,
    required,
    type: 'select',
  };

  return (
    <div className="d-flex">
      <ManagedInput
        name="month"
        value={selectedMonth}
        onValueChange={setSelectedMonth}
        {...commonProps}
      >
        <Options options={monthOptions} />
      </ManagedInput>
      <ManagedInput
        name="date"
        value={selectedDate}
        onValueChange={setSelectedDate}
        {...commonProps}
      >
        <Options options={getDaysOptions(selectedMonth, selectedYear)} />
      </ManagedInput>
      <YearInput
        name="year"
        value={selectedYear}
        yearsOffset={yearsOffset}
        onValueChange={setSelectedYear}
        {...commonProps}
      />
    </div>
  );
};

ManagedDateDropDownInput.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  yearsOffset: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default ManagedDateDropDownInput;
