import { connect } from 'react-redux';
import { Button } from 'react-bootstrap-5';
import React from 'react';
import PropTypes from 'prop-types';
import { CA_NOT_SIGNED, COLLECT_INFORMATION, REGISTRATION } from '../../../helpers/vault_access_helper';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import PreviewPortal from './index';

function GateFormPortal({ nextStep, profile, setProfileData, steps, onNext }) {
  function previewButtonClicked() {
    const previewProfileData = {};
    if (steps.includes(COLLECT_INFORMATION) || steps.includes(REGISTRATION)) {
      previewProfileData[PROFILE_OPTIONS.PROFILE_PRESENT] = true;
    }
    if (steps.includes(CA_NOT_SIGNED)) previewProfileData[PROFILE_OPTIONS.CA_SIGNED] = true;
    if (steps.includes(REGISTRATION)) previewProfileData[PROFILE_OPTIONS.VALID_LOGIN] = true;

    const updatedProfile = { ...profile, ...previewProfileData };

    setProfileData(updatedProfile);
    onNext(nextStep, true, { previewProfile: updatedProfile });
  }

  return (
    <PreviewPortal>
      <Button variant="primary" onClick={previewButtonClicked}>Fill out information and submit</Button>
    </PreviewPortal>
  );
}

GateFormPortal.propTypes = {
  nextStep: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(GateFormPortal);

