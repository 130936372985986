import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { debounce, isEmpty } from 'lodash';
import { fetchTags } from '../../helpers/prospect_api';
import { getRecentTags, setRecentTag } from '../../helpers/local_storage';

export default function TagPicker({ emptyState, emptyStateLabel, excludeNames, onTagSelected }) {
  const [tags, setTags] = useState([]);
  const [showOverlay, setShowOverlay] = useState(false);
  const [searchString, setSearchString] = useState('');

  const tagsForDisplay = useMemo(() => tags.filter(tag => !excludeNames.includes(tag)), [tags, excludeNames]);

  useEffect(() => {
    if (!showOverlay) {
      setSearchString('');
      return;
    }

    const storedTags = getRecentTags();
    setTags(storedTags);

    fetchTags('', Math.max(5 - storedTags.length, 1)).then((response) => {
      const responseTags = response['connect/tags'].map(tag => tag.name);

      setTags([
        ...storedTags,
        ...responseTags.filter(tag => !storedTags.includes(tag))
      ]);
    });

  }, [showOverlay]);

  const debouncedSearch = useCallback(
    debounce((inputString) => {
      if (!inputString) {
        setTags(getRecentTags());
      } else {
        fetchTags(inputString, 5).then(response => setTags(response['connect/tags'].map(tag => tag.name)));
      }
    }, 300),
    []
  );

  const tagSelected = useCallback(
    (tag) => {
      const trimmedTag = tag.trim();
      onTagSelected(trimmedTag);
      setRecentTag(trimmedTag);
      setShowOverlay(false);
    },
    [onTagSelected]
  );

  useEffect(() => {
    if (showOverlay && searchString) debouncedSearch(searchString);
  }, [showOverlay, searchString]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();

    if (tagsForDisplay.length === 0 || tagsForDisplay.includes(searchString.trim())) {
      tagSelected(searchString);
    } else {
      tagSelected(tagsForDisplay[0]);
    }
  }, [tagsForDisplay, searchString]);

  const popover = (
    <Popover onClick={e => e.stopPropagation()}>
      <Popover.Header as="h6">Add a tag</Popover.Header>
      <Popover.Body className="px-0 pt-2 pb-0">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-2 px-3">
            <Form.Label>Search all tags</Form.Label>

            <div className="with-icon">
              <div className="icon search-icon">
                <FontAwesomeIcon icon={faSearch} />
              </div>

              <Form.Control
                autoFocus
                type="text"
                onChange={e => setSearchString(e.target.value)}

              />
            </div>
          </Form.Group>
        </Form>

        <ListGroup style={{ maxHeight: '200px', overflow: 'auto' }}>
          {tagsForDisplay.map(tag => (
            <ListGroup.Item
              action
              className="border-0"
              key={tag}
              onClick={() => tagSelected(tag)}
            >
              {tag}
            </ListGroup.Item>
          ))}
          {isEmpty(tagsForDisplay) && (
            <ListGroup.Item className="border-0">
              No results found
            </ListGroup.Item>
          )}
        </ListGroup>
        {searchString && (
          <Button size="sm" variant="link" onClick={() => tagSelected(searchString)}>
            + Create new tag ({searchString})
          </Button>
        )}
      </Popover.Body>
    </Popover>
  );

  let triggerChild;
  if (isEmpty(excludeNames) && emptyState) {
    triggerChild = emptyState;
  } else if (isEmpty(excludeNames) && !emptyState) {
    triggerChild = (
      <div className="clickable d-flex align-items-center fw-bold">
        {emptyStateLabel || 'Add Tag'}
        <FontAwesomeIcon className="ms-2" icon={faPlus} />
      </div>
    );
  } else {
    triggerChild = (
      <div className="clickable">
        <FontAwesomeIcon icon={faPlus} />
      </div>
    );
  }


  return (
    <OverlayTrigger
      overlay={popover}
      placement="bottom"
      rootClose
      show={showOverlay}
      trigger="click"
      onToggle={show => setShowOverlay(show)}
    >
      {triggerChild}
    </OverlayTrigger>
  );
}

TagPicker.propTypes = {
  emptyState: PropTypes.node,
  emptyStateLabel: PropTypes.string,
  excludeNames: PropTypes.array.isRequired,
  onTagSelected: PropTypes.func.isRequired,
};
