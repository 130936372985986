import { LOAD_DEAL_ROOM, ADD_MEMBERS, UPDATE_MEMBER, DELETE_MEMBER } from "../actions";

export default (state = {}, action) => {
  switch (action.type) {

  case ADD_MEMBERS: {
    const newState = { ...state };
    action.members.forEach(member => newState[member.id] = member);
    return newState;
  }
  case LOAD_DEAL_ROOM: {
    const newState = {};
    action.allMembers.forEach(member => newState[member.id] = member);
    return newState;
  }
  case UPDATE_MEMBER:
    return { ...state, [action.member.id]: action.member };
  case DELETE_MEMBER: {
    const newState = { ...state };
    delete newState[action.member.id];

    return newState;
  }
  default:
    return state;
  }
};
