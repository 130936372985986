import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap-5';
import { faFileExclamation } from '@fortawesome/pro-solid-svg-icons';

export default function EmptyState({
  cardClasses,
  header = 'There are no documents available here.',
  icon = faFileExclamation
}) {
  return (
    <Row className="justify-content-center my-5">
      <Col xs="auto">
        <Card className={cardClasses}>
          <Card.Body className="p-5">
            <div className="text-center text-muted mb-3">
              <FontAwesomeIcon icon={icon} size="3x" />
            </div>
            <h5>{header}</h5>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

EmptyState.propTypes = {
  cardClasses: PropTypes.string,
  header: PropTypes.string,
  icon: PropTypes.object
};
