import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import { loadLlcData, saveLlcData } from '../../helpers/local_storage';
import { offMarketAssociatedPropertiesCount } from 'bundles/connect/helpers/prospect_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import LlcDetails from './llc_details';
import { llcLookup } from './api';
import ManualLookup from './manual_lookup';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash';

export default function Ownership({ owner, ownerOccupied, address, propertyId }) {
  const [loading, setLoading] = useState(false);
  const [llcData, setLlcData] = useState(null);
  const [lookupFailure, setLookupFailure] = useState(false);
  const [associatedPropertiesCount, setAssociatedPropertiesCount] = useState(0);

  const fullAddress = [address?.address, address?.city, `${address?.state} ${address?.zip}`]
    .filter(s => !isEmpty(s))
    .join(', ');

  useEffect(() => {
    setLlcData(loadLlcData(propertyId));

    offMarketAssociatedPropertiesCount(propertyId)
      .then(setAssociatedPropertiesCount)
      .catch(console.warn);
  }, []);

  const lookupLlc = () => {
    if (llcData) return;

    setLoading(true);
    llcLookup(propertyId).then((data) => {
      setLoading(false);
      if (data) {
        setLlcData(data);
        saveLlcData(propertyId, data);
      } else {
        setLookupFailure(true);
      }
    }).catch(() => {
      setLoading(false);
      setLookupFailure(true);
    });
  };

  const lookupButton = () => {
    if (llcData || !owner.isCorporation) return;

    if (loading) return (
      <FontAwesomeIcon className="text-muted px-3" icon={faSpinner} spin />
    );

    if (lookupFailure) return (
      <Button
        className="ms-3 text-danger"
        disabled
        size="sm"
        variant="link"
      >
        No LLC Data Found
      </Button>
    );

    return (
      <Button
        className="text-secondary"
        disabled={loading}
        size="sm"
        variant="link"
        onClick={lookupLlc}
      >
        Get LLC Info
      </Button>
    );
  };

  const tableRow = (label, value, button = (<React.Fragment/>)) => {
    if (!value) return null;

    return (
      <tr>
        <td className="fw-bold">{label}</td>
        <td className="d-flex align-items-center justify-content-between">
          {value}
          {button}
        </td>
      </tr>
    );
  };

  const associatedPropertiesUrl = '/prospect/search?' + [
    `ownerAddress=${address?.address || ''}`,
    `ownerCity=${address?.city || ''}`,
    `ownerState=${address?.state || ''}`,
    `ownerZip=${address?.zip || ''}`,
    'associated_search=true'
  ].join('&');

  const associatedPropertiesLink = (
    associatedPropertiesCount > 0 ? (
      <a
        className="text-decoration-underline"
        href={associatedPropertiesUrl}
        rel="noopener noreferrer"
        target="_blank"
      >
        {associatedPropertiesCount} {associatedPropertiesCount == 1 ? 'Property' : 'Properties'}
      </a>
    ) : 'No Properties Available'
  );

  if (!owner) return null;

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center gap-2 mb-2">
        <FontAwesomeIcon className="text-secondary" icon={faCircleUser} />
        <h6 className="m-0 fw-bolder">Ownership</h6>
      </div>
      <Table>
        <tbody>
          {tableRow('Owner Name', <span className="privacy-mask">{owner.fullName}</span>, lookupButton())}
          {tableRow('Owner Type', (owner.isCorporation ? 'Company' : 'Private'))}
          {tableRow('Owner Occupies Property', (ownerOccupied ? 'Yes' : 'No'))}
          {tableRow('Owner Address', <span className="privacy-mask">{fullAddress}</span>)}
          {tableRow('Associated Properties Nationally', associatedPropertiesLink)}
        </tbody>
      </Table>
      <LlcDetails llcData={llcData} />
      {lookupFailure && <ManualLookup defaultState={address.state} />}
    </div>
  );
}

Ownership.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  owner: PropTypes.shape({
    fullName: PropTypes.string,
    isCorporation: PropTypes.bool
  }),
  ownerOccupied: PropTypes.bool,
  propertyId: PropTypes.string
};
