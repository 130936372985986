import ReactDOM from 'react-dom';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownload, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap-5';
import { faArrowPointer } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import ListItem from 'components/file_system/list_item';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { partition } from 'lodash';
import { handleNavigation, previewFile } from '../actions';

export function DocumentNavigationSidebar({
  currentFolder,
  currentFolderLoaded,
  downloadAllUrl,
  file,
  fileNavigationContainerSelector,
  handleNavigation,
  isEuRequest,
  parentFolder,
  previewFile
}) {
  // Note: "locked" means the file isn't accessible by the user
  const [unlockedChildren, inaccessibleChildren] =
    partition(currentFolder.children, ({ locked, lostAccess }) => !locked || lostAccess);
  const gainApprovalChild = currentFolder.children.find(({ accessIfApproved }) => accessIfApproved);
  const handleParentFolderClick = () => parentFolder ? handleNavigation(parentFolder) : null;
  const keyForFile = file => file.folder ? `folder${file.id}` : `file${file.id}`;

  if (typeof currentFolderLoaded === 'function') {
    currentFolderLoaded(!!currentFolder.isRoot, unlockedChildren, inaccessibleChildren, file);
  }

  const downloadAllTooltip = (
    <Tooltip id="download-all-tooltip">This downloads all documents you have access to</Tooltip>
  );

  const renderRow = (item, idx) => {
    const tooltip = <Tooltip id={`list-item-${item.id}`}>{item.name}</Tooltip>;
    const selected = file?.id == item.id;

    const wrapper = (
      <ListGroup.Item
        action
        active={selected}
        className={classNames('rounded-3 border-0 py-3 ', { 'bg-branding': selected })}
      />
    );

    return (
      <OverlayTrigger delay={{ show: 500, hide: 100 }} key={item.id} overlay={tooltip} placement="top">
        <ListItem
          file={item}
          index={idx}
          key={keyForFile(item)}
          selected={selected}
          wrapper={wrapper}
          onNavigation={handleNavigation}
          onPreview={previewFile}
        />
      </OverlayTrigger>
    );
  };

  return (ReactDOM.createPortal(
    (
      <React.Fragment>
        <div>
          <div className="d-flex px-3 align-items-center mb-1">
            <span className="clickable flex-grow-1" onClick={handleParentFolderClick}>
              <strong>
                {parentFolder && <FontAwesomeIcon className="me-2" icon={faChevronLeft}/>}
                {currentFolder.name}
              </strong>
            </span>
          </div>
          <ListGroup variant="flush">
            {unlockedChildren.map(renderRow)}
          </ListGroup>
          {gainApprovalChild && !isEuRequest && (
            <div className="my-4">
              <Button
                className="w-100"
                data-testid="access-secure-documents"
                variant="outline-primary"
                onClick={() => previewFile(gainApprovalChild)}
              >
                <FontAwesomeIcon className="me-2" icon={faArrowPointer}/>
                <strong>Access secure documents</strong>
              </Button>
            </div>
          )}
        </div>
        {downloadAllUrl && (
          <div className="border-top py-3 mt-3">
            <a
              className="btn btnl-link text-body text-branding-hover ps-0 fw-normal"
              href={downloadAllUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon className="fa-fw" icon={faDownload} size="lg"/>
              <span>Download All</span>
              <OverlayTrigger overlay={downloadAllTooltip}>
                <FontAwesomeIcon icon={faInfoCircle} size="xs"/>
              </OverlayTrigger>
            </a>
          </div>
        )}
      </React.Fragment>
    ), document.querySelector(fileNavigationContainerSelector)
  ));
}

DocumentNavigationSidebar.propTypes = {
  currentFolder: PropTypes.shape({
    children: PropTypes.array.isRequired
  }),
  currentFolderLoaded: PropTypes.func,
  downloadAllUrl: PropTypes.string,
  file: PropTypes.shape({
    folder: PropTypes.bool,
    locked: PropTypes.bool,
    id: PropTypes.number.isRequired
  }),
  fileNavigationContainerSelector: PropTypes.string.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  isEuRequest: PropTypes.bool.isRequired,
  parentFolder: PropTypes.object,
  previewFile: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ui: { downloadAllUrl } }) => ({ downloadAllUrl: downloadAllUrl });
const mapDispatchToProps = { handleNavigation, previewFile };

export default connect(mapStateToProps, mapDispatchToProps)(DocumentNavigationSidebar);
