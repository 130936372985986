import React from 'react';
import PropTypes from 'prop-types';

import { confirmable } from 'react-confirm';
import Confirm from 'react-confirm-bootstrap';

class ConfirmDialog extends React.Component {
  static propTypes = {
    message: PropTypes.node.isRequired,
    options: PropTypes.shape({
      buttonLabel: PropTypes.string.isRequired,
      buttonStyle: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired,
    show: PropTypes.bool,

    cancel: PropTypes.func,
    proceed: PropTypes.func
  }

  render() {
    return (
      <Confirm
        body={this.props.message}
        confirmBSStyle={this.props.options.buttonStyle}
        confirmText={this.props.options.buttonLabel}
        title={this.props.options.title || ''}
        visible={this.props.show}
        onClose={this.props.cancel}
        onConfirm={this.props.proceed}
      />
    );
  }
}

export default confirmable(ConfirmDialog);
