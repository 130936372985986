import React from 'react';
import { Col, Form, Row } from 'react-bootstrap-5';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';
import { countries } from 'helpers/countries';
import PasswordInput from 'components/form/password_input';
import { investorTypeMap } from '../../../helpers/vault_access_helper';
import ErrorsForInput, { getErrorsForInput } from '../errors_for_input';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { PRIMARY_ROLE_INVESTOR_OWNER } from '../../../actions/ui';

const emailTakenError = (isRegistration) => {
  const suffix = isRegistration ? 'Please login to Connect to continue.' : 'Login or fill out the form to continue.';
  return `An account with this email already exists. ${suffix}`;
};

export default function GateAccountFields({
  disableForm = false,
  emailTaken,
  errors = [],
  formFields,
  isInvalidProfile = false,
  isRegistration,
  readonly,
  primaryRole,
  onFormFieldChange,
  onInvestorFieldChange
}) {
  const {
    address,
    city,
    companyName,
    countryId,
    email,
    firstName,
    investorTypes,
    jobTitle,
    lastName,
    password,
    phoneNumber,
    state,
    zip
  } = formFields;

  return (
    <React.Fragment>
      {!isInvalidProfile && (
        <Form.Group className="mb-4">
          <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(errors, 'Email').length > 0 || disableForm}
            name="email"
            placeholder="Enter email address"
            readOnly={readonly}
            required
            value={email}
            onChange={e => onFormFieldChange('email', e.target.value)}
          />
          <ErrorsForInput errors={errors} errorString="Email"/>
          {emailTaken && (
            <React.Fragment>
              <ErrorsForInput
                errors={[emailTakenError(isRegistration)]}
                errorString={emailTakenError(isRegistration)}
              />
              <Button
                className="w-100 mt-6 js-login"
              >
                <FontAwesomeIcon icon={faRightFromBracket}/>
                Log in with Buildout
              </Button>
            </React.Fragment>
          )}
        </Form.Group>
      )}


      {!disableForm && isRegistration && (
        <div className="mb-4">
          <PasswordInput
            inputProps={{ readOnly: readonly, isInvalid: getErrorsForInput(errors, 'Password').length > 0 }}
            value={password}
            onChange={value => onFormFieldChange('password', value)}
          />
          <ErrorsForInput errors={errors} errorString="Password" />
        </div>
      )}

      {primaryRole === PRIMARY_ROLE_INVESTOR_OWNER && isRegistration && (
        <Form.Group className="mb-4">
          <Form.Label>Investor Type </Form.Label>
          {Object.keys(investorTypeMap).map(key => (
            <Form.Check
              checked={investorTypes.includes(key)}
              disabled={disableForm}
              id={`investory-type-${key}`}
              key={key}
              label={investorTypeMap[key]}
              name={`investor_type_${key}`}
              type="checkbox"
              value={key}
              onChange={onInvestorFieldChange}
            />
          ))}
        </Form.Group>
      )}

      <Form.Group className="mb-4">
        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
        <Form.Control
          disabled={disableForm}
          isInvalid={getErrorsForInput(errors, 'First Name').length > 0}
          name="first_name"
          placeholder="First Name"
          readOnly={readonly}
          required
          value={firstName}
          onChange={e => onFormFieldChange('firstName', e.target.value)}
        />
        <ErrorsForInput errors={errors} errorString="First Name"/>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
        <Form.Control
          disabled={disableForm}
          isInvalid={getErrorsForInput(errors, 'Last Name').length > 0}
          name="last_name"
          placeholder="Last Name"
          readOnly={readonly}
          required
          value={lastName}
          onChange={e => onFormFieldChange('lastName', e.target.value)}
        />
        <ErrorsForInput errors={errors} errorString="Last Name"/>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
        <Form.Control
          disabled={disableForm}
          isInvalid={getErrorsForInput(errors, 'Phone number').length > 0}
          name="phone_number"
          placeholder="(000) 000-0000"
          readOnly={readonly}
          required
          value={formatPhoneNumber(phoneNumber)}
          onChange={e => onFormFieldChange('phoneNumber', formatPhoneNumber(e.target.value))}
        />
        <ErrorsForInput errors={errors} errorString="Phone number"/>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Company <span className="text-danger">*</span></Form.Label>
        <Form.Control
          disabled={disableForm}
          isInvalid={getErrorsForInput(errors, 'Company').length > 0}
          name="company"
          placeholder="Company Name"
          readOnly={readonly}
          required
          value={companyName}
          onChange={e => onFormFieldChange('companyName', e.target.value)}
        />
        <ErrorsForInput errors={errors} errorString="Company"/>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>Job Title <span className="text-danger">*</span></Form.Label>
        <Form.Control
          disabled={disableForm}
          isInvalid={getErrorsForInput(errors, 'Title').length > 0}
          name="job_title"
          placeholder="Job Title"
          readOnly={readonly}
          required
          value={jobTitle}
          onChange={e => onFormFieldChange('jobTitle', e.target.value)}
        />
        <ErrorsForInput errors={errors} errorString="Title"/>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Country <span className="text-danger">*</span></Form.Label>
        <Form.Select
          disabled={disableForm}
          name="country"
          readOnly={readonly}
          value={countryId}
          onChange={e => onFormFieldChange('countryId', Number(e.target.value))}
        >
          {map(countries, ({ id, label }) => (
            <option key={id} value={id}>{label}</option>
          ))}
        </Form.Select>
      </Form.Group>

      {isRegistration && (
        <React.Fragment>
          {countryId === 1 && (
            <Form.Group className="mb-4">
              <Form.Label>Address</Form.Label>
              <Form.Control
                disabled={disableForm}
                name="address"
                placeholder="Address"
                readOnly={readonly}
                value={address}
                onChange={e => onFormFieldChange('address', e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group className="mb-4">
            <Form.Label>City</Form.Label>
            <Form.Control
              disabled={disableForm}
              name="city"
              placeholder="City"
              readOnly={readonly}
              value={city}
              onChange={e => onFormFieldChange('city', e.target.value)}
            />
          </Form.Group>
          {countryId === 1 && (
            <Row>
              <Col>
                <Form.Group className="mb-4">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    disabled={disableForm}
                    name="state"
                    placeholder="State"
                    readOnly={readonly}
                    value={state}
                    onChange={e => onFormFieldChange('state', e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    disabled={disableForm}
                    name="zip"
                    placeholder="Zip"
                    readOnly={readonly}
                    value={zip}
                    onChange={e => onFormFieldChange('zip', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

GateAccountFields.propTypes = {
  disableForm: PropTypes.bool,
  emailTaken: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  formFields: PropTypes.object.isRequired,
  isInvalidProfile: PropTypes.bool,
  isRegistration: PropTypes.bool.isRequired,
  primaryRole: PropTypes.string,
  readonly: PropTypes.bool.isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
  onInvestorFieldChange: PropTypes.func
};
