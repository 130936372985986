export function setOffMarketPropertyId(id) {
  window.postMessage(
    {
      eventName: 'off_market_property_show',
      payload: { id }
    }
  );
}

export function setExclusiveListingId(id) {
  window.postMessage(
    {
      eventName: 'exclusive_listing_show',
      payload: { id }
    }
  );
}
