export default {
  name: 'Contacts',
  slug: 'contacts',
  fields: [
    {
      key: 'companyName',
      type: 'string',
      label: 'Company Name',
    },
    {
      key: 'companyPhoneNumber',
      type: 'string',
      label: 'Company Phone Number',
    },
    {
      key: 'companyWebsite',
      type: 'string',
      label: 'Company Website',
    },
    {
      key: 'companyFax',
      type: 'string',
      label: 'Company Fax',
    },
    {
      key: 'companyDescription',
      type: 'string',
      label: 'Company Description',
    },
    {
      key: 'companyTags',
      type: 'string',
      label: 'Company Tags',
    },
    {
      key: 'companyNotes',
      type: 'string',
      label: 'Company Notes',
    },
    {
      key: 'title',
      type: 'string',
      label: 'Title',
    },
    {
      key: 'firstName',
      type: 'string',
      label: 'First Name',
      constraints: [{ type: 'required' }],
    },
    {
      key: 'lastName',
      type: 'string',
      label: 'Last Name',
    },
    {
      key: 'email',
      type: 'string',
      label: 'Email',
    },
    {
      key: 'secondaryEmail',
      type: 'string',
      label: 'Secondary Email',
    },
    {
      key: 'mobilePhone',
      type: 'string',
      label: 'Mobile Phone',
    },
    {
      key: 'homePhone',
      type: 'string',
      label: 'Home Phone',
    },
    {
      key: 'otherPhone',
      type: 'string',
      label: 'Other Phone',
    },
    {
      key: 'contact_type',
      type: 'enum',
      label: 'Contact Type',
      config: {
        options: [
          { value: 'blank', label: 'Blank' },
          { value: 'owner_landlord', label: 'Owner/Landlord' },
          { value: 'owner_user', label: 'Owner/User' },
          { value: 'partner', label: 'Partner' },
          { value: 'investor', label: 'Investor' },
          { value: 'property_manager', label: 'Property Manager' },
          { value: 'tenant', label: 'Tenant' },
          { value: 'lender', label: 'Lender' },
          { value: 'developer', label: 'Developer' },
          { value: 'listing_broker', label: 'Listing Broker' },
          {
            value: 'owner_representative',
            label: 'Owner Representative',
          },
          { value: 'buyer', label: 'Buyer' },
          { value: 'seller', label: 'Seller' },
          { value: 'buyer_agent', label: 'Buyer Agent' },
          { value: 'tenant_contact', label: 'Tenant Contact' },
          { value: 'owner_landlord_agent', label: 'Owner Agent' },
          { value: 'seller_agent', label: 'Seller Agent' },
          { value: 'vendor', label: 'Vendor' },
          { value: 'payer', label: 'Payer' },
          { value: 'lender_contact', label: 'Lender Contact' },
          { value: 'buyer_contact', label: 'Buyer Contact' },
          { value: 'seller_contact', label: 'Seller Contact' },
          { value: 'tenant_agent', label: 'Tenant Agent' },
          { value: 'commercial_buyer', label: 'Commerical Buyer' },
          { value: 'commercial_agent', label: 'Commerical Agent' },
          {
            value: 'residential_agent',
            label: 'Residential Agent',
          },
          { value: 'client', label: 'Client' },
          {
            value: 'municipal_government',
            label: 'Municipal Goverment',
          },
          {
            value: 'title_officer',
            label: 'Title Officer',
          },
          {
            value: 'title_examiner',
            label: 'Title Examiner',
          },
          {
            value: 'attorney',
            label: 'Attorney',
          },
          {
            value: 'appraiser',
            label: 'Appraiser',
          },
          {
            value: 'project_manager',
            label: 'Project Manager',
          },
          {
            value: 'facilities_manager',
            label: 'Facilities Manager',
          },
          {
            value: 'architect',
            label: 'Architect',
          },
          {
            value: 'consultant',
            label: 'Consultant',
          },
          {
            value: 'accountant',
            label: 'Accountant',
          },
          {
            value: 'financial_advisor',
            label: 'Financial Advisor',
          },
          {
            value: 'referring_agent',
            label: 'Referring Agent',
          },
          {
            value: 'broker',
            label: 'Broker',
          },
          {
            value: 'contractor',
            label: 'Contractor',
          },
          {
            value: 'subcontractor',
            label: 'Subcontractor',
          },
          {
            value: 'insurance_agent',
            label: 'Insurance Agent',
          },
          {
            value: 'property_inspector',
            label: 'Property Inspector',
          },
          {
            value: 'surveyor',
            label: 'Surveryor',
          },
          {
            value: 'principal',
            label: 'Principal',
          },
        ],
      },
    },
    {
      key: 'addressLabel',
      type: 'string',
      label: 'Address Label',
    },
    {
      key: 'addressLineOne',
      type: 'string',
      label: 'Address Line One',
    },
    {
      key: 'addressLineTwo',
      type: 'string',
      label: 'Address Line Two',
    },
    {
      key: 'addressCity',
      type: 'string',
      label: 'Address City',
    },
    {
      key: 'addressState',
      type: 'string',
      label: 'Address State',
    },
    {
      key: 'addressZip',
      type: 'string',
      label: 'Address Zip',
    },
    {
      key: 'tags',
      type: 'string',
      label: 'Tags',
    },
    {
      key: 'notes',
      type: 'string',
      label: 'Notes',
    },
  ]
};
