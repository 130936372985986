import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSparkles } from '@fortawesome/pro-solid-svg-icons';

import { LIKELY_TO_SELL_RANK_TO_LIKELIHOOD } from '../filters/constants';

export default function LikelyToSellBar({ likelyToSell }) {
  if (likelyToSell?.restricted) return (
    <div
      className="d-flex align-items-center gap-2 py-1 px-2 fw-bold small"
      style={{ backgroundColor: '#7422ce', color: '#f2eafd' }}
    >
      <FontAwesomeIcon icon={faLock} />
      Unlock Likely Seller Score
    </div>
  );

  if (!LIKELY_TO_SELL_RANK_TO_LIKELIHOOD[likelyToSell?.rank]) return null;

  return (
    <div
      className="d-flex align-items-center gap-2 py-1 px-2 fw-bold small"
      style={{ backgroundColor: '#f2eafd' }}
    >
      <FontAwesomeIcon icon={faSparkles} />
      {LIKELY_TO_SELL_RANK_TO_LIKELIHOOD[likelyToSell?.rank]} To Sell
    </div>
  );
}

LikelyToSellBar.propTypes = {
  likelyToSell: PropTypes.shape({
    rank: PropTypes.number,
    score: PropTypes.number,
    restricted: PropTypes.bool
  }),
};
