import React from 'react';
import PropTypes from 'prop-types';
import ManagedInput from 'components/form/managed_input';
import Options from 'components/form/managed_input/options';

const YearInput = ({ alwaysShow, yearsOffset, ...props }) => {
  if (yearsOffset === 1 && !alwaysShow) return null;

  const currentYear = new Date().getFullYear();
  const options = [];
  for (let i = 0; i <= yearsOffset; i++) {
    const year = currentYear + i;
    options.push({ label: year, value: year });
  }

  return (
    <ManagedInput {...props}>
      <Options options={options} />
    </ManagedInput>
  );
};

YearInput.propTypes = {
  alwaysShow: PropTypes.bool,
  yearsOffset: PropTypes.number,
};

export default YearInput;
