import {
  LOAD_DEAL_ROOM,
  RENAMED_DEAL_ROOM,
  ADD_MEMBERS,
  DELETE_MEMBER,
  SET_DEAL_ROOM_EDITING_NAME,
} from '../actions';
export default (state = {}, action) => {
  switch (action.type) {

  case LOAD_DEAL_ROOM:
    return action.dealRoom;
  case RENAMED_DEAL_ROOM:
    return {
      ...state,
      name: action.dealRoom.name,
      editingName: null
    };
  case ADD_MEMBERS:
    return { ...state, memberIds: [...state.memberIds, ...(action.members.map(member => member.id))] };
  case DELETE_MEMBER:
    return { ...state, memberIds: state.memberIds.filter(id => id !== action.member.id) };
  case SET_DEAL_ROOM_EDITING_NAME:
    return { ...state, editingName: action.editingName };
  default:
    return state;
  }
};
