import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import StateSelect from 'components/state_select';

export default function Addresses({ addresses, onSet }) {
  const handleChange = useCallback((idx, values) => {
    onSet(addresses.map((address, index) => {
      if (index == idx) {
        return {
          ...address,
          ...values
        };
      }

      return address;
    }));
  }, [addresses]);

  const handleRemove = useCallback((idx) => {
    onSet(addresses.map((address, index) => {
      if (index == idx) {
        return {
          ...address,
          _destroy: true
        };
      }

      return address;
    }));
  }, [addresses]);

  const handleNew = useCallback(() => {
    onSet([...addresses, { }]);
  }, [addresses]);

  return (
    <div>
      <Row className="align-items-center mb-2">
        <Col><Form.Label className="my-0">Addresses</Form.Label></Col>
        <Col xs="auto">
          <Button size="sm" variant="shaded-primary" onClick={handleNew}>
            + Add New Address
          </Button>
        </Col>
      </Row>
      {addresses?.map((address, index) => {
        if (address._destroy) return;

        const label = () => {
          if (address.label) return address.label;
          if (address.twoLine) return `${address.twoLine[0]} - ${address.twoLine[1]}`;

          return 'New Address';
        };

        return (
          <Accordion className="pb-2" key={`address-${index}`}>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                {label()}
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-3">
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      placeholder="Title"
                      value={address.label || ''}
                      onChange={e => handleChange(index, { label: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Address Line 1</Form.Label>
                    <Form.Control
                      placeholder="Line 1"
                      required
                      value={address.addressOne || ''}
                      onChange={e => handleChange(index, { addressOne: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control
                      placeholder="Line 2"
                      value={address.addressTwo || ''}
                      onChange={e => handleChange(index, { addressTwo: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      placeholder="City"
                      required
                      value={address.city || ''}
                      onChange={e => handleChange(index, { city: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <StateSelect
                      className="form-select"
                      required
                      value={address.state || ''}
                      onChange={e => handleChange(index, { state: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      placeholder="zip"
                      value={address.zip || ''}
                      onChange={e => handleChange(index, { zip: e.target.value })}
                    />
                  </Form.Group>
                  <div className="text-end w-100">
                    <Button variant="shaded-danger" onClick={() => handleRemove(index)}>
                      <FontAwesomeIcon className="shaded-danger" icon={faTrash}/>
                      <span>Remove</span>
                    </Button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
      })}
    </div>
  );
}

Addresses.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    addressOne: PropTypes.string,
    addressTwo: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    _destroy: PropTypes.bool
  })),
  onSet: PropTypes.func.isRequired
};
