import React from 'react';
import PropTypes from 'prop-types';

export default function ImagePreview({ file }) {
  return (
    <React.Fragment>
      <div className="text-center px-5">
        <img
          alt=""
          className="bg-white d-inline-block shadow-sm mw-100"
          name="image-preview"
          src={file.url}
        />
      </div>
    </React.Fragment>
  );
}

ImagePreview.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string
  }),
  zoomRef: PropTypes.object
};
