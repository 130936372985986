import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLock } from '@fortawesome/pro-regular-svg-icons';

export default function DocAccessTooLow() {
  return (
    <div className="py-2">
      <h5 className="text-center mb-4">
        <FontAwesomeIcon className="me-2" icon={faFileLock} />
        <strong>Broker Action Required</strong>
      </h5>
      <p className="text-center m-0">
        There are additional documents that you do not have access to.
        You will receive an email if a broker gives you access to any additional documents.
      </p>
    </div>
  );
}
