import { uniq } from 'lodash';

/* eslint-disable no-console */
const CONNECT_SEARCH_CRITERIA = 'CONNECT_SEARCH_CRITERIA';

export const loadCriteria = () => {
  try {
    const serializedState = localStorage.getItem(CONNECT_SEARCH_CRITERIA);
    if (serializedState === null) return undefined;
    const { criteria } = JSON.parse(serializedState);

    return { criteria };
  } catch (e) {
    return undefined;
  }
};

export const saveCriteria = (criteria) => {
  try {
    const serialState = JSON.stringify(criteria);
    localStorage.setItem(CONNECT_SEARCH_CRITERIA, serialState);
  } catch (e) {
    console.warn('ERROR SAVING CRITERIA', { e });
  }
};

const CONNECT_OFF_MARKET_SEARCH = 'CONNECT_OFF_MARKET_SEARCH';

export const loadOffMarketSearchFlag = () => {
  try {
    const item = localStorage.getItem(CONNECT_OFF_MARKET_SEARCH);
    if (item !== 'false') return true;

    return false;
  } catch (e) {
    return true;
  }
};

export const saveOffMarketSearchFlag = (flag) => {
  try {
    localStorage.setItem(CONNECT_OFF_MARKET_SEARCH, flag);
  } catch (e) {
    console.warn('ERROR SAVING OFF MAERKET SEARCH FLAG', { e });
  }
};

const corporateLlcDataStorageKey = id => `CORPORATE_LLC_DATA_${id}`;

export const loadLlcData = (propertyId) => {
  try {
    const data = localStorage.getItem(corporateLlcDataStorageKey(propertyId));
    if (data) return JSON.parse(data);
  } catch (e) {
    return true;
  }
};

export const saveLlcData = (propertyId, data) => {
  try {
    localStorage.setItem(corporateLlcDataStorageKey(propertyId), JSON.stringify(data));
  } catch (e) {
    console.warn('ERROR SAVING CORPORATE LLC DATA', { e });
  }
};

const corporateDocumentsStorageKey = id => `CORPORATE_DOCUMENTS_${id}`;

export const loadCompanyDocuments = (companyId) => {
  try {
    const data = localStorage.getItem(corporateDocumentsStorageKey(companyId));
    if (data) return JSON.parse(data);
  } catch (e) {
    return true;
  }
};

export const saveCompanyDocuments = (companyId, documents) => {
  try {
    localStorage.setItem(corporateDocumentsStorageKey(companyId), JSON.stringify(documents));
  } catch (e) {
    console.warn('ERROR SAVING COMPANY DOCUMENTS', { e });
  }
};

const RECENT_CONNECT_TAGS = 'RECENT_CONNECT_TAGS';

export const getRecentTags = () => {
  try {
    const tagString = localStorage.getItem(RECENT_CONNECT_TAGS);
    return JSON.parse(tagString) || [];
  } catch (e) {
    console.warn('ERROR FETCHING LOCALLY STORED TAGS', { e });
  }
};

export const setRecentTag = (tag) => {
  try {
    let recentTags = getRecentTags();
    recentTags = uniq([tag, ...recentTags]).slice(0, 4);
    localStorage.setItem(RECENT_CONNECT_TAGS, JSON.stringify(recentTags));
  } catch (e) {
    console.warn('ERROR SAVING TAGS TO LOCALSTORAGE', { e });
  }
};
/* eslint-enable no-console */
