// status should be one of the following ("success", "info", "error")
export const displayToast = (message, status) => {
  const event = new CustomEvent('display-toast', {
    detail: {
      message: message,
      status: status
    }
  });

  window.dispatchEvent(event);
};
