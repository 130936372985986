import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap-5';
import { isEmpty } from 'lodash';
import { createCallList } from '../../helpers/prospect_api';

export default function CreateListModal({ defaultName, show, onClose, onCreate }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [saving, setSaving] = useState(false);

  useEffect(()=>{
    if (show) {
      setName(defaultName || '');
      setDescription('');
    }
  }, [show]);

  const handleSave = () => {
    setSaving(true);

    createCallList(name, description)
      .then((newCallList) => {
        onCreate(newCallList);
      })
      .finally(() => setSaving(false));
  };

  return (
    <Modal
      centered
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>New List</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formCallListName">
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" type="text" value={name} onChange={e => setName(e.target.value)}/>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCallListDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={3}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="link" onClick={onClose}>Cancel</Button>
        <Button disabled={saving || isEmpty(name)} variant="primary" onClick={handleSave}>
          {saving ? 'Saving...' : 'Save List'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CreateListModal.propTypes = {
  defaultName: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired
};
