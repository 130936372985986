import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class MsOfficePreview extends Component {
  static propTypes = {
    file: PropTypes.shape({
      url: PropTypes.string
    })
  };

  render() {
    const iframeSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.props.file.url)}`;
    return (
      <div className="w-100">
        <iframe className="office-preview" src={iframeSrc}/>
      </div>
    );
  }
}
