import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { clearForceRenameFocus, renameDealRoom, setDealRoomEditingName } from '../actions';
class NameEditor extends React.Component {
  static propTypes = {
    clearForceRenameFocus: PropTypes.func.isRequired,
    dealRoom: PropTypes.object,
    editingName: PropTypes.string,
    forceRenameFocus: PropTypes.bool,
    name: PropTypes.string,
    renameDealRoom: PropTypes.func.isRequired,
    renamingDealRoom: PropTypes.bool,
    setDealRoomEditingName: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.forceRenameFocus) {
      this.inputRef.current.focus();
      this.inputRef.current.setSelectionRange(0, -1);
      this.props.clearForceRenameFocus();
    }
  }
  handleChange = (event) => {
    this.props.setDealRoomEditingName(event.target.value);
  }
  handleKeyDown = (event) => {
    event.stopPropagation();
    switch (event.key) {
    case 'Escape':
      this.revert();
      break;
    case 'Enter':
      this.inputRef.current.blur();
      break;
    default:
    }
  }
  save = () => {
    this.props.renameDealRoom(this.props.dealRoom, this.props.editingName);
  }
  revert = () => {
    this.props.setDealRoomEditingName(null).then(() => this.inputRef.current.blur());
  }
  render() {
    if (!this.props.dealRoom.editable) {
      return (
        <span className="grow deal-room-name">
          {this.props.name}
        </span>
      );
    }
    return (
      <React.Fragment>
        <input
          className="deal-room-name form-control"
          disabled={this.props.renamingDealRoom}
          ref={this.inputRef}
          value={this.props.editingName == null ? this.props.name : this.props.editingName}
          onBlur={this.save}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
        />
        {this.props.renamingDealRoom ? <FontAwesomeIcon className="ml-3" icon={faSpinner} size='2x' spin /> : null}
      </React.Fragment>

    );
  }
}
const mapStateToProps = ({ ui: { forceRenameFocus, renamingDealRoom }, dealRoom }) => ({
  name: dealRoom.name,
  editingName: dealRoom.editingName,
  dealRoom,
  forceRenameFocus,
  renamingDealRoom
});
const mapDispatchToProps = {
  clearForceRenameFocus,
  renameDealRoom,
  setDealRoomEditingName
};
export default connect(mapStateToProps, mapDispatchToProps)(NameEditor);
