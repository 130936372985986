import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faClose,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

export const variants = {
  danger: {
    className: 'snackbar--danger',
    icon: faCircleExclamation,
  },
  info: {
    className: 'snackbar--info',
    icon: faCircleInfo,
  },
  success: {
    className: 'snackbar--success',
    icon: faCircleCheck,
  },
  warning: {
    className: 'snackbar--warning',
    icon: faTriangleExclamation,
  },
};

export default function Snackbar({
  autohide = true,
  delay = 4000,
  icon,
  portalElementSelector = 'body',
  position = 'bottom',
  snackbarEndingPosition,
  show = false,
  text,
  variant,
  onClose,
}) {
  const [hasAnimated, setHasAnimated] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    if (!show) {
      if (hasAnimated) setHasAnimated(false);
      return;
    }

    setTimeout(() => setHasAnimated(true), 50);
  }, [show]);

  useEffect(() => {
    if (autohide && show) {
      timeoutRef.current = setTimeout(onClose, delay);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [autohide, show]);

  if (!show) {
    return null;
  }

  const variantConfig = variants[variant];
  icon = icon || variantConfig?.icon;

  const positionBasedEnding = position === 'bottom' ? '-160px' : '0px';
  const endingPositionWithFallback = snackbarEndingPosition || positionBasedEnding;

  return createPortal(
    <div
      aria-label="notification"
      className={`snackbar-container d-flex justify-content-center ${`snackbar-container--${position}`}`}
      style={{ '--snackbar-ending-position': hasAnimated ? endingPositionWithFallback : '0' }}
    >
      <div
        className={classNames('snackbar fade d-inline-flex', variantConfig?.className)}
        onClose={onClose}
      >
        <div className="d-flex align-items-center py-3 px-4 snackbar-gap">
          {icon && <FontAwesomeIcon icon={icon}/>}

          <div className="flex-1">
            {text}
          </div>

          <FontAwesomeIcon
            aria-label="close"
            icon={faClose}
            role="button"
            onClick={onClose}
          />
        </div>
      </div>
    </div>,
    document.querySelector(portalElementSelector),
  );
}

Snackbar.propTypes = {
  autohide: PropTypes.bool,
  delay: PropTypes.number,
  icon: PropTypes.object,
  portalElementSelector: PropTypes.string,
  position: PropTypes.oneOf(['bottom', 'top']),
  show: PropTypes.bool,
  snackbarEndingPosition: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(Object.keys(variants)),
  onClose: PropTypes.func.isRequired,
};
