import { Controller } from '@hotwired/stimulus';
import { every } from 'lodash';

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.inputs = this.element.querySelectorAll('input, select');

    this.element.addEventListener('change', this.handleChange.bind(this));
    this.element.addEventListener('keyup', this.handleChange.bind(this));

    this.handleChange();
  }

  handleChange() {
    const valid = every(this.inputs, i => i.checkValidity());

    this.buttonTarget.toggleAttribute('disabled', !valid);
  }
}
