import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import OffMarketCompDetailsCard from './off_market_comp_details_card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faCodeCompare } from '@fortawesome/pro-solid-svg-icons';
import { formatWithCommas } from '../../../../../helpers/formatters/number_formats';
import { Col, Form, Row } from 'react-bootstrap-5';
import OffMarketCompChart from './off_market_comp_chart';

const CHARTS = {
  avgPrice: { label: 'Avg. Price', title: 'Average Price' },
  avgPricePerSf: { label: 'Avg. Price Per SF', title: 'Average Price Per SF' },
  avgPricePerUnit: { label: 'Avg. Price Per Unit', title: 'Average Price Per Unit' }
};

const chartedPropertyAmount = (property, chartType, propertyTypeId) => {
  if (chartType === 'avgPrice') {
    return property.lastSalePrice;
  } else if (chartType === 'avgPricePerSf' && propertyTypeId === 5) {
    return property.lastSalePrice / property.lotSizeSf;
  } else if (chartType === 'avgPricePerSf' && propertyTypeId !== 5) {
    return property.lastSalePrice / property.buildingSizeSf;
  } else if (chartType === 'avgPricePerUnit') {
    return property.lastSalePrice / ((property.commercialUnits || 0) + (property.residentialUnits || 0));
  }
};

export default function OffMarketComps({ initialProperties, products, subjectProperty }) {
  const [properties, setProperties] = useState(initialProperties);
  const [selectedChart, setSelectedChart] = useState('avgPrice');
  const propertyTypeId = subjectProperty.propertyTypeId;

  const avgSalePriceSFProperties = useMemo(() => {
    if (propertyTypeId === 5) {
      return properties.filter(property => property.lotSizeSf || property.lotSizeSf > 0);
    } else {
      return properties.filter(property => property.buildingSizeSf || property.buildingSizeSf > 0);
    }
  }, [properties]);

  const avgSalePricePerUnitProperties = useMemo(() => {
    return properties.filter(property => property.commercialUnits + property.residentialUnits > 0);
  }, [properties]);

  const avgSalePrice = useMemo(() => {
    const totalSum = properties.reduce((sum, property) => sum + property.lastSalePrice, 0);
    return Math.floor(totalSum / properties.length);
  }, [properties]);

  const avgSalePriceSF = useMemo(() => {
    if (avgSalePriceSFProperties.length === 0) {
      return 0;
    }
    let totalSum;
    // If land calc by lot size
    if (propertyTypeId === 5) {
      totalSum = avgSalePriceSFProperties.reduce(
        (sum, property) => sum + (property.lastSalePrice / property.lotSizeSf), 0
      );
    } else {
      totalSum = avgSalePriceSFProperties.reduce(
        (sum, property) => sum + (property.lastSalePrice / property.buildingSizeSf), 0
      );
    }

    return Math.floor(totalSum / avgSalePriceSFProperties.length);
  }, [properties]);

  const avgSalePricePerUnit = useMemo(() => {
    if (avgSalePricePerUnitProperties.length === 0) {
      return 0;
    }
    const totalSum = avgSalePricePerUnitProperties.reduce(
      (sum, property) => (
        sum + (property.lastSalePrice / ((property.commercialUnits || 0) + (property.residentialUnits || 0))), 0
      )
    );

    return Math.floor(totalSum / avgSalePricePerUnitProperties.length);
  }, [properties]);

  const chartOptions = useMemo(() => {
    const options = ['avgPrice', 'avgPricePerSf'];
    if (propertyTypeId === 1 || propertyTypeId === 6) options.push('avgPricePerUnit');
    return options;
  }, [subjectProperty]);

  const chartData = useMemo(() => {
    let filteredProperties = [];
    if (selectedChart === 'avgPrice') {
      filteredProperties = properties;
    } else if (selectedChart === 'avgPricePerSf') {
      filteredProperties = avgSalePriceSFProperties;
    } else if (selectedChart === 'avgPricePerUnit') {
      filteredProperties = avgSalePricePerUnitProperties;
    }

    filteredProperties = [subjectProperty, ...filteredProperties];

    return filteredProperties.map(
      property => (
        {
          name: property.address,
          sale_price: Math.floor(chartedPropertyAmount(property, selectedChart, propertyTypeId))
        }
      )
    );
  }, [selectedChart, properties, subjectProperty]);

  const avgChartLine = useMemo(() => {
    if (selectedChart === 'avgPrice') {
      return avgSalePrice;
    } else if (selectedChart === 'avgPricePerSf') {
      return avgSalePriceSF;
    } else if (selectedChart === 'avgPricePerUnit') {
      return avgSalePricePerUnit;
    }
  }, [properties, selectedChart]);

  const handlePropertyRemoval = useCallback((propertyId) => {
    const updatedProperties = properties.filter(property => property.propertyId !== propertyId);
    setProperties(updatedProperties);
  }, [properties]);

  return (
    <div className="d-flex flex-column gap-6">
      <div>
        <div className="d-flex align-items-center gap-2 mb-3">
          <FontAwesomeIcon className="text-secondary" icon={faChartSimple} />
          <h6 className="my-0 fw-bolder"> Analysis </h6>
        </div>
        <div className="d-flex flex-column gap-3">
          <Row className="gap-3 mx-0">
            { avgSalePrice > 0 && (
              <Col className="bg-light p-2 rounded">
                <strong>{`$${formatWithCommas(avgSalePrice)}`}</strong>
                <div className="small text-muted">Avg. Sold Price</div>
              </Col>
            )}
            { (propertyTypeId === 1 || propertyTypeId === 6) && avgSalePricePerUnit > 0 && (
              <Col className="bg-light p-2 rounded">
                <strong>${formatWithCommas(avgSalePricePerUnit)} <small>/ Unit</small></strong>
                <div className="small text-muted">Avg. Sold Price (Unit)</div>
              </Col>
            )}
            { avgSalePriceSF > 0 && (
              <Col className="bg-light p-2 rounded">
                <strong>${formatWithCommas(avgSalePriceSF)} <small>/ SF</small></strong>
                <div className="small text-muted">Avg. Sold Price (SF)</div>
              </Col>
            )}
          </Row>
          <div>
            <Row className="align-items-center">
              <Col>
                <h6>{CHARTS[selectedChart].title}</h6>
              </Col>
              <Col>
                <Form.Select value={selectedChart} onChange={e => setSelectedChart(e.target.value)}>
                  {chartOptions.map(option => (
                    <option key={option} value={option}>
                      {CHARTS[option].label}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <OffMarketCompChart avgLine={avgChartLine} data={chartData} />
          </div>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center gap-2 mb-3">
          <FontAwesomeIcon className="text-secondary" icon={faCodeCompare} />
          <h6 className="my-0 fw-bolder"> Comparables </h6>
        </div>
        <div>
          <Row className="g-3 align-items-stretch">
            {properties.map(property => (
              <Col key={property.propertyId} xs={6}>
                <OffMarketCompDetailsCard products={products} property={property} onRemoval={handlePropertyRemoval} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}

OffMarketComps.propTypes = {
  initialProperties: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  subjectProperty: PropTypes.object.isRequired
};
