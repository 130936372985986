import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import NewTemplateModal from './new_template_modal';
import EditTemplateModal from './edit_template_modal';
import TemplateList from './template_list';
import { deleteDataExportTemplate } from '../../helpers/prospect_api';

export default function ExportTemplates({ columnOptions, templates }) {
  const [showModal, setShowModal] = useState(false);
  const [templateList, setTemplateList] = useState(templates);
  const [editingTemplate, setEditingTemplate] = useState(null);

  const handleTemplateAdded = (template) => {
    setTemplateList([...templateList, template]);
  };

  const handleDeleteTemplate = (template) => {
    if (confirm(`Are you sure you want to delete template "${template.name}"?`)) {
      deleteDataExportTemplate(template.id).then(() => {
        setTemplateList(templateList.filter(t => t.id !== template.id));
      });
    }
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
  };

  const handleTemplateUpdated = (template) => {
    setTemplateList(templates.map((t) => {
      if (t.id == template.id) return template;

      return t;
    }));
  };

  return (
    <Card>
      <Card.Header className="p-6">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="mb-0">Export Templates</h5>

          <Button variant="secondary" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus}/>
            New Template
          </Button>

          <NewTemplateModal
            columnOptions={columnOptions}
            show={showModal}
            onHide={() => setShowModal(false)}
            onTemplateAdded={handleTemplateAdded}
          />
          <EditTemplateModal
            columnOptions={columnOptions}
            template={editingTemplate}
            onHide={() => setEditingTemplate(null)}
            onTemplateUpdated={handleTemplateUpdated}
          />
        </div>
      </Card.Header>
      <Card.Body className="p-6">
        <TemplateList
          templates={templateList}
          onDeleteTemplate={handleDeleteTemplate}
          onEditTemplate={handleEditTemplate}
        />
      </Card.Body>
    </Card>
  );
}

ExportTemplates.propTypes = {
  columnOptions: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired
};
