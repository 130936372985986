import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faRectangleHistoryCircleUser } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from '../empty_state';

export default function Lists({ callListItems, onDelete }) {
  if (callListItems.length === 0) {
    return (
      <EmptyState
        icon={faRectangleHistoryCircleUser}
        text="This contact is not in any lists"
      />
    );
  }

  return (
    <React.Fragment>
      {callListItems.map(callListItem => (
        <div className="d-flex border-bottom justify-content-between p-2" key={callListItem.callList.id}>
          <div>
            <div className="text-truncate fw-bolder">
              <a href={`/prospect/connect_call_lists/${callListItem.callList.id}`}>{callListItem.callList.name}</a>
            </div>
            <small className="text-muted">Created: {callListItem.callList.createdAt}</small>
          </div>
          <Button variant="link" onClick={() => onDelete(callListItem)}>
            <FontAwesomeIcon className="text-danger" icon={faTrash} />
          </Button>
        </div>
      ))}
    </React.Fragment>
  );
}

Lists.propTypes = {
  callListItems: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
};
