import React from 'react';
import PropTypes from 'prop-types';

import { onDownload } from 'components/file_system/viewers';
import { Button, Card, Col, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChainBroken, faDownload } from '@fortawesome/free-solid-svg-icons';

export default function NoPreview({ file }) {
  const downloadUrl = file && (file.downloadUrl || file.url);

  return (
    <Row className="justify-content-center">
      <Col md={8} xl={6}>
        <Card>
          <Card.Body>
            <div className="text-center my-8">
              {downloadUrl && (
                <div name="downloadable">
                  <div>
                    This file cannot be previewed in the browser.<br />
                    Click the button below to download the document.
                  </div>
                  <Button className="mt-4" variant="primary" onClick={() => onDownload(file)}>
                    <FontAwesomeIcon className="me-1" icon={faDownload} />
                    Download
                  </Button>
                </div>
              )}
              {!downloadUrl && (
                <div name="not-downloadable">
                  <FontAwesomeIcon className="mb-6 text-muted" icon={faChainBroken} size="3x" />
                  <div>Sorry, something went wrong and this file cannot be displayed at this time.</div>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

NoPreview.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string
  })
};
