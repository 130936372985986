export const SET_SHOW_LOGIN = 'SET_SHOW_LOGIN';

export function setShowLogin(show) {
  return {
    type: SET_SHOW_LOGIN,
    payload: show
  };
}

export const SET_SHOW_INFO_REQUEST = 'SET_SHOW_INFO_REQUEST';

export function setShowInfoRequest(show) {
  return {
    type: SET_SHOW_INFO_REQUEST,
    payload: show
  };
}

export const SET_PRIMARY_ROLE = 'SET_PRIMARY_ROLE';
export const USER_TYPE_BROKER = 'broker';
export const USER_TYPE_OWNER = 'owner';
export const PRIMARY_ROLE_INVESTOR_OWNER = 'investor_owner';
export const PRIMARY_ROLE_COMMERCIAL_BROKER = 'commercial_broker';
export const PRIMARY_ROLE_RESIDENTIAL_BROKER = 'residential_broker';
export const PRIMARY_ROLE_OTHER = 'other';

export function isValidPrimaryRole(primaryRole) {
  return primaryRole === PRIMARY_ROLE_INVESTOR_OWNER || primaryRole === PRIMARY_ROLE_COMMERCIAL_BROKER ||
         primaryRole === PRIMARY_ROLE_RESIDENTIAL_BROKER || primaryRole === PRIMARY_ROLE_OTHER;
}
export function labelForPrimaryRole(primaryRole) {
  switch (primaryRole) {
  case PRIMARY_ROLE_INVESTOR_OWNER:
    return 'Investor / Owner / Developer';
  case PRIMARY_ROLE_COMMERCIAL_BROKER:
    return 'Commercial Broker';
  case PRIMARY_ROLE_RESIDENTIAL_BROKER:
    return 'Residential Broker';
  case PRIMARY_ROLE_OTHER:
    return 'Other Service Provider';
  default:
    return '';
  }
}

export const SET_SHOW_NO_DOCS_BANNER = 'SET_SHOW_NO_DOCS_BANNER';

// see reducer for what `show` can be
export function setShowNoDocsBanner(show) {
  return {
    type: SET_SHOW_NO_DOCS_BANNER,
    payload: show
  };
}
