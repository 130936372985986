import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['messageInput', 'requiredLabel', 'optionalLabel']

  connect() {
    this.setMessageRequired();
  }

  setMessageRequired() {
    const value = this.element.querySelector('input:checked')?.value;
    this.messageInputTarget.required = value === 'other';
    this.requiredLabelTarget.classList.toggle('d-none', value !== 'other');
    this.optionalLabelTarget.classList.toggle('d-none', value === 'other');
  }
}
