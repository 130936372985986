import { LOAD_RECYCLE_BIN, RESTORED_ENTRY, DELETED_ENTRY, RECYCLED_ENTRY } from "../actions";

export default (state = { entryIds: [] }, action) => {
  switch (action.type) {

  case LOAD_RECYCLE_BIN:
    return { entryIds: action.entries.map(entry => entry.id) };
  case RECYCLED_ENTRY:
    return { ...state, entryIds: [...state.entryIds, action.entry.id] };
  case DELETED_ENTRY:
  case RESTORED_ENTRY:
    return { entryIds: state.entryIds.filter(id => id !== action.entry.id) };
  default:
    return state;
  }
};
