import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'spinner']

  connect() {
    this.boundResize = this.resizeAlphamapIframe.bind(this);
    window.addEventListener('message', this.boundResize);
  }

  resizeAlphamapIframe(e) {
    if (e && e.data && e.data.payload && e.data.payload.height) {
      this.contentTarget.style.height = Math.floor(e.data.payload.height) + 'px';
    }
  }

  showMap() {
    this.spinnerTarget.classList.add('d-none');
    this.contentTarget.classList.remove('d-none');
  }

  disconnect() {
    window.removeEventListener('message', this.boundResize);
  }
}
