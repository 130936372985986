import React, { useState } from 'react';
import { Form } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

export default function HideShowPassword(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="position-relative">
      <Form.Control
        {...props}
        type={showPassword ? 'text' : 'password'}
      />
      <button
        className="btn btn-icon position-absolute end-0 top-50 translate-middle-y me-2"
        type="button"
        onClick={() => setShowPassword(!showPassword)}
      >
        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash}/>
      </button>
    </div>
  );
}