import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import StateSelect from 'components/state_select';

import { setFilterAttribute } from '../../../store/actions/criteria';

function FilterStateSelect({ criteria, setFilterAttribute }) {
  return (
    <StateSelect
      className={`form-select ${classNames('as-button', criteria.ownerState ? 'active' : 'text-muted')}`}
      includeBlank="Select..."
      value={criteria.ownerState}
      onChange={e => setFilterAttribute('ownerState', e.target.value)}
    />
  );
}

FilterStateSelect.propTypes = {
  criteria: PropTypes.object.isRequired,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(FilterStateSelect);
