import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { faFolderPlus, faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import { createFolder, uploadFiles } from '../actions';

export const NewDropdown = ({ contributor, createFolder, creatingFolder, inRecycleBin, fileSearchOpen,
  uploadFiles, currentFolderId, dropdownButtonComponent, dropdownMenuItemComponent }) => {

  const DropdownButton = dropdownButtonComponent;
  const MenuItem = dropdownMenuItemComponent;

  const handleDrop = (files) => {
    uploadFiles(files, currentFolderId);
  };

  const handleCreateFolder = () => {
    if (creatingFolder) return;

    createFolder();
  };

  const renderNewFolderButton = () => {
    return (
      <MenuItem name="new-folder-button" onClick={handleCreateFolder}>
        <FontAwesomeIcon className="mr-2 me-2" icon={creatingFolder ? faSpinner : faFolderPlus} spin={creatingFolder} />
        New Folder
      </MenuItem>
    );
  };

  const renderUploadButton = () => {
    return (
      <MenuItem name="upload-button">
        <Dropzone noDrag onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <section {...getRootProps()}>
              <span>
                <FontAwesomeIcon className="mr-2 me-2" icon={faUpload} />
                Upload
              </span>
              <input {...getInputProps()} />
            </section>
          )}
        </Dropzone>
      </MenuItem>
    );
  };

  if (!contributor || inRecycleBin || fileSearchOpen) return null;

  return (
    <DropdownButton
      bsStyle="create"
      id="new-dropdown"
      pullRight
      title="+ New "
    >
      {renderUploadButton()}
      {renderNewFolderButton()}
    </DropdownButton>
  );
};

NewDropdown.propTypes = {
  contributor: PropTypes.bool,
  createFolder: PropTypes.func,
  creatingFolder: PropTypes.bool,
  currentFolderId: PropTypes.number,
  dropdownButtonComponent: PropTypes.elementType,
  dropdownMenuItemComponent: PropTypes.elementType,
  fileSearchOpen: PropTypes.bool,
  inRecycleBin: PropTypes.bool,
  uploadFiles: PropTypes.func
};

const mapStateToProps = ({
  ui: { inRecycleBin, creatingFolder, currentFolderId },
  fileSearch: { open },
  dealRoom
}) => ({
  contributor: dealRoom.access.contributor,
  creatingFolder,
  currentFolderId,
  fileSearchOpen: open,
  inRecycleBin,
});

const mapDispatchToProps = { createFolder, uploadFiles };

export default connect(mapStateToProps, mapDispatchToProps)(NewDropdown);
