import React from 'react';
import PropTypes from 'prop-types';

import DefaultDropdown from 'components/dropdowns/dropdown';
import FileIcon from './file_icon';
import ManagedInput from 'components/form/managed_input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCog, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatDateTime } from 'helpers/formatters/date_time_formats';

import { ATTRIBUTES } from './table';
import { download } from './helpers';
import { listItemPropTypes, reorderableListItem } from 'helpers/react_dnd/reorderable_list';

export class Row extends React.Component {
  static propTypes = {
    ...listItemPropTypes,
    actionIcon: PropTypes.object,
    actions: PropTypes.arrayOf(PropTypes.shape),
    additionalColumns: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      source: PropTypes.string,
    })),
    attributeOverrides: PropTypes.object,
    dropdownComponent: PropTypes.elementType.isRequired,
    excludedColumns: PropTypes.arrayOf(PropTypes.string),
    file: PropTypes.shape({
      children: PropTypes.array,
      createdBy: PropTypes.string,
      folder: PropTypes.bool,
      id: PropTypes.number,
      lastAccessed: PropTypes.string,
      lastModified: PropTypes.string,
      name: PropTypes.string,
      selected: PropTypes.bool,
      size: PropTypes.string,
      url: PropTypes.string
    }),
    highlighted: PropTypes.bool,
    preventDrag: PropTypes.bool,
    showActivityModal: PropTypes.func,
    onNavigation: PropTypes.func,
    onPreview: PropTypes.func,
    onRename: PropTypes.func,
    onSelect: PropTypes.func
  }

  renameInput = React.createRef();

  static defaultProps = {
    additionalColumns: [],
    dropdownComponent: DefaultDropdown,
    excludedColumns: [],
    actions: [],
  }

  state = {
    renaming: false,
    fileName: this.props.file.name,
  }

  getAttributeProps() {
    const attributeProps = {};

    ATTRIBUTES.forEach((attribute) => {
      const overrides = this.props.attributeOverrides && this.props.attributeOverrides[attribute.key];
      attributeProps[attribute.key] = { ...attribute, ...overrides };
    });

    if (this.props.additionalColumns) {
      this.props.additionalColumns.forEach((column) => {
        attributeProps[column.key] = column;
      });
    }

    return attributeProps;
  }

  componentDidUpdate(prevProps) {
    if (this.state.renaming && this.renameInput.current) this.renameInput.current.focus();

    if (prevProps.file.name !== this.props.file.name) this.setState({ fileName: null });
  }

  folder = () => {
    const { file } = this.props;
    return this.iconType() == 'folder' || file.folder;
  }

  iconType = () => this.props.file.icon || this.props.file.iconType;

  renameFile = () => {
    this.setState({ renaming: true, fileName: this.props.file.name });
  }

  handleFileClick = () => {
    if (this.state.renaming) return;

    const { file, onNavigation, onPreview } = this.props;
    this.folder() ? onNavigation(file) : onPreview(file);
  }

  updateFileName = (changes) => {
    this.setState({ ...changes });
  }

  saveFileName = () => {
    this.setState({ renaming: false });
    const { file } = this.props;
    const { fileName } = this.state;
    if (fileName !== file.name) this.props.onRename(this.props.file, this.state.fileName);
  }

  selectFile = () => {
    const { file, onSelect } = this.props;
    onSelect(file);
  }

  renderCheckbox() {
    return (
      this.props.onSelect &&
      <div>
        <ManagedInput
          checked={this.props.file.selected}
          name="selected"
          type="checkbox"
          onChange={this.selectFile}
        />
      </div>
    );
  }

  renderFileName() {
    return this.state.renaming ? (
      <ManagedInput
        aria-label="fileNameInput"
        className="form-control"
        name="fileName"
        ref={this.renameInput}
        value={this.state.fileName}
        onBlur={this.saveFileName}
        onChange={this.updateFileName}
        onEnter={this.saveFileName}
      />
    ) : (
      <span name="static-name">
        {this.state.fileName || this.props.file.name}
      </span>
    );
  }

  renderActionsDropdown() {
    const Dropdown = this.props.dropdownComponent;
    const options = [];

    if (this.props.onRename && this.props.file.icon !== 'trash') {
      options.push({ icon: faPencilAlt, label: 'Rename', name: 'rename', onClick: this.renameFile });
    }

    if (this.props.file.url) {
      options.push({ icon: faDownload, label: 'Download', name: 'download', onClick: () => download(this.props.file) });
    }

    this.props.actions.forEach(action => (
      options.push({ name: action.key, icon: action.icon, label: action.label, onClick: action.callback })
    ));

    return (
      <Dropdown optionGroups={[options]}>
        <FontAwesomeIcon className="text-muted" icon={this.props.actionIcon || faCog} size="lg" />
      </Dropdown>
    );
  }

  renderLastAccessed() {
    const { file, showActivityModal, excludedColumns } = this.props;

    return (!excludedColumns.includes('lastAccessed') &&
      <React.Fragment>
        <td className="padded">{file.lastAccessed ? formatDateTime(file.lastAccessed) : ''}</td>
        <td>
          {file.lastAccessed &&
            <a className="clickable" onClick={showActivityModal}>View Activity</a>
          }
        </td>
      </React.Fragment>
    );
  }

  renderRow() {
    const { additionalColumns, file, excludedColumns, highlighted } = this.props;
    const attributeProps = this.getAttributeProps();

    const paddingClass = key => attributeProps[key].padded ? 'padded' : '';

    return (
      <tr className={highlighted ? 'warning' : undefined}>
        {!excludedColumns.includes('select') && (
          <td className="fit-content py-0 pr-0">{this.renderCheckbox()}</td>
        )}
        <td className="fit-content pr-0" onClick={this.handleFileClick}>
          <FileIcon type={this.iconType()} />
        </td>
        <td className="clickable bold file-name" name="name-col" onClick={this.handleFileClick}>
          {this.renderFileName()}
        </td>

        {!excludedColumns.includes('size') && <td className={paddingClass('size')}>{file.size}</td>}
        {!excludedColumns.includes('owner') && <td className={paddingClass('createdBy')}>{file.createdBy}</td>}
        {!excludedColumns.includes('lastModified') &&
          <td className={paddingClass('lastModified')}>{file.lastModified ? formatDateTime(file.lastModified) : ''}</td>
        }

        {this.renderLastAccessed()}
        {additionalColumns?.map(column => (
          <td className={paddingClass(column.key)} key={column.key}>{file[column.key]}</td>
        ))}
        <td>{this.renderActionsDropdown()}</td>
      </tr>
    );
  }

  render() {
    const { connectDragPreview, connectDropTarget, connectDragSource, preventDrag } = this.props;
    if (preventDrag) return this.renderRow();

    const draggable = connectDragPreview(connectDragSource(this.renderRow()));
    return this.folder() ? connectDropTarget(draggable) : draggable;
  }
}

export default reorderableListItem(Row, 'fileRow', {
  beginDrag(props) {
    props.onBeginDrag(props.index);
    return { id: props.file.id };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) props.onEndDrag(props, monitor);
  }
});
