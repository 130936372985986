import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStreetView } from '@fortawesome/free-solid-svg-icons';

const MAX_RADIUS = 200;

export default function StreetViewModal({ address, city, state, zip }) {
  const [showModal, setShowModal] = useState(false);
  const [panoId, setPanoId] = useState();
  const streetViewRef = useRef();
  const svService = new google.maps.StreetViewService();
  const geocoder = new google.maps.Geocoder();
  const fullAddress = `${address}, ${city}, ${state} ${zip}`;

  useEffect(() => {
    fetchStreetView();
  }, []);

  useEffect(() => {
    if (showModal) {
      new google.maps.StreetViewPanorama(streetViewRef.current, {
        pano: panoId,
      });
    }
  }, [showModal]);

  // To avoid the black screen issue in some places
  // We'll try to find the closest panoramic view
  // In case we can't find a panoramic view, we won't show this button
  const findPanorama = (location, radius = 50) => {
    svService.getPanorama(
      { location: location, radius: radius },
      (data, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          setPanoId(data.location.pano);
        } else if (radius <= MAX_RADIUS) {
          findPanorama(location, radius + 5);
        }
      }
    );
  };

  const fetchStreetView = () => {
    geocoder.geocode({ address: fullAddress }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        findPanorama(results[0].geometry.location);
      }
    });
  };

  if (!panoId) return null;

  return (
    <React.Fragment>
      <Button
        className="m-2"
        variant="outline-primary"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faStreetView} />
        Street View
      </Button>
      <Modal
        centered
        dialogClassName="modal-90w"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="py-3" closeButton>
          <Modal.Title as="h5">Street View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 h-100" ref={streetViewRef} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

StreetViewModal.propTypes = {
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
};
