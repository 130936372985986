import { connect } from 'react-redux';
import { Button } from 'react-bootstrap-5';
import React from 'react';
import PropTypes from 'prop-types';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import PreviewPortal from './index';

function AccountFormPortal({ profile, setProfileData, onNext }) {
  function previewButtonClicked() {
    const updatedProfile = { ...profile, [PROFILE_OPTIONS.PROFILE_PRESENT]: true };
    setProfileData(updatedProfile);
    onNext('', true, { previewProfile: updatedProfile });
  }

  return (
    <PreviewPortal>
      <Button variant="primary" onClick={previewButtonClicked}>Fill out information and submit</Button>
    </PreviewPortal>
  );
}

AccountFormPortal.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(AccountFormPortal);
