import React from 'react';
import PropTypes from 'prop-types';

export default class Breadcrumbs extends React.Component {
  static propTypes = {
    folders: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number,
    })),
    onNavigation: PropTypes.func.isRequired
  }

  render() {
    const folders = this.props.folders.map((folder, i) => {
      return (
        <span className="clickable" key={i} onClick={() => this.props.onNavigation(folder)}>
          {folder.name}
          {i < this.props.folders.length - 1 && (
            <span className="mx-2">{'>'}</span>
          )}
        </span>
      );
    });

    return (
      <h4>
        {folders}
      </h4>
    );
  }
}
