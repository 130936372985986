import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Badge } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import CriteriaDecorator from '../../helpers/criteria_decorator';
import { setFilterAttribute } from '../../store/actions/criteria';

import PropTypes from 'prop-types';

function FilterBadge({ filter, removeFilter }) {
  const removeable = !!filter.keys;
  if (filter.keys && filter.keys.includes('locationAttributes')) return null;

  return (
    <Col key={filter.label} xs="auto">
      <Badge
        bg="powder-blue"
        className={`rounded-pill px-3 py-2 ${removeable ? 'clickable' : ''}`}
        onClick={filter.keys ? () => removeFilter(filter) : undefined}
      >
        {filter.label}
        {removeable && <FontAwesomeIcon className="ms-2" icon={faXmark} size="lg" />}
      </Badge>
    </Col>
  );
}

FilterBadge.propTypes = {
  filter: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string
  }),
  removeFilter: PropTypes.func
};

function EmptyStateBadges({ filterSummary, setFilterAttribute }) {
  const removeFilter = (filter) => {
    if (!filter.keys) return;

    filter.keys.forEach(key => setFilterAttribute(key, undefined));
  };

  return (
    <Row className="g-2 justify-content-center">
      {filterSummary.map(filter => <FilterBadge filter={filter} key={filter.label} removeFilter={removeFilter}/>)}
    </Row>
  );
}

EmptyStateBadges.propTypes = {
  filterSummary: PropTypes.arrayOf(PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    label: PropTypes.string
  })),
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria, ui: { offMarketSearch } }) => ({
  filterSummary: new CriteriaDecorator(criteria, offMarketSearch).filterSummary()
});
const mapDispatchToProps = { setFilterAttribute };

export default connect(mapStateToProps, mapDispatchToProps)(EmptyStateBadges);
