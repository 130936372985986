import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Collapse, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function ContactSection({ children, defaultOpen, title }) {
  const [open, setOpen] = useState(!!defaultOpen);

  return (
    <div className="bg-light p-3">
      <Row className="align-items-center">
        <Col><strong>{title}</strong></Col>
        <Col xs="auto">
          <Button className="btn-icon" variant="secondary-text" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={faAngleRight} rotation={open ? 90 : undefined} />
          </Button>
        </Col>
      </Row>
      <Collapse in={open}>
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  );
}

ContactSection.propTypes = {
  children: PropTypes.node.isRequired,
  defaultOpen: PropTypes.bool,
  title: PropTypes.string.isRequired
};

