import { HANDLE_NAVIGATION, PREVIEW_FILE } from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
  case HANDLE_NAVIGATION:
    return action.navigation;
  case PREVIEW_FILE:
    return { ...state, lockedFolderId: null };
  default:
    return state;
  }
};
