import {
  faDollarSign, faChartBar, faMapMarkerAlt, faImage, faInfoCircle, faPercent
} from '@fortawesome/pro-solid-svg-icons';

export const REJECTION_OTHER = 'other';
export const REJECTION_REASONS = [
  { name: 'price', label: 'Price', icon: faDollarSign },
  { name: 'cap_rate', label: 'Cap rate', icon: faPercent },
  { name: 'noi', label: 'NOI', icon: faChartBar },
  { name: 'location', label: 'Location', icon: faMapMarkerAlt },
  { name: 'photos', label: 'Photo', icon: faImage },
  { name: 'need_more_info', label: 'Need more information', icon: faInfoCircle },
  { name: REJECTION_OTHER, label: 'Other' }
];
