import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col } from 'react-bootstrap-5';
import { isEmpty, map, omitBy, uniq } from 'lodash';

import { post, get, put } from 'helpers/api';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';
import { countries } from 'helpers/countries';

import PasswordInput from 'components/form/password_input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { investorTypeMap } from '../../helpers/vault_access_helper';
import ErrorsForInput, { getErrorsForInput } from './errors_for_input';
import { getQueryParameter } from 'helpers/browser_helpers';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function AccountForm({
  accountPath,
  invitedLeadAccountPath,
  isUpdate,
  readonly,
  sharingId,
  onCancel,
  onNext
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [countryId, setCountryId] = useState(1);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [primaryRole, setPrimaryRole] = useState();
  const [investorTypes, setInvestorTypes] = useState([]);
  const [errors, setErrors] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);

  React.useEffect(() => {
    let promise = null;
    if (getQueryParameter('email') && invitedLeadAccountPath) {
      promise = getInvitedLeadData();
    } else if (isUpdate) {
      promise = getProfileData();
    }

    if (promise === null) return;

    setFetchingDetails(true);
    promise.finally(() => setFetchingDetails(false));
  }, []);

  function getProfileData() {
    return get(accountPath).then(({ profile }) => {
      setFirstName(profile.firstName || '');
      setLastName(profile.lastName || '');
      setPhoneNumber(profile.phoneNumber || '');
      setCompanyName(profile.companyName || '');
      setJobTitle(profile.jobTitle || '');
      setPrimaryRole(profile.primaryRole);
      setInvestorTypes(profile.investorTypes || []);
      if (profile.countryId) setCountryId(profile.countryId);
    });
  }

  function getInvitedLeadData() {
    return get(invitedLeadAccountPath).then(({ profileFields }) => {
      setEmail(profileFields.email || '');
      setFirstName(profileFields.firstName || '');
      setLastName(profileFields.lastName || '');
      setPhoneNumber(profileFields.phoneNumber || '');
      setCompanyName(profileFields.companyName || '');
      setJobTitle(profileFields.jobTitle || '');
      if (profileFields.countryId) setCountryId(profileFields.countryId);  
    });
  }

  const createAccount = (e) => {
    setSubmittingForm(true);
    e.preventDefault();

    const loginCredential = omitBy({
      email,
      password
    }, isEmpty);

    let profileAttributes = {
      firstName,
      lastName,
      phoneNumber,
      companyName,
      jobTitle,
      primaryRole,
      investorTypes,
      fromConnect: true
    };

    // Address inputs are only present when creating a new profile
    if (!isUpdate) {
      profileAttributes = {
        ...profileAttributes,
        countryId,
        address,
        city,
        state,
        zip
      };
    }

    const payload = {
      sharingId: sharingId,
      loginCredential: {
        ...loginCredential,
        profileAttributes
      }
    };

    if (!isUpdate && getQueryParameter('email')) {
      payload.invitedLead = {
        email: getQueryParameter('email'),
        token: getQueryParameter('lead_token')
      };
    }

    const requestMethod = isUpdate ? put : post;

    requestMethod(accountPath, payload)
      .then(onNext)
      .catch((errors) => {
        setErrors(errors || ['There was an issue creating your account. Please review the form and try again.']);
      })
      .finally(() => setSubmittingForm(false));
  };

  const setInvestorTypesFromInput = (e) => {
    if (e.target.checked) {
      setInvestorTypes([...investorTypes, e.target.value]);
    } else {
      setInvestorTypes(investorTypes.filter(typeKey => typeKey !== e.target.value));
    }
  };

  const uniqErrors = uniq(errors);

  function renderFormFields() {
    return (
      <React.Fragment>
        {!isUpdate && (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
              <Form.Control
                autoFocus
                isInvalid={getErrorsForInput(uniqErrors, 'Email').length > 0}
                name="email"
                placeholder="Enter email address"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <ErrorsForInput errors={uniqErrors} errorString="Email"/>
            </Form.Group>
            <div>
              <PasswordInput
                inputProps={{ isInvalid: getErrorsForInput(uniqErrors, 'Password').length > 0 }}
                value={password}
                onChange={setPassword}
              />
              <ErrorsForInput errors={uniqErrors} errorString="Password"/>
              <div className="mt-3">
                <small>
                  This is the username and password used to access any documents powered by Buildout.
                </small>
              </div>
            </div>
          </React.Fragment>
        )}
        <Form.Group>
          <Form.Label>Role <span className="text-danger">*</span></Form.Label>
          <div>
            <Form.Check
              checked={primaryRole === 'investor_owner'}
              id="user_type-0"
              isInvalid={getErrorsForInput(uniqErrors, 'primary role').length > 0}
              label="Investor / Owner / Developer"
              name="user_type_investor"
              type="radio"
              value="investor_owner"
              onChange={e => setPrimaryRole(e.target.value)}
            />
            <Form.Check
              checked={primaryRole === 'commercial_broker'}
              id="user_type-1"
              isInvalid={getErrorsForInput(uniqErrors, 'primary role').length > 0}
              label="Commercial Broker"
              name="user_type_commercial_broker"
              type="radio"
              value="commercial_broker"
              onChange={e => setPrimaryRole(e.target.value)}
            />
            <Form.Check
              checked={primaryRole === 'residential_broker'}
              id="user_type-2"
              isInvalid={getErrorsForInput(uniqErrors, 'primary role').length > 0}
              label="Residential Broker"
              name="user_type_residential_broker"
              type="radio"
              value="residential_broker"
              onChange={e => setPrimaryRole(e.target.value)}
            />
            <Form.Check
              checked={primaryRole === 'other'}
              id="user_type-3"
              isInvalid={getErrorsForInput(uniqErrors, 'primary role').length > 0}
              label="Other Service Provider"
              name="user_type_other"
              type="radio"
              value="other"
              onChange={e => setPrimaryRole(e.target.value)}
            />
            <ErrorsForInput errors={uniqErrors} errorString="primary role"/>
          </div>
        </Form.Group>
        {primaryRole === 'investor_owner' && (
          <Form.Group>
            <Form.Label>Investor Type </Form.Label>
            {Object.keys(investorTypeMap).map(key => (
              <Form.Check
                checked={investorTypes.includes(key)}
                id={`investory-type-${key}`}
                key={key}
                label={investorTypeMap[key]}
                name={`investor_type_${key}`}
                type="checkbox"
                value={key}
                onChange={setInvestorTypesFromInput}
              />
            ))}
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>First Name <span className="text-danger">*</span> </Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(uniqErrors, 'First Name').length > 0}
            name="first_name"
            placeholder="First Name"
            required
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <ErrorsForInput errors={uniqErrors} errorString="First Name"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(uniqErrors, 'Last Name').length > 0}
            name="last_name"
            placeholder="Last Name"
            required
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <ErrorsForInput errors={uniqErrors} errorString="Last Name"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number <span className="text-danger">*</span> </Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(uniqErrors, 'Phone number').length > 0}
            name="phone_number"
            placeholder="(000) 000-0000"
            required
            value={formatPhoneNumber(phoneNumber)}
            onChange={e => setPhoneNumber(formatPhoneNumber(e.target.value))}
          />
          <ErrorsForInput errors={uniqErrors} errorString="Phone number"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Company <span className="text-danger">*</span></Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(uniqErrors, 'Company').length > 0}
            name="company"
            placeholder="Company Name"
            required
            value={companyName}
            onChange={e => setCompanyName(e.target.value)}
          />
          <ErrorsForInput errors={uniqErrors} errorString="Company"/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Job Title <span className="text-danger">*</span></Form.Label>
          <Form.Control
            isInvalid={getErrorsForInput(uniqErrors, 'Title').length > 0}
            name="job_title"
            placeholder="Job Title"
            required
            value={jobTitle}
            onChange={e => setJobTitle(e.target.value)}
          />
          <ErrorsForInput errors={uniqErrors} errorString="Title"/>
        </Form.Group>
        {!isUpdate && (
          <React.Fragment>
            <Form.Group>
              <Form.Label>Country <span className="text-danger">*</span></Form.Label>
              <Form.Select
                name="country"
                value={countryId}
                onChange={e => setCountryId(e.target.value)}
              >
                {map(countries, ({ id, label }) => (
                  <option key={id} value={id}>{label}</option>
                ))}
              </Form.Select>
            </Form.Group>
            {countryId === 1 && (
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  name="address"
                  placeholder="Address"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                name="city"
                placeholder="City"
                value={city}
                onChange={e => setCity(e.target.value)}
              />
            </Form.Group>
            {countryId === 1 && (
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      name="state"
                      placeholder="State"
                      value={state}
                      onChange={e => setState(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control
                      name="zip"
                      placeholder="Zip"
                      value={zip}
                      onChange={e => setZip(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
          </React.Fragment>
        )}
        <div>Please fill out Required Fields <span className="text-danger">*</span></div>

        <Button disabled={readonly || submittingForm} name="save" type="submit" variant="primary">
          {isUpdate ? 'Get Access' : 'Create Account'}
        </Button>
      </React.Fragment>
    );
  }

  return (
    <form className="d-flex flex-column gap-4" noValidate onSubmit={createAccount}>
      {typeof onCancel === 'function' && (
        <div>
          <Button className="d-none d-lg-block" type="button" variant="link" onClick={onCancel}>
            <FontAwesomeIcon className="me-2" icon={faChevronLeft} size="lg"/>
            Back
          </Button>
        </div>
      )}
      <h5 className="text-center m-0">
        {isUpdate ? 'We just need a few details before getting access' : (<strong>Create an Account</strong>)}
      </h5>
      <p className="text-center m-0">
        {isUpdate ? ' Please add some additional information to access the secure documents. ' :
          ' Create a Buildout account to access secure documents. '
        }
      </p>

      {fetchingDetails ? (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size="2x" spin/>
        </div>
      ) : (
        renderFormFields()
      )}
    </form>
  );
}

AccountForm.propTypes = {
  accountPath: PropTypes.string.isRequired,
  invitedLeadAccountPath: PropTypes.string,
  isUpdate: PropTypes.bool,
  readonly: PropTypes.bool,
  sharingId: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onNext: PropTypes.func.isRequired
};
