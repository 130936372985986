import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Viewers from './index';

const { Image, CSV, PDF, Video, Text, BODoc, NoPreview } = Viewers;
const ICON_MAP = {
  'jpg': Image,
  'csv': CSV,
  'pdf': PDF,
  'vid': Video,
  'txt': Text,

  // We are removing support to render
  // MsOffice docs because seems view.officeapps.live.com
  // fails rendering some files
  'doc': NoPreview,
  'ppt': NoPreview,
  'xls': NoPreview,

  'bo-doc': BODoc,
  'none': NoPreview
};

export const hasPreviewer = (file) => {
  return getPreviewerByFile(file) !== NoPreview;
};

const getPreviewerByFile = (file) => {
  const icon = file.icon || file.iconType || file.ottercon;

  return ICON_MAP[icon] || ICON_MAP['none'];
};

class FilePreview extends Component {
  render() {
    const { file, noPreviewComponent } = this.props;

    let Klass = getPreviewerByFile(file);
    if (Klass === NoPreview && noPreviewComponent) {
      Klass = noPreviewComponent;
    }

    return <Klass {...this.props}/>;
  }
}

FilePreview.propTypes = {
  file: PropTypes.shape({
    icon: PropTypes.string,
    iconType: PropTypes.string,
    ottercon: PropTypes.string,
  }),
  noPreviewComponent: PropTypes.node
};

export default FilePreview;
