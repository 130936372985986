import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { fetchMatch } from './api';
import MatchDetails from './details';

export default function MatchShow({ googleMapsApiKey, match }) {
  const [fetchedMatch, setFetchedMatch] = useState();
  const [key, setKey] = useState(0);

  const reloadMatch = () => {
    fetchMatch(match.id).then((data) => {
      setFetchedMatch(data);

      // The match details will disable the buttons after a selection is made. This was a purposeful design choice
      // for the match review process. To make the buttons clickable again, we need to re-render the component which
      // we can do by assigning it a new key.
      setKey(key + 1);
    });
  };

  return (
    <MatchDetails
      googleMapsApiKey={googleMapsApiKey}
      key={key}
      match={fetchedMatch || match}
      onNext={reloadMatch}
    />
  );
}

MatchShow.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  match: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
};
