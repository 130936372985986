import React from 'react';
import PropTypes from 'prop-types';

import CurrencyInput from './currency_input';

export class CurrencyInputWrapper extends React.Component {
  static propTypes = {
    allowEmpty: PropTypes.bool,
    allowNegative: PropTypes.bool,
    autoFocus: PropTypes.bool,
    decimalSeparator: PropTypes.string,
    inputType: PropTypes.string,
    precision: PropTypes.number,
    prefix: PropTypes.string,
    selectAllOnFocus: PropTypes.bool,
    suffix: PropTypes.string,
    thousandSeparator: PropTypes.string,
    value: PropTypes.number,
    onChangeEvent: PropTypes.func
  }

  static defaultProps = {
    prefix: '$'
  };

  render() {
    const { ...inputProps } = this.props;

    if (inputProps.selectAllOnFocus === undefined) {
      inputProps.selectAllOnFocus = inputProps.value < 1;
    }

    return (
      <CurrencyInput {...inputProps}/>
    );
  }
}

export default CurrencyInputWrapper;
