import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, ListGroup, Row, Spinner } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNote } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from '../empty_state';
import { fetchNotes } from '../../helpers/prospect_api';
import NewNoteForm from './notes/new_note_form';
import NoteCard from './notes/note_card';

export default function NotesCard({ recordId, recordType }) {
  const [notes, setNotes] = useState();
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    fetchNotes(recordId, recordType).then(setNotes);
  }, [recordId, recordType]);

  const handleCreate = useCallback((note) => {
    setNotes(prevNotes => [note, ...prevNotes]);
  }, [notes]);

  const handleDelete = useCallback((noteToDelete) => {
    setNotes(prevNotes => prevNotes.filter(note => note.id !== noteToDelete.id));
  }, [notes]);

  const handleUpdate = useCallback((updatedNote) => {
    setNotes(prevNotes => prevNotes.map(note => note.id === updatedNote.id ? updatedNote : note));
  }, [notes]);

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5 className="fw-bolder mb-0">Notes</h5>
          </Col>
          <Col xs="auto">
            <Button className="btn-as-text" variant="text-secondary" onClick={() => setCreating(true)}>
              <FontAwesomeIcon icon={faNote} /> New Note
            </Button>
          </Col>
        </Row>
      </Card.Header>
      {(creating || notes === undefined || notes.length === 0) && (
        <Card.Body className="d-flex flex-column gap-4">
          <NewNoteForm
            recordId={recordId}
            recordType={recordType}
            show={creating}
            onCreate={handleCreate}
            onHide={() => setCreating(false)}
          />
          {notes === undefined && (
            <div className="text-center">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          {!creating && notes && notes.length === 0 && (
            <EmptyState
              icon={faNote}
              text="There are no notes."
            />
          )}
        </Card.Body>
      )}
      {notes && notes.length > 0 && (
        <ListGroup variant="flush">
          {notes.map(note => (
            <ListGroup.Item key={note.id}>
              <NoteCard note={note} onDelete={handleDelete} onUpdate={handleUpdate} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Card>
  );
}

NotesCard.propTypes = {
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired
};
