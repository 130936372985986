import React, { useState } from 'react';
import { Card } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import { USD } from 'helpers/formatters/number_formats';
import {
  formatDate,
  DAY_MONTH_FORMAT,
} from 'helpers/formatters/date_time_formats';
import { useAdjustedValuation } from '../../../helpers/hooks';
import ValuationTool from './valuation_tool';
import PropertyPrice from './property_price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbDollar } from '@fortawesome/pro-solid-svg-icons';

export default function PropertyValuation({ listings, propertyId, valuation }) {
  const [adjust, setAdjust] = useState(false);
  const [valuationAdjustment, onAdjustmentChange, onAdjustmentReset] = useAdjustedValuation(propertyId);
  const estimate = valuationAdjustment?.estimate || valuation.estimate;
  // We're assuming that the array is ordered
  const lastUpdatedAt = (valuation?.history || []).at(-1)?.date;

  const valuationAdjusted = (adjustment) => {
    adjustment ? onAdjustmentChange(adjustment) : onAdjustmentReset();

    setAdjust(false);
  };

  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-row gap-2 align-items-center">
        <FontAwesomeIcon className="text-secondary" icon={faLightbulbDollar} />
        <h6 className="my-0 fw-bolder">Valuation Estimate</h6>
      </div>
      <p className="my-0">
        Our valuation is based on a comparison with similar properties in the market,
        factoring in attributes like sale price, building class, and investment type.
      </p>
      {!adjust && (
        <Card>
          <Card.Body className="d-flex flex-column gap-2 align-items-center">
            {(!!valuationAdjustment || !!lastUpdatedAt) && (
              <small className="text-muted">
                Updated on&nbsp;
                {formatDate(
                  valuationAdjustment?.updatedAt || lastUpdatedAt,
                  DAY_MONTH_FORMAT
                )}
              </small>
            )}
            <PropertyPrice
              estimatedValue={valuation?.estimate}
              hideSubtitle
              listingValue={listings?.salePrice}
              propertyId={propertyId}
              valueClass="h3"
            />
            {estimate === valuation.estimate && (
              <span>
                Know something we don’t?&nbsp;
                <strong
                  className="text-primary clickable"
                  onClick={() => setAdjust(true)}
                >
                  Adjust Valuation
                </strong>
              </span>
            )}
            {estimate !== valuation.estimate && (
              <div className="d-flex align-items-center gap-2 px-2 py-1 rounded-pill bg-light">
                <small className="text-muted">
                  Buildout Estimate&nbsp;
                  <strong>{USD.format(valuation.estimate, { round: true })}</strong>
                </small>
                <strong
                  className="text-primary clickable"
                  onClick={onAdjustmentReset}
                >
                  Reset
                </strong>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
      {adjust && (
        <ValuationTool
          comments={valuationAdjustment?.comments}
          defaultEstimate={valuation.estimate}
          estimate={estimate}
          lastUpdatedAt={lastUpdatedAt}
          rangeHigh={valuation.range_high}
          rangeLow={valuation.range_low}
          onCancel={() => setAdjust(false)}
          onSubmit={valuationAdjusted}
        />
      )}
    </div>
  );
}

PropertyValuation.propTypes = {
  listings: PropTypes.shape({
    salePrice: PropTypes.number,
  }),
  propertyId: PropTypes.string.isRequired,
  valuation: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        estimate: PropTypes.number,
        date: PropTypes.string,
      })
    ),
    estimate: PropTypes.number.isRequired,
    range_high: PropTypes.number.isRequired,
    range_low: PropTypes.number.isRequired,
    quality: PropTypes.string,
    thirty_day_change: PropTypes.number,
  }),
};
