import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Pagination, Table } from 'react-bootstrap';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { getEntryActivity } from '../api';
import { formatDateTime } from 'helpers/formatters/date_time_formats';

const RESULTS_PER_PAGE = 8;

export default class DocActivityModal extends React.Component {
  static propTypes = {
    file: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired
  }

  state = {
    activePage: 1,
    loading: false,
    activities: []
  }

  componentDidUpdate(prevProps) {
    if (!this.props.show || prevProps.show === this.props.show) return;

    this.setState({ loading: true });

    getEntryActivity(this.props.file.id)
      .then(activities => this.setState({ activities, loading: false }));
  }

  totalPages = () => Math.ceil(this.state.activities.length / RESULTS_PER_PAGE);

  prevPage = () => {
    const { activePage } = this.state;
    if (activePage === 1) return;

    this.setState({ activePage: activePage - 1 });
  }

  nextPage = () => {
    const { activePage } = this.state;
    if (activePage === this.totalPages()) return;

    this.setState({ activePage: activePage + 1 });
  }

  renderActivityRows() {
    const { activePage } = this.state;
    const endIndex = activePage * RESULTS_PER_PAGE;
    const startIndex = endIndex - RESULTS_PER_PAGE;
    const shownRows = _.slice(this.state.activities, startIndex, endIndex);

    return shownRows.map((activity, i) => {
      const { dateAccessed, name, email, type } = activity;
      return (
        <tr key={i} name="activity-row">
          <td>
            <strong>{name}</strong>
          </td>
          <td className="text-muted ml-2">{email}</td>
          <td>{type}</td>
          <td>{formatDateTime(dateAccessed)}</td>
        </tr>
      );
    });
  }

  renderTable() {
    return (
      <Table>
        <thead>
          <tr>
            <th>User</th>
            <th></th>
            <th>Action</th>
            <th>Date Accessed</th>
          </tr>
        </thead>
        <tbody>
          {this.renderActivityRows()}
        </tbody>
      </Table>
    );
  }

  renderPagination() {
    const { activePage } = this.state;
    const items = [];

    if (this.totalPages() === 1) return;

    for (let i = 1; i <= this.totalPages(); i++) {
      items.push(
        <Pagination.Item
          active={i === activePage}
          key={i}
          onClick={() => this.setState({ activePage: i })}
        >
          {i}
        </Pagination.Item>
      );
    }

    return (
      <Pagination>
        <Pagination.Prev disabled={activePage === 1} onClick={this.prevPage} />
        {items}
        <Pagination.Next disabled={activePage === this.totalPages()} onClick={this.nextPage} />
      </Pagination>
    );
  }

  renderBody() {
    if (this.state.loading) {
      return (
        <div className="text-center">
          <FontAwesomeIcon icon={faSpinner} size='2x' spin />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.renderTable()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  render() {
    const { show, onClose, file } = this.props;

    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>Document Activity: {file.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody()}
        </Modal.Body>
      </Modal>
    );
  }
}
