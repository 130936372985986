import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce, get as objectGet } from 'lodash';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AsyncSelect from 'react-select/async';
import { components } from 'react-select';

import { removeLocation } from '../../../store/actions/criteria';
import classNames from 'classnames';

const MultiValue = ({ index, getValue, ...props }) => {
  if (index === 0 || props.selectProps.menuIsOpen) {
    return <components.MultiValue {...props} />;
  } else if (index === 1) {
    return (
      <div
        style={{
          backgroundColor: 'hsl(0, 0%, 90%)',
          margin: '2px',
          borderRadius: '2px',
          color: 'hsl(0, 0%, 20%)',
          fontSize: '85%',
          padding: '3px 6px'
        }}
      >+ {getValue().length - 1} more</div>
    );
  } else {
    return null;
  }
};
MultiValue.propTypes = { children: PropTypes.node };

const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList {...props}>
      {children}
      {children.length && (
        <div className="small text-end px-1 py-2">
          powered by
          <img className="mx-1" src="/images/google_integration/google_on_white.png"/>
        </div>
      )}
    </components.MenuList>
  );
};
MenuList.propTypes = { children: PropTypes.node };

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <FontAwesomeIcon
        className="icon text-primary clickable align-self-start"
        icon={faMagnifyingGlass}
        style={{ marginTop: '11px' }}
      />
      {children}
    </components.Control>
  );
};
Control.propTypes = { children: PropTypes.node };

// Input on it's own line
const Input = (props) => {
  const focused = props.selectProps.menuIsOpen;
  const anySelectedValues = props.getValue().length > 0;

  if (props.isHidden || !anySelectedValues || !focused) return (
    <components.Input {...props}/>
  );

  return (
    <div className="w-100">
      <components.Input {...props}/>
    </div>
  );
};
Input.propTypes = {
  getValue: PropTypes.func,
  isHidden: PropTypes.bool,
  selectProps: PropTypes.shape({ menuIsOpen: PropTypes.bool })
};

export function LocationInput({ hasPolygonSearch, locations, removeLocation }) {
  const autocompleteService = new google.maps.places.AutocompleteService();
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const [menuOpen, setMenuOpen] = useState(false);

  const userLocations = locations.map(
    loc => ({ label: loc.name || 'Map Drawing', ...loc })
  );

  const placeSelected = (places, event) => {
    if (event.action === 'clear') {
      clearLocation();
    } else if (event.action === 'select-option' && places.length > 0) {
      const place = places.pop();
      if (!place) return;
      const detail = {
        placeId: place.id,
        address: place.label,
        types: place.types
      };
      const event = new CustomEvent('placeSelect', { detail });
      window.dispatchEvent(event);
    } else if (event.action === 'remove-value' && event.removedValue) {
      removeLocation(event.removedValue.id);
    }
  };

  const fetchOptions = async (input) => {
    return await new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          input,
          sessionToken,
          types: ['geocode'],
          componentRestrictions: { country: 'us' }
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };

  const loadOptions = (inputValue, callback) => {
    fetchOptions(inputValue).then((predictions) => {
      const response = predictions.map(prediction => (
        {
          id: prediction.place_id,
          value: prediction.place_id,
          label: prediction.description,
          types: prediction.types
        }
      ));
      callback(response);
    }).catch((e) => {
      if (e == google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        callback([]);
      }
    });
  };

  const debouncedLoadOptions = debounce(loadOptions, 300);


  const clearLocation = () => {
    const event = new CustomEvent('clearLocation');
    window.dispatchEvent(event);
  };

  return (
    <div className="position-relative">
      <AsyncSelect
        className={classNames('rounded', { 'floating-input': menuOpen })}
        classNames={{ control: state => state.isFocused || hasPolygonSearch ? 'ps-3 rounded border-secondary-focus' : 'ps-3 rounded border' }}
        components={{
          Control,
          DropdownIndicator: null,
          MenuList,
          MultiValue,
          Input
        }}
        defaultValue={locations}
        isClearable
        isMulti
        key="location-search"
        loadOptions={debouncedLoadOptions}
        noOptionsMessage={({ inputValue }) => !inputValue ? null : 'No locations found'}
        placeholder="Search Location"
        styles={{
          control: base => ({
            ...base,
            boxShadow: 'none', // no box-shadow
            minHeight: '40px'
          }),
          multiValue: base => ({
            ...base,
            minWidth: 50
          })
        }}
        value={userLocations}
        onChange={placeSelected}
        onMenuClose={() => setMenuOpen(false)}
        onMenuOpen={() => setMenuOpen(true)}
      />
    </div>
  );
}

LocationInput.propTypes = {
  hasPolygonSearch: PropTypes.bool.isRequired,
  locations: PropTypes.array,
  removeLocation: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => {
  const locations = objectGet(criteria, 'locationsAttributes') || [];

  return { hasPolygonSearch: locations.length > 0, locations };
};

export default connect(mapStateToProps, { removeLocation })(LocationInput);
