import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import ProgressEntry from './progress_entry';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import classNames from 'classnames';

import { closeUploadProgress, collapseUploadProgress, openUploadProgress } from '../actions';
import { allUploadsCompleted } from '../helpers/file_system';
import { UPLOAD_WINDOW_STATUS } from '../actions';

class UploadProgress extends React.Component {
  static propTypes = {
    closeUploadProgress: PropTypes.func.isRequired,
    collapseUploadProgress: PropTypes.func.isRequired,
    openUploadProgress: PropTypes.func.isRequired,
    show: PropTypes.string,
    uploads: PropTypes.array
  }

  state = {
    minimized: false
  }

  minimizeOrMaximizeButton = () => {
    let onClick;
    if (this.props.show === UPLOAD_WINDOW_STATUS.COLLAPSED) {
      onClick = this.props.openUploadProgress;
    } else {
      onClick = this.props.collapseUploadProgress;
    }
    const classes = classNames(
      'clickable window-button',
      { 'maximize-button': this.props.show === UPLOAD_WINDOW_STATUS.COLLAPSED },
      { 'minimize-button': this.props.show !== UPLOAD_WINDOW_STATUS.COLLAPSED }
    );
    return <FontAwesomeIcon className={classes} icon={faChevronDown} onClick={onClick} />;

  }

  titleMessage = () => {
    if (allUploadsCompleted(this.props.uploads)) {
      setTimeout(this.props.closeUploadProgress, 5000);
      return "Upload Finished";
    }
    const length = this.props.uploads.length;
    return `Uploading ${length} ${pluralize('file', length)}`;
  }

  renderHeader = () => {
    if (this.props.show === UPLOAD_WINDOW_STATUS.CLOSED) {
      return null;
    }
    return (
      <div className="upload-progress-header w-100 py-3 px-3">
        <strong>
          {this.titleMessage()}
        </strong>
        <div className="pull-right window-buttons">
          {this.minimizeOrMaximizeButton()}
          <FontAwesomeIcon
            className="clickable window-button ml-4"
            icon={faTimes}
            onClick={this.props.closeUploadProgress}
          />
        </div>
      </div>
    );
  }

  renderContent = () => {
    if (this.props.show !== UPLOAD_WINDOW_STATUS.OPEN) {
      return null;
    }
    const entries = this.props.uploads.map((upload) => {
      const uuid = upload.uuid;
      return <ProgressEntry key={`progress-${uuid}`} uuid={uuid}/>;
    });
    return (
      <div className="upload-progress-content">
        {entries}
      </div>
    );
  }

  render() {
    if (this.props.show === UPLOAD_WINDOW_STATUS.CLOSED) {
      return null;
    }
    return (
      <div
        className={classNames(
          "upload-progress-popup",
          { 'collapsed': this.props.show === UPLOAD_WINDOW_STATUS.COLLAPSED }
        )}
      >
        {this.renderHeader()}
        {this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = ({ ui: { uploadsInProgress, showUploadProgress } }) => {
  return {
    show: showUploadProgress || UPLOAD_WINDOW_STATUS.CLOSED,
    uploads: uploadsInProgress
  };
};

const mapDispatchToProps = { closeUploadProgress, collapseUploadProgress, openUploadProgress };

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress);
