import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Col, Row } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { REJECTION_OTHER, REJECTION_REASONS } from 'helpers/boost/constants';

export default function RejectionModal({ show, showCapRate, showNoi, onHide }) {
  const [message, setMessage] = useState(null);
  const [rejectionReason, setRejectionReason] = useState(null);

  const selectReason = (reason) => {
    setRejectionReason(reason);
    if (reason !== REJECTION_OTHER) submitReason(reason);
  };

  const submitReason = (reason) => {
    onHide(reason, message);
  };

  const renderButton = (reason) => {
    if (reason.name === 'cap_rate' && !showCapRate) return;
    if (reason.name === 'noi' && !showNoi) return;

    return (
      <Button
        className={`w-100 text-start mb-3 ${reason.name === rejectionReason ? 'active' : ''}`}
        key={reason.name}
        name={`pass-${reason.name}`}
        variant="outline-light"
        onClick={() => selectReason(reason.name)}
      >
        {reason.icon && <FontAwesomeIcon className="me-2 text-primary" fixedWidth icon={reason.icon}/>}
        {reason.label}
      </Button>
    );
  };

  return (
    <Modal centered show={show} onHide={() => submitReason(null)}>
      <Modal.Header className="border-0 pb-0">
        <Modal.Title as="h5" className="text-center w-100">Why are you passing on this listing?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row className="justify-content-center">
          <Col md={10} xs={12}>
            <p className="my-2 mb-4 text-center">
              Letting us know why you’re passing helps us increase the quality of listings that we provide to you!
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={7} xs={10}>
            {REJECTION_REASONS.map(renderButton)}
            {rejectionReason === REJECTION_OTHER && (
              <React.Fragment>
                <Form.Control
                  as="textarea"
                  className="mb-3"
                  maxLength={1000}
                  name="message"
                  placeholder="Enter other reason (optional)..."
                  rows={3}
                  value={message || ''}
                  onChange={e => setMessage(e.target.value)}
                />
                <div className="text-center">
                  <Button name="submit-message" variant="primary" onClick={() => submitReason(rejectionReason)}>
                    Submit and go to next match
                  </Button>
                </div>
              </React.Fragment>
            )}
            {rejectionReason !== REJECTION_OTHER && (
              <div className="text-center">
                <Button name="skip-reason" variant="link" onClick={() => submitReason(null)}>
                  Skip and go to next listing
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

RejectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showCapRate: PropTypes.bool.isRequired,
  showNoi: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};
