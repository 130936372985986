import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setFilterAttribute } from '../../../store/actions/criteria';
import Toggle from './toggle';

function OpportunityZoneToggleInput({
  bordered = false,
  criteria,
  noLabel,
  setFilterAttribute
}) {
  return (
    <Toggle
      bordered={bordered}
      checked={!!criteria.opportunityZone}
      label={noLabel ? null : <div className="text-nowrap">Opportunity Zone</div>}
      onClick={() => setFilterAttribute('opportunityZone', !criteria.opportunityZone)}
    />
  );
}

OpportunityZoneToggleInput.propTypes = {
  bordered: PropTypes.bool,
  criteria: PropTypes.object.isRequired,
  noLabel: PropTypes.bool,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(OpportunityZoneToggleInput);
