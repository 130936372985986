import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap-5';

import { isEmpty } from 'lodash';
import { createNote, updateNote } from '../../helpers/prospect_api';
import TrixEditor from 'shared/trix/trix_editor';

export default function PropertyNoteForm({ insightsPropertyId, note, onClose, onSave }) {
  const [body, setBody] = useState(note?.body);

  const handleSave = () => {
    if (note?.id) {
      updateNote(note.id, body).then(onSave);
    } else {
      createNote(undefined, undefined, body, insightsPropertyId).then(onSave);
    }
  };

  return (
    <div className="p-4 mb-3">
      <Form.Group>
        <Form.Label>{note?.id ? 'Edit Note' : 'New Note'}</Form.Label>
        <TrixEditor id="new-note" initialValue={body || ''} name="body" onChange={setBody} />
      </Form.Group>
      <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
        <Button variant="link" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isEmpty(body)} variant="secondary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  );
}

PropertyNoteForm.propTypes = {
  insightsPropertyId: PropTypes.string,
  note: PropTypes.shape({
    id: PropTypes.number,
    body: PropTypes.string,
    notable_id: PropTypes.number,
    notable_type: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
