import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UnstyledDropdownToggle from 'components/bootstrap_extensions/unstyled_dropdown_toggle';

export default function GenericDropdown({ optionGroups, children }) {
  const optionGroupsToUse = optionGroups.filter(optionGroup => optionGroup.length > 0);

  const menuItems = [];
  for (const [i, optionGroup] of optionGroupsToUse.entries()) {
    for (const option of optionGroup) {
      let menuItem = (
        <MenuItem
          disabled={option.disabled}
          href={option.href}
          key={option.name}
          name={option.name}
          onClick={option.onClick}
        >
          {option.icon && <FontAwesomeIcon className="dropdown-icon mr-2" icon={option.icon} />}
          {option.label}
        </MenuItem>
      );

      if (option.tooltip) {
        menuItem = (
          <OverlayTrigger overlay={<Tooltip>{option.tooltip}</Tooltip>} placement="bottom">
            {menuItem}
          </OverlayTrigger>
        );
      }

      menuItems.push(menuItem);
    }

    if (i < optionGroupsToUse.length - 1) {
      menuItems.push(<MenuItem divider />);
    }
  }

  return (
    <Dropdown id="generic-dropdown" pullRight>
      <UnstyledDropdownToggle bsRole="toggle">
        {children}
      </UnstyledDropdownToggle>
      <Dropdown.Menu>
        {menuItems}
      </Dropdown.Menu>
    </Dropdown>
  );
}

GenericDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  optionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string.isRequired,
        name: PropTypes.stringisRequired,
        onClick: PropTypes.func
      })
    )
  )
};
