import React from 'react';
import PropTypes from 'prop-types';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap-5';

import { formatDate } from 'helpers/formatters/date_time_formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

export default function DocumentListItem({ document }) {
  const unavailable = isEmpty(document.url);

  const handleClick = () => {
    if (unavailable) return;

    window.open(document.url, '_blank');
  };

  const linkColumn = () => {
    if (unavailable) return null;

    return (
      <Col className="fw-bold text-primary" md={3}>
        View
        <FontAwesomeIcon className="ms-2" icon={faArrowRight} size="1x"/>
      </Col>
    );
  };

  const body = (
    <Row
      className={classNames('mx-2 p-3 bg-light rounded border align-items-center', { clickable: !unavailable })}
      onClick={handleClick}
    >
      <Col md={2}>
        <FontAwesomeIcon className={unavailable ? 'text-muted' : 'icon-gradient'} icon={faFilePdf} size="2x"/>
      </Col>
      <Col md={unavailable ? 10 : 7}>
        <div className="text-truncate fw-bold">{document.title}</div>
        <div className="small text-muted">{formatDate(document.date)}</div>
      </Col>
      {linkColumn()}
    </Row>
  );

  if (!unavailable) return (
    <Col md={6}>
      {body}
    </Col>
  );

  const unavailableTooltip = (
    <Tooltip id={`unavailable-document-${document.id}`}>
      Document not available
    </Tooltip>
  );

  return (
    <Col md={6}>
      <OverlayTrigger overlay={unavailableTooltip}>
        {body}
      </OverlayTrigger>
    </Col>
  );
}

DocumentListItem.propTypes = {
  document: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.number
  })
};
