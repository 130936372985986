import React from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

class ManagedNumberInput extends React.Component {
  static propTypes = {
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired
  }

  handleChange = (event) => {
    const value = event.target.value == '' ? null : parseFloat(event.target.value);
    if (event.target.value.match(/^0+\d/)) {
      event.target.value = event.target.value.replace(/^0+/, '');
    } else if (value === null) {
      event.target.value = null;
    }

    if (value !== null) {
      if (!isUndefined(this.props.minValue) && value < this.props.minValue) return;
      if (!isUndefined(this.props.maxValue) && value > this.props.maxValue) return;
    }

    this.props.onChange({ [this.props.name]: value }, event);
    this.props.onValueChange(value, event);
  }

  handleFocus = (event) => {
    if (this.props.value === 0) {
      event.target.select();
    }
  }

  render() {
    const { value, onChange, onValueChange, minValue, maxValue, ...props } = this.props;

    return (
      <input
        {...props}
        autoComplete="off"
        type="number"
        value={value == null ? '' : value}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
      />
    );
  }
}

export default ManagedNumberInput;
