import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Table, Button, Badge } from 'react-bootstrap-5';

import InterestedModal from './review/interested_modal';
import RejectionModal from './review/rejection_modal';
import MatchPhotoAndMap from './review/match_photo_and_map';
import { updateMatch, createMatch } from './api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

const PASS = 'pass';
const INTERESTED = 'interested';

export default function MatchDetails({ googleMapsApiKey, match, onNext }) {
  const [showInterestedModal, setShowInterestedModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [passed, setPassed] = useState(false);
  const [interested, setInterested] = useState(false);

  const saveMatch = (response, message, rejectionReason, rejectionReasonText) => {
    const body =  { response, message, rejectionReason, rejectionReasonText };
    if (match.id) return updateMatch(match.id, body);

    return createMatch(match.saleListingId, match.requirementsSetId, body);
  };

  const passClicked = () => {
    setPassed(true);
    setShowRejectionModal(true);
  };

  const saveRejection = (rejectionReason, rejectionMessage) => {
    setShowRejectionModal(false);
    saveMatch(PASS, null, rejectionReason, rejectionMessage).then(onNext);
  };

  const interestedClicked = () => {
    setInterested(true);
    setShowInterestedModal(true);
  };

  const saveInterested = (message) => {
    setShowInterestedModal(false);
    saveMatch(INTERESTED, message).then(onNext);
  };

  const attributesContainWord = (word) => {
    return match.attributesTable.some(attribute => attribute[0].toLowerCase().includes(word));
  };

  const highlightPass = !interested && (passed || match.response === PASS);
  const highlightInterested = !passed && (interested || match.response === INTERESTED);

  return (
    <React.Fragment>
      <Row className="justify-content-center">
        <Col>
          <Card className="shadow-sm rounded-3">
            <Card.Body className="px-lg-4 py-4">
              {match.buildoutExclusive && (
                <Badge bg="primary" className="w-100 p-3 mt-2 mb-4 shadow-sm">
                  <h4 className="m-0 fw-light">
                    <FontAwesomeIcon className="me-3" icon={faBuildoutLogo} />
                    EXCLUSIVE OFF MARKET LISTING
                  </h4>
                </Badge>
              )}
              <Row className="mb-4 align-items-center">
                <Col md={6}>
                  <h2 className="mt-0 fw-semi-bold">
                    {match.title}
                  </h2>
                  <h4 className="text-muted fw-semi-light mb-0">
                    {match.priceSummary}
                  </h4>
                </Col>
                <Col md={6}>
                  <Row className="my-3 my-md-0 justify-content-end">
                    <Col className="gx-md-2" md="auto" sm={6}>
                      <Button
                        className={`w-100 d-block d-md-inline-block ${interested ? 'opacity-25' : ''}`}
                        disabled={passed || interested}
                        name="pass-button"
                        size="lg"
                        variant={highlightPass ? 'danger' : 'outline-danger'}
                        onClick={passClicked}
                      >
                        <i className="fa fa-times me-2" />
                        PASS
                      </Button>
                    </Col>
                    <Col className="mt-2 mt-sm-0" md="auto" sm={6}>
                      <Button
                        className={`w-100 d-block d-md-inline-block ${passed ? 'opacity-25' : ''}`}
                        disabled={passed || interested}
                        name="interested-button"
                        size="lg"
                        variant={highlightInterested ? 'success' : 'outline-success'}
                        onClick={interestedClicked}
                      >
                        <i className="fa fa-star me-2" />
                        INTERESTED
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <MatchPhotoAndMap
                approximateLatLng={match.approximateLatLng}
                googleMapsApiKey={googleMapsApiKey}
                photoUrl={match.photo}
              />
              <Table className="remove-last-border">
                <tbody>
                  {match.attributesTable.map(attribute => (
                    <tr key={attribute[0]}>
                      <th className="w-25 py-2">{attribute[0]}</th>
                      <td className="w-75 py-2">{attribute[1]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <InterestedModal show={showInterestedModal} onHide={saveInterested} />
      <RejectionModal
        show={showRejectionModal}
        showCapRate={attributesContainWord('cap rate')}
        showNoi={attributesContainWord('noi')}
        onHide={saveRejection}
      />
    </React.Fragment>
  );
}

MatchDetails.propTypes = {
  googleMapsApiKey: PropTypes.string.isRequired,
  match: PropTypes.shape({
    attributesTable: PropTypes.array.isRequired,
    buildoutExclusive: PropTypes.bool,
    id: PropTypes.number,
    photo: PropTypes.string.isRequired,
    priceSummary: PropTypes.string.isRequired,
    requirementsSetId: PropTypes.number.isRequired,
    response: PropTypes.string,
    saleListingId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    approximateLatLng: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    })
  }).isRequired,
  onNext: PropTypes.func.isRequired
};
