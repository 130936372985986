import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

export default function Emails({ emails, onSet }) {
  const handleChange = useCallback((idx, changes) => {
    onSet(emails.map((email, index) => {
      if (index == idx) {
        return {
          ...email,
          ...changes
        };
      }

      return email;
    }));
  }, [emails]);

  const handleRemove = useCallback((idx) => {
    onSet(emails.map((email, index) => {
      if (index == idx) {
        return {
          ...email,
          _destroy: true
        };
      }

      return email;
    }));
  }, [emails]);

  const handleNew = useCallback(() => {
    onSet([...emails, { }]);
  }, [emails]);

  return (
    <div>
      <Row className="align-items-center">
        <Col><Form.Label className="my-0">Email Addresses</Form.Label></Col>
        <Col xs="auto">
          <Button size="sm" variant="shaded-primary" onClick={handleNew}>
            + Add New Email
          </Button>
        </Col>
      </Row>
      <div className="d-flex flex-column gap-2 mt-2">
        {emails.map((email, index) => {
          if (email._destroy) return;

          return (
            <div className="d-flex" key={`email-address-${index}`}>
              <Form.Control
                placeholder="Email Address"
                required
                value={email.email}
                onChange={e => handleChange(index, { email: e.target.value })}
              />
              <Button variant="link" onClick={() => handleRemove(index)}>
                <FontAwesomeIcon className="text-danger" icon={faTrash}/>
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Emails.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    email: PropTypes.string,
    _destroy: PropTypes.bool
  })),
  onSet: PropTypes.func.isRequired
};
