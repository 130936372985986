import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { applyMiddleware } from 'helpers/redux_dev_tools';
import thunk from 'redux-thunk';
import PropTypes from 'prop-types';

import reducers from '../reducers';
import DealRoomContent from './deal_room_content';
import AlertModal from './alert_modal';
import FlashMessage from './flash_message';
import UploadProgress from './upload_progress';
import DealRoomSocket from './deal_room_socket';
import { uiDefaultState } from '../reducers/ui';

class DealRoom extends React.Component {
  static propTypes = {
    contactsAndLeads: PropTypes.object,
    dropdownButtonComponent: PropTypes.elementType,
    dropdownComponent: PropTypes.elementType,
    dropdownMenuItemComponent: PropTypes.elementType,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    openToRename: PropTypes.bool,
    profileId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const startingState = {};
    startingState.ui = uiDefaultState(props.openToRename);
    startingState.contactsAndLeads = props.contactsAndLeads;

    if (props.profileId) startingState.globalProps = { profileId: props.profileId };
    this.store = createStore(reducers, startingState, applyMiddleware(thunk));
  }

  renderStatusMessages = () => {
    return (
      <div className="status-messages d-flex align-items-end">
        <UploadProgress />
        <FlashMessage />
      </div>
    );
  }

  render() {
    return (
      <Provider store={this.store}>
        <React.Fragment>
          <DealRoomSocket dealRoomId={this.props.id}/>
          <div id="deal-room-wrapper">
            <AlertModal closeDealRoom={this.props.onClose}/>
            {this.renderStatusMessages()}
            <DealRoomContent
              dealRoomId={this.props.id}
              dropdownButtonComponent={this.props.dropdownButtonComponent}
              dropdownComponent={this.props.dropdownComponent}
              dropdownMenuItemComponent={this.props.dropdownMenuItemComponent}
              onClose={this.props.onClose}
            />
          </div>
        </React.Fragment>
      </Provider>
    );
  }
}

export default DealRoom;
