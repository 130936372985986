import React from 'react';
import ActionCable from 'actioncable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { handleRemoteAction } from '../actions';

const WEB_SOCKETS_URL = '/cable';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  handleRemoteAction
};

class DealRoomSocket extends React.Component {
  static propTypes = {
    dealRoomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    handleRemoteAction: PropTypes.func.isRequired
  }
  componentDidMount() {
    this.cableConsumer = ActionCable.createConsumer(WEB_SOCKETS_URL);
    this.dealRoomCable = this.cableConsumer.subscriptions.create({
      channel: 'DealRoomsChannel',
      deal_room_id: this.props.dealRoomId
    },
    {
      received: message => this.props.handleRemoteAction(message)
    });
  }

  componentWillUnmount() {
    this.cableConsumer.disconnect();
    // this.dealRoomCable.unsubscribe();
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DealRoomSocket);
