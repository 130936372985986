import {
  LOAD_DEAL_ROOM,
  CREATED_ENTRY,
  RENAMED_ENTRY,
  MOVED_ENTRY,
  DELETED_ENTRY,
  RECYCLED_ENTRY,
  RESTORED_ENTRY
} from '../actions';

export default (state = {}, action) => {
  switch (action.type) {

  case CREATED_ENTRY: {
    const parent = state[action.entry.parentId];
    const entryIds = parent.entryIds.filter(val => val !== (action.overwriteEntry || {}).id);
    return {
      ...state,
      [action.entry.id]: action.entry,
      [parent.id]: { ...parent, entryIds: [...entryIds, action.entry.id] }
    };
  }
  case LOAD_DEAL_ROOM: {
    return action.entriesById;
  }
  case RESTORED_ENTRY: {
    const restoredChildrenIds = [];
    const dfs = (entryId) => {
      restoredChildrenIds.push(entryId);
      state[entryId].entryIds.forEach(dfs);
    };
    dfs(action.entry.id);
    const newState = { ...state };
    for (const id of restoredChildrenIds) {
      newState[id] = { ...newState[id], recycledAt: null };
    }
    newState[action.entry.id] = { ...action.entry };
    return newState;
  }
  case RECYCLED_ENTRY:
  case RENAMED_ENTRY: {
    return { ...state, [action.entry.id]: { ...action.entry } };
  }
  case MOVED_ENTRY: {
    const parent = state[action.entry.parentId];
    const oldParent = state[state[action.entry.id].parentId];
    const oldParentEntryIds = oldParent.entryIds.filter(val => val !== action.entry.id);
    const parentEntryIds = parent.entryIds.filter(val => val !== (action.overwriteEntry || {}).id);
    return {
      ...state,
      [oldParent.id]: { ...oldParent, entryIds: oldParentEntryIds },
      [parent.id]: { ...parent, entryIds: [...parentEntryIds, action.entry.id] },
      [action.entry.id]: action.entry
    };
  }
  case DELETED_ENTRY: {
    const parent = state[action.entry.parentId];
    const entryIds = parent.entryIds.filter(val => val !== action.entry.id);
    return { ...state, [parent.id]: { ...parent, entryIds } };
  }
  default:
    return state;
  }
};
