import { findFolderInFolder } from './folder_helpers';

export default function parseNavigationVariables(navigation) {
  const { currentFolderId, lockedFolderId, rootFolder } = navigation || {};
  let currentFolder = rootFolder;
  let parentFolder = null;
  let lockedFolder = null;

  if (lockedFolderId) {
    const folderResult = findFolderInFolder(rootFolder, lockedFolderId);
    lockedFolder = folderResult.folder;
  }

  if (currentFolderId) {
    const folderResult = findFolderInFolder(rootFolder, currentFolderId);
    currentFolder = folderResult.folder;
    parentFolder = folderResult.parentFolder;
  }

  return { currentFolder, lockedFolder, rootFolder, parentFolder };
}
