import React from 'react';
import PropTypes from 'prop-types';

export class ManagedTextInput extends React.Component {
  static propTypes = {
    forwardedRef: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.oneOf(['text', 'textarea', 'number']),
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onEnter: PropTypes.func,
    onValueChange: PropTypes.func.isRequired
  }

  handleChange = (event) => {
    this.props.onChange({ [this.props.name]: event.target.value }, event);
    this.props.onValueChange(event.target.value, event);
  }

  handleKeyUp = (event) => {
    if (event.key == 'Enter') this.props.onEnter();
  }

  render() {
    const { type, value, forwardedRef, onChange, onValueChange, onEnter, ...props } = this.props;
    const inputProps = {
      ...props,
      ref: forwardedRef,
      value: value || '',
      onChange: this.handleChange
    };

    if (onEnter) inputProps.onKeyUp = this.handleKeyUp;

    return type === 'textarea'
      ? <textarea {...inputProps} />
      : <input {...inputProps} />;
  }
}

export default React.forwardRef((props, ref) => {
  return <ManagedTextInput {...props} forwardedRef={ref} />;
});
