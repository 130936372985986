import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PropertyNoteCard from './property_note_card';
import PropertyNoteForm from './property_note_form';

export default function NoteSection({ note, onUpdate, onRemove }) {
  const [showNoteEditor, setShowNoteEditor] = useState(!note);

  const handleSaveNote = (newNote) => {
    onUpdate(newNote);

    setShowNoteEditor(false);
  };

  if (showNoteEditor) return (
    <PropertyNoteForm
      note={note}
      onClose={() => setShowNoteEditor(false)}
      onSave={handleSaveNote}
    />
  );

  return (
    <PropertyNoteCard note={note} onEdit={() => setShowNoteEditor(true)} onRemove={onRemove} />
  );
}

NoteSection.propTypes = {
  note: PropTypes.object,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func
};
