import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'react-bootstrap-5';

export default function InterestedModal({ show, onHide }) {
  const [message, setMessage] = useState('');

  return (
    <Modal centered show={show} onHide={() => onHide('')}>
      <Modal.Header>
        <Modal.Title as="h5">You've marked yourself as interested!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The broker for this property will be notified that you have interest in this property
          and will reach out to you shortly.
        </p>
        <Form.Group className="mt-3" controlId="exampleForm.ControlInput1">
          <Form.Label>
            <strong>Send a message</strong>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            placeholder="Ask the broker specific questions here."
            rows={3}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button name="back" variant="secondary" onClick={() => onHide('')}>Back To Matches</Button>
        <Button name="send" variant="primary" onClick={() => onHide(message)}>Send Message</Button>
      </Modal.Footer>
    </Modal>
  );
}

InterestedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};
