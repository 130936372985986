import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    with: String
  }

  connect() {
    this.boundDisable = this.disable.bind(this);
    this.element.addEventListener('turbo:submit-start', this.boundDisable);
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-start', this.boundDisable);
  }

  disable(e) {
    const { submitter } = e.detail.formSubmission;
    if (submitter.getAttribute('value')) {
      submitter.setAttribute('value', this.withValue);
    } else {
      if (this.withValue) submitter.textContent = this.withValue;
    }
  }
}
