import PropTypes from 'prop-types';

export const listingShape = {
  closingLeaseSpace: PropTypes.string,
  selectedProperty: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    id: PropTypes.number,
    state: PropTypes.string,
    zip: PropTypes.string
  }),
  commissionEstimates: PropTypes.shape({
    brokerCommissions: PropTypes.arrayOf(PropTypes.shape({
      brokerId: PropTypes.number.isRequired,
      brokerName: PropTypes.string.isRequired,
      grossAmount: PropTypes.number,
      grossPercent: PropTypes.number
    })).isRequired,
  }),
  contactRoles: PropTypes.array,
  dateLikelyToClose: PropTypes.string,
  daysLikelyToClose: PropTypes.number,
  id: PropTypes.number,
  leaseClosed: PropTypes.bool,
  leaseDate: PropTypes.string,
  leaseRate: PropTypes.number,
  leaseRateUnits: PropTypes.string,
  leaseTerm: PropTypes.number,
  leaseTermUnits: PropTypes.string,
  percentageLikelyToClose: PropTypes.number,
  projectId: PropTypes.number,
  renewalReminder: PropTypes.shape({
    expirationCount: PropTypes.number,
    expirationUnit: PropTypes.string
  }),
  researchTransaction: PropTypes.object,
  saleClosed: PropTypes.bool,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validStatuses: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  voucher: PropTypes.shape({
    grossCommissionAmount: PropTypes.number,
    grossCommissionPercent: PropTypes.number,
    id: PropTypes.number,
    path: PropTypes.string
  })
};

export default PropTypes.shape(listingShape);
