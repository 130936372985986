import { Controller } from '@hotwired/stimulus';

const debounce = (callback, wait) => {
  let timeout = null;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 300 }
  };

  formChange(event) {
    const form = event.target.form;
    form.requestSubmit();
  }

  debouncedSubmit = debounce(event => this.formChange(event), this.debounceValue);
}
