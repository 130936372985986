import * as Redux from 'redux';

export function applyDevTools() {
  return window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
}

// TODO: Support options to __REDUX_DEVTOOLS_EXTENSION_COMPOSE__, e.g. name
export function applyMiddleware(...middleware) {
  const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    || getParentDevToolsCompose()
    || Redux.compose;
  return composeWithDevTools(Redux.applyMiddleware(...middleware));
}

/* eslint-disable no-console */
function getParentDevToolsCompose() {
  try {
    return window.parent && window.parent.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } catch (ex) {
    console.log('Unable to install Redux dev tools.');
    console.log(ex);
    return null;
  }
}
/* eslint-enable no-console */
