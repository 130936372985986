import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap-5';

// For a modal with a form inside, this controller hides the modal and resets the form data upon submission
export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.modal = new Modal(this.element);
  }

  close() {
    this.modal.hide();
    setTimeout(() => this.formTarget.reset(), 100);
  }
}
