import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// We cannot go past react-dnd 14 for now since v15 removes support
// for class based components (called Decorator API) https://github.com/react-dnd/react-dnd/releases/tag/v15.0.0

// if there is a need for a higher react-dnd version. Make sure to not mix and match different
// versions on the same page as that will cause breakage

export default function withDragDropContext(Component) {
  return function WrappedComponent(props) {
    return (
      <DndProvider backend={HTML5Backend}>
        <Component {...props} />
      </DndProvider>
    );
  };
}
