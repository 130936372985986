import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap-5';
import ButtonGroupRadio from '../../call_list/activity_forms/button_group_radio';
import { faFileWaveform, faMemoCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import ActivityExport from './activity';
import PropertyOwnerExport from './property_owner';

const PROPERTY_OWNER_TAB = 'PROPERTY_OWNER_TAB';
const ACTIVITY_TAB = 'ACTIVITY_TAB';

const TYPE_OPTIONS = [
  { icon: faMemoCircleCheck, label: 'Property / Owner Data', value: PROPERTY_OWNER_TAB },
  { icon: faFileWaveform, label: 'Activities', value: ACTIVITY_TAB }
];

export default function CallListExportModal({ callListId, exportPath, show, templates, onHide }) {
  const [tab, setTab] = useState(PROPERTY_OWNER_TAB);

  const activityTemplates = useMemo(() => templates.filter(t => t.dataExportType == 'activity'), [templates]);
  const propertyTemplates = useMemo(() => templates.filter(t => t.dataExportType == 'property'), [templates]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Export List Data
      </Modal.Header>
      <Modal.Body className="pb-0">
        <Form.Group>
          <Form.Label>Type of Export</Form.Label>
          <ButtonGroupRadio
            checked={tab}
            name="export_type"
            options={TYPE_OPTIONS}
            onChange={setTab}
          />
        </Form.Group>
      </Modal.Body>
      <ActivityExport
        exportPath={exportPath}
        show={tab == ACTIVITY_TAB}
        templates={activityTemplates}
        onHide={onHide}
      />
      <PropertyOwnerExport
        callListId={callListId}
        exportPath={exportPath}
        show={tab == PROPERTY_OWNER_TAB}
        templates={propertyTemplates}
        onHide={onHide}
      />
    </Modal>
  );
}

CallListExportModal.propTypes = {
  callListId: PropTypes.number.isRequired,
  exportPath: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape({
    dataExportType: PropTypes.string.isRequired
  })).isRequired,
  onHide: PropTypes.func.isRequired
};
