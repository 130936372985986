import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';
import classNames from 'classnames';
import { isBoolean } from 'lodash';

import { setFilterAttribute } from '../../../store/actions/criteria';

function BooleanSelect({ criteria, attribute, includeBlank, setFilterAttribute }) {
  const handleOnChange = (e) => {
    let value;

    switch (e.target.value) {
    case 'true':
      value = true;
      break;
    case 'false':
      value = false;
      break;
    }

    setFilterAttribute(attribute, value);
  };

  return (
    <Form.Select
      className={classNames('as-button', isBoolean(criteria[attribute]) ? 'active' : 'text-muted')}
      value={criteria[attribute]?.toString() || ''}
      onChange={handleOnChange}
    >
      {includeBlank &&
        <option value="">
          {typeof includeBlank === 'string' ? includeBlank : 'Select...'}
        </option>
      }

      <option value={true}>Yes</option>
      <option value={false}>No</option>
    </Form.Select>
  );
}

BooleanSelect.propTypes = {
  attribute: PropTypes.string.isRequired,
  criteria: PropTypes.object.isRequired,
  includeBlank: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(BooleanSelect);
