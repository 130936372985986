import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('show.bs.tab', this.updateIcon.bind(this));
    this.element.addEventListener('hide.bs.tab', this.updateIcon.bind(this));

    this.setInitialIcons();
  }

  setInitialIcons() {
    const buttons = this.element.querySelectorAll('.nav-link');

    buttons.forEach((button) => {
      const icon = button.querySelector('i');

      if (button.classList.contains('active')) {
        icon?.classList.replace('far', 'fas');
      } else {
        icon?.classList.replace('fas', 'far');
      }
    });
  }

  updateIcon(event) {
    const button = event.target;
    const icon = button.querySelector('i');

    if (button.classList.contains('active')) {
      icon?.classList.replace('fas', 'far');
    } else {
      icon?.classList.replace('far', 'fas');
    }
  }
}
