import { formatWithCommas, numberShorthand } from 'helpers/formatters/number_formats';
import { propertyTypeMappings, propertySubtypeMappings } from 'helpers/property_types';
import { without } from 'lodash';
import {
  ASSET_CLASS_LABELS,
  EXCLUSIVE_LISTING_FILTERS,
  OFF_MARKET_FILTERS
} from '../components/filters/constants';

const DEFAULT_BUILDING_SIZE_UNITS = 'SF';

export default class CriteriaDecorator {
  constructor(criteria, offMarketSearch) {
    this.criteria = criteria;
    this.filters = offMarketSearch ? OFF_MARKET_FILTERS : EXCLUSIVE_LISTING_FILTERS;
  }

  locationFormatted() {
    const locations = this.criteria.locationsAttributes;
    if (!locations || locations.length == 0) return 'Map View';
    if (locations.length > 1) return 'Multiple locations';

    return locations[0].name;
  }

  assetClassShortLabel() {
    const { assetClassIds } = this.criteria;

    if (!assetClassIds || assetClassIds.length == 0) return '';
    if (assetClassIds.length > 2) return 'Multiple Property Types';

    return assetClassIds.map((id) => {
      if (id < 100) return propertyTypeMappings[id];
      return propertySubtypeMappings[id];
    }).join(', ');
  }

  name() {
    if (this.criteria.name) return this.criteria.name;

    return `${this.locationFormatted()} ${this.assetClassShortLabel()}`;
  }

  filterSummary() {
    return this.filters.map((filter) => {
      const props = filter.keys.map(key => this.criteria[key]);
      return {
        keys: filter.keys,
        label: filter.formatted(...props)
      };
    }).filter(n => n.label);
  }

  minCapRate() {
    return this.criteria.minCapRate || '';
  }

  maxCapRate() {
    return this.criteria.maxCapRate || '';
  }

  maxSalePrice() {
    return this.numToString(this.criteria.maxSalePrice);
  }

  minSalePrice() {
    return this.numToString(this.criteria.minSalePrice);
  }

  minBuildingSize() {
    return this.numToString(this.criteria.minBuildingSizeSf);
  }

  maxBuildingSize() {
    return this.numToString(this.criteria.maxBuildingSizeSf);
  }

  onMarketDuration() {
    if (this.criteria.onMarketDuration === 0) return '< 3 months';
    if (this.criteria.onMarketDuration === 1) return '< 6 months';
    if (this.criteria.onMarketDuration === 2) return '< 9 months';
    if (this.criteria.onMarketDuration === 3) return '< 12 months';
  }

  assetClassSummary(excludeSubtypes = false) {
    let assetClassIds = [...(this.criteria.assetClassIds || [])];
    const idsToRemove = [];
    if (excludeSubtypes) assetClassIds = assetClassIds.filter(id => id < 100);

    // When a subtype is selected, remove the primary type from the list of types that we want to summarize
    assetClassIds.forEach(id => idsToRemove.push(Math.floor(id / 100)));
    const idsToShow = without(assetClassIds, ...idsToRemove);

    if (idsToShow.length > 1) {
      return `${ASSET_CLASS_LABELS[idsToShow[0]]} (+${idsToShow.length - 1})`;
    }
    return idsToShow.map(id => ASSET_CLASS_LABELS[id]).join(', ');
  }

  priceSummary() {
    if (this.criteria.minSalePrice && !this.criteria.maxSalePrice) {
      return `$${numberShorthand(this.criteria.minSalePrice)} and up`;
    } else if (!this.criteria.minSalePrice && this.criteria.maxSalePrice) {
      return `Up to $${numberShorthand(this.criteria.maxSalePrice)}`;
    }

    return [this.criteria.minSalePrice, this.criteria.maxSalePrice]
      .filter(val => !!val)
      .map(val => `$${numberShorthand(val)}`)
      .join(' - ');
  }

  sizeSummary(key, units) {
    const min = this.criteria[`min${key}`];
    const max = this.criteria[`max${key}`];
    if (!min && !max) return;

    let summary = '';

    if (min && !max) {
      summary = `Over ${formatWithCommas(min)}`;
    } else if (!min && max) {
      summary = `Under ${formatWithCommas(max)}`;
    } else {
      summary = [min, max]
        .filter(val => !!val)
        .map(val => `${formatWithCommas(val)}`)
        .join(' - ');
    }

    return summary + ` ${units}`;
  }

  buildingSizeSummary() {
    return this.sizeSummary('BuildingSizeSf', DEFAULT_BUILDING_SIZE_UNITS);
  }

  lotSizeSummary() {
    return this.sizeSummary('LotSizeAcres', 'Acres');
  }

  numberOfUnitsSummary() {
    const { minNumberOfUnits, maxNumberOfUnits } = this.criteria;

    if (minNumberOfUnits && maxNumberOfUnits) {
      return `${minNumberOfUnits} - ${maxNumberOfUnits} Units`;
    }

    if (minNumberOfUnits) {
      return `More than ${minNumberOfUnits} Units`;
    }

    if (maxNumberOfUnits) {
      return `Less than ${maxNumberOfUnits} Units`;
    }
  }

  numToString(num) {
    if (!num) return '';
    return formatWithCommas(num);
  }

  numCriteriaExcludingLocation() {
    return this.filterSummary().length;
  }
}
