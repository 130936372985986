import {
  LOAD_DEAL_ROOM,
  SET_CURRENT_FOLDER_ID,
  SET_UPLOAD_PROGRESS,
  SET_SORT_ORDER,
  SET_RECYCLE_SORT_ORDER,
  START_DRAGGING_ENTRIES,
  STOP_DRAGGING_ENTRIES,
  DRAG_ENTRIES,
  DELETING_ENTRIES,
  DELETED_ENTRY,
  MOVING_ENTRIES,
  MOVED_ENTRY,
  MOVE_CANCELLED,
  RENAMING_ENTRY,
  RENAMED_ENTRY,
  UPLOADING_FILES,
  UPLOAD_COMPLETED,
  SET_UPLOAD_WINDOW_STATUS,
  SHOW_RIGHT_CLICK_MENU,
  CLOSE_RIGHT_CLICK_MENU,
  SHOW_RENAME_ENTRY_INPUT,
  HIDE_RENAMED_ENTRY_INPUT,
  OPEN_RECYCLE_BIN,
  CLOSE_RECYCLE_BIN,
  RECYCLED_ENTRY,
  RESTORE_CANCELLED,
  RESTORED_ENTRY,
  RESTORING_ENTRIES,
  OPEN_DEAL_ROOM_SETTINGS,
  CLEAR_FORCE_RENAME_FOCUS,
  CLOSE_DEAL_ROOM_SETTINGS,
  ERROR_RENAMING_ENTRY,
  ERROR_UPLOADING_FILE,
  ERROR_RECYCLING_ENTRY,
  ERROR_DELETING_ENTRY,
  ERROR_RESTORING_ENTRY,
  ERROR_MOVING_ENTRY,
  CREATING_FOLDER,
  DONE_CREATING_FOLDER,
  ERROR_CREATING_FOLDER,
  UPDATING_MEMBER,
  UPDATE_MEMBER,
  ERROR_UPDATING_MEMBER,
  DELETING_MEMBER,
  DELETE_MEMBER,
  ERROR_DELETING_MEMBER
} from "../actions";
import { DEFAULT_SORT_ORDER, RECYCLED_AT_DESCENDING } from '../helpers/sorting';
import { allUploadsCompleted } from '../helpers/file_system';
import { UPLOAD_WINDOW_STATUS } from '../actions';

export const uiDefaultState = (openToRename) => {
  return {
    loading: true,
    renamingDealRoom: false,
    recycleSortOrder: RECYCLED_AT_DESCENDING,
    sortOrder: DEFAULT_SORT_ORDER,
    draggedEntryIds: [],
    mutatingEntryIds: [],
    mutatingMemberIds: {},
    uploadsInProgress: [],
    showUploadProgress: UPLOAD_WINDOW_STATUS.CLOSED,
    inRecycleBin: false,
    forceRenameFocus: openToRename
  };
};

export default (state = uiDefaultState(), action) => {
  switch (action.type) {
  case LOAD_DEAL_ROOM: {
    return { ...state, currentFolderId: action.dealRoom.rootEntryId, loading: false };
  }
  case RESTORING_ENTRIES:
  case DELETING_ENTRIES:
  case MOVING_ENTRIES: {
    return { ...state, mutatingEntryIds: [...state.mutatingEntryIds, ...action.entries.map(entry => entry.id)] };
  }
  case RESTORE_CANCELLED:
  case MOVE_CANCELLED:
  case RENAMED_ENTRY:
  case ERROR_RENAMING_ENTRY:
  case DELETED_ENTRY:
  case ERROR_DELETING_ENTRY:
  case RECYCLED_ENTRY:
  case ERROR_RECYCLING_ENTRY:
  case RESTORED_ENTRY:
  case ERROR_RESTORING_ENTRY:
  case MOVED_ENTRY:
  case ERROR_MOVING_ENTRY: {
    return { ...state, mutatingEntryIds: state.mutatingEntryIds.filter(id => id !== action.entry.id) };
  }
  case RENAMING_ENTRY: {
    return { ...state, mutatingEntryIds: [...state.mutatingEntryIds, action.entry.id] };
  }
  case OPEN_DEAL_ROOM_SETTINGS: {
    return { ...state, inDealRoomSettings: true };
  }
  case CLOSE_DEAL_ROOM_SETTINGS:
    return { ...state, inDealRoomSettings: false };
  case SET_CURRENT_FOLDER_ID:
    return { ...state, currentFolderId: action.folderId };
  case UPLOADING_FILES: {
    const newUploads = action.fileUuids.map((uuid) => {
      return {
        uuid,
        progress: 0
      };
    });
    let uploadsInProgress;
    if (allUploadsCompleted(state.uploadsInProgress)) {
      uploadsInProgress = newUploads;
    } else {
      uploadsInProgress = [...state.uploadsInProgress, ...newUploads];
    }
    return { ...state, uploadsInProgress, showUploadProgress: UPLOAD_WINDOW_STATUS.OPEN };
  }
  case ERROR_UPLOADING_FILE: {
    const newUploadsInProgress = [...state.uploadsInProgress].map((obj) => {
      if (obj.uuid === action.uuid) {
        return { ...obj, uploadFailed: true };
      }
      return obj;
    });
    return { ...state, uploadsInProgress: newUploadsInProgress };
  }
  case UPLOAD_COMPLETED: {
    const newUploadsInProgress = [...state.uploadsInProgress].map((obj) => {
      if (obj.uuid === action.uuid) {
        return { ...obj, uploadCompleted: true };
      }
      return obj;
    });
    return { ...state, uploadsInProgress: newUploadsInProgress };
  }
  case SET_UPLOAD_PROGRESS: {
    const newUploadsInProgress = [...state.uploadsInProgress].map((obj) => {
      if (obj.uuid === action.uuid) {
        return { ...obj, progress: action.progress };
      }
      return obj;
    });
    return { ...state, uploadsInProgress: newUploadsInProgress };
  }
  case SET_UPLOAD_WINDOW_STATUS: {
    return { ...state, showUploadProgress: action.status };
  }
  case SET_SORT_ORDER: {
    return { ...state, sortOrder: action.order };
  }
  case SET_RECYCLE_SORT_ORDER:
    return { ...state, recycleSortOrder: action.order };
  case START_DRAGGING_ENTRIES: {
    return { ...state, draggedEntryIds: action.entryIds, dragGhostPosition: action.position };
  }
  case STOP_DRAGGING_ENTRIES: {
    return { ...state, draggedEntryIds: [] };
  }
  case DRAG_ENTRIES: {
    return { ...state, dragGhostPosition: action.position };
  }
  case SHOW_RIGHT_CLICK_MENU: {
    return { ...state, showRightClickMenu: true, rightClickMenuPosition: action.position };
  }
  case CLOSE_RIGHT_CLICK_MENU: {
    return { ...state, showRightClickMenu: false };
  }
  case SHOW_RENAME_ENTRY_INPUT: {
    return { ...state, showRenameEntryInput: true };
  }
  case HIDE_RENAMED_ENTRY_INPUT: {
    return { ...state, showRenameEntryInput: false };
  }
  case OPEN_RECYCLE_BIN:
    return { ...state, inRecycleBin: true };
  case CLOSE_RECYCLE_BIN:
    return { ...state, inRecycleBin: false };
  case CLEAR_FORCE_RENAME_FOCUS:
    return { ...state, forceRenameFocus: false };
  case CREATING_FOLDER:
    return { ...state, creatingFolder: true };
  case ERROR_CREATING_FOLDER:
  case DONE_CREATING_FOLDER:
    return { ...state, creatingFolder: false };
  case UPDATING_MEMBER:
    return { ...state, mutatingMemberIds: { ...state.mutatingMemberIds, [action.member.id]: true } };
  case UPDATE_MEMBER:
  case ERROR_UPDATING_MEMBER: {
    const mutatingMemberIds = { ...state.mutatingMemberIds };
    delete mutatingMemberIds[action.member.id];
    return { ...state, mutatingMemberIds };
  }
  case DELETING_MEMBER:
    return { ...state, mutatingMemberIds: { ...state.mutatingMemberIds, [action.member.id]: true } };
  case DELETE_MEMBER:
  case ERROR_DELETING_MEMBER: {
    const mutatingMemberIds = { ...state.mutatingMemberIds };
    delete mutatingMemberIds[action.member.id];
    return { ...state, mutatingMemberIds };
  }
  default:
    return state;
  }
};
