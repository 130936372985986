import React, { useState } from 'react';
import { Card } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';

const MAX_HEIGHT = 400;

export default function CaText({ caText }) {
  const [cardComponent, setCardComponent] = useState();
  const [expand, setExpand] = useState(false);

  const showExpand = cardComponent && cardComponent.scrollHeight > MAX_HEIGHT;
  const heightOfCa = expand ? '' : `${MAX_HEIGHT}px`;

  return (
    <div className="mb-4">
      <Card className="overflow-auto bg-light" ref={setCardComponent} style={{ maxHeight: heightOfCa }}>
        <Card.Body style={{ whiteSpace: 'pre-wrap' }}>
          {caText}
        </Card.Body>
      </Card>
      {showExpand && (
        <div className="text-end">
          <button className="btn btn-link" type="button" onClick={() => setExpand(!expand)}>
            <FontAwesomeIcon
              icon={faChevronDown}
              style={{ transition: 'transform .2s', transform: `rotate(${expand ? 180 : 0}deg)` }}
            />
            {expand ? 'Hide' : 'Expand'}
          </button>
        </div>
      )}
    </div>
  );
}

CaText.propTypes = {
  caText: PropTypes.string.isRequired
};