import ContributorSVG from 'otter_icons/standalone/contributor.svg';
import DownloadOnlySVG from 'otter_icons/standalone/download_only.svg';
import FullAccessSVG from 'otter_icons/standalone/full_access.svg';

import humanizeString from 'humanize-string';

export const ACCESS_LEVEL_ICONS = {
  contributor: ContributorSVG,
  download_only: DownloadOnlySVG,
  full_access: FullAccessSVG
};

export const accessLevelOptions = dealRoom => (
  dealRoom.memberAccessLevels.map(accessLevel => ({
    icon: ACCESS_LEVEL_ICONS[accessLevel],
    key: accessLevel,
    label: humanizeString(accessLevel),
    value: accessLevel
  }))
);
