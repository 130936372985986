import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col, Card, Form, Button, Alert } from 'react-bootstrap-5';
import ReCAPTCHA from 'react-google-recaptcha';
import { uniq } from 'lodash';

import axios from 'axios';
import { get } from 'helpers/api';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';
import { getQueryParameter } from 'helpers/browser_helpers';
import snakecaseKeys from 'snakecase-keys';

import { setShowInfoRequest } from '../actions/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faMessage, faSpinner } from '@fortawesome/pro-solid-svg-icons';

export const AXIOS_REQUEST_HEADERS = {
  headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }
};

export function RequestMoreInfo({ accountPath, propertyId, recaptchaKey, setShowInfoRequest, sharingId }) {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [recaptchaResponse, setRecaptchaResponse] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const fastCaToken = getQueryParameter('fast_ca');

  React.useEffect(() => {
    get(accountPath).then((response) => {
      if (response.profile) setLoggedIn(true);
      setLoading(false);
    }).catch(() => setLoading(false)); // ignore for missing profile
  }, []);

  const submitForm = () => {
    setSaving(true);
    axios.post(
      '/plugin_more_info_requests',
      {
        'g-recaptcha-response': recaptchaResponse,
        plugin_more_info_request: snakecaseKeys({
          fastCa: fastCaToken,
          propertyId,
          sharingId,
          message,
          email,
          phone,
          name
        })
      },
      AXIOS_REQUEST_HEADERS
    ).then((response) => {
      if (response.data.errors) {
        setSaving(false);
        setErrors(response.data.errors);
      } else {
        setSaved(true);
        setTimeout(() => setShowInfoRequest(false), 2500);
      }
    });
  };

  const disableSave = () => {
    const captchaSaveDisabled = !recaptchaResponse || saving || saved;
    if (loggedIn) return captchaSaveDisabled;

    const formDataDisabled = (!loggedIn && (!name || !email || !phone));

    return captchaSaveDisabled || formDataDisabled;
  };

  return (
    <React.Fragment>
      <Row className="justify-content-center py-6 gx-0 my-auto">
        <Col className="connect-card-container" lg={10} xl={8} xxl={7}>
          <Card className="overflow-hidden connect-card">
            <Card.Body className="p-5">
              <button className="btn btn-link mb-2" onClick={() => setShowInfoRequest(false)}>
                <FontAwesomeIcon icon={faChevronLeft}/>
                Back
              </button>
              {loading ? (
                <div className="text-center text-muted">
                  <FontAwesomeIcon icon={faSpinner} name="loading-spinner" size="2x" spin/>
                </div>
              ) : (
                <React.Fragment>
                  <h5 className="text-center mb-4 strong">
                    {saved ? 'Request Sent!' : (
                      <React.Fragment>
                        <FontAwesomeIcon className="me-2" icon={faMessage}/>
                        Request More Information
                      </React.Fragment>
                    )}
                  </h5>
                  <p className="text-center">
                    Interested in this property? We'll put you in contact with the brokers.
                    Start the conversation by entering a message
                  </p>
                  {errors.length > 0 && (
                    <Alert bsStyle="danger">
                      {uniq(errors).map(error => (
                        <div key={error}>{error}</div>
                      ))}
                    </Alert>
                  )}
                  {!loggedIn && (
                    <React.Fragment>
                      <Form.Group className="mb-4">
                        <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          autoFocus
                          name="name"
                          placeholder="John Smith"
                          required
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>Email Address <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          name="email"
                          placeholder="Enter email address"
                          required
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-4">
                        <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          name="phone"
                          placeholder="(000) 000-0000"
                          required
                          value={formatPhoneNumber(phone)}
                          onChange={e => setPhone(formatPhoneNumber(e.target.value))}
                        />
                      </Form.Group>
                    </React.Fragment>
                  )}
                  <Form.Group className="mb-4">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      placeholder="Enter your message here"
                      required
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA sitekey={recaptchaKey} onChange={setRecaptchaResponse}/>
                  </div>
                  <Button
                    className="mt-4 btn-secondary text-center w-100"
                    disabled={disableSave()}
                    name="submit-request"
                    onClick={submitForm}
                  >
                    {saved ? 'Request Sent!' : 'Contact Broker'}
                  </Button>
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="mt-auto"></div>
    </React.Fragment>
  );
}

RequestMoreInfo.propTypes = {
  accountPath: PropTypes.string.isRequired,
  propertyId: PropTypes.number.isRequired,
  recaptchaKey: PropTypes.string.isRequired,
  setShowInfoRequest: PropTypes.func.isRequired,
  sharingId: PropTypes.string.isRequired
};

export const mapStateToProps = ({ vault }) => ({
  propertyId: vault.propertyId,
  sharingId: vault.sharingId,
  accountPath: vault.profileUrl
});
export default connect(mapStateToProps, { setShowInfoRequest })(RequestMoreInfo);
