import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import configureStore from '../call_list/store/configure';
import TaskList from './task_list';

export default function TasksIndex(
  { activities }
) {
  const store = useMemo(() => configureStore({ activities }), []);

  return (
    <Provider store={store}>
      <TaskList />
    </Provider>
  );
}

TasksIndex.propTypes = {
  activities: PropTypes.array.isRequired
};

