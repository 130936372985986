import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setFilterAttribute } from '../../../store/actions/criteria';
import Toggle from './toggle';

function OnMarketDurationToggle({
  bordered = false,
  criteria,
  setFilterAttribute
}) {
  const controlClicked = () => {
    setFilterAttribute('onMarketDuration', !criteria.onMarketDuration ? 'weeks1' : undefined);
  };

  return (
    <Toggle
      bordered={bordered}
      checked={!!criteria.onMarketDuration}
      label="New Listings Only"
      onClick={controlClicked}
    />
  );
}

OnMarketDurationToggle.propTypes = {
  bordered: PropTypes.bool,
  criteria: PropTypes.object.isRequired,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(OnMarketDurationToggle);
