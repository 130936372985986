import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap-5';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { getQueryParameter, setQueryParameter } from 'helpers/browser_helpers';

import { get } from 'helpers/api';
import {
  COLLECT_INFORMATION,
  COMPLETE,
  CREATE_ACCOUNT,
  DOC_ACCESS_TOO_LOW,
  EMAIL_NOT_VERIFIED,
  FAST_TRACK_CA_USER_TYPE,
  findComponentStep,
  noDocsBannerContent,
  INVALID_PROFILE,
  NOT_APPROVED,
  NOT_LOGGED_IN,
  REGISTRATION,
  USER_TYPE,
  VAULT_GATE_IDENTIFIER,
  WAIT_FOR_DOCS,
  hasGates,
  extractGateSteps
} from '../helpers/vault_access_helper';

import { setShowLogin, setShowNoDocsBanner } from '../actions/ui';

const HEADER_TURBO_FRAME_SELECTORS = ['#profile_actions_desktop', '#profile_actions_mobile'];

const LOGIN_ONLY_STEPS = [NOT_LOGGED_IN, CREATE_ACCOUNT, INVALID_PROFILE, EMAIL_NOT_VERIFIED, COMPLETE];
const DO_NOT_SHARE_INFO_STEPS = [FAST_TRACK_CA_USER_TYPE, NOT_LOGGED_IN, CREATE_ACCOUNT, INVALID_PROFILE,
  USER_TYPE, REGISTRATION, COLLECT_INFORMATION];

export function VaultAccess({
  companyName,
  docVaultEntryId,
  fetchingNavigation,
  loginOnly,
  mobile,
  noDocs,
  previewProfile,
  profileUrl,
  readonly,
  setShowLogin,
  setShowNoDocsBanner,
  sharingId,
  sharingUrl,
  showNoDocsBanner,
  userType,
  vaultPath,
  onRefreshNavigation
}) {
  const [step, setStep] = useState();
  const [currentEmail, setCurrentEmail] = useState();

  const fastCaToken = getQueryParameter('fast_ca');

  const goToStep = (nextStep, refreshTurboFrames = false) => {
    if (refreshTurboFrames || ([CREATE_ACCOUNT, NOT_LOGGED_IN].includes(step) && nextStep !== step)) {
      HEADER_TURBO_FRAME_SELECTORS.forEach(selector => document.querySelector(selector)?.reload());
    }
    handleStepChange(nextStep);
    setStep(nextStep);
  };

  const handleStepChange = (nextStep) => {
    if (!hasGates(nextStep)) return;

    const steps = extractGateSteps(nextStep);
    if (steps.includes(WAIT_FOR_DOCS)) {
      setShowNoDocsBanner(false);
    } else if (noDocs) {
      setShowNoDocsBanner(true);
    }
  };

  const refreshVaultStepsParams = updatedData => (
    {
      docVaultEntryId: (loginOnly ? null : docVaultEntryId),
      fastCa: fastCaToken,
      readonly,
      previewProfile,
      userType,
      'invited_lead[email]': getQueryParameter('email'),
      'invited_lead[token]': getQueryParameter('lead_token'),
      ...updatedData
    }
  );

  const refreshVaultStep = (refreshTurboFrames = false, updatedData = {}) => {
    get(vaultPath, { params: refreshVaultStepsParams(updatedData) }).then((data) => {
      setCurrentEmail(data.email);
      goToStep(data.rejectionReason || COMPLETE, refreshTurboFrames);
    });
  };

  const onNext = (overrideStep, refreshTurboFrames = false, updatedData = {}) => {
    setShowNoDocsBanner(false);

    if (overrideStep) {
      goToStep(overrideStep, refreshTurboFrames);
    } else {
      refreshVaultStep(refreshTurboFrames, updatedData);
    }
  };

  const onFastCaUnsupported = () => {
    setQueryParameter('fast_ca', null);
    refreshVaultStep();
  };

  React.useEffect(() => {
    let isSubscribed = true;
    if (fetchingNavigation) return;

    // in order to avoid react memory leaks, we need to be able to discard the results if the component is no longer
    // mounted. We need this functionality inside this effect so we can properly check the isSubscribed value
    get(vaultPath, { params: refreshVaultStepsParams({}) }).then((data) => {
      if (isSubscribed) {
        setCurrentEmail(data.email);
        goToStep(data.rejectionReason || COMPLETE, false);
      }
    });

    return () => isSubscribed = false;
  }, [fetchingNavigation]);

  // TODO: move these out of useEffects. these should be able to go in goToStep
  React.useEffect(() => {
    if (step === NOT_APPROVED || step === DOC_ACCESS_TOO_LOW) {
      onRefreshNavigation({ unlocked: false, onlyGetUnlockedFile: false });
    }
  }, [step]);

  React.useEffect(() => {
    if (loginOnly && !LOGIN_ONLY_STEPS.includes(step) && step !== COMPLETE &&
      !step?.startsWith(VAULT_GATE_IDENTIFIER)) {
      setStep(COMPLETE);
    }

    if (step !== COMPLETE) return;

    const timer = setTimeout(() => {
      onRefreshNavigation({ onlyGetUnlockedFile: true });
    }, 2000);

    return () => clearTimeout(timer);
  }, [step]);

  const stepArguments = {
    step,
    noDocs,
    onNext,
    vaultPath,
    loginOnly,
    fastCaToken,
    userType,
    sharingId,
    sharingUrl,
    profileUrl,
    currentEmail,
    goToStep,
    onFastCaUnsupported,
    readonly,
    setShowLogin
  };
  const { Component, PortalComponent } = findComponentStep(stepArguments);

  const body = (
    <div style={{ '--animate-duration': '0.3s' }}>
      <div>
        <SwitchTransition mode="out-in">
          <CSSTransition
            classNames={{
              enter: 'animate__animated',
              enterActive: step === NOT_LOGGED_IN ? 'animate__fadeIn' : 'animate__fadeInRight',
              exit: 'animate__animated',
              exitActive: 'animate__fadeOutLeft'
            }}
            key={step}
            timeout={500}
          >
            {Component}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {readonly ? PortalComponent : null}
      {mobile && (
        <React.Fragment>
          {showNoDocsBanner && (
            <div className="pb-4 pt-6">
              {noDocsBannerContent({ mobile })}
            </div>
          )}
          <div className="p-4">
            {body}
          </div>
        </React.Fragment>
      )}
      {!mobile && (
        <React.Fragment>
          <Row className="justify-content-center py-6 gx-0 my-auto">
            <Col className="connect-card-container" lg={10} xl={8} xxl={7}>
              {showNoDocsBanner && (
                <Card className="overflow-hidden connect-card mb-1">
                  <Card.Body className="">
                    {noDocsBannerContent()}
                  </Card.Body>
                </Card>
              )}
              <Card className="overflow-hidden connect-card">
                <Card.Body className="p-8">
                  {body}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Row className={`d-flex text-center gx-0 mt-auto ${mobile ? 'px-4' : ''}`}>
        {DO_NOT_SHARE_INFO_STEPS.some(s => step?.includes(s)) && (
          <React.Fragment>
            <div className="text-muted">{companyName} powers their website with Buildout.</div>
            <a className="pb-4 pt-2 text-muted" href="https://share.hsforms.com/1Dkqlus8cQv20XNq6q5obAA4rjlf">
              Do not sell or share my personal info
            </a>
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
}

VaultAccess.propTypes = {
  companyName: PropTypes.string,
  docVaultEntryId: PropTypes.number,
  fetchingNavigation: PropTypes.bool,
  loginOnly: PropTypes.bool,
  mobile: PropTypes.bool,
  noDocs: PropTypes.bool,
  previewProfile: PropTypes.object,
  profileUrl: PropTypes.string.isRequired,
  readonly: PropTypes.bool.isRequired,
  setShowLogin: PropTypes.func.isRequired,
  setShowNoDocsBanner: PropTypes.func.isRequired,
  sharingId: PropTypes.string.isRequired,
  sharingUrl: PropTypes.string.isRequired,
  showNoDocsBanner: PropTypes.bool,
  userType: PropTypes.string,
  vaultPath: PropTypes.string.isRequired,
  onRefreshNavigation: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview, vault, file, ui }) => ({
  companyName: vault.companyName,
  docVaultEntryId: file?.docVaultEntryId,
  fetchingNavigation: ui.fetchingNavigation,
  vaultPath: vault.path,
  previewProfile: preview.profile,
  profileUrl: vault.profileUrl,
  readonly: preview.readonly,
  sharingId: vault.sharingId,
  sharingUrl: vault.fileSharingUrl,
  showNoDocsBanner: ui.showNoDocsBanner,
  userType: ui.userType
});

const mapDispatchToProps = { setShowNoDocsBanner, setShowLogin };

export default connect(mapStateToProps, mapDispatchToProps)(VaultAccess);
