import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CSV from 'comma-separated-values';
import ReactDataGrid from 'react-data-grid';

import { get } from 'helpers/api';

export default class CsvPreview extends Component {
  static propTypes = {
    file: PropTypes.shape({
      url: PropTypes.string
    })
  };

  state = {
    data: null
  }

  parse = (data) => {
    const rows = [];
    const columns = [];

    new CSV(data).forEach((array) => {
      if (columns.length < 1) {
        array.forEach((cell, idx) => {
          columns.push({
            key: `key-${idx}`,
            name: cell,
            resizable: true,
          });
        });
      } else {
        const row = {};
        array.forEach((cell, idx) => {
          row[`key-${idx}`] = cell;
        });
        rows.push(row);
      }
    });

    return { rows, columns };
  }

  componentDidMount() {
    get(this.props.file.url).then(data => this.setState({ data: this.parse(data) }));
  }

  renderCSV() {
    const { columns, rows } = this.state.data;
    return (
      <div>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
        />
      </div>
    );
  }

  render() {
    if (this.state.data) return this.renderCSV();
    return (
      <div>
        Loading...
        {/* <FontAwesomeIcon icon={faSpinner} size='2x' spin /> */}
      </div>
    );
  }
}
