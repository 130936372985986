import { ADD_TRANSACTION_UUID, REMOVE_TRANSACTION_UUID } from "../actions";

export default (state = {}, action) => {
  switch (action.type) {
  case ADD_TRANSACTION_UUID:
    return { ...state, [action.uuid]: true };
  case REMOVE_TRANSACTION_UUID: {
    const newState = { ...state };
    delete newState[action.uuid];
    return newState;
  }
  default:
    return state;
  }
};