import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import InviteForm from './invite_form';
import { accessLevelOptions } from '../helpers/access_levels';

import { closeDealRoomSettings, updateMember, deleteMember } from '../actions/index';
import AccessLevelSelect from './access_level_select';
import { partition } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

export class UsersModal extends Component {
  state = {
    inviting: false,
    memberPendingDelete: null
  }

  updateAccessLevel = (option, member) => {
    this.props.updateMember({ ...member, accessLevel: option.value });
  }

  handleDeleteMember = () => {
    this.props.deleteMember(this.state.memberPendingDelete);
    this.closeConfirmDelete();
  }

  openConfirmDelete = (member) => {
    this.setState({ memberPendingDelete: member });
  }

  closeConfirmDelete = () => {
    this.setState({ memberPendingDelete: null });
  }

  renderMemberRow = (member) => {
    const options = accessLevelOptions(this.props.dealRoom);

    return (
      <tr key={member.email} name="member-row">
        <td>
          <div className="text-truncate">
            {member.name || member.email}
          </div>
        </td>
        <td>
          {member.invitedBy}
        </td>
        <td className="text-right">
          <div className="row-eq-height">
            <AccessLevelSelect
              options={options}
              value={options.find(o => o.value === member.accessLevel)}
              onChange={option => this.updateAccessLevel(option, member)}
            />

            <span className="ml-3">
              <FontAwesomeIcon
                className="clickable text-danger"
                icon={faTrash}
                name="delete-button"
                size="lg" onClick={() => this.openConfirmDelete(member)}
              />
            </span>
          </div>
        </td>

      </tr>
    );
  }

  renderUsersTable(users, title, name) {
    if (users.length < 1) return;

    return (
      <React.Fragment>
        <h3 className="mb-3 mt-0">{title}</h3>
        <table className="table table-condensed table-borderless table-fixed" name={name}>
          <thead className="bold">
            <tr>
              <td>Name</td>
              <td>Invited By</td>
            </tr>
          </thead>
          <tbody>
            {users.map(this.renderMemberRow)}
          </tbody>
        </table>
      </React.Fragment>
    );
  }

  renderInviteForm() {
    return <InviteForm onReturn={() => this.setState({ emails: [], inviting: false })}/>;
  }

  renderConfirmDeleteForm() {
    const { memberPendingDelete } = this.state;

    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <h4 className="m-0">
            Remove User
          </h4>
        </Modal.Header>
        <Modal.Body name="confirm-delete-form">
          Remove {memberPendingDelete.name || memberPendingDelete.email} from the deal room?
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <div className="btn btn-link row-eq-height" name="back-button" onClick={this.closeConfirmDelete}>
            <FontAwesomeIcon className="mr-2" icon={faAngleLeft}/>
            Back
          </div>
          <div className="btn btn-danger" name="confirm-delete-button" onClick={this.handleDeleteMember}>
            Remove User
          </div>
        </Modal.Footer>
      </React.Fragment>
    );
  }

  renderContents() {
    if (this.state.inviting || this.props.members.length === 0) return this.renderInviteForm();
    const [members, pendingMembers] = partition(this.props.members, m => m.inviteAcceptedAt);

    return (this.state.memberPendingDelete ? this.renderConfirmDeleteForm() :
      <React.Fragment>
        <Modal.Header closeButton>
          <h4 className="m-0">
            {this.props.dealRoom.name}: User Access
          </h4>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mb-3 mt-0">Invite New Users</h3>
          <button className="btn btn-dark" name="invite-button" onClick={() => this.setState({ inviting: true })}>
            Invite Users
          </button>
          <hr className="w-100" />
          {this.renderUsersTable(members, 'Current Users', 'members')}
          {this.renderUsersTable(pendingMembers, 'Pending Users', 'pending')}
        </Modal.Body>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Modal className="deal-room-users-modal scrollable-body" show={this.props.show} onHide={this.props.onClose}>
        {this.renderContents()}
      </Modal>
    );
  }
}

UsersModal.propTypes = {
  dealRoom: PropTypes.shape({
    name: PropTypes.string
  }),
  deleteMember: PropTypes.func.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string,
    invitedBy: PropTypes.string,
    accessLevel: PropTypes.string
  })),
  show: PropTypes.bool,
  updateMember: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = ({ dealRoom, membersById, ui: { inDealRoomSettings, mutatingMemberIds } }) => {
  return {
    dealRoom,
    memberAccessLevels: dealRoom.memberAccessLevels,
    members: dealRoom.memberIds.map(id => membersById[id]),
    mutatingMemberIds,
    show: inDealRoomSettings
  };
};

const mapDispatchToProps = { onClose: closeDealRoomSettings, updateMember, deleteMember };

export default connect(mapStateToProps, mapDispatchToProps)(UsersModal);
