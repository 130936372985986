import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import Tooltip from 'components/tooltip';
import UserIcon from 'components/user_icon';
import { ProjectDatabaseMemberPropTypes } from 'components/project/prop_types';

export const VISIBLE_USERS_SIZE = 4;

export default function UserIconList({ size, users, onAddClicked, placement = 'top', showHidden = true }) {
  const visibleUsers = showHidden ? users : users.slice(0, VISIBLE_USERS_SIZE);
  const userIcons = visibleUsers.map((user) => {
    const prefix = user.role ? `${user.role}: ` : '';
    return (
      <Tooltip content={`${prefix}${user.name || user.email}`} dark key={user.id} placement={placement}>
        <span>
          <UserIcon size={size} user={user} />
        </span>
      </Tooltip>
    );
  });

  let hiddenIcon;
  const hiddenUsers = users.slice(VISIBLE_USERS_SIZE + 1, users.length - 1);
  if (!showHidden && hiddenUsers.length > 0) {
    const tooltip = hiddenUsers.map(user => (<div key={user.id}>{user.name || user.email}</div>));
    hiddenIcon = (
      <Tooltip content={tooltip} dark placement={placement}>
        <div className="user-group-icon" name="hidden-users-icon">
          <span className="hidden-user-count">{`+${hiddenUsers.length}`}</span>
        </div>
      </Tooltip>
    );
  }

  return (
    <span className="user-icon-list ml-4">
      {userIcons}
      {hiddenIcon}
      {onAddClicked &&
        <span
          className="add-button clickable user-group-icon"
          style={{ width: size, height: size }}
          onClick={onAddClicked}
        >
          <FontAwesomeIcon icon={faCog} size="lg" />
        </span>
      }
    </span>
  );
};

UserIconList.propTypes = {
  placement: PropTypes.string,
  showHidden: PropTypes.bool,
  size: PropTypes.string,
  users: PropTypes.arrayOf(ProjectDatabaseMemberPropTypes).isRequired,
  onAddClicked: PropTypes.func
};
