import React from 'react';
import PropTypes from 'prop-types';

import ManagedInput from './index';

import './styles/checkbox.scss';

class ManagedCheckboxGroup extends React.Component {
  static propTypes = {
    checkboxClassName: PropTypes.string,
    disabled: PropTypes.boolean,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string
    })),
    value: PropTypes.object,
    onValueChange: PropTypes.func.isRequired,
  }

  handleChange = (changes) => {
    this.props.onValueChange({ ...this.props.value, ...changes });
  }

  render() {
    const { checkboxClassName, disabled, options, value, ...props } = this.props;

    return (
      <div {...props}>
        {
          options.map(({ name, label }) => (
            <ManagedInput
              disabled={disabled}
              key={name}
              name={name}
              type="checkbox"
              value={value[name]}
              wrapperClassName={checkboxClassName}
              onChange={this.handleChange}
            >
              {label}
            </ManagedInput>
          ))
        }
      </div>
    );

  }
}

export default ManagedCheckboxGroup;
