const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December',
];

export const monthOptions = monthNames.map((month, index) => ({
  label: month,
  value: index
}));

export function getDaysOptions(month, year) {
  const daysInMonth = new Date(year, parseInt(month) + 1, 0).getDate();
  const options = [];
  for (let i = 1; i <= daysInMonth; i++) {
    const date = i;
    options.push({ label: date, value: date });
  }

  return options;
}

/**
 * @param {string|number} year - Selected year that will be the base to determine the start date
 * @param {string|number} monthIndex - Index of the selected month where January is 0
 * @param {string|number} date - Selected date where the first day of the month is 1
 * @param {number} yearsOffset - The number of years before the selected year to determine the start date 
 * @returns {Date} - A formatted date where the year is the passed year minus the yearsOffset
 */
export function getDateByYearsOffset(year, monthIndex, date, yearsOffset = 1) {
  const month = parseInt(monthIndex);
  date = parseInt(date);

  if (yearsOffset > 1) {
    year = parseInt(year) - yearsOffset;
  }

  return new Date(year, month, date);
}
