import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import { USD } from 'helpers/formatters/number_formats';
import {
  formatDate,
  DAY_MONTH_FORMAT,
} from 'helpers/formatters/date_time_formats';

const convertToDiffPercent = (value, reference) => {
  return ((value || 0) / (reference || 1) - 1) * 100;
};

const getDiffPercent = (minRangePercent, maxRangePercent, percent) => {
  return (
    minRangePercent + ((maxRangePercent - minRangePercent) * percent) / 100
  );
};

const getPercent = (minRange, maxRange, value) => {
  return (100 * (value - minRange)) / (maxRange - minRange);
};

export default function ValuationTool({
  comments,
  defaultEstimate,
  estimate,
  lastUpdatedAt,
  rangeHigh,
  rangeLow,
  onCancel,
  onSubmit,
}) {
  // Between 0 and 100
  const defaultPercent = getPercent(rangeLow, rangeHigh, defaultEstimate);
  const [sliding, setSliding] = useState(false);
  const [changed, setChanged] = useState(false);
  // Between 0 and 100
  const [percent, setPercent] = useState(getPercent(rangeLow, rangeHigh, estimate));
  const maxRangePercent = convertToDiffPercent(rangeHigh, defaultEstimate);
  const minRangePercent = convertToDiffPercent(rangeLow, defaultEstimate);
  // Between minRangePercent and maxRangePercent
  const diffPercent = getDiffPercent(minRangePercent, maxRangePercent, percent);
  const [adjustmentComments, setAdjustmentComments] = useState(comments);

  // TODO: send this value when the slidign is over
  // We'll send this value
  let adjustedValue;

  switch (percent) {
  case 0:
    adjustedValue = rangeLow;
    break;
  case 100:
    adjustedValue = rangeHigh;
    break;
  default:
    adjustedValue = Math.round(defaultEstimate * (1 + diffPercent / 100));
    break;
  }

  const disabled = !adjustmentComments || !changed;

  const handleReset = () => {
    setPercent(defaultPercent);
    setChanged(false);
  };

  const handleSubmit = () => {
    onSubmit(changed ? { comments: adjustmentComments, estimate: adjustedValue } : undefined);
  };

  useEffect(() => {
    if (!sliding) setChanged(percent !== defaultPercent);
  }, [sliding, percent]);

  return (
    <Card>
      <Card.Body className="d-flex flex-column gap-2">
        <div className="text-center">
          {!!lastUpdatedAt && (
            <small className="text-muted">
              Updated on {formatDate(lastUpdatedAt, DAY_MONTH_FORMAT)}
            </small>
          )}
          <h3>{USD.format(adjustedValue, { round: true })}</h3>
        </div>
        {changed && (
          <div className="d-flex align-items-center gap-2 px-2 py-1 rounded-pill bg-light align-self-center">
            <small className="text-muted">
              Buildout Estimate&nbsp;
              <strong>{USD.format(defaultEstimate, { round: true })}</strong>
            </small>
            <strong
              className="text-primary small clickable"
              onClick={handleReset}
            >
              Reset
            </strong>
          </div>
        )}
        <strong>
          Insider Adjustment&nbsp;
          <span className="text-danger">*</span>
        </strong>
        <Form.Range
          max={100}
          min={0}
          value={percent}
          onChange={e => setPercent(Number(e.target.value))}
          onMouseDown={() => setSliding(true)}
          onMouseUp={() => setSliding(false)}
        />
        <div className="d-flex align-items-center justify-content-between">
          <small className="text-muted">Lower</small>
          <small className="text-muted">Higher</small>
        </div>
        <small className="text-muted">
          Adjustment range is based on our confidence interval for this
          estimate.
        </small>
        <Form.Group className="mb-3">
          <Form.Label>
            Comments&nbsp;
            <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="I am adjusting this valuation because..."
            rows={4}
            value={adjustmentComments}
            onChange={e => setAdjustmentComments(e.target.value)}
          />
        </Form.Group>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <Button variant="outline-neutral" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={disabled} variant="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

ValuationTool.propTypes = {
  comments: PropTypes.string,
  defaultEstimate: PropTypes.number.isRequired,
  estimate: PropTypes.number.isRequired,
  lastUpdatedAt: PropTypes.string,
  rangeHigh: PropTypes.number.isRequired,
  rangeLow: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
