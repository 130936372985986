
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

class ProgressEntry extends React.Component {
  static propTypes = {
    uploadInfo: PropTypes.object,
    uuid: PropTypes.string.isRequired
  }

  name = () => {
    return this.props.uploadInfo.name || window.fileData[this.props.uuid].name;
  }

  progressBar = () => {
    if (this.props.uploadInfo.uploadCompleted) {
      return (
        <span className="upload-check pull-right"><FontAwesomeIcon icon={faCheck} /></span>
      );
    } else if (this.props.uploadInfo.uploadFailed) {
      return (
        <span className="upload-ex pull-right"><FontAwesomeIcon icon={faTimes} /></span>
      );
    }
    return <ProgressBar active now={this.props.uploadInfo.progress} />;
  }


  render() {
    return (
      <div className="d-flex w-100 justify-content-between py-2 px-3 prog-entry">
        <div className={classNames("pr-2 text-truncate", { 'w-50': !this.props.uploadInfo.uploadCompleted })}>
          {this.name()}
        </div>
        <div className={this.props.uploadInfo.uploadCompleted ? 'check' : 'prog-bar'}>
          {this.progressBar()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ui: { uploadsInProgress } }, defaultProps) => {
  let uploadInfo;
  uploadsInProgress.forEach((upload) => {
    if (upload.uuid === defaultProps.uuid) {
      uploadInfo = upload;
    }
  });
  return { uploadInfo };
};

export default connect(mapStateToProps)(ProgressEntry);
