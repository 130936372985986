import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { formatDateTime } from 'helpers/formatters/date_time_formats';

import { download } from './helpers';
import FilePreview from './viewers/file_viewer';

export default class Preview extends Component {
  static propTypes = {
    file: PropTypes.shape({
      displayName: PropTypes.string,
      lastModified: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.string,
      updatedAt: PropTypes.string,
      url: PropTypes.string
    }),
    noPreviewComponent: PropTypes.node,
    onExit: PropTypes.func
  }

  renderEmptyState() {
    return (
      <div>
        No File Selected
      </div>
    );
  }


  render() {
    const { file, noPreviewComponent, onExit } = this.props;
    if (!file) return this.renderEmptyState();

    const downloadUrl = file.downloadUrl || file.url;
    const downloadLinkChildren = <React.Fragment>
      <FontAwesomeIcon className="mr-2 me-2" icon={faDownload}/>
      Download
    </React.Fragment>;
    let downloadLink;
    if (file.path) {
      downloadLink = <a href="#" onClick={() => download(file)}>{downloadLinkChildren}</a>;
    } else if (downloadUrl) {
      downloadLink = <a href={downloadUrl} rel="noopener noreferrer" target="_blank">{downloadLinkChildren}</a>;
    }

    return (
      <Row>
        <Col sm={9}>
          <div className="border-bottom mb-5">
            {onExit &&
              <span className="clickable">
                <FontAwesomeIcon icon={faArrowLeft} onClick={onExit}/>
              </span>
            }
            <h4 className="d-inline-block ml-2 ms-2">{file.displayName || file.name}</h4>
          </div>
          <div className="pb-4">
            <FilePreview file={file} noPreviewComponent={noPreviewComponent}/>
          </div>
        </Col>
        <Col className="border-left" sm={3}>
          <h4>File Details</h4>
          <div className="my-3">
            Modified
            <div className="text-muted">
              {formatDateTime(file.lastModified || file.updatedAt)}
            </div>
          </div>
          {file.size && (
            <div className="mb-3">
              Size
              <div className="text-muted">
                {file.size}
              </div>
            </div>
          )}
          <div>{downloadLink}</div>
        </Col>
      </Row>
    );
  }
}
