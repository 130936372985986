export const propertyTypeMappings = {
  1: 'Office',
  2: 'Retail',
  3: 'Industrial',
  5: 'Land',
  6: 'Multifamily',
  7: 'Special Purpose',
  8: 'Hospitality'
};

export const propertySubtypeMappings = {
  101: 'Office Building',
  102: 'Creative/Loft',
  103: 'Executive Suites',
  104: 'Medical',
  105: 'Institutional/Governmental',
  106: 'Office Warehouse',
  107: 'Office Condo',
  108: 'Coworking',
  109: 'Lab',
  201: 'Street Retail',
  202: 'Strip Center',
  203: 'Free Standing Building',
  204: 'Regional Mall',
  205: 'Retail Pad',
  206: 'Vehicle Related',
  207: 'Outlet Center',
  208: 'Power Center',
  209: 'Neighborhood Center',
  210: 'Community Center',
  211: 'Specialty Center',
  212: 'Theme/Festival Center',
  213: 'Restaurant',
  214: 'Post Office',
  215: 'Retail Condo',
  216: 'Lifestyle Center',
  301: 'Manufacturing',
  302: 'Warehouse/Distribution',
  303: 'Flex Space',
  304: 'Research & Development',
  305: 'Refrigerated/Cold Storage',
  306: 'Office Showroom',
  307: 'Truck Terminal/Hub/Transit',
  308: 'Self Storage',
  309: 'Industrial Condo',
  310: 'Data Center',
  501: 'Office',
  502: 'Retail',
  503: 'Retail-Pad',
  504: 'Industrial',
  505: 'Residential',
  506: 'Multifamily',
  507: 'Other',
  601: 'High-Rise',
  602: 'Mid-Rise',
  603: 'Low-Rise/Garden',
  604: 'Government Subsidized',
  605: 'Mobile Home Park',
  606: 'Senior Living',
  607: 'Skilled Nursing',
  608: 'Single Family Rental Portfolio',
  701: 'School',
  702: 'Marina',
  703: 'Other',
  704: 'Golf Course',
  705: 'Church',
  801: 'Full Service',
  802: 'Limited Service',
  803: 'Select Service',
  804: 'Resort',
  805: 'Economy',
  806: 'Extended Stay',
  807: 'Casino'
};

export function getPropertyTypesById() {
  const propertyTypesById = {};
  for (const id in propertyTypeMappings) {
    propertyTypesById[id] = {
      id: parseInt(id),
      label: propertyTypeMappings[id],
      subtypesById: {},
    };
  }

  for (let id in propertySubtypeMappings) {
    id = parseInt(id);
    propertyTypesById[Math.floor(id / 100)].subtypesById[id] = {
      id,
      label: propertySubtypeMappings[id],
    };
  }

  return propertyTypesById;
}
