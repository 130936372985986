import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

import Emails from './emails';
import PhoneNumbers from './phone_numbers';
import Addresses from './addresses';

export default function CompanyForm({
  formData,
  addresses,
  emails,
  phoneNumbers,
  onFormChange,
  onSetAddresses,
  onSetEmails,
  onSetPhoneNumbers
}) {
  return (
    <React.Fragment>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control name="name" value={formData.name || ''} onChange={onFormChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Website</Form.Label>
        <Form.Control name="website" value={formData.website || ''} onChange={onFormChange}/>
      </Form.Group>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" value={formData.description || ''} onChange={onFormChange}/>
      </Form.Group>
      <Emails
        emails={emails}
        onSet={onSetEmails}
      />
      <PhoneNumbers
        phoneNumbers={phoneNumbers}
        onSet={onSetPhoneNumbers}
      />
      <Addresses
        addresses={addresses}
        onSet={onSetAddresses}
      />
    </React.Fragment>
  );
}

CompanyForm.propTypes = {
  addresses: PropTypes.array,
  emails: PropTypes.array,
  formData: PropTypes.object,
  phoneNumbers: PropTypes.array,
  onFormChange: PropTypes.func.isRequired,
  onSetAddresses: PropTypes.func.isRequired,
  onSetEmails: PropTypes.func.isRequired,
  onSetPhoneNumbers: PropTypes.func.isRequired
};
