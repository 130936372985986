import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { reorderableList } from 'helpers/react_dnd/reorderable_list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

import ManagedInput from 'components/form/managed_input';

import './styles/index.scss';

export const ATTRIBUTES = [
  { key: 'name', label: 'Name', width: '100%' },
  { key: 'size', label: 'Size', padded: true },
  { key: 'createdBy', label: 'Owner', padded: true },
  { key: 'lastModified', label: 'Date Modified', padded: true },
  { key: 'lastAccessed', label: 'Last Accessed', padded: true }
];

export class Table extends React.Component {
  static propTypes = {
    additionalColumns: PropTypes.arrayOf(PropTypes.shape({
      className: PropTypes.string,
      key: PropTypes.string,
      label: PropTypes.string,
      padded: PropTypes.bool,
      width: PropTypes.string
    })),
    allSelected: PropTypes.bool,
    attributeOverrides: PropTypes.objectOf(PropTypes.shape({
      className: PropTypes.string,
      label: PropTypes.string,
    })),
    children: PropTypes.node,
    excludedColumns: PropTypes.arrayOf(PropTypes.string),
    sort: PropTypes.shape({
      key: PropTypes.string,
      reverse: PropTypes.bool
    }),
    onHeaderClick: PropTypes.func,
    onSelectAll: PropTypes.func
  }

  static defaultProps = {
    additionalColumns: [],
    excludedColumns: []
  }

  handleHeaderClick = (key) => {
    this.props.onHeaderClick(key);
  }

  renderArrow = () => {
    const icon = this.props.sort.reverse ? faArrowUp : faArrowDown;
    return <FontAwesomeIcon className="left-buffer-sm ms-2" icon={icon}/>;
  }

  renderColumnHeader = (attribute) => {
    const key = attribute.key;
    if (this.props.excludedColumns.includes(key)) return;

    const overrides = this.props.attributeOverrides && this.props.attributeOverrides[key];
    const { className, label, padded, width } = { ...attribute, ...overrides };

    return (
      <th
        aria-label={`${key}Header`}
        className={classNames('clickable', className, { padded })}
        data-name={key}
        key={key}
        name={key}
        width={width}
        onClick={() => this.handleHeaderClick(key)}
      >
        {label}
        {key == this.props.sort.key && this.renderArrow()}
      </th>
    );
  }

  renderColumnHeaders() {
    const columns = [...ATTRIBUTES];
    if (this.props.additionalColumns) {
      columns.push(...this.props.additionalColumns);
    }
    return columns.map(this.renderColumnHeader);
  }

  renderCheckbox() {
    return (
      <ManagedInput
        checked={this.props.allSelected}
        className="my-0"
        name="selected"
        type="checkbox"
        onChange={this.props.onSelectAll}
      />
    );
  }

  render() {
    if (this.props.children.length == 0) return <React.Fragment/>;
    return (
      <table className="table table-hover files-table mb-0">
        <thead>
          <tr>
            {!this.props.excludedColumns.includes('select') && (
              <th className="pr-0 py-0 align-middle">{this.renderCheckbox()}</th>
            )}
            <th/>
            {this.renderColumnHeaders()}
            {!this.props.excludedColumns.includes('lastAccessed') && <th/>}
            <th className={this.props.attributeOverrides?.actions?.className}>
              {this.props.attributeOverrides?.actions?.label}
            </th>
          </tr>
        </thead>
        <tbody className="align-middle">
          {this.props.children}
        </tbody>
      </table>
    );
  }
}

export default reorderableList(Table);
