import { SET_RESULTS } from '../actions/results';

export default function(
  state = {
    allResults: null,
    detailedResults: null,
    offMarketResults: null,
    allOffMarketResults: null,
    totalOffMarketResults: null,
    page: 1,
    totalPages: 1
  },
  action
) {
  switch (action.type) {
  case SET_RESULTS:
    return { ...state, ...action.payload };
  default:
    return state;
  }
}
