import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FileSystem from 'components/file_system';
import DocActivityModal from './doc_activity_modal';

import { renameEntry, toggleSelectEntry, onEntryDrop, setCurrentFolderId,
  startDraggingEntries, stopDraggingEntries, dragEntries, moveEntries, showRightClickMenu,
  hideRenameEntryInput, openFilePreview, deleteEntries, downloadEntries, restoreEntries } from '../actions';

import { faTrash, faDownload, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { faTrashUndo } from '@fortawesome/pro-solid-svg-icons';

class EntryRow extends React.Component {
  static propTypes = {
    deleteEntries: PropTypes.func,
    downloadEntries: PropTypes.func.isRequired,
    dragEntries: PropTypes.func,
    draggedEntries: PropTypes.array,
    dropdownComponent: PropTypes.elementType,
    entry: PropTypes.object.isRequired,
    excludedColumns: PropTypes.arrayOf(PropTypes.string),
    hideRenameEntryInput: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    inRecycleBin: PropTypes.bool,
    isEditing: PropTypes.bool,
    moveEntries: PropTypes.func,
    moveToParentId: PropTypes.number,
    mutating: PropTypes.bool.isRequired,
    openFilePreview: PropTypes.func.isRequired,
    renameEntry: PropTypes.func,
    restoreEntries: PropTypes.func,
    selected: PropTypes.bool,
    setCurrentFolderId: PropTypes.func.isRequired,
    showRightClickMenu: PropTypes.func.isRequired,
    startDraggingEntries: PropTypes.func,
    stopDraggingEntries: PropTypes.func,
    toggleSelectEntry: PropTypes.func.isRequired,
    onBeginDrag: PropTypes.func.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onEntryDrop: PropTypes.func,
    onReorder: PropTypes.func
  }

  state = {
    showActivityModal: false
  }

  handleNavigation = ({ id }) => {
    this.props.setCurrentFolderId(id);
  }

  handlePreview = () => {
    this.props.openFilePreview(this.props.entry);
  }

  handleFileRename = (file, name) => {
    if (name !== '' && name !== file.name) {
      this.props.renameEntry(this.props.entry, name);
    }
  }

  setName = (event) => {
    const name = event.target.value;
    if (name !== '' && name !== this.props.entry.name) {
      this.props.renameEntry(this.props.entry, name);
    }
    this.props.hideRenameEntryInput();
  }

  toggleSelect = () => {
    this.props.toggleSelectEntry(this.props.entry, this.props.selected);
  }

  actionsFor = (doc) => {
    if (this.props.inRecycleBin) {
      return [{
        label: "Delete Permanently",
        key: "hardDelete",
        icon: faTrash,
        callback: () => this.props.deleteEntries([doc], !this.props.inRecycleBin)
      }, {
        label: "Restore",
        key: "restore",
        icon: faTrashUndo,
        callback: () => this.props.restoreEntries([doc])
      }];
    }

    const actions = [];

    if (this.props.entry.canModify) {
      actions.push({
        label: 'Delete',
        key: 'delete',
        icon: faTrash,
        callback: () => this.props.deleteEntries([doc], !this.props.inRecycleBin)
      });
    }

    actions.push({
      label: 'Download',
      key: 'download',
      icon: faDownload,
      callback: () => this.props.downloadEntries([doc])
    });

    if (this.props.entry.canModify && this.props.moveToParentId) {
      actions.push({
        label: 'Move to Parent Folder',
        key: 'moveUp',
        icon: faFileExport,
        callback: () => this.props.moveEntries([doc], this.props.moveToParentId)
      });
    }

    return actions;
  }

  file = () => {
    return {
      ...this.props.entry,
      selected: this.props.selected
    };
  }

  render() {
    const { onBeginDrag, onEndDrag, onReorder, entry, index, excludedColumns } = this.props;
    const dndProps = entry.canModify ? { onBeginDrag, onEndDrag, onReorder } : {};
    return (
      <React.Fragment>
        <DocActivityModal
          file={this.file()}
          show={this.state.showActivityModal}
          onClose={() => this.setState({ showActivityModal: false })}
        />
        <FileSystem.Row
          {...dndProps}
          actions={this.actionsFor(entry)}
          dropdownComponent={this.props.dropdownComponent}
          excludedColumns={excludedColumns}
          file={this.file()}
          index={index}
          key={index}
          preventDrag={!entry.canModify}
          showActivityModal={() => this.setState({ showActivityModal: true })}
          onNavigation={this.handleNavigation}
          onPreview={this.handlePreview}
          onRename={entry.canModify ? this.handleFileRename : null}
          onSelect={this.toggleSelect}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(
  {
    entriesById,
    selected: { entryIds },
    ui: { draggedEntryIds, mutatingEntryIds, inRecycleBin, showRenameEntryInput }
  }, props) {

  const mutating = mutatingEntryIds.includes(props.id);
  return {
    entry: entriesById[props.id],
    entriesById: entriesById,
    selected: entryIds.includes(props.id),
    draggedEntries: draggedEntryIds.map(id => entriesById[id]),
    mutating,
    inRecycleBin,
    isEditing: showRenameEntryInput && entryIds.includes(props.id) && !mutating
  };
}

const mapDispatchToProps = {
  renameEntry,
  toggleSelectEntry,
  onEntryDrop,
  setCurrentFolderId,
  startDraggingEntries,
  stopDraggingEntries,
  moveEntries,
  dragEntries,
  showRightClickMenu,
  hideRenameEntryInput,
  openFilePreview,
  deleteEntries,
  downloadEntries,
  restoreEntries
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryRow);
