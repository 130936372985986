import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck as farCircleCheck, faCircleXmark as farCircleXMark } from '@fortawesome/pro-regular-svg-icons';

import { setOwnerContactMethodScore } from '../store/actions/owners';
import { connect } from 'react-redux';

const goodTooltip = (contactMethodKey) => {
  if (contactMethodKey === 'phoneNumbers') {
    return 'Verified Phone';
  } else if (contactMethodKey === 'emails') {
    return 'Verified Email';
  } else if (contactMethodKey === 'addresses') {
    return 'Verified Address';
  }
};

const badTooltip = (contactMethodKey) => {
  if (contactMethodKey === 'phoneNumbers') {
    return 'Bad Phone';
  } else if (contactMethodKey === 'emails') {
    return 'Bad Email';
  } else if (contactMethodKey === 'addresses') {
    return 'Bad Address';
  }
};

function ContactMethod({
  owner,
  contactMethodKey,
  renderDisplayName,
  renderLogActivity,
  setOwnerContactMethodScore
}) {
  const [showMore, setShowMore] = useState(false);


  const upvoted = contactMethod => contactMethod.connectRankScore > 0;
  const downvoted = contactMethod => contactMethod.connectRankScore < 0;

  const handleUpdate = (method, newScore) => {
    setOwnerContactMethodScore(owner, contactMethodKey, method.id, newScore);
  };

  const handleUpvote = (contactMethod) => {
    const newScore = upvoted(contactMethod) ? 0 : 1;
    handleUpdate(contactMethod, newScore);
  };

  const handleDownvote = (contactMethod) => {
    const newScore = downvoted(contactMethod) ? 0 : -1;
    handleUpdate(contactMethod, newScore);
  };

  const goodContactMethods = owner.contact[contactMethodKey]
    .filter(contactMethod => !contactMethod._destroy && contactMethod.connectRankScore >= 0)
    .sort((a, b) => b.connectRankScore - a.connectRankScore);
  const badContactMethods = owner.contact[contactMethodKey]
    .filter(contactMethod => !contactMethod._destroy && contactMethod.connectRankScore < 0)
    .sort((a, b) => b.connectRankScore - a.connectRankScore);


  const renderedContactMethods = (contactMethods) => {
    return contactMethods.map((contactMethod) => {
      return (
        <div className="d-flex align-items-center hover-toggle-parent gap-2" key={contactMethod.id}>
          {
            renderDisplayName(
              contactMethod,
              upvoted(contactMethod) ? 'fw-bold' :
                downvoted(contactMethod) ? 'text-decoration-line-through text-muted' : ''
            )
          }
          {owner.id && (
            <React.Fragment>
              <div className={`d-flex ps-1 gap-1 ${contactMethod.connectRankScore === 0 ? 'hover-v-hidden' : ''}`}>
                <OverlayTrigger overlay={<Tooltip>{goodTooltip(contactMethodKey)}</Tooltip>}>
                  <Button className="p-1 h-auto" variant="link" onClick={() => handleUpvote(contactMethod)}>
                    <FontAwesomeIcon
                      className={
                        upvoted(contactMethod) ? 'text-success' :
                          downvoted(contactMethod) ? 'text-muted opacity-25' : 'thumbs-inactive'
                      }
                      icon={upvoted(contactMethod) ? faCircleCheck : farCircleCheck}
                    />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger overlay={<Tooltip>{badTooltip(contactMethodKey)}</Tooltip>}>
                  <Button className="p-1 h-auto" variant="link" onClick={() => handleDownvote(contactMethod)}>
                    <FontAwesomeIcon
                      className={
                        downvoted(contactMethod) ? 'text-danger' :
                          upvoted(contactMethod) ? 'text-muted opacity-25' : 'thumbs-inactive'
                      }
                      icon={downvoted(contactMethod) ? faCircleXmark : farCircleXMark}
                    />
                  </Button>
                </OverlayTrigger>
              </div>
            </React.Fragment>
          )}
          {renderLogActivity && renderLogActivity(contactMethodKey, contactMethod)}
        </div>
      );
    });
  };

  const firstGroup = renderedContactMethods(goodContactMethods.slice(0, 3));
  const secondGroup = renderedContactMethods(goodContactMethods.slice(3).concat(badContactMethods));

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-3">
        {firstGroup}
      </div>
      {secondGroup.length > 0 && (
        <Collapse in={showMore}>
          <div>
            <div className="d-flex flex-column gap-3">
              {secondGroup}
            </div>
          </div>
        </Collapse>
      )}
      {!showMore && secondGroup.length > 0 && (
        <Button
          className="w-100"
          size="sm"
          variant="text-primary"
          onClick={() => setShowMore(true)}
        >
          Show More
        </Button>
      )}
    </div>
  );
}

ContactMethod.propTypes = {
  contactMethodKey: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.shape({
      id: PropTypes.number.isRequired,
      addresses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        twoLine: PropTypes.arrayOf(PropTypes.string).isRequired
      })).isRequired
    })
  }),
  renderDisplayName: PropTypes.func.isRequired,
  renderLogActivity: PropTypes.func,
  setOwnerContactMethodScore: PropTypes.func.isRequired
};

export default connect(null, { setOwnerContactMethodScore })(ContactMethod);
