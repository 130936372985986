import {
  OPEN_FILE_SEARCH,
  CLOSE_FILE_SEARCH,
  SET_FILE_SEARCH_QUERY,
  SET_SEARCH_MATCHES,
  SET_CURRENT_FOLDER_ID
} from "../actions";

export default (state = { entryIds: [], open: false, query: '', searchPerformed: false }, action) => {
  switch (action.type) {

  case OPEN_FILE_SEARCH:
    return { ...state, open: true };
  case SET_CURRENT_FOLDER_ID:
  case CLOSE_FILE_SEARCH:
    return { ...state, open: false, query: '', entryIds: [], searchPerformed: false };
  case SET_FILE_SEARCH_QUERY:
    return { ...state, query: action.query };
  case SET_SEARCH_MATCHES:
    return { ...state, entryIds: action.entryIds, searchPerformed: true };
  default:
    return state;
  }
};