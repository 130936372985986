export const contactTypeOptions = [
  {
    key: 'owner_landlord',
    value: 0,
    label: 'Owner Landlord'
  },
  {
    key: 'owner_user',
    value: 1,
    label: 'Owner User'
  },
  {
    key: 'partner',
    value: 2,
    label: 'Partner'
  },
  {
    key: 'investor',
    value: 3,
    label: 'Investor'
  },
  {
    key: 'property_manager',
    value: 4,
    label: 'Property Manager'
  },
  {
    key: 'tenant',
    value: 5,
    label: 'Tenant'
  },
  {
    key: 'lender',
    value: 6,
    label: 'Lender'
  },
  {
    key: 'developer',
    value: 7,
    label: 'Developer'
  },
  {
    key: 'listing_broker',
    value: 8,
    label: 'Listing Broker'
  },
  {
    key: 'owner_representative',
    value: 9,
    label: 'Owner Representative'
  },
  {
    key: 'buyer',
    value: 10,
    label: 'Buyer'
  },
  {
    key: 'seller',
    value: 11,
    label: 'Seller'
  },
  {
    key: 'buyer_agent',
    value: 12,
    label: 'Buyer Agent'
  },
  {
    key: 'tenant_contact',
    value: 13,
    label: 'Tenant Contact'
  },
  {
    key: 'owner_landlord_agent',
    value: 14,
    label: 'Owner Landlord Agent'
  },
  {
    key: 'seller_agent',
    value: 15,
    label: 'Seller Agent'
  },
  {
    key: 'vendor',
    value: 16,
    label: 'Vendor'
  },
  {
    key: 'payer',
    value: 17,
    label: 'Payer'
  },
  {
    key: 'lender_contact',
    value: 18,
    label: 'Lender Contact'
  },
  {
    key: 'buyer_contact',
    value: 19,
    label: 'Buyer Contact'
  },
  {
    key: 'seller_contact',
    value: 20,
    label: 'Seller Contact'
  },
  {
    key: 'tenant_agent',
    value: 21,
    label: 'Tenant Agent'
  },
  {
    key: 'commercial_buyer',
    value: 22,
    label: 'Commercial Buyer'
  },
  {
    key: 'commercial_agent',
    value: 23,
    label: 'Commercial Agent'
  },
  {
    key: 'residential_agent',
    value: 24,
    label: 'Residential Agent'
  },
  {
    key: 'client',
    value: 25,
    label: 'Client'
  },
  {
    key: 'municipal_government',
    value: 26,
    label: 'Municipal Government'
  },
  {
    key: 'title_officer',
    value: 27,
    label: 'Title Officer'
  },
  {
    key: 'title_examiner',
    value: 28,
    label: 'Title Examiner'
  },
  {
    key: 'attorney',
    value: 29,
    label: 'Attorney'
  },
  {
    key: 'appraiser',
    value: 30,
    label: 'Appraiser'
  },
  {
    key: 'project_manager',
    value: 31,
    label: 'Project Manager'
  },
  {
    key: 'facilities_manager',
    value: 32,
    label: 'Facilities Manager'
  },
  {
    key: 'architect',
    value: 33,
    label: 'Architect'
  },
  {
    key: 'consultant',
    value: 34,
    label: 'Consultant'
  },
  {
    key: 'accountant',
    value: 35,
    label: 'Accountant'
  },
  {
    key: 'financial_advisor',
    value: 36,
    label: 'Financial Advisor'
  },
  {
    key: 'referring_agent',
    value: 37,
    label: 'Referring Agent'
  },
  {
    key: 'broker',
    value: 38,
    label: 'Broker'
  },
  {
    key: 'contractor',
    value: 39,
    label: 'Contractor'
  },
  {
    key: 'subcontractor',
    value: 40,
    label: 'Subcontractor'
  },
  {
    key: 'insurance_agent',
    value: 41,
    label: 'Insurance Agent'
  },
  {
    key: 'property_inspector',
    value: 42,
    label: 'Property Inspector'
  },
  {
    key: 'surveyor',
    value: 43,
    label: 'Surveyor'
  },
  {
    key: 'principal',
    value: 44,
    label: 'Principal'
  }
];
