import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { setShowLogin } from '../actions/ui';
import { connect } from 'react-redux';

function LostAccess({ entry, setShowLogin }) {
  const entryType = entry.folder ? 'folder' : 'document';

  return (
    <div className="h-100 w-100 position-absolute overflow-auto d-flex flex-column">
      <Row className="justify-content-center py-6 gx-0 my-auto">
        <Col className="connect-card-container" lg={10} xl={8} xxl={7}>
          <Card className="overflow-hidden connect-card text-center">
            <Card.Body className="px-5 py-8">
              <FontAwesomeIcon className="text-branding" icon={faLock} size="2x"/>
              {entry.removed ? (<NoLongerAvailable entryType={entryType}/>) : (
                <ContinueLeadFlow entry={entry} entryType={entryType} onClick={setShowLogin}/>)}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="mt-auto"></div>
    </div>
  );
}

function ContinueLeadFlow({ entry, entryType, onClick }) {
  // if its 1 or higher, the user cannot access the doc unless the broker gives them higher access
  const canAccessDoc = entry.accessLevel < 1;

  return (
    <React.Fragment>
      <h5 className="my-5"><strong>Access to this {entryType} has changed</strong></h5>
      <div>
        The {entryType} you were trying to access has been changed from public to private.
      </div>
      {canAccessDoc && (
        <Button className="mt-6 btn-primary" onClick={onClick}>Access Secure {entryType}</Button>
      )}
    </React.Fragment>
  );
}

function NoLongerAvailable({ entryType }) {
  return (
    <React.Fragment>
      <h5 className="my-5"><strong>This {entryType} is no longer available</strong></h5>
      <div>
        The {entryType} you were trying to access has been removed.
        If you need assistance or have questions please contact the broker
      </div>
    </React.Fragment>
  );
}

const entryProps = PropTypes.shape({
  accessLevel: PropTypes.number,
  lostAccess: PropTypes.bool,
  removed: PropTypes.bool
});

LostAccess.propTypes = { entry: entryProps, setShowLogin: PropTypes.func.isRequired };
ContinueLeadFlow.propTypes = {
  entry: entryProps,
  entryType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};
NoLongerAvailable.propTypes = { entryType: PropTypes.string.isRequired };

const mapDispatchToProps = { setShowLogin };
export default connect(null, mapDispatchToProps)(LostAccess);
