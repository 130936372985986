import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content']

  toggleVisibility() {
    if (this.contentTarget.style.display === 'none') {
      this.contentTarget.style.setProperty('display', 'inherit');
    } else {
      this.contentTarget.style.setProperty('display', 'none', 'important');
    }
  }
}
