import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

export default function EmailForm({ activity, emails, onUpdate }) {
  return (
    <div className="d-flex flex-column gap-3">
      {emails && emails.length > 0 && (
        <Form.Group>
          <Form.Label>To</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faEnvelope}/>
            </InputGroup.Text>
            <Form.Select
              className="border-0"
              value={activity.email}
              onChange={e => onUpdate({ ...activity, email: e.target.value })}
            >
              <option hidden value="">Select an email from the contact</option>
              <option value=""></option>
              {emails.map(email => (
                <option key={email.email} value={email.email}>{email.email}</option>
              ))}
            </Form.Select>
          </InputGroup>
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>Subject</Form.Label>
        <Form.Control
          value={activity.emailSubject}
          onChange={e => onUpdate({ ...activity, emailSubject: e.target.value })}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Body</Form.Label>
        <Form.Control
          as="textarea"
          className="privacy-mask"
          rows={5}
          value={activity.description}
          onChange={e => onUpdate({ ...activity, description: e.target.value })}
        />
      </Form.Group>
    </div>
  );
}

EmailForm.propTypes = {
  activity: PropTypes.object.isRequired,
  emails: PropTypes.array,
  onUpdate: PropTypes.func.isRequired
};
