import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Row } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { loadCompanyDocuments, saveCompanyDocuments } from '../../../helpers/local_storage';
import { fetchDocuments } from '../api';
import DocumentListItem from './list_item';

// capping the number of documents to show for now
const DISPLAY_LIMIT = 16;

export default function DocumentsModal({ companyId, jurisdictionCode, show, onHide }) {
  const [documents, setDocuments] = useState([]);
  const [lookupFailure, setLookupFailure] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (documents.length || !show) return;

    const savedDocs = loadCompanyDocuments(companyId) || [];
    if (savedDocs.length > 0) {
      setDocuments(savedDocs);
      setLoading(false);
    }
  }, [show]);

  useEffect(() => {
    if (documents.length || !show) return;

    fetchDocuments(companyId, jurisdictionCode).then((docs) => {
      if (docs.length > 0) {
        saveCompanyDocuments(companyId, docs);
        setDocuments(docs);
      } else {
        setLookupFailure(true);
      }
      setLoading(false);
    });
  }, [show]);

  const renderBody = () => {
    if (lookupFailure) return (
      <div>Failure loading documents. Try again later.</div>
    );

    if (loading) return (
      <FontAwesomeIcon className="text-muted" icon={faSpinner} size="2x" spin/>
    );

    return (
      <div>No Results</div>
    );
  };

  return (
    <Modal centered show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        Documents
      </Modal.Header>
      <Modal.Body>
        {documents.length > 0 ? (
          <Row className="g-2">
            {documents.slice(0, DISPLAY_LIMIT).map((document, i) => (
              <DocumentListItem document={document} key={i} />
            ))}
          </Row>
        ) : (
          <div className="text-center p-3">
            {renderBody()}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button variant="text-primary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

DocumentsModal.propTypes = {
  companyId: PropTypes.string.isRequired,
  jurisdictionCode: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func
};
