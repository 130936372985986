import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap-5';

import { setOffMarketSearch } from '../store/actions/ui';

import ConnectFilters from './connect_filters';
import OffMarketFilters from './off_market_filters';
import Badges from './filters/badges';
import ProductFeature from './product_feature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

function Filters({ offMarketSearch, setOffMarketSearch }) {
  const body = offMarketSearch ? <OffMarketFilters /> : <ConnectFilters />;
  const activeKey = offMarketSearch ? 'off-market' : 'exclusive';

  const selectTab = useCallback((key) => {
    if (key == 'exclusive' && offMarketSearch) {
      setOffMarketSearch(false);
    } else if (key == 'off-market' && !offMarketSearch) {
      setOffMarketSearch(true);
    }
  }, [offMarketSearch, setOffMarketSearch]);

  return (
    <React.Fragment>
      <ProductFeature product="exclusiveListings">
        <Nav
          activeKey={activeKey}
          className="connect-search-toggle border-bottom mt-2"
          variant="tabs"
          onSelect={selectTab}
        >
          <Nav.Item className="flex-grow-0 text-nowrap ms-2">
            <Nav.Link active={offMarketSearch} eventKey="off-market">
              <div className="text-start" style={{ lineHeight: '1.2rem' }}>
                <div>All Properties</div>
                <small className="text-muted fw-normal d-none d-sm-block">Research all U.S. properties</small>
              </div>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="flex-grow-0 text-nowrap">
            <Nav.Link active={!offMarketSearch} eventKey="exclusive">
              <div className="d-flex align-items-center" style={{ lineHeight: '1.2rem' }}>
                <div className="me-3">
                  <FontAwesomeIcon className="text-accent" icon={faBuildoutLogo} size="lg" />
                </div>
                <div className="text-start">
                  <div>Exclusive Listings</div>
                  <small className="text-muted fw-normal d-none d-sm-block">
                    Active listings that can only be found on Buildout
                  </small>
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </ProductFeature>
      <Row className="border-bottom z-1 bg-white">
        <Col>
          <Row className="pt-3 pb-2 gy-2 gx-2">
            {body}
          </Row>
          <Row className="pb-2 gy-2 gx-1">
            <Badges />
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

Filters.propTypes = {
  offMarketSearch: PropTypes.bool.isRequired,
  setOffMarketSearch: PropTypes.func.isRequired
};

const mapStateToProps = ({ ui }) => ({ offMarketSearch: ui.offMarketSearch });
export default connect(mapStateToProps, { setOffMarketSearch })(Filters);
