import { createStore } from 'redux';
import thunk from 'redux-thunk';

import { applyMiddleware } from 'helpers/redux_dev_tools';
import rootReducer from './root_reducer';

export default function (state) {
  const store = createStore(rootReducer, state, applyMiddleware(thunk));

  if (module.hot) {
    module.hot.accept('./root_reducer', () => {
      store.replaceReducer(require('./root_reducer'));
    });
  }

  return store;
}
