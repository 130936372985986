import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { setPrimaryRole } from '../../../actions';
import {
  PRIMARY_ROLE_INVESTOR_OWNER,
  PRIMARY_ROLE_COMMERCIAL_BROKER,
  PRIMARY_ROLE_RESIDENTIAL_BROKER,
  PRIMARY_ROLE_OTHER,
  labelForPrimaryRole
} from '../../../actions/ui';
import PreviewPortal from './index';

function UserTypePortal({ setPrimaryRole }) {
  return (
    <PreviewPortal>
      <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_INVESTOR_OWNER)}>
        {labelForPrimaryRole(PRIMARY_ROLE_INVESTOR_OWNER)}
      </Button>
      <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_COMMERCIAL_BROKER)}>
        {labelForPrimaryRole(PRIMARY_ROLE_COMMERCIAL_BROKER)}
      </Button>
      <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_RESIDENTIAL_BROKER)}>
        {labelForPrimaryRole(PRIMARY_ROLE_RESIDENTIAL_BROKER)}
      </Button>
      <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_OTHER)}>
        {labelForPrimaryRole(PRIMARY_ROLE_OTHER)}
      </Button>
    </PreviewPortal>
  );
}

UserTypePortal.propTypes = { setPrimaryRole: PropTypes.func.isRequired };
const mapDispatchToProps = { setPrimaryRole };
export default connect(null, mapDispatchToProps)(UserTypePortal);
