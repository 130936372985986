import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';

export default function GenericDropdown({ optionGroups, children }) {
  const optionGroupsToUse = optionGroups.filter(optionGroup => optionGroup.length > 0);

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as={UnstyledDropdownToggle}>
        {children}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {optionGroupsToUse.map((optionGroup, i) => (
          <React.Fragment key={i}>
            {optionGroup.map(option => (
              <Dropdown.Item href={option.href} key={option.name} name={option.name} onClick={option.onClick}>
                {option.icon && <FontAwesomeIcon className="me-2" icon={option.icon} />}
                {option.label}
              </Dropdown.Item>
            ))}
            {i < optionGroupsToUse.length - 1 && (
              <Dropdown.Divider />
            )}
          </React.Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

GenericDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  optionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string,
        label: PropTypes.string.isRequired,
        name: PropTypes.stringisRequired,
        onClick: PropTypes.func
      })
    )
  )
};
