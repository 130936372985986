import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Offcanvas, Placeholder } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-light-svg-icons';
import SessionModal from '../../session_modal';
import { handleFrameUnload } from '../../../helpers/turbo_react';

// The actual turbo frame component is separated out so that we can memoize it and not have it re-render
// anytime the parent component is updated.
function FrameComponent({ src }) {
  const frameRef = useRef(null);

  useEffect(() => {
    // When unmounted, we need to manually clean up any react components mounted by react on rails
    // inside the turbo frame.
    // Without this call, the react components mounted inside the frame will continue to run after the frame is
    // removed from the dom.
    // Normally this would be handled by the `turbo:before-frame-render` event handler buildout_connect.js, but because
    // react is removing the frame from the dom before that listener fires, we have to handle this ourselves.
    return () => {
      if (src && frameRef.current) {
        handleFrameUnload(frameRef.current);
      }
    };
  }, [src]);

  if (!src) return null;

  return (
    <turbo-frame class="h-100" id="match_details" ref={frameRef} src={src}>
      <div style={{ minHeight: '500px' }}>
        <div className="px-3 py-4 border-bottom">
          <Placeholder animation="wave">
            <div>
              <Placeholder as="h3" lg={4} xs={6} />
            </div>
            <Placeholder as="h6" lg={2} xs={5} />
          </Placeholder>
        </div>
        <div className="px-3 py-5">
          <div className="text-center">
            <FontAwesomeIcon
              beat
              className="text-primary text-opacity-75"
              icon={faBuilding}
              size="4x"
              style={{ '--fa-beat-scale': 1.1, '--fa-animation-duration': '1.5s' }}
            />
          </div>
        </div>
      </div>
    </turbo-frame>
  );
}

FrameComponent.propTypes = {
  src: PropTypes.string
};

const MemoizedFrameComponent = memo(FrameComponent);

export default function DetailOffcanvas({ src, onHide }) {
  const [persistedSrc, setPersistedSrc] = useState();
  const [showSessionModal, setSessionModal] = useState(false);

  // Keep a reference to the src because we don't want to unset it when the src prop is cleared out.
  // By persisting the src we can ensure that the modal has a smooth transition out. If we don't do this,
  // the body of the modal will dissapear the instant it starts to close which is a very jarring experience
  useEffect(() => {
    if (src) setPersistedSrc(src);
  }, [src]);

  useEffect(() => {
    if (src) {
      const clickEvent = (e) => {
        if (e.target?.dataset?.jsStartSessionModal) setSessionModal(true);
      };
      window.addEventListener('click', clickEvent);

      return () => window.removeEventListener('click', clickEvent);
    }
  }, [src]);

  if (showSessionModal) return <SessionModal onHide={() => setSessionModal(false)} />;

  return (
    <Offcanvas
      className="property-offcanvas"
      data-bs-backdrop="false"
      data-controller="offcanvas"
      enforceFocus={false} // needs to be false for the image lightbox to be able to work on top of the modal
      placement="end"
      show={!!src && !showSessionModal}
      onHide={onHide}
    >
      <button className="btn-close position-absolute top-0 end-0 pt-4 px-6 d-lg-none z-2" onClick={onHide} />
      <MemoizedFrameComponent src={persistedSrc} />
    </Offcanvas>
  );
}

DetailOffcanvas.propTypes = {
  src: PropTypes.string,
  onHide: PropTypes.func.isRequired
};
