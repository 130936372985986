import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content']

  // Checkbox support - to disable inputs with other actions, create a new method below
  checkToggleTargets(e) {
    const checked = e.target.checked;

    this.contentTargets.forEach((target) => {
      if (checked) {
        target.removeAttribute('disabled');
      } else {
        target.setAttribute('disabled', 'true');
      }
    });
  }
}