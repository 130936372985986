import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import ProspectLogo from 'application-logos/prospect-horizontal-navy.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';

export default function SessionModal({ onHide }) {
  const [show, setShow] = useState(true);

  const handleHide = () => {
    setShow(false);
    if (onHide) onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} >
      <Modal.Header>
        <img src={ProspectLogo} style={{ width: '180px' }} />
        <FontAwesomeIcon className="clickable" icon={faClose} size="sm" onClick={handleHide}/>
      </Modal.Header>
      <Modal.Body className="p-4">
        <turbo-frame id="session" src="/prospect/login">
          <div style={{ height: '400px' }} />
        </turbo-frame>
      </Modal.Body>
    </Modal>
  );
}

SessionModal.propTypes = {
  onHide: PropTypes.func
};
