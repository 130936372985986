import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, ListGroup, Modal, Spinner } from 'react-bootstrap-5';

import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';
import { addItemToCallList, propertiesByAddress } from '../../helpers/prospect_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';


export default function AddProperty({ callListId }) {
  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);
  const [insightsProperties, setInsightsProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!searchText) return;
    debouncedSearch(searchText);
  }, [searchText]);

  const handleChange = useCallback(e => setSearchText(e.target.value));

  const handleAddToList = (property) => {
    addItemToCallList(callListId, property.propertyId).then(setShowAddPropertyModal(false));
  };

  const debouncedSearch = useCallback(debounce((address) => {
    setLoading(true);
    setInsightsProperties([]);
    propertiesByAddress(address).then(setInsightsProperties).finally(() => setLoading(false));
  }, 300), []);

  return (
    <React.Fragment>
      <Button size="sm" variant="shaded-secondary" onClick={() => setShowAddPropertyModal(true)}>
        <FontAwesomeIcon icon={faPlus}/>Add Property
      </Button>
      <Modal centered show={showAddPropertyModal} onHide={() => setShowAddPropertyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Add Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-2 px-3">
            <Form.Label>Search for a property</Form.Label>

            <div className="with-icon">
              <div className="icon search-icon">
                <FontAwesomeIcon icon={faSearch}/>
              </div>

              <Form.Control autoFocus type="text" onChange={handleChange}/>
            </div>
          </Form.Group>
          {loading && (
            <div className="text-center p-3">
              <Spinner animation="border" variant="secondary" />
            </div>
          )}
          {isEmpty(insightsProperties) && !loading && searchText ? (
            <div className="py-2 px-3">No results found</div>
          ) : (
            <ListGroup style={{ maxHeight: '200px', overflow: 'auto' }}>
              {
                insightsProperties.map(property => (
                  <ListGroup.Item
                    action
                    className="border-0 text-muted fw-bolder"
                    key={property.propertyId}
                    onClick={() => handleAddToList(property)}
                  >
                    {property.address}, {property.city}, {property.state}
                  </ListGroup.Item>
                ))
              }
            </ListGroup>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

AddProperty.propTypes = {
  callListId: PropTypes.number.isRequired
};
