import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';
import { find, get } from 'lodash';
import classNames from 'classnames';

import { addGeoJson, clearBounds } from '../../../store/actions/criteria';
import { REGIONS } from 'bundles/connect/constants';

function RegionInput({
  clearBounds,
  criteria,
  addGeoJson
}) {
  const selectedLabel = get(criteria, 'locationsAttributes[0].polygonGeojson.label');

  const regionSelected = (e) => {
    const region = find(REGIONS, region => region.label === e.target.value);
    if (region) {
      addGeoJson(region, { name: region.label });
    } else {
      clearBounds();
    }
  };

  return (
    <Form.Select
      className={classNames('as-button', selectedLabel ? 'active' : 'text-muted')}
      value={selectedLabel || ''}
      onChange={regionSelected}
    >
      <option value={''}>Select a region</option>
      {REGIONS.map(region => (
        <option key={region.label} value={region.label}>
          {region.label}
        </option>
      ))}
    </Form.Select>
  );
}

RegionInput.propTypes = {
  addGeoJson: PropTypes.func.isRequired,
  clearBounds: PropTypes.func,
  criteria: PropTypes.object.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { addGeoJson, clearBounds })(RegionInput);
