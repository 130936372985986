import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

export default function Complete({ loginOnly }) {
  return (
    <div>
      <div className="text-center mb-3 text-branding">
        <FontAwesomeIcon icon={loginOnly ? faBuildoutLogo : faLockOpen} size="2x" />
      </div>
      <h5 className="text-center mb-3">
        <strong>{loginOnly ? 'Logging in ...' : 'File Unlocked!'}</strong>
      </h5>
      <p className="text-center">Hold tight while we fetch your documents.</p>
    </div>
  );
}

Complete.propTypes = {
  loginOnly: PropTypes.bool
};
