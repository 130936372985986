function formatCurrency(value, locale, currency, options = {}) {
  const formatOptions = {
    currency,
    style: 'currency'
  };

  let suffix;
  if (options.abbreviate) {
    formatOptions.minimumFractionDigits = 0;

    if (value != null) {
      const suffixes = ['k', 'M', 'B', 'T', '+'];
      let i = 0;
      while (value >= 1000 && i < suffixes.length) {
        value = value / 1000;
        suffix = suffixes[i];
        i++;
      }

      formatOptions.maximumFractionDigits = (options.significantDigits || 3) - value.toFixed(0).length;
    }
  } else if (options.round) {
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  if (options.accounting) {
    formatOptions.currencySign = 'accounting';
  }

  let formatted = new Intl.NumberFormat(locale, formatOptions).format(value);
  if (suffix) {
    formatted = `${formatted}${suffix}`;
  }

  return formatted;
}

export const USD = {
  format(value, options = {}) {
    return formatCurrency(value, 'en-US', 'USD', options);
  },

  toFloat(value) {
    return parseFloat(value.replace(/[^0-9.-]+/g, ''));
  }
};

export function formatPercent(value, options = {}) {
  if (value != null) return Number(value).toLocaleString(undefined, { style: 'percent', ...options });
}

export function formatWithCommas(value) {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function numberShorthand(value) {
  if (value >= 10000000) {
    return `${Math.round(value / 1000000)}M`;
  } else if (value >= 1000000) {
    return `${Math.round((value / 1000000) * 100) / 100}M`;
  } else if (value > 1000) {
    return `${Math.round(value / 1000)}K`;
  } else {
    return value;
  }
}
