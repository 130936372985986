import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import { post } from 'axios';
import { Button } from 'react-bootstrap-5';
import PdfCa from './pdf_ca';

export default function SignCaPdf({ caFilePath, signCaPath, onNext, caType }) {
  const [saving, setSaving] = useState(false);
  const [uploadedFile, setUploadedFiled] = useState();

  const signCa = (e) => {
    e.preventDefault();

    setSaving(true);

    const formData = new FormData();
    formData.append('signed_ca_file', uploadedFile);
    post(
      signCaPath,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then(() => onNext());
  };

  return (
    <div>
      <div className="mb-3 text-center text-branding">
        <FontAwesomeIcon icon={faLock} size="2x"/>
      </div>
      <h5 className="mb-3 text-center">
        Sign the CA to view this document
      </h5>
      <PdfCa caFilePath={caFilePath} caType={caType} onFileUploaded={setUploadedFiled} />
      {uploadedFile && (
        <div className="text-center mt-4" style={{ '--animate-duration': '1s' }}>
          <Button
            className="animate__animated animate__pulse"
            disabled={saving}
            name="submit-ca"
            variant="primary"
            onClick={signCa}
          >
            {saving ? 'Saving ...' : 'Submit'}
          </Button>
        </div>
      )}
    </div>
  );
}

SignCaPdf.propTypes = {
  caFilePath: PropTypes.string.isRequired,
  caType: PropTypes.string.isRequired,
  signCaPath: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired
};
