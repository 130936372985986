import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faCheckCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import Alert from 'components/alert';
import { EMAIL_REGEX } from '../helpers/regexes';
import { closeDealRoomSettings, sendInvites } from '../actions/index';
import { accessLevelOptions } from '../helpers/access_levels';
import AccessLevelSelect from './access_level_select';
import ContactSearch from './contact_search';

import './deal_room.scss';

export class InviteForm extends Component {
  state = {
    contacts: [],
    emails: [],
    errors: null,
    invites: [],
    message: '',
    searchQuery: ''
  }

  accessLevelStage = () => this.state.invites.length > 0;

  handleCreateInvites = () => {
    this.setState((state) => {
      const invites = [];
      invites.push(...state.emails.map(email => ({ email, accessLevel: this.props.dealRoom.memberAccessLevels[1] })));
      invites.push(...state.contacts.map((contact) => {
        return { email: contact.email, name: contact.name, accessLevel: this.props.dealRoom.memberAccessLevels[1] };
      }));
      return { invites };
    });
  }

  sendInvites = () => {
    this.props.sendInvites(
      this.state.invites,
      this.props.dealRoom,
      this.state.message
    ).then(({ errors }) => {
      errors ? this.setState({ errors }) : this.props.onReturn();
    });
  }

  updateAccessLevel = (option, invite) => {
    this.setState((state) => {
      const { invites } = state;
      invites.forEach((inv) => {
        if (inv.email !== invite.email) return;

        inv.accessLevel = option.value;
      });

      return { invites };
    });
  }

  toggleContactSelect = (name, email, contactId, selected) => {
    this.setState((state) => {
      let { contacts } = state;
      if (selected) {
        contacts = contacts.filter(contact => contact.contactId != contactId);
      } else {
        contacts.push({ name, email, contactId });
      }
      return { contacts };
    });
  }

  renderSelectorRow(name, email, contactId) {
    const selected = this.state.contacts.some(contact => contact.contactId == contactId);
    const icon = selected ? faCheckCircle : faCircle;
    const iconColorClass = selected ? 'text-primary' : 'text-muted';

    return (
      <tr
        className="clickable"
        key={contactId}
        name="selector-row"
        onClick={() => this.toggleContactSelect(name, email, contactId, selected)}
      >
        <td>
          {name}
          <div className="text-muted">
            {email}
          </div>
        </td>
        <td>
          <div className="text-right">
            <FontAwesomeIcon className={iconColorClass} icon={icon} size="2x"/>
          </div>
        </td>
      </tr>
    );
  }

  filteredContactsAndLeads = () => {
    const { members, contactsAndLeads } = this.props;
    const { leads, contacts } = contactsAndLeads;

    return {
      leads: leads.filter(lead => !members.some(member => member.email == lead.leadData.email) &&
        lead.displayName.toLowerCase().includes(this.state.searchQuery.toLowerCase())),
      contacts: contacts.filter(contact => !members.some(member => member.email == contact.email) &&
        contact.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()))
    };
  }

  anyContactsOrLeads = () => {
    const { contacts, leads } = this.filteredContactsAndLeads();
    return leads.length > 0 || contacts.length > 0;
  }

  renderContactRow = contact => this.renderSelectorRow(contact.name, contact.email, contact.contactId);

  renderLeadRow = lead => this.renderSelectorRow(lead.displayName, lead.leadData.email, lead.contactId);

  renderContactsSelector = () => {
    const { contacts, leads } = this.filteredContactsAndLeads();
    return (
      <table className="table mb-0 table-hover">
        <tbody>
          {contacts.map(this.renderContactRow)}
          {leads.map(this.renderLeadRow)}
        </tbody>
      </table>
    );
  }

  renderNameOrEmail = (invite) => {
    return (
      <td>
        <span className="fa-stack fa-lg align-middle text-center invite-users-avatar">
          {invite.name && invite.name !== invite.email ?
            invite.name.split(' ').map(name => name[0]).join('').toUpperCase() :
            (<FontAwesomeIcon className="align-middle" icon={faEnvelope}/>)
          }
        </span>
        <span className="ml-3">{invite.name || invite.email}</span>
      </td>
    );
  }

  renderNewInviteRow = (invite) => {
    const options = accessLevelOptions(this.props.dealRoom);

    return (
      <tr key={invite.email} name="new-invite-row">
        {this.renderNameOrEmail(invite)}
        <td>
          <AccessLevelSelect
            options={options}
            value={options.find(o => o.value === invite.accessLevel)}
            onChange={option => this.updateAccessLevel(option, invite)}
          />
        </td>
      </tr>
    );
  }

  renderAccessLevelForm() {
    return (
      <Modal.Body>
        <h4>
          <strong>Select Access Level for each user</strong>
        </h4>
        <p className="mb-4">
          Access Levels determine what each user is able to do within the deal room:
          Full Access, Contributor, or Download Only.
          For more information,&nbsp;
          <a
            href="https://support.buildout.com/hc/en-us/articles/1260801501529"
            rel="noopener noreferrer"
            target="_blank"
          >
            visit our support center
          </a>.
        </p>
        <table className="table member-table table-condensed">
          <tbody>
            {this.state.invites.map(this.renderNewInviteRow)}
          </tbody>
        </table>
        <h4>
          <strong>Add a custom message to the invite email&nbsp;</strong>
          <span className="text-muted">(optional)</span>
        </h4>
        <textarea
          className="w-100 border p-2"
          placeholder="Enter a custom message"
          value={this.state.message}
          onChange={e => this.setState({ message: e.target.value })}
        />
      </Modal.Body>
    );
  }

  handleSearch = (searchQuery) => {
    this.setState({ searchQuery });
  }

  renderSearcher() {
    const { leads, contacts } = this.props.contactsAndLeads;
    const totalContactsAndLeads = leads.length + contacts.length;

    return <ContactSearch handleSearch={this.handleSearch} totalContactsAndLeads={totalContactsAndLeads}/>;
  }

  renderBody() {
    if (this.accessLevelStage()) return this.renderAccessLevelForm();
    const placeholder = this.anyContactsOrLeads() ?
      'Not listed above? Invite by email address...' :
      'Add email address...';

    return (
      <React.Fragment>
        {this.anyContactsOrLeads() ? (
          <React.Fragment>
            <div className="m-4">{this.renderSearcher()}</div>
            <Modal.Body className="pb-0">
              {this.state.errors && <Alert messages={this.state.errors} title="Could not add members" />}
              <div className="contact-selector">
                {this.renderContactsSelector()}
              </div>
            </Modal.Body>
          </React.Fragment>
        ) : (
          <Modal.Body className="pb-0">
            Email Addresses
          </Modal.Body>
        )}
        <div className="m-4">
          <TagsInput
            addOnBlur
            inputProps={{ placeholder }}
            validationRegex={EMAIL_REGEX}
            value={this.state.emails}
            onChange={emails => this.setState({ emails })}
          />
        </div>
      </React.Fragment>
    );
  }

  renderFooter() {
    const accessLevelStage = this.accessLevelStage();
    const backFunc = accessLevelStage ? () => this.setState({ invites: [] }) : this.props.onReturn;
    const nextFunc = accessLevelStage ? this.sendInvites : this.handleCreateInvites;
    const disabled = this.state.emails.length === 0 && this.state.contacts.length === 0;
    const visibilityBackBtn = !accessLevelStage && this.props.members.length === 0;

    return (
      <Modal.Footer className="d-flex">
        <div
          className="btn btn-link row-eq-height"
          name="back-button"
          style={{ display: visibilityBackBtn ? 'none' : 'block' }}
          onClick={backFunc}
        >
          <FontAwesomeIcon className="mr-2" icon={faAngleLeft}/>
          Back
        </div>
        <div
          className="btn btn-dark"
          disabled={disabled}
          name="next-button"
          onClick={nextFunc}
        >
          {accessLevelStage ? 'Send Invites' : 'Continue'}
        </div>
      </Modal.Footer>
    );
  }

  renderHeader() {
    return (
      <React.Fragment>
        <Modal.Header closeButton>
          <h4 className="m-0">
            {this.state.inviteEmailsSelected ? 'Select Access Level' : (
              `Invite users to ${this.props.dealRoom.name}`
            )}
          </h4>
        </Modal.Header>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="invite-form">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    );
  }
}

InviteForm.propTypes = {
  contactsAndLeads: PropTypes.shape({
    contacts: PropTypes.array.isRequired,
    leads: PropTypes.array.isRequired
  }).isRequired,
  dealRoom: PropTypes.shape({
    name: PropTypes.string,
    memberAccessLevels: PropTypes.array
  }),
  members: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string
  })),
  sendInvites: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired
};

const mapStateToProps = ({ contactsAndLeads, dealRoom, membersById, ui: { mutatingMemberIds } }) => ({
  contactsAndLeads,
  dealRoom,
  members: dealRoom.memberIds.map(id => membersById[id]),
  mutatingMemberIds
});

const mapDispatchToProps = { onClose: closeDealRoomSettings, sendInvites };

export default connect(mapStateToProps, mapDispatchToProps)(InviteForm);
