import React from 'react';
import PropTypes from 'prop-types';

import { Alert as BootstrapAlert } from 'react-bootstrap';

class Alert extends React.Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string
  }

  static defaultProps = {
    bsStyle: 'warning'
  }

  render() {
    const { messages, title, ...props } = this.props;
    if (!messages || messages.length === 0) {
      return null;
    }

    return (
      <BootstrapAlert {...props}>
        {title && (
          <h4 className="mt-0">
            <strong>Whoops! -</strong> {title}
          </h4>
        )}
        <ul>
          {messages.map((message, i) => (
            <li key={i}>{message}</li>
          ))}
        </ul>
      </BootstrapAlert>
    );
  }
}

export default Alert;
