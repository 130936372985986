import React from 'react';
import PropTypes from 'prop-types';

class ManagedSelectInput extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    includeBlank: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired
  }

  handleChange = (event) => {
    this.props.onChange({ [this.props.name]: event.target.value }, event);
    this.props.onValueChange(event.target.value, event);
  }

  render() {
    const { includeBlank, value, onChange, onValueChange, ...props } = this.props;
    const blankOption = (<option disabled hidden value="">
      {typeof includeBlank === 'string' ? includeBlank : 'Select...'}
    </option>);
    const inputProps = {
      ...props,
      value: value || '',
      onChange: this.handleChange
    };

    delete inputProps.type;

    return (
      <select {...inputProps}>
        {includeBlank && blankOption}
        {this.props.children}
      </select>
    );
  }
}

export default ManagedSelectInput;
