import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap-5';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallListExportModal from './modal';


export default function CallListExport({ callListId, exportPath, templates }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Button size="sm" variant="link" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon={faFileExport}/>
        Export
      </Button>
      <CallListExportModal
        callListId={callListId}
        exportPath={exportPath}
        show={showModal}
        templates={templates}
        onHide={() => setShowModal(false)}
      />
    </React.Fragment>
  );
}

CallListExport.propTypes = {
  callListId: PropTypes.number.isRequired,
  exportPath: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired
};
