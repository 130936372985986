import React from 'react';
import PropTypes from 'prop-types';

import { faBuildingCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function EmptyState({ title, subtitle }) {
  return (
    <React.Fragment>
      <div className="text-center py-6">
        <FontAwesomeIcon className="text-muted" icon={faBuildingCircleXmark} size="3x" />
      </div>
      <h4 className="text-center m-0">{title}</h4>
      {subtitle && <p className="text-center">{subtitle}</p>}
    </React.Fragment>
  );
}

EmptyState.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};
