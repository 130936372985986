import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import { connect } from 'react-redux';
import PreviewPortal from './index';

function WaitlistPortal({ nextStep, profile, setProfileData, onNext }) {
  function waitlistApproved() {
    const updatedProfile = { ...profile, [PROFILE_OPTIONS.WAITLISTED]: false, [PROFILE_OPTIONS.ACCESS_LEVEL]: 'low' };
    setProfileData(updatedProfile);
    onNext(nextStep, false, { previewProfile: updatedProfile });
  }

  return (
    <PreviewPortal>
      <Button variant="primary" onClick={waitlistApproved}>Approve Access</Button>
    </PreviewPortal>
  );
}


WaitlistPortal.propTypes = {
  nextStep: PropTypes.string.isRequired,
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(WaitlistPortal);
