import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap-5';
import PersonForm from './person_form';
import CompanyForm from './company_form';

export default function ContactForm({ initialContact = {}, onSave, onCancel, type }) {
  const [formData, setFormData] = useState({ ...initialContact, type });
  const [emails, setEmails] = useState(initialContact.emails || []);
  const [phoneNumbers, setPhoneNumbers] = useState(initialContact.phoneNumbers || []);
  const [addresses, setAddresses] = useState(initialContact.addresses || []);

  const handleSave = useCallback(() => {
    onSave({
      contact: {
        ...formData,
        type,
        emailsAttributes: emails,
        phoneNumbersAttributes: phoneNumbers,
        addressesAttributes: addresses
      }
    });
  }, [formData, emails, phoneNumbers, addresses, type]);

  const handleFormChange = useCallback((e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }, [formData]);

  const FormComponent = useMemo(() => {
    if (type == 'Connect::Person') return PersonForm;
    if (type == 'Connect::Company') return CompanyForm;
  }, [type]);

  return (
    <React.Fragment>
      <Modal.Body className="d-flex flex-column gap-3">
        <FormComponent
          addresses={addresses}
          emails={emails}
          formData={formData}
          phoneNumbers={phoneNumbers}
          onFormChange={handleFormChange}
          onSetAddresses={setAddresses}
          onSetEmails={setEmails}
          onSetPhoneNumbers={setPhoneNumbers}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end gap-2">
        <Button variant="outline-primary" onClick={onCancel}>Cancel</Button>
        <Button variant="secondary" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </React.Fragment>
  );
}

ContactForm.propTypes = {
  initialContact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    birthday: PropTypes.string,
    type: PropTypes.string,
    addresses: PropTypes.array,
    emails: PropTypes.array,
    phoneNumbers: PropTypes.array
  }),
  type: PropTypes.oneOf(['Connect::Person', 'Connect::Company']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
