import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form } from 'react-bootstrap-5';
import { setFilterAttribute } from '../../../store/actions/criteria';
import CriteriaDecorator from '../../../helpers/criteria_decorator';

function DateRangeInput({
  criteria,
  maxAttribute,
  minAttribute,
  placeholder,
  setFilterAttribute
}) {
  const decoratedCriteria = new CriteriaDecorator(criteria);
  const minValue = decoratedCriteria[minAttribute] ? decoratedCriteria[minAttribute]() : criteria[minAttribute];
  const maxValue = decoratedCriteria[maxAttribute] ? decoratedCriteria[maxAttribute]() : criteria[maxAttribute];

  return (
    <div className="d-flex align-items-center">
      <Form.Control
        className={minValue ? 'active' : ''}
        placeholder={`Min ${placeholder}`}
        type="date"
        value={minValue || ''}
        onChange={e => setFilterAttribute(minAttribute, e.target.value, { debounce: true })}
      />
      <div className="px-1">-</div>
      <Form.Control
        className={maxValue ? 'active' : ''}
        placeholder={`Max ${placeholder}`}
        type="date"
        value={maxValue || ''}
        onChange={e => setFilterAttribute(maxAttribute, e.target.value, { debounce: true  })}
      />
    </div>
  );
}

DateRangeInput.propTypes = {
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  maxAttribute: PropTypes.string.isRequired,
  minAttribute: PropTypes.string.isRequired,
  percent: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(DateRangeInput);
