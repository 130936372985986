import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';

import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';

export default function PhoneNumberInput({ initialValue, inputName, label }) {
  const [val, setVal] = React.useState(initialValue || '');

  return (
    <Form.Group className="mb-3">
      {label !== false && (
        <Form.Label>
          <strong>Phone Number *</strong>
        </Form.Label>
      )}

      <Form.Control
        id="phone-number-input"
        name={inputName || 'phone_number'}
        placeholder="(000) 000-0000"
        required
        value={formatPhoneNumber(val)}
        onChange={e => setVal(formatPhoneNumber(e.target.value))}
      />
    </Form.Group>
  );
}

PhoneNumberInput.propTypes = {
  initialValue: PropTypes.string,
  inputName: PropTypes.string,
  label: PropTypes.bool
};
