import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { paginate } from '../../store/actions/results';
import { Pagination } from 'react-bootstrap-5';

export function Paginator({ containerRef, page, totalPages, paginate }) {
  const handlePageChange = (page) => {
    if (containerRef.current) containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });

    paginate(page);
  };

  if (!totalPages || totalPages <= 1) return null;

  return (
    <Pagination>
      {page > 2 && (<Pagination.First onClick={() => handlePageChange(1)} />)}
      {page > 1 && (<Pagination.Prev onClick={() => handlePageChange(page - 1)} />)}
      <Pagination.Item>{page}</Pagination.Item>
      {page < totalPages && (<Pagination.Next onClick={() => handlePageChange(page + 1)} />)}
    </Pagination>
  );
}

Paginator.propTypes = {
  containerRef: PropTypes.object,
  page: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  totalPages: PropTypes.number
};

const mapStateToProps = ({ results }) => ({ ...results });

export default connect(mapStateToProps, { paginate })(Paginator);
