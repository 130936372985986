import React from 'react';
import { Card, Col, Row } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import { setOffMarketPropertyId } from '../helpers/navigation_helper';
import OffMarketSummary from './results/off_market_summary';
import LikelyToSellBar from './results/likely_to_sell_bar';
import OffMarketPropertyImage from './results/off_market_property_image';

export default function OffMarketDetailsCard({ property }) {
  return (
    <Card>
      <Row className="g-0 align-items-stretch clickable" onClick={() => setOffMarketPropertyId(property.propertyId)}>
        <Col className="d-flex flex-column">
          <Card.Body className="p-2">
            <OffMarketSummary property={property} />
          </Card.Body>
          {!property?.listings?.forSale && (
            <LikelyToSellBar likelyToSell={property?.scores?.likelyToSell} />
          )}
        </Col>
        <Col className="map-result-thumb off-market-result-thumb d-none d-md-block" md="auto">
          <OffMarketPropertyImage result={property} />
        </Col>
      </Row>
    </Card>
  );
}

OffMarketDetailsCard.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    listings: PropTypes.shape({
      forSale: PropTypes.bool,
      salePrice: PropTypes.number,
    }),
    propertyId: PropTypes.string,
    valuation: PropTypes.shape({
      estimate: PropTypes.number,
    }),
  }).isRequired,
};
