
export const NAME_ASCENDING = 'NAME_ASCENDING';
export const NAME_DESCENDING = 'NAME_DESCENDING';
export const SIZE_ASCENDING = 'SIZE_ASCENDING';
export const SIZE_DESCENDING = 'SIZE_DESCENDING';
export const DATE_ASCENDING = 'DATE_ASCENDING';
export const DATE_DESCENDING = 'DATE_DESCENDING';
export const OWNER_ASCENDING = 'OWNER_ASCENDING';
export const OWNER_DESCENDING = 'OWNER_DESCENDING';
export const RECYCLED_AT_ASCENDING = 'RECYCLED_AT_ASCENDING';
export const RECYCLED_AT_DESCENDING = 'RECYCLED_AT_DESCENDING';
export const DEFAULT_SORT_ORDER = NAME_ASCENDING;

export const SORT_LOOKUP = { 
  [NAME_ASCENDING]: { key: 'name', reverse: false },
  [NAME_DESCENDING]: { key: 'name', reverse: true },
  [SIZE_ASCENDING]: { key: 'size', reverse: false },
  [SIZE_DESCENDING]: { key: 'size', reverse: true },
  [OWNER_ASCENDING]: { key: 'createdBy', reverse: false },
  [OWNER_DESCENDING]: { key: 'createdBy', reverse: true },
  [DATE_ASCENDING]: { key: 'lastModified', reverse: false },
  [DATE_DESCENDING]: { key: 'lastModified', reverse: true },
  [RECYCLED_AT_ASCENDING]: { key: 'recycledAt', reverse: false },
  [RECYCLED_AT_DESCENDING]: { key: 'recycledAt', reverse: true }
};

const compareEntries = sortOrder => (a, b) => {
  const sort = SORT_LOOKUP[sortOrder];
  const [aVal, bVal] = [a[sort.key], b[sort.key]];
  let sortVal;
  if (sort.key === 'size') {
    sortVal = (aVal || 0) - (bVal || 0);
  } else {
    sortVal = (aVal || "").localeCompare(bVal || "");
  }
  if (sortVal === 0) {
    return a.name.localeCompare(b.name);
  }

  return sort.reverse ? -1 * sortVal : sortVal;
};

export const sortEntries = (entries, sortOrder) => {
  return entries.sort(compareEntries(sortOrder));
};
