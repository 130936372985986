import { OPEN_FILE_PREVIEW, CLOSE_FILE_PREVIEW, DELETING_ENTRIES } from "../actions";

export default (state = { open: false }, action) => {
  switch (action.type) {

  case OPEN_FILE_PREVIEW:
    return { open: true, entryId: action.entry.id };
  case DELETING_ENTRIES:
  case CLOSE_FILE_PREVIEW:
    return { ...state, open: false, entryId: null };
  default:
    return state;
  }
};