import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    propertyId: Number,
    eventName: String
  }
  connect() {
    if (window.gtag) window.gtag('event', this.eventNameValue, { propertyId: this.propertyIdValue });
  }
}
