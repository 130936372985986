export const countries = [
  { id: 0, label: 'Other' },
  { id: 1, label: 'United States' },
  { id: 40, label: 'American Samoa' },
  { id: 39, label: '\u00c5land Islands' },
  { id: 41, label: 'Antarctica' },
  { id: 6, label: 'Australia' },
  { id: 23, label: 'Austria' },
  { id: 2, label: 'Bahamas' },
  { id: 29, label: 'Belgium' },
  { id: 42, label: 'Bonaire\n Sint Eustatius\n Saba' },
  { id: 43, label: 'Bouvet Island' },
  { id: 44, label: 'British Indian Ocean Territory (the)' },
  { id: 17, label: 'Bulgaria' },
  { id: 3, label: 'Canada' },
  { id: 37, label: 'China' },
  { id: 45, label: 'Christmas Island' },
  { id: 46, label: 'Cocos (Keeling) Islands (the)' },
  { id: 47, label: 'Colombia' },
  { id: 48, label: 'Comoros (the)' },
  { id: 49, label: 'Congo (the Democratic Republic of the)' },
  { id: 50, label: 'Congo (the)' },
  { id: 51, label: 'Cook Islands (the)' },
  { id: 52, label: 'Costa Rica' },
  { id: 19, label: 'Croatia' },
  { id: 54, label: 'Cuba' },
  { id: 55, label: 'Cura\u00e7ao' },
  { id: 28, label: 'Cyprus' },
  { id: 21, label: 'Czech Republic' },
  { id: 53, label: 'C\u00f4te d\'Ivoire' },
  { id: 27, label: 'Denmark' },
  { id: 56, label: 'Djibouti' },
  { id: 57, label: 'Dominica' },
  { id: 58, label: 'Dominican Republic (the)' },
  { id: 59, label: 'Ecuador' },
  { id: 60, label: 'Egypt' },
  { id: 61, label: 'El Salvador' },
  { id: 62, label: 'Equatorial Guinea' },
  { id: 63, label: 'Eritrea' },
  { id: 34, label: 'Estonia' },
  { id: 64, label: 'Eswatini' },
  { id: 65, label: 'Ethiopia' },
  { id: 66, label: 'Falkland Islands (the) [Malvinas]' },
  { id: 67, label: 'Faroe Islands (the)' },
  { id: 68, label: 'Fiji' },
  { id: 25, label: 'Finland' },
  { id: 8, label: 'France' },
  { id: 69, label: 'French Guiana' },
  { id: 70, label: 'French Polynesia' },
  { id: 71, label: 'French Southern Territories (the)' },
  { id: 72, label: 'Gabon' },
  { id: 73, label: 'Gambia (the)' },
  { id: 74, label: 'Georgia' },
  { id: 12, label: 'Germany' },
  { id: 75, label: 'Ghana' },
  { id: 76, label: 'Gibraltar' },
  { id: 36, label: 'Great Britain' },
  { id: 18, label: 'Greece' },
  { id: 77, label: 'Greenland' },
  { id: 78, label: 'Grenada' },
  { id: 79, label: 'Guadeloupe' },
  { id: 80, label: 'Guam' },
  { id: 81, label: 'Guatemala' },
  { id: 82, label: 'Guernsey' },
  { id: 83, label: 'Guinea' },
  { id: 84, label: 'Guinea-Bissau' },
  { id: 85, label: 'Guyana' },
  { id: 86, label: 'Haiti' },
  { id: 87, label: 'Heard Island and McDonald Islands' },
  { id: 88, label: 'Holy See (the)' },
  { id: 89, label: 'Honduras' },
  { id: 90, label: 'Hong Kong' },
  { id: 24, label: 'Hungary' },
  { id: 91, label: 'Iceland' },
  { id: 92, label: 'India' },
  { id: 93, label: 'Indonesia' },
  { id: 94, label: 'Iran (Islamic Republic of)' },
  { id: 95, label: 'Iraq' },
  { id: 22, label: 'Ireland' },
  { id: 96, label: 'Isle of Man' },
  { id: 97, label: 'Israel' },
  { id: 13, label: 'Italy' },
  { id: 98, label: 'Jamaica' },
  { id: 38, label: 'Japan' },
  { id: 99, label: 'Jersey' },
  { id: 100, label: 'Jordan' },
  { id: 101, label: 'Kazakhstan' },
  { id: 102, label: 'Kenya' },
  { id: 103, label: 'Kiribati' },
  { id: 104, label: 'Korea (the Democratic People\'s Republic of)' },
  { id: 105, label: 'Korea (the Republic of)' },
  { id: 106, label: 'Kuwait' },
  { id: 107, label: 'Kyrgyzstan' },
  { id: 108, label: 'Lao People\'s Democratic Republic (the)' },
  { id: 35, label: 'Latvia' },
  { id: 109, label: 'Lebanon' },
  { id: 110, label: 'Lesotho' },
  { id: 111, label: 'Liberia' },
  { id: 112, label: 'Libya' },
  { id: 113, label: 'Liechtenstein' },
  { id: 31, label: 'Lithuania' },
  { id: 114, label: 'Luxembourg' },
  { id: 115, label: 'Macao' },
  { id: 117, label: 'Madagascar' },
  { id: 118, label: 'Malawi' },
  { id: 119, label: 'Malaysia' },
  { id: 120, label: 'Maldives' },
  { id: 121, label: 'Mali' },
  { id: 30, label: 'Malta' },
  { id: 122, label: 'Marshall Islands (the)' },
  { id: 123, label: 'Martinique' },
  { id: 124, label: 'Mauritania' },
  { id: 125, label: 'Mauritius' },
  { id: 126, label: 'Mayotte' },
  { id: 4, label: 'Mexico' },
  { id: 127, label: 'Micronesia (Federated States of)' },
  { id: 128, label: 'Moldova (the Republic of)' },
  { id: 9, label: 'Monaco' },
  { id: 129, label: 'Mongolia' },
  { id: 130, label: 'Montenegro' },
  { id: 131, label: 'Montserrat' },
  { id: 132, label: 'Morocco' },
  { id: 133, label: 'Mozambique' },
  { id: 134, label: 'Myanmar' },
  { id: 135, label: 'Namibia' },
  { id: 136, label: 'Nauru' },
  { id: 137, label: 'Nepal' },
  { id: 20, label: 'Netherlands' },
  { id: 138, label: 'New Caledonia' },
  { id: 139, label: 'New Zealand' },
  { id: 140, label: 'Nicaragua' },
  { id: 141, label: 'Niger (the)' },
  { id: 142, label: 'Nigeria' },
  { id: 143, label: 'Niue' },
  { id: 144, label: 'Norfolk Island' },
  { id: 116, label: 'North Macedonia' },
  { id: 145, label: 'Northern Mariana Islands (the)' },
  { id: 146, label: 'Norway' },
  { id: 147, label: 'Oman' },
  { id: 148, label: 'Pakistan' },
  { id: 149, label: 'Palau' },
  { id: 150, label: 'Palestine, State of' },
  { id: 151, label: 'Panama' },
  { id: 152, label: 'Papua New Guinea' },
  { id: 153, label: 'Paraguay' },
  { id: 154, label: 'Peru' },
  { id: 155, label: 'Philippines (the)' },
  { id: 156, label: 'Pitcairn' },
  { id: 14, label: 'Poland' },
  { id: 26, label: 'Portugal' },
  { id: 157, label: 'Puerto Rico' },
  { id: 158, label: 'Qatar' },
  { id: 15, label: 'Romania' },
  { id: 160, label: 'Russian Federation (the)' },
  { id: 161, label: 'Rwanda' },
  { id: 159, label: 'R\u00e9union' },
  { id: 162, label: 'Saint Barth\u00e9lemy' },
  { id: 163, label: 'Saint Helena\n Ascension Island\n Tristan da Cunha' },
  { id: 164, label: 'Saint Kitts and Nevis' },
  { id: 165, label: 'Saint Lucia' },
  { id: 166, label: 'Saint Martin (French part)' },
  { id: 167, label: 'Saint Pierre and Miquelon' },
  { id: 168, label: 'Saint Vincent and the Grenadines' },
  { id: 169, label: 'Samoa' },
  { id: 170, label: 'San Marino' },
  { id: 171, label: 'Sao Tome and Principe' },
  { id: 172, label: 'Saudi Arabia' },
  { id: 173, label: 'Senegal' },
  { id: 174, label: 'Serbia' },
  { id: 175, label: 'Seychelles' },
  { id: 176, label: 'Sierra Leone' },
  { id: 177, label: 'Singapore' },
  { id: 178, label: 'Sint Maarten (Dutch part)' },
  { id: 33, label: 'Slovakia' },
  { id: 32, label: 'Slovenia' },
  { id: 179, label: 'Solomon Islands' },
  { id: 180, label: 'Somalia' },
  { id: 181, label: 'South Africa' },
  { id: 182, label: 'South Georgia and the South Sandwich Islands' },
  { id: 183, label: 'South Sudan' },
  { id: 10, label: 'Spain' },
  { id: 184, label: 'Sri Lanka' },
  { id: 185, label: 'Sudan (the)' },
  { id: 186, label: 'Suriname' },
  { id: 187, label: 'Svalbard\n Jan Mayen' },
  { id: 16, label: 'Sweden' },
  { id: 7, label: 'Switzerland' },
  { id: 188, label: 'Syrian Arab Republic (the)' },
  { id: 189, label: 'Taiwan (Province of China)' },
  { id: 190, label: 'Tajikistan' },
  { id: 191, label: 'Tanzania, the United Republic of' },
  { id: 192, label: 'Thailand' },
  { id: 193, label: 'Timor-Leste' },
  { id: 194, label: 'Togo' },
  { id: 195, label: 'Tokelau' },
  { id: 196, label: 'Tonga' },
  { id: 197, label: 'Trinidad and Tobago' },
  { id: 198, label: 'Tunisia' },
  { id: 5, label: 'Turkey' },
  { id: 199, label: 'Turkmenistan' },
  { id: 200, label: 'Turks and Caicos Islands (the)' },
  { id: 201, label: 'Tuvalu' },
  { id: 202, label: 'Uganda' },
  { id: 203, label: 'Ukraine' },
  { id: 204, label: 'United Arab Emirates (the)' },
  { id: 11, label: 'United Kingdom' },
  { id: 205, label: 'United States Minor Outlying Islands (the)' },
  { id: 206, label: 'Uruguay' },
  { id: 207, label: 'Uzbekistan' },
  { id: 208, label: 'Vanuatu' },
  { id: 209, label: 'Venezuela (Bolivarian Republic of)' },
  { id: 210, label: 'Viet Nam' },
  { id: 211, label: 'Virgin Islands (British)' },
  { id: 212, label: 'Virgin Islands (U.S.)' },
  { id: 213, label: 'Wallis and Futuna' },
  { id: 214, label: 'Western Sahara' },
  { id: 215, label: 'Yemen' },
  { id: 216, label: 'Zambia' },
  { id: 217, label: 'Zimbabwe' }
];
