import { Controller } from '@hotwired/stimulus';
import { customPropertyMarkerContent } from '../helpers/map_helper';

const DEFAULT_CIRCLE_COLOR = '#0044ff';

export default class extends Controller {
  static values = {
    options: Object,
    circleRadius: Number,
    polygon: Array,
    propertyTypeId: Number,
    radii: Array,
    radiiColors: Array,
  }

  async connect() {
    const { Map } = await google.maps.importLibrary('maps');

    const map = new Map(this.element, {
      ...this.optionsValue,
      mapId: '5de8e726806581b',
      zoom: this.polygonValue ? 16 : 14,
      disableDefaultUI: true,
      gestureHandling: 'cooperative'
    });

    if (this.polygonValue && this.polygonValue.length > 0) {
      // Construct the polygon.
      new google.maps.Polygon({
        paths: this.polygonValue,
        map,
        strokeColor: '#5A75D4',
        strokeOpacity: 0.8,
        strokeWeight: 3,
        fillColor: '#5A75D4',
        fillOpacity: 0.35,
      });
    }

    if (this.circleRadiusValue) {
      new google.maps.Circle({
        strokeColor: DEFAULT_CIRCLE_COLOR,
        strokeOpacity: 0.26,
        strokeWeight: 2,
        fillColor: DEFAULT_CIRCLE_COLOR,
        fillOpacity: 0.17,
        map,
        center: this.optionsValue.center,
        radius: this.circleRadiusValue,
      });
    } else if (this.radiiValue && this.radiiValue.length > 0) {
      const radiiIndexes = this.radiiValue
        .map((_, index) => index)
        .sort((a, b) => this.radiiValue[a] - this.radiiValue[b]);

      const indexMap = Object.fromEntries(radiiIndexes.map((val, i) => [val, i]));

      const circles = this.radiiValue.map((radius, i) => (
        new google.maps.Circle({
          strokeColor: this.radiiColorsValue[i] || DEFAULT_CIRCLE_COLOR,
          strokeOpacity: 0.26,
          strokeWeight: 2,
          fillColor: this.radiiColorsValue[i] || DEFAULT_CIRCLE_COLOR,
          fillOpacity: 0.6,
          map,
          center: this.optionsValue.center,
          radius: radius,
          zIndex: this.radiiValue.length - indexMap[i],
        })
      ));

      map.fitBounds(circles[circles.length - 1].getBounds());
    } else {
      new google.maps.marker.AdvancedMarkerElement({
        content: customPropertyMarkerContent(this.propertyTypeIdValue, true),
        map: map,
        position: this.optionsValue.center
      });
    }
  }
}

