import { Controller } from '@hotwired/stimulus';
import { Tab } from 'bootstrap-5';

export default class extends Controller {
  connect() {
    if (window.location.hash) {
      const tabElement = document.querySelector(`[data-bs-target='${window.location.hash}']`);
      if (tabElement) (new Tab(tabElement)).show();
    }
  }

  updateURL(e) {
    history.replaceState({}, '', e.target.dataset.bsTarget);
  }
}
