import React from 'react';
import PropTypes from 'prop-types';

function PaginationItem({ active, index, label, onSelect }) {
  function handleClick(e) {
    e.preventDefault();
    onSelect(index);
  }

  return (
    <li className="page-item">
      <a className={`page-link ${active ? 'active' : ''}`} href="#" onClick={handleClick}>{label}</a>
    </li>
  );
}

PaginationItem.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired
};

export default function Paginator({ total, padding = 3, perPage, currentPage, onSelect }) {
  const pages = Math.ceil(total / perPage);

  const items = [];

  if (currentPage > padding) {
    items.push(<PaginationItem index={1} key="first" label="« First" onSelect={onSelect} />);
  }

  if (currentPage > 1) {
    items.push(<PaginationItem index={currentPage - 1} key="prev" label="‹ Prev" onSelect={onSelect} />);
  }

  for (let i = (currentPage - padding + 1); i <= currentPage + padding; i++) {
    if (i < 1 || i > pages) continue;

    items.push(<PaginationItem active={i === currentPage} index={i} key={i} label={i} onSelect={onSelect} />);
  }

  if (currentPage < (pages - 1)) {
    items.push(<PaginationItem index={currentPage + 1} key="next" label="Next ›" onSelect={onSelect} />);
  }

  if (currentPage < (pages - padding)) {
    items.push(<PaginationItem index={pages} key="last" label="Last »" onSelect={onSelect} />);
  }

  return (
    <nav className="pagination-container w-auto">
      <ul className="pagination">
        {items}
      </ul>
    </nav>
  );
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  padding: PropTypes.number,
  perPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired
};
