import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';

export default function PhoneNumbers({ phoneNumbers, onSet }) {
  const handleChange = useCallback((idx, changes) => {
    onSet(phoneNumbers.map((phoneNumber, index) => {
      if (index == idx) {
        return {
          ...phoneNumber,
          ...changes
        };
      }

      return phoneNumber;
    }));
  }, [phoneNumbers]);

  const handleRemove = useCallback((idx) => {
    onSet(phoneNumbers.map((phoneNumber, index) => {
      if (index == idx) {
        return {
          ...phoneNumber,
          _destroy: true
        };
      }

      return phoneNumber;
    }));
  }, [phoneNumbers]);

  const handleNew = useCallback(() => {
    onSet([...phoneNumbers, { }]);
  }, [phoneNumbers]);

  return (
    <div>
      <Row className="align-items-center">
        <Col><Form.Label className="my-0">Phone Numbers</Form.Label></Col>
        <Col xs="auto">
          <Button size="sm" variant="shaded-primary" onClick={handleNew}>
            + Add New Phone Number
          </Button>
        </Col>
      </Row>
      <div className="d-flex flex-column gap-2 mt-2">
        {phoneNumbers.map((phoneNumber, index) => {
          if (phoneNumber._destroy) return;

          return (
            <Row className="gx-2" key={`phone-number-${index}`} >
              <Col>
                <Form.Control
                  placeholder="Label"
                  required
                  value={phoneNumber.label || ''}
                  onChange={e => handleChange(index, { label: e.target.value })}
                />
              </Col>
              <Col>
                <Form.Control
                  placeholder="(000) 000-0000"
                  required
                  value={formatPhoneNumber(phoneNumber.number || '')}
                  onChange={e => handleChange(index, { number: e.target.value })}
                />
              </Col>
              <Col xs="auto">
                <Button variant="link" onClick={() => handleRemove(index)}>
                  <FontAwesomeIcon className="text-danger" icon={faTrash}/>
                </Button>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
}

PhoneNumbers.propTypes = {
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    email: PropTypes.string,
    _destroy: PropTypes.bool
  })),
  onSet: PropTypes.func.isRequired
};
