export function getQueryParameter(key) {
  return getSearchParams().get(key);
}

export function getTabQueryParam(tabs, param) {
  const queryParam = getQueryParameter(param);

  if (Object.values(tabs).includes(queryParam)) {
    return queryParam;
  } else {
    return Object.keys(tabs)[0];
  }
}

export function setQueryParameter(key, value) {
  const params = getSearchParams();
  if (value) {
    params.set(key, value);
  } else {
    params.delete(key);
  }
  const baseUrl = window.location.href.split('?')[0];
  window.history.pushState({}, '', baseUrl + '?' + params.toString());
}

function getSearchParams() {
  return new URLSearchParams(window.location.search);
}
