import Accent from './accent';
import Cancel from './cancel';
import Danger from './danger';
import Info from './info';
import Primary from './primary';
import Secondary from './secondary';
import Success from './success';
import Warning from './warning';

const Button = {
  Accent,
  Cancel,
  Danger,
  Info,
  Primary,
  Secondary,
  Success,
  Warning,
};

export default Button;
