import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Dropdown, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteNote, updateNote } from '../../../helpers/prospect_api';
import { faEllipsisVertical, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import TrixEditor from 'shared/trix/trix_editor';

export default function NoteCard({ note, onDelete, onUpdate }) {
  const [body, setBody] = useState();
  const [saving, setSaving] = useState(false);

  const handleDelete = useCallback(() => {
    deleteNote(note.id).then(() => onDelete(note));
  }, [note.id]);

  const handleUpdate = useCallback(() => {
    setSaving(true);
    updateNote(note.id, body).then((updatedNote) => {
      onUpdate(updatedNote);
      setBody();
    }).finally(() => setSaving(false));
  }, [body]);

  const handleStartEdit = useCallback(() => {
    setBody(note.body);
  }, [note]);

  const createdAt = new Date(note.createdAt).toLocaleDateString();
  const updatedAt = new Date(note.updatedAt).toLocaleDateString();

  return (
    <div>
      {body !== undefined && (
        <div className="d-flex flex-column gap-2">
          <div className="privacy-mask">
            <TrixEditor id="new-note" initialValue={body} name="body" onChange={setBody} />
          </div>

          <Row className="justify-content-end">
            <Col xs="auto">
              <Button disabled={saving} variant="muted" onClick={() => setBody()}>Cancel</Button>
            </Col>
            <Col xs="auto">
              <Button disabled={saving} variant="secondary" onClick={handleUpdate}>Save</Button>
            </Col>
          </Row>
        </div>
      )}
      {body === undefined && (
        <Row>
          <Col className="d-flex flex-column gap-2">

            {body === undefined && (
              <div className="privacy-mask" dangerouslySetInnerHTML={{ __html: note.body }} />
            )}
            <small className="text-muted">
              Created on {createdAt}
              {createdAt !== updatedAt && (
                <span> • Updated on {updatedAt}</span>
              )}
            </small>
          </Col>
          <Col xs="auto">
            <Dropdown>
              <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
                <FontAwesomeIcon className="clickable px-2" icon={faEllipsisVertical}/>
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-0">
                <Dropdown.Item className="border-top-0" onClick={handleStartEdit}>
                  <FontAwesomeIcon className="me-2" fixedWidth icon={faPencil}/>
                  Edit
                </Dropdown.Item>
                <Dropdown.Item className="border-bottom-0" onClick={handleDelete}>
                  <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash}/>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      )}
    </div>
  );
}

NoteCard.propTypes = {
  note: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
