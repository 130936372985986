import {
  ADD_OWNERSHIP,
  DELETE_OWNERSHIP,
  SET_OWNERS,
  SET_OWNER_CONTACT_METHOD_SCORE,
  SET_VERIFIED_OWNER,
  UPDATE_CONTACT
} from '../actions/owners';

export default function(state = null, action) {
  switch (action.type) {
  case SET_OWNERS:
    return action.payload;
  case SET_VERIFIED_OWNER: {
    return state.map((owner) => {
      if (owner.id == action.payload.ownerId) return { ...owner, verified: action.payload.verified };

      return owner;
    });
  }
  case SET_OWNER_CONTACT_METHOD_SCORE: {
    return state.map((owner) => {
      if (owner.id == action.payload.ownerId) {
        const { contactMethodKey, contactMethodId, newScore } = action.payload;
        return {
          ...owner,
          contact: {
            ...owner.contact,
            [contactMethodKey]: owner.contact[contactMethodKey].map(contactMethod => ({
              ...contactMethod,
              connectRankScore: (contactMethod.id == contactMethodId) ? newScore : contactMethod.connectRankScore
            }))
          }
        };
      }

      return owner;
    });
  }
  case DELETE_OWNERSHIP: {
    return state.filter(owner => owner.id != action.payload.ownerId);
  }
  case ADD_OWNERSHIP: {
    return [action.payload, ...state];
  }
  case UPDATE_CONTACT: {
    return state.map((owner) => {
      if (owner.contact.id == action.payload.id) return {
        ...owner,
        contact: {
          ...owner.contact,
          ...action.payload
        }
      };

      return owner;
    });
  }
  default:
    return state;
  }
}
