import { Controller } from '@hotwired/stimulus';
import { trackEvent } from '../components/search/helpers/google_analytics_helpers';

export default class extends Controller {
  static targets = [
    'connectPremiumCard',
    'connectPremiumTab',
    'connectTab'
  ];

  trackTabChange(e) {
    const gaTitle = e.target.dataset['gaTitle'];
    if (gaTitle) {
      trackEvent(`connect_off_market_${gaTitle}_tab_opened`);
    }
  }

  connect() {
    this.pillsTab = this.element.querySelector('#pills-tab');
    if (this.pillsTab) this.pillsTab.addEventListener('shown.bs.tab', this.trackTabChange);
  }

  disconnect() {
    if (this.pillsTab) this.pillsTab.removeEventListener('shown.bs.tab', this.trackTabChange);
  }

  hideContent() {
    if (this.hasConnectPremiumCardTarget) {
      this.connectPremiumCardTarget.classList.remove('d-none');
    }
  }
      
  showContent() {
    if (this.hasConnectPremiumCardTarget) {
      this.connectPremiumCardTarget.classList.add('d-none');
    }
  }
}
