import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, FormGroup, FormControl } from 'react-bootstrap';

import { renameDealRoom } from '../actions';

export function RenameDealRoomModal({ dealRoom, renameDealRoom, shown, onHide }) {
  const [newName, setNewName] = useState(dealRoom.name);
  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    setSaving(true);

    renameDealRoom(dealRoom, newName).then(() => {
      setSaving(false);
      onHide();
    });
  };

  return (
    <Modal name="rename-deal-room-modal" show={shown} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rename Deal Room</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <FormControl
            name="deal-room-name-input"
            type="text"
            value={newName}
            onChange={e => setNewName(e.target.value)}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className="text-right">
        <button
          className="btn btn-cancel"
          name="cancel-rename-dr-btn"
          onClick={onHide}
        >
          Cancel
        </button>
        <button
          className="btn btn-save"
          disabled={saving}
          name="save-rename-dr-btn"
          onClick={handleSave}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

RenameDealRoomModal.propTypes = {
  dealRoom: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  renameDealRoom: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

const mapStateToProps = ({ dealRoom }) => ({
  dealRoom
});

const mapDispatchToProps = {
  renameDealRoom
};

export default connect(mapStateToProps, mapDispatchToProps)(RenameDealRoomModal);
