import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap-5';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import NewTaskModal from './tasks/new_task_modal';
import EmptyState from '../empty_state';
import Task from './tasks/task';
import EditTaskModal from './tasks/edit_task_modal';
import { addActivity, removeActivity, updateActivity } from './store/actions/activities';
import { PROPERTY_MODEL } from '../../constants';

function Tasks({ callListId, recordId, recordType, activities, addActivity, removeActivity, updateActivity }) {
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [taskForEdit, setTaskForEdit] = useState();
  const tasks = activities.filter(activity => activity.isTask && !activity.completedAt);

  return (
    <div>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h5 className="fw-bolder mb-0">Tasks</h5>
            </Col>
            <Col xs="auto">
              <Button className="btn-as-text" variant="text-secondary" onClick={() => setShowNewTaskModal(true)}>
                <FontAwesomeIcon icon={faCirclePlus} /> New Task
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {tasks.length === 0 && (
          <Card.Body>
            <EmptyState
              icon={faCalendar}
              text="There are no tasks in the queue."
            />
          </Card.Body>
        )}
        {tasks.length > 0 && (
          <ListGroup variant="flush">
            {tasks.map(task => (
              <Task
                key={task.id}
                showConnectionBadges={false}
                task={task}
                onDelete={() => removeActivity(task.id, 'Task')}
                onEdit={setTaskForEdit}
                onUpdate={updateActivity}
              />
            ))}
          </ListGroup>
        )}
      </Card>
      <NewTaskModal
        autoAddConnectedRecords={[{ connectedRecordType: recordType, connectedRecordId: recordId }]}
        callListId={callListId}
        show={showNewTaskModal}
        showContactInput={recordType === PROPERTY_MODEL ? true : false}
        onAdd={addActivity}
        onHide={() => setShowNewTaskModal(false)}
      />
      <EditTaskModal
        existingActivity={taskForEdit}
        onHide={() => setTaskForEdit()}
        onUpdate={updateActivity}
      />
    </div>

  );
}

Tasks.propTypes = {
  activities: PropTypes.array.isRequired,
  addActivity: PropTypes.func.isRequired,
  callListId: PropTypes.number.isRequired,
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired,
  removeActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired
};

const mapStateToProps = ({ activities }) => ({ activities });
export default connect(mapStateToProps, { addActivity, removeActivity, updateActivity })(Tasks);
