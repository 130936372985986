import React from 'react';
import SignCa from '../components/vault_steps/sign_ca';
import AccountForm from '../components/vault_steps/account_form';
import VerifyEmail from '../components/vault_steps/verify_email';
import NotApproved from '../components/vault_steps/not_approved';
import CreateLead from '../components/vault_steps/create_lead';
import DocAccessTooLow from '../components/vault_steps/doc_access_too_low';
import Complete from '../components/vault_steps/complete';
import AskUserType from '../components/vault_steps/ask_user_type';
import Login from '../components/vault_steps/login';
import GateForm from '../components/vault_steps/gate_form';
import SendVerifyEmail from '../components/vault_steps/gates/send_verify_email';
import WaitForDocs from '../components/vault_steps/gates/wait_for_docs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePen } from '@fortawesome/pro-solid-svg-icons';
import UserTypePortal from '../components/vault_steps/preview_portals/user_type';
import VerifyEmailPortal from '../components/vault_steps/preview_portals/verify_email';
import WaitlistPortal from '../components/vault_steps/preview_portals/waitlist';
import GateFormPortal from '../components/vault_steps/preview_portals/gate_form';
import LoginPortal from '../components/vault_steps/preview_portals/login';
import AccountFormPortal from '../components/vault_steps/preview_portals/account_form';
import ChangeDocAccessPortal from '../components/vault_steps/preview_portals/change_doc_access_portal';
import InvalidRegion from '../components/vault_steps/invalid_region';
import { getQueryParameter } from 'helpers/browser_helpers';

export const FAST_TRACK_CA_USER_TYPE = 'fast_track_ca_user_type';
export const NOT_LOGGED_IN = 'not_logged_in';
export const EMAIL_NOT_VERIFIED = 'email_not_verified';
export const INVALID_PROFILE = 'invalid_profile';
export const CA_NOT_SIGNED = 'ca_not_signed';
export const NOT_APPROVED = 'not_approved';
export const COMPLETE = 'complete';
export const CREATE_ACCOUNT = 'create_account';
export const NO_PROJECT_LEAD = 'no_project_lead';
export const DOC_ACCESS_TOO_LOW = 'doc_access_too_low';
export const NOT_SHARED_WITH_EMAIL = 'not_shared_with_email';
export const COLLECT_INFORMATION = 'collect_information';
export const REGISTRATION = 'registration';
export const USER_TYPE = 'user_type';
export const WAIT_FOR_DOCS = 'wait_for_documents';
const WAITLISTED = 'waitlisted';
const INVALID_REGION = 'invalid_region';

export const investorTypeMap = {
  private_investor: 'Private Investor',
  institutional_fund: 'Institutional Fund',
  opportunity_fund: 'Opportunity Fund',
  pension_fund_advisor: 'Pension Fund/Advisor',
  reit: 'REIT',
  foreign: 'Foreign',
  insurance_co: 'Insurance Co.',
  owner_user: 'Owner/User'
};

export const VAULT_GATE_IDENTIFIER = 'vault_gate--';
const VAULT_GATE_FORM_STEPS = [REGISTRATION, COLLECT_INFORMATION, CA_NOT_SIGNED, INVALID_PROFILE];

function addStepToStepString(stepString, stepToAdd) {
  if (stepString.length === 0) return stepToAdd;

  return `${stepString}--${stepToAdd}`;
}

function gateFormNextStep(steps, noDocs) {
  if (noDocs || steps.some(step => !VAULT_GATE_FORM_STEPS.includes(step))) {
    return '';
  }

  return COMPLETE;
}

function emailPortalNextStep(steps) {
  if (steps.length === 1) return COMPLETE;

  return '';
}

export function hasGates(step) {
  return step?.startsWith(VAULT_GATE_IDENTIFIER);
}

export function extractGateSteps(step) {
  return step.replace(VAULT_GATE_IDENTIFIER, '').split('--');
}

function baseVaultGatesProfilePath(vaultPath) {
  const urlSafeEmail = encodeURIComponent(getQueryParameter('email'));
  const urlSafeToken = encodeURIComponent(getQueryParameter('lead_token'));
  return `${vaultPath}/vault_gates_profile_data?invited_lead[email]=${urlSafeEmail}` +
    `&invited_lead[token]=${urlSafeToken}`;
}

function handleVaultGateSteps({
  step,
  onNext,
  userType,
  vaultPath,
  currentEmail,
  fastCaToken,
  resendVerificationPath,
  createLeadPath,
  noDocs,
  readonly,
  loginComponents
}) {
  const vaultGatesProfilePath = `${baseVaultGatesProfilePath(vaultPath)}&user_type=${userType}` +
    `&readonly=${readonly}&fast_ca=${fastCaToken}`;
  const vaultGatesFormPath = `${vaultPath}/vault_gates_form`;
  const vaultGatesVerifyEmailPath = `${vaultPath}/verify_email`;

  const steps = extractGateSteps(step);

  if (loginComponents.loginOnly) {
    const { checkSsoRedirectPath, loginOnly, loginPath, redirectTo, resetPasswordPath } = loginComponents;
    return {
      Component: (
        <Login
          checkSsoRedirectPath={checkSsoRedirectPath}
          hasGates={true}
          loginOnly={loginOnly}
          loginPath={loginPath}
          readonly={readonly}
          redirectTo={redirectTo}
          resetPasswordPath={resetPasswordPath}
          onNext={() => window.location.href = redirectTo}
        />
      ),
      PortalComponent: <LoginPortal onNext={onNext} />
    };
  }

  if (steps.includes(USER_TYPE)) {
    return { Component: <AskUserType onNext={onNext}/>, PortalComponent: <UserTypePortal/> };
  }
  if (steps.includes(WAIT_FOR_DOCS)) {
    return { Component: <WaitForDocs/> };
  }

  if (steps.some(s => VAULT_GATE_FORM_STEPS.includes(s))) {
    const previousStep = addStepToStepString(step, USER_TYPE);
    const nextStep = gateFormNextStep(steps, noDocs);

    return {
      Component: (
        <GateForm
          nextStep={nextStep}
          noDocs={noDocs}
          previousStep={previousStep}
          readonly={readonly}
          steps={steps}
          vaultGatesFormPath={vaultGatesFormPath}
          vaultGatesProfilePath={vaultGatesProfilePath}
          vaultGatesVerifyEmailPath={vaultGatesVerifyEmailPath}
          onNext={onNext}
        />
      ),
      PortalComponent: <GateFormPortal nextStep={nextStep} steps={steps} onNext={onNext}/>
    };
  }

  if (steps.includes(EMAIL_NOT_VERIFIED)) {
    const emailToShow = readonly ? 'email@email.com' : currentEmail;

    return {
      Component: (
        <SendVerifyEmail email={emailToShow} readonly={readonly} sendVerificationPath={resendVerificationPath}/>
      ),
      PortalComponent: <VerifyEmailPortal nextStep={emailPortalNextStep(steps)} onNext={onNext}/>
    };
  }

  if (steps.includes(WAITLISTED)) {
    return {
      Component: <NotApproved readonly={readonly}/>,
      PortalComponent: <WaitlistPortal nextStep={emailPortalNextStep(steps)} onNext={onNext}/>
    };
  }

  if (steps.includes(NO_PROJECT_LEAD)) {
    return { Component: <CreateLead createLeadPath={createLeadPath} onNext={onNext}/> };
  }
}

export function findComponentStep({
  noDocs,
  step,
  onNext,
  vaultPath,
  loginOnly,
  fastCaToken,
  userType,
  sharingId,
  sharingUrl,
  profileUrl,
  currentEmail,
  goToStep,
  readonly,
  onFastCaUnsupported,
  setShowLogin
}) {
  const checkSsoRedirectPath = `${vaultPath}/check_sso_redirect`;
  const loginPath = `${vaultPath}/login`;
  const caPath = `${vaultPath}/ca`;
  const signCaPath = `${vaultPath}/sign_ca`;
  const fastTrackProfilePath = `${vaultPath}/fast_track_profile?fast_ca=${fastCaToken}`;
  const fastCaPath = `${vaultPath}/fast_track_ca?user_type=${userType}&fast_ca=${fastCaToken}`;
  const fastSignCaPath = `${vaultPath}/fast_track_sign_ca?user_type=${userType}&fast_ca=${fastCaToken}`;
  const createLeadPath = `${vaultPath}/access`;
  const resendVerificationPath = `${vaultPath}/resend_email_verification`;
  const resetPasswordPath = '/prospect/reset_password';
  const loginComponents = {
    checkSsoRedirectPath: checkSsoRedirectPath,
    loginOnly: loginOnly,
    loginPath: loginPath,
    redirectTo: sharingUrl,
    resetPasswordPath: resetPasswordPath,
    setShowLogin: setShowLogin
  };

  if (hasGates(step)) {
    return handleVaultGateSteps({
      step,
      onNext,
      userType,
      vaultPath,
      fastCaToken,
      currentEmail,
      resendVerificationPath,
      createLeadPath,
      noDocs,
      readonly,
      loginComponents
    });
  }

  switch (step) {
  case FAST_TRACK_CA_USER_TYPE:
    return { Component: <AskUserType nextStep={CA_NOT_SIGNED} onNext={onNext}/> };
  case NOT_LOGGED_IN:
    return {
      Component: (
        <Login
          checkSsoRedirectPath={checkSsoRedirectPath}
          hasGates={false}
          loginOnly={loginOnly}
          loginPath={loginPath}
          readonly={readonly}
          redirectTo={sharingUrl}
          resetPasswordPath={resetPasswordPath}
          onNext={onNext}
        />
      ),
      PortalComponent: <LoginPortal onNext={onNext} />
    };
  case CA_NOT_SIGNED: {
    let chosenCaPath, chosenSignCaPath;
    if (userType && fastCaToken) {
      chosenCaPath = fastCaPath;
      chosenSignCaPath = fastSignCaPath;
    } else {
      chosenCaPath = caPath;
      chosenSignCaPath = signCaPath;
    }
    return {
      Component: (
        <SignCa
          caPath={chosenCaPath}
          fastTrackProfilePath={fastTrackProfilePath}
          signCaPath={chosenSignCaPath}
          onFastCaUnsupported={onFastCaUnsupported}
          onNext={onNext}
        />
      )
    };
  }
  case CREATE_ACCOUNT:
    return {
      Component:
        <AccountForm
          accountPath={profileUrl}
          invitedLeadAccountPath={baseVaultGatesProfilePath(vaultPath)}
          readonly={readonly}
          sharingId={sharingId}
          onCancel={() => goToStep(NOT_LOGGED_IN)}
          onNext={onNext}
        />,
      PortalComponent: <AccountFormPortal onNext={onNext}/>
    };
  case INVALID_PROFILE:
    return { Component: <AccountForm accountPath={profileUrl} isUpdate={true} sharingId={sharingId} onNext={onNext}/> };
  case EMAIL_NOT_VERIFIED: {
    const emailToShow = readonly ? 'email@email.com' : currentEmail;
    return {
      Component: <VerifyEmail email={emailToShow} readonly={readonly} resendVerificationPath={resendVerificationPath}/>,
      PortalComponent: <VerifyEmailPortal nextStep="" onNext={onNext}/>
    };
  }
  case NOT_APPROVED:
    return {
      Component: <NotApproved readonly={readonly}/>,
      PortalComponent: <WaitlistPortal nextStep="" onNext={onNext}/>
    };
  case NO_PROJECT_LEAD:
    return { Component: <CreateLead createLeadPath={createLeadPath} onNext={onNext}/> };
  case DOC_ACCESS_TOO_LOW:
    return { Component: <DocAccessTooLow/>, PortalComponent: <ChangeDocAccessPortal onNext={onNext}/> };
  case NOT_SHARED_WITH_EMAIL:
    return { Component: <NotApproved/> };
  case COMPLETE:
    return { Component: <Complete loginOnly={loginOnly}/> };
  case INVALID_REGION:
    return { Component: <InvalidRegion/> };
  default:
    return { Component: <div></div> };
  }
}

export function noDocsBannerContent({ mobile = false } = {}) {
  const icon = <FontAwesomeIcon className="text-branding" icon={faFilePen} size="1x"/>;
  const text = 'Complete these steps to be notified when documents are available';

  if (mobile) {
    return (
      <div className="d-flex flex-column gap-2 justify-content-center px-4">
        {icon}
        <div className="text-center" style={{ lineHeight: '1.5rem' }}>{text}</div>
      </div>
    );
  } else {
    return (
      <div className="d-flex gap-2 justify-content-center px-4">
        <div className="d-flex flex-column justify-content-center">
          {icon}
        </div>
        <div style={{ lineHeight: '1rem' }}>{text}</div>
      </div>
    );
  }
}
