import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConnectListingMapMarker from './connect_listing_map_marker';
import OffMarketMapMarker from './off_market_map_marker';

function MapMarkers({ allResults, offMarketSearch }) {
  if (offMarketSearch) return (
    <React.Fragment>
      {allResults.map(result => (
        <OffMarketMapMarker
          key={result.propertyId}
          result={result}
        />
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {allResults.map(result => (
        <ConnectListingMapMarker
          key={result.saleListingId}
          result={result}
          saleListingId={result.saleListingId}
        />
      ))}
    </React.Fragment>
  );
}

MapMarkers.propTypes = {
  allResults: PropTypes.array.isRequired,
  offMarketSearch: PropTypes.bool.isRequired
};

const mapStateToProps = ({ results, ui: { offMarketSearch } }) => {
  const allResults = (offMarketSearch ? results.allOffMarketResults : results.allResults) || [];

  return { allResults, offMarketSearch };
};

export default connect(mapStateToProps)(MapMarkers);
