import {
  SELECT_ENTRY, DESELECT_ENTRY, SET_CURRENT_FOLDER_ID,
  SELECT_ENTRIES, SET_SORT_ORDER, DELETING_ENTRIES, OPEN_RECYCLE_BIN, 
  CLOSE_RECYCLE_BIN,
  RECYCLED_ENTRY,
  DELETED_ENTRY,
  MOVED_ENTRY
} from "../actions";
import _ from 'lodash';

export default (state = { entryIds: [] }, action) => {
  switch (action.type) {
  
  case SELECT_ENTRY: {
    return { ...state, entryIds: [...state.entryIds, action.entry.id], anchorId: action.entry.id };
  }
  case MOVED_ENTRY:
  case RECYCLED_ENTRY:
  case DELETED_ENTRY:
  case DESELECT_ENTRY: {
    return { ...state, entryIds: state.entryIds.filter(id => id !== action.entry.id) };
  }
  case SELECT_ENTRIES: {
    return { ...state, entryIds: action.entries.map(x => x.id) };
  }
  case DELETING_ENTRIES: {
    return { ...state, entryIds: state.entryIds.filter(id => !_.some(action.entries, { id })) };
  }
  case SET_CURRENT_FOLDER_ID:
  case SET_SORT_ORDER:
  case CLOSE_RECYCLE_BIN:
  case OPEN_RECYCLE_BIN: 
    return { ...state, entryIds: [], anchorId: null };
  default:
    return state;
  }
};
