import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { get } from 'helpers/api';
import SignCaPdf from './sign_ca_pdf';
import SignCaText from './sign_ca_text';
import FastSignCaText from './fast_sign_ca_text';

export default function SignCa({ caPath, fastTrackProfilePath, signCaPath, onFastCaUnsupported, onNext }) {
  const [caData, setCaData] = useState({
    caFilePath: '',
    caText: '',
    caType: '',
    isFastCa: null,
    requiredName: ''
  });

  React.useEffect(() => {
    get(caPath).then((data) => {
      setCaData({
        caText: data.ca,
        caFilePath: data.file,
        isFastCa: data.isFastCa,
        requiredName: data.requiredName,
        caType: data.caType,
      });
    }).catch((error) => {
      /* eslint-disable no-console */
      if (error.response.data?.error === 'fast_track_not_supported') {
        console.warn('fast track not supported; redirecting to normal flow');
        onFastCaUnsupported?.();
      } else {
        console.error('fetch ca failed', { message: error.message, data: error.response.data });
      }
      /* eslint-enable no-console */
    });
  }, []);

  const { caFilePath, caText, caType, isFastCa, requiredName } = caData;

  if (!caText && !caFilePath) {
    return (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} size="2x" spin={true}/>
      </div>
    );
  }

  if (caFilePath) {
    return <SignCaPdf caFilePath={caFilePath} caType={caType} signCaPath={signCaPath} onNext={onNext}/>;
  } else if (isFastCa) {
    return (
      <FastSignCaText
        caText={caText}
        fastTrackProfilePath={fastTrackProfilePath}
        signCaPath={signCaPath}
        onNext={onNext}
      />
    );
  } else {
    return <SignCaText caText={caText} name={requiredName} signCaPath={signCaPath} onNext={onNext}/>;
  }
}

SignCa.propTypes = {
  caPath: PropTypes.string.isRequired,
  fastTrackProfilePath: PropTypes.string.isRequired,
  signCaPath: PropTypes.string.isRequired,
  onFastCaUnsupported: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};
