import React, { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Card, Form, InputGroup } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons';
import { faFileDownload, faFileExport, faMagnifyingGlass, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { formatDate, SHORT_DATE_FORMAT } from 'helpers/formatters/date_time_formats';

import EmptyState from '../empty_state';
import { dataExportDownloadUrl } from '../../helpers/prospect_api';
import Paginator from '../paginator';

const PER_PAGE = 5;
export default function ExportList({ exports }) {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSeachResults] = useState(exports);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!searchText) {
      setSeachResults(exports);
    } else {
      setSeachResults(
        exports.filter(e => e.name.toLowerCase().includes(searchText))
      );
    }
  }, [searchText]);

  const paginatedResults = useMemo(() => {
    return searchResults.slice((page - 1) * PER_PAGE, page * PER_PAGE);
  }, [searchResults, page]);

  const link = useCallback((ex) => {
    if (ex.dataFileSize) return (
      <Button data-turbo={false} href={dataExportDownloadUrl(ex.id)} variant="link">
        <FontAwesomeIcon icon={faFileDownload}/>
        Download
      </Button>
    );

    if (ex.exportError) return (
      <Button className="text-danger" disabled variant="link">
        Error
      </Button>
    );

    return (
      <Button className="text-muted" disabled variant="link">
        <FontAwesomeIcon icon={faSpinner} spin/>
        Exporting
      </Button>
    );
  }, []);

  return (
    <Card data-turbo-prefetch={false}>
      <Card.Header className="p-6">
        <h5 className="mb-0">Past Exports</h5>
      </Card.Header>
      <Card.Body className="p-6">
        {exports.length > 0 ? (
          <React.Fragment>
            <Form.Label className="mb-0">Search Files</Form.Label>
            <InputGroup className="my-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search by export name"
                type="text"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </InputGroup>
            {paginatedResults.map(ex => (
              <div className="d-flex py-2 gap-2 border-bottom" key={ex.id}>
                <FontAwesomeIcon className="align-self-start py-1" icon={faFileSpreadsheet} size="lg" />
                <div className="flex-grow-1">
                  <div className="fw-bold">{ex.name}</div>
                  <div className="text-muted small">
                    Exported on {formatDate(ex.createdAt, SHORT_DATE_FORMAT)}
                    {ex.lastDownloaded && (
                      <span> - Last downloaded {formatDate(ex.lastDownloaded, SHORT_DATE_FORMAT)}</span>
                    )}
                  </div>
                </div>
                <div className="text-end">
                  {link(ex)}
                </div>
              </div>
            
            ))}
            {searchResults.length > PER_PAGE && (
              <div className="d-flex justify-content-center">
                <Paginator currentPage={page} perPage={PER_PAGE} total={searchResults.length} onSelect={setPage}/>
              </div>
            )}
          </React.Fragment>
        ) : (
          <EmptyState icon={faFileExport} title="You don't have any data exports"/>
        )}
      </Card.Body>
    </Card>
  );
}

ExportList.propTypes = {
  exports: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lastDownloaded: PropTypes.string,
    id: PropTypes.number.isRequired
  }))
};
