import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FileSystem from 'components/file_system';
import { closeRecycleBin, setCurrentFolderId } from '../actions';

const mapDispatchToProps = { closeRecycleBin, setCurrentFolderId };

class Breadcrumbs extends React.Component {
  static propTypes = {
    closeRecycleBin: PropTypes.func.isRequired,
    currentFolderId: PropTypes.number.isRequired,
    entriesById: PropTypes.object.isRequired,
    inRecycleBin: PropTypes.bool,
    rootEntryId: PropTypes.number.isRequired,
    setCurrentFolderId: PropTypes.func.isRequired
  }

  handleNavigation = ({ id }) => {
    this.props.closeRecycleBin();
    if (id > 0) this.props.setCurrentFolderId(id);
  }

  render() {
    const breadcrumbs = [];
    if (this.props.inRecycleBin) {
      breadcrumbs.push({ id: -1, name: "Recycle Bin" });
      const rootFolder = this.props.entriesById[this.props.rootEntryId];
      breadcrumbs.push({ id: this.props.rootEntryId, name: rootFolder.name });
    } else {
      const currentFolder = this.props.entriesById[this.props.currentFolderId];
      let ptr = currentFolder;
      breadcrumbs.push({ id: currentFolder.id, name: currentFolder.name });
      while (ptr.parentId) {
        ptr = this.props.entriesById[ptr.parentId];
        breadcrumbs.push({ id: ptr.id, name: ptr.name });
      }
    }
    return (
      <FileSystem.Breadcrumbs folders={breadcrumbs.reverse()} onNavigation={this.handleNavigation}/>
    );
  }
}

function mapStateToProps({ ui: { currentFolderId, inRecycleBin }, entriesById, dealRoom: { rootEntryId } }) {
  return {
    currentFolderId,
    entriesById,
    inRecycleBin,
    rootEntryId
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
