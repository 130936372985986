import React, { useState } from 'react';
import PropTypes from 'prop-types';

function formatValue(str, offset) {
  if (str == '') return null;

  const chars = [...str];
  const output = chars.reduce((values, char, i) => {
    char = char.trim();
    const isPushable = !!char && !(i === chars.length - 1 && offset === 1);

    if (!isNaN(char) && isPushable) values.push(char);
    if (char === '.' && !values.includes('.') && isPushable) values.push(char);

    return values;
  }, []);

  return output.join('');
}

// The main characteristic of this input is to add a '%' symbol to the end of the input
function PercentageInput({ name, value, onChange, onValueChange, ...props }) {
  const [inputValue, setInputValue] = useState(value || '');

  React.useEffect(() => {
    value = value ? value + '%' : '';
    setInputValue(value);
  }, [value]);

  const handleChange = (event) => {
    // When the user deletes the '%' symbol, we need to offset the number by 1
    // so the last digit gets deleted instead of the '%' symbol
    const numberOffset = event.nativeEvent?.inputType === 'deleteContentBackward' ? 1 : 0;
    const newValue = formatValue(event.target.value, numberOffset);
    setInputValue(newValue + '%');

    onChange({ [name]: newValue }, event);
    onValueChange(newValue, event);
  };

  const handleFocus = (event) => {
    if (value === 0) {
      event.target.select();
    }
  };

  return (
    <input
      {...props}
      autoComplete="off"
      type="text"
      value={inputValue}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
}

PercentageInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};

export default PercentageInput;
