import _ from 'helpers/no_conflict_lodash';

export default function (actionCreators, debounceTime = 250) {
  const debouncedActionCreators = {};

  for (const name in actionCreators) {
    const actionCreator = actionCreators[name];
    debouncedActionCreators[name] = _.debounce((dispatch, ...args) => {
      return dispatch(actionCreator(...args));
    }, debounceTime);
  }

  return debouncedActionCreators;
}
