import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { deleteOwnershipRequest, fetchContactOwnedProperties } from '../../helpers/prospect_api';
import { Spinner } from 'react-bootstrap-5';
import OwnedPropertiesList from './owned_properties_list';

export default function OwnedPropertiesCard({ contactId }) {
  const [loading, setLoading] = useState(true);
  const [ownedProperties, setOwnedProperties] = useState([]);

  useEffect(() => {
    fetchContactOwnedProperties(contactId)
      .then((res) => {
        setOwnedProperties(res);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleOwnedPropertyDelete = (ownedProperty) => {
    deleteOwnershipRequest(ownedProperty.propertyId, ownedProperty.ownershipId)
      .then(setOwnedProperties(ownedProperties.filter(p => p.ownershipId != ownedProperty.ownershipId)));
  };

  if (loading) {
    return (
      <div className="px-3 py-5">
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }

  return (
    <OwnedPropertiesList ownedProperties={ownedProperties} onDeleteOwnedProperty={handleOwnedPropertyDelete}/>
  );
}

OwnedPropertiesCard.propTypes = {
  contactId: PropTypes.number.isRequired
};
