import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React from 'react';

export default function PreviewPortal({ children }) {
  return (
    ReactDOM.createPortal(
      <div className="py-4 d-flex justify-content-center border-top w-100 gap-4">
        {children}
      </div>,
      document.querySelector('#react-portal-preview-container')
    )
  );
}

PreviewPortal.propTypes = {
  children: PropTypes.node.isRequired
};
