import React from 'react';
import PropTypes from 'prop-types';

class List extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  }

  render() {
    if (this.props.children.length == 0) return <React.Fragment/>;
    return (
      <div className={`file-system-list p-2 ${this.props.className}`}>
        {this.props.children}
      </div>
    );
  }
}

export default List;
