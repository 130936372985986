import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap-5';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

export default function Toggle({
  bordered = false,
  checked,
  label,
  onClick
}) {
  const id = useMemo(() => uuid(), []);

  return (
    <label
      className={
        classNames(
          'd-flex align-items-center gap-2',
          {
            'active': checked,
            'justify-content-between flex-row-reverse form-control as-button bg-white': bordered,
          }
        )
      }
      htmlFor={`toggle-${id}`}
      style={{ width: bordered ? '100%' : 'max-content' }}
    >
      <Form.Check
        checked={checked}
        className="m-0 p-0"
        id={`toggle-${id}`}
        type="switch"
        onChange={onClick}
      />
      <div className={classNames({ 'fw-bold': !bordered })}>{label}</div>
    </label>
  );
}

Toggle.propTypes = {
  bordered: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};
