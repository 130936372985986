import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export default function AccessLevelSelect({ options, value, onChange }) {
  return (
    <div className="access-level-select w-100">
      <Select
        className="w-100"
        isSearchable={false}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        name="accessLevelSelect"
        options={options}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}


AccessLevelSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired
};
