import { Controller } from '@hotwired/stimulus';
import { displayToast } from '../helpers/display_toast';

export default class extends Controller {
  static values = {
    message: String,
    status: String
  }

  connect() {
    if (this.messageValue) {
      displayToast(this.messageValue, this.statusValue);
    }
  }
}
