import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap-5';

import { updateContact } from '../store/actions/owners';
import { connect } from 'react-redux';
import ContactForm from '../../contacts/form/contact_form';

export function EditModal({ initialContact, show, onHide, updateContact }) {
  const handleUpdate = ({ contact }) => {
    updateContact(contact.id, contact).then(() => {
      onHide();
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact</Modal.Title>
      </Modal.Header>
      <ContactForm
        initialContact={initialContact}
        type={initialContact.type}
        onCancel={onHide}
        onSave={handleUpdate}
      />
    </Modal>
  );
}

EditModal.propTypes = {
  initialContact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.number.isRequired,
    jobTitle: PropTypes.string,
    contactType: PropTypes.string,
    birthday: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }).isRequired,
  show: PropTypes.bool,
  updateContact: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
};

export default connect(null, { updateContact })(EditModal);
