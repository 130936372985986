import { Controller } from '@hotwired/stimulus';
import { Offcanvas } from 'bootstrap-5';

export default class extends Controller {
  connect() {
    this.offcanvas = new Offcanvas(this.element);

    this.element.addEventListener('shown.bs.offcanvas', () => {
      this.offcanvas._focustrap.deactivate();
    });
  }

  disconnect() {
    this.offcanvas.dispose();
  }
}
