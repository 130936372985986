import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  attachData(event) {
    const requestBody = event.detail.formSubmission.fetchRequest.body;
    requestBody.append('feedback[url]', document.location.toString());

    for (const [key, value] of Object.entries(this.systemInfo())) {
      requestBody.append(`feedback[system_info][${key}]`, value);
    }
  }

  systemInfo() {
    try {
      return {
        screen: screen.width + 'x' + screen.height,
        window: window.innerWidth + 'x' + window.innerHeight,
        user_agent: navigator.userAgent,
        referrer: document.referrer
      };
    } catch (e) {
      return {};
    }
  }
}
