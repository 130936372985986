import React from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default function Controls({ disabled, onBack, saving, showNext, showSave }) {
  return (
    <div className="mt-4">
      {onBack && (
        <Button className="me-2" variant="outline-secondary" onClick={onBack}>
          <FontAwesomeIcon className="me-2" icon={faAngleLeft} />
          Back
        </Button>
      )}
      {showNext && (
        <Button className="me-2" disabled={disabled} type="submit" variant="secondary">
          <React.Fragment>
            Next
            <FontAwesomeIcon className="ms-2" icon={faAngleRight} />
          </React.Fragment>
        </Button>
      )}
      {showSave && (
        <Button disabled={disabled} type="submit" variant="secondary">
          {saving && <Spinner animation="border" size="sm" />}
          Save
        </Button>
      )}
    </div>
  );
}

Controls.propTypes = {
  disabled: PropTypes.bool,
  saving: PropTypes.bool,
  showNext: PropTypes.bool,
  showSave: PropTypes.bool,
  onBack: PropTypes.func,
};
