import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, ListGroup, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faCalendar, faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_MEETING, ACTIVITY_TYPE_OTHER, CONTACT_MODEL
} from '../../constants';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';
import { formatDateString } from '../../helpers/date_helpers';

const ICON_MAP = {
  [ACTIVITY_TYPE_CALL]: faPhone,
  [ACTIVITY_TYPE_MEETING]: faCalendar,
  [ACTIVITY_TYPE_OTHER]: faPencil,
  [ACTIVITY_TYPE_EMAIL]: faEnvelope
};

export const activityTypeLabel = (activity) => {
  const peopleNames = (activity.connectedRecordsAttributes || [])
    .filter(connectedRecord => connectedRecord.connectedRecordType === CONTACT_MODEL)
    .map(({ label }) => label);

  let label;

  switch (activity.type) {
  case ACTIVITY_TYPE_CALL:
    label = 'Called';
    if (peopleNames.length > 0) label += ` ${peopleNames[0]}`;
    break;
  case ACTIVITY_TYPE_MEETING:
    label = 'Meeting';
    if (peopleNames.length > 0) label += ` with ${peopleNames[0]}`;
    break;
  case ACTIVITY_TYPE_OTHER:
    label = 'Activity logged';
    if (peopleNames.length > 0) label += ` for ${peopleNames[0]}`;
    break;
  case ACTIVITY_TYPE_EMAIL:
    label = 'Email';
    if (peopleNames.length > 0) label += ` to ${peopleNames[0]}`;
  }

  return label;
};

const activityCreatedAtLabel = (activity) => {
  const date = new Date(activity.createdAt);

  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return `${formattedDate} at ${formattedTime}`;
};

export default function ActivityHistoryItem({ activity, onDelete, onEdit }) {
  const [deleting, setDeleting] = useState(false);

  const handleStartUpdate = useCallback(() => onEdit(activity), []);

  const handleDelete = useCallback(() => {
    setDeleting(true);
    onDelete(activity.id).catch(() => setDeleting(false));
  }, []);

  return (
    <ListGroup.Item className="py-3">
      <Row className="gx-2">
        <Col xs="auto">
          <FontAwesomeIcon className="text-secondary fa-fw fa-xs" icon={ICON_MAP[activity.type] || faPhone} />
        </Col>
        <Col>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between lh-sm">
              <div className="clickable" onClick={handleStartUpdate}>
                <div className="fw-bold">
                  {activity.name || activityTypeLabel(activity)}
                </div>
                <div>
                  {activity.completedAt && (
                    <small className="text-success">Completed: {formatDateString(activity.completedAt)}</small>
                  )}
                  {!activity.completedAt && (
                    <small className="text-muted">{activityCreatedAtLabel(activity)}</small>
                  )}
                  {activity.phoneNumber && (
                    <small className="privacy-mask"> • {formatPhoneNumber(activity.phoneNumber)}</small>
                  )}
                </div>
              </div>
              <Button
                className="btn-as-text align-self-start"
                disabled={deleting}
                variant="link"
                onClick={handleDelete}
              >
                <FontAwesomeIcon className="text-danger" icon={faTrashCan} />
              </Button>
            </div>
            <div className="clickable" onClick={handleStartUpdate}>
              {activity.emailSubject && (
                <small className="text-muted privacy-mask">Subject: {activity.emailSubject}</small>
              )}
              <div className="privacy-mask">{activity.description}</div>
              {activity.call_list_name && <small>Call List: {activity.call_list_name}</small>}
            </div>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

ActivityHistoryItem.propTypes = {
  activity: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};
