import { Controller } from '@hotwired/stimulus';

import { Toast } from 'bootstrap-5';

const ICON_MAP = {
  'success': 'fa-circle-check',
  'info': 'fa-circle-info',
  'error': 'fa-circle-exclamation'
};

const ICON_COLOR_MAP = {
  'success': 'text-success',
  'info': 'text-muted',
  'error': 'text-error'
};

export default class extends Controller {
  connect() {
    this.handleEvent = this.handleEvent.bind(this);
    window.addEventListener('display-toast', this.handleEvent);
  }

  disconnect() {
    window.removeEventListener('display-toast', this.handleEvent);
  }

  handleEvent(event) {
    const toastElement = this.createToastElement(event.detail.message, event.detail.status);
    this.element.appendChild(toastElement);

    const toast = new Toast(toastElement);
    toast.show();
  }

  createToastElement(message, status) {
    const toastTemplate = document.createElement('template');
    const title = status.charAt(0).toUpperCase() + status.slice(1);
    toastTemplate.innerHTML =
      `
        <div class="toast fade hide" aria-atomic="true" aria-live="assertive" role="alert">
          <div class="toast-header">
            <i class="fas ${ICON_MAP[status]} ${ICON_COLOR_MAP[status]} me-2" aria-hidden="true"></i>
            <strong class="me-auto">${title}</strong>
            <button class="btn-close" aria-label="close" data-bs-dismiss="toast"></button>
          </div>
          <div class="toast-body">${message}</div>
        </div>
      `;

    return toastTemplate.content.firstElementChild;
  }
}
