import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap-5';

import StateSelect from 'components/state_select';
import { isEmpty } from 'lodash';
import { searchCompanies } from '../api';
import LlcSearchResult from './llc_search_result';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingCircleXmark } from '@fortawesome/pro-solid-svg-icons';

export default function ManualLookup({ defaultState = '' }) {
  const [formData, setFormData] = useState({ name: '', address: '', city: '', state: defaultState });
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const handleFormChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const disableSubmit = () => {
    return searching || isEmpty(formData.state) || (isEmpty(formData.address) && isEmpty(formData.name));
  };

  const handleSearch = () => {
    setSearching(true);
    searchCompanies(formData).then((results) => {
      setSearchResults(results);
      setSearching(false);
      setSearchPerformed(true);
    });
  };

  const renderResults = () => {
    if (searchResults.length > 0) return searchResults.map(({ company }, i) => (
      <LlcSearchResult key={i} owner={company}/>
    ));

    return (
      <div className="justify-content-center align-items-center py-2 d-flex gap-3">
        <FontAwesomeIcon className="icon-gradient" icon={faBuildingCircleXmark} size="3x" />
        <div>
          <h6>No LLC match found.</h6>
          <div className="text-muted">Please try again with different criteria.</div>
        </div>
      </div>
    );
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          No LLC Match Found. Try Manual Search
        </Accordion.Header>
        <Accordion.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" placeholder="LLC Name" value={formData.name} onChange={handleFormChange} />
          </Form.Group>
          <Row className="py-2">
            <Form.Group as={Col}>
              <Form.Label>Address</Form.Label>
              <Form.Control name="address" placeholder="Address" value={formData.address} onChange={handleFormChange} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>City</Form.Label>
              <Form.Control name="city" placeholder="City" value={formData.city} onChange={handleFormChange} />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>State</Form.Label>
              <StateSelect className="form-select" name="state" value={formData.state} onChange={handleFormChange}/>
            </Form.Group>
          </Row>
          <div className="text-end">
            <Button disabled={disableSubmit()} variant="secondary" onClick={handleSearch}>
              {searching ? 'Searching...' : 'Search'}
            </Button>
          </div>
          {searchPerformed && renderResults()}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

ManualLookup.propTypes = {
  defaultState: PropTypes.string
};
