import { PREVIEW_FILE, HANDLE_NAVIGATION } from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
  case HANDLE_NAVIGATION:
  case PREVIEW_FILE:
    return action.file;
  default:
    return state;
  }
};
