import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDownload } from '@fortawesome/free-solid-svg-icons';

import FilePreview from 'components/file_system/viewers/file_viewer';
import { hasPreviewer } from '../../../components/file_system/viewers/file_viewer';
import { connect } from 'react-redux';

const PDF_TYPES = ['pdf', 'bo-doc'];

export function FileViewer({
  file: originalFile,
  higherFloatingButton = false,
  previewProfile,
  readonly = false,
  userType
}) {
  const updatedUrl = new URL(originalFile.url);
  updatedUrl.searchParams.append('user_type', userType);
  updatedUrl.searchParams.append('readonly', readonly);
  updatedUrl.searchParams.append('preview_profile', JSON.stringify(previewProfile));
  const file = { ...originalFile, url: updatedUrl.toString() };

  const isPdf = PDF_TYPES.includes(file.icon);
  const showDownloadBtn = hasPreviewer(file) && !isPdf;

  const preview = () => {
    if (isPdf) {
      return <FilePreview file={file}/>;
    }

    return (
      <div className="file-preview py-4">
        <div className="text-center text-muted text-truncate mb-3">
          {file.name}
        </div>
        <FilePreview file={file}/>
      </div>
    );
  };

  const downloadLink = () => {
    if (!file || file.locked) return;

    const downloadUrl = file.downloadUrl || file.url;

    if (downloadUrl) return (
      <div className="px-3 py-2" name="download-file">
        <a className="bg-branding" href={downloadUrl} rel="noopener noreferrer" target="_blank">
          <span>Download</span> <FontAwesomeIcon className="mr-2" icon={faDownload}/>
        </a>
      </div>
    );
  };

  const containerClass = classNames(
    'h-100 w-100 file-viewer position-absolute d-flex flex-column',
    { 'overflow-auto': !isPdf }
  );

  return (
    <div className={containerClass}>
      {preview()}
      {showDownloadBtn && (
        <div
          className="position-fixed floating-download-button bottom-0 translate-middle-x d-inline-block"
          style={{ paddingBottom: higherFloatingButton ? '80px' : '12px' }}
        >
          <div className="badge bg-branding rounded-pill d-inline-flex fs-6 shadow-sm">
            {downloadLink()}
          </div>
        </div>
      )}
    </div>
  );
}

FileViewer.propTypes = {
  file: PropTypes.shape({
    downloadUrl: PropTypes.string,
    icon: PropTypes.string,
    locked: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.string,
    url: PropTypes.string
  }),
  higherFloatingButton: PropTypes.bool,
  previewProfile: PropTypes.object,
  readonly: PropTypes.bool,
  userType: PropTypes.string,
};

const mapStateToProps = ({ ui: { userType } }) => ({ userType });

export default connect(mapStateToProps)(FileViewer);
