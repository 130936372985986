import React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Polygon, useGoogleMap } from '@react-google-maps/api';

import {
  setOffMarketHoverPropertyId
} from '../../store/actions/ui';
import { setOffMarketPropertyId } from '../../helpers/navigation_helper';
import { customPropertyMarkerContent } from '../../../../helpers/map_helper';

function highlight(markerView, restricted = false) {
  markerView.content.classList.add(restricted ? 'highlight-restricted' : 'highlight');
  markerView.element.style.zIndex = 1;
}

function unhighlight(markerView, restricted = false) {
  markerView.content.classList.remove(restricted ? 'highlight-restricted' : 'highlight');
  markerView.element.style.zIndex = 0;
}

function OffMarketMapMarker({
  adjustedValuation,
  isHovering,
  largeIcon,
  result,
  setOffMarketHoverPropertyId
}) {
  const map = useGoogleMap();
  const marker = useRef();

  useEffect(() => {
    const markerElementContent = customPropertyMarkerContent(
      result.propertyTypeId,
      largeIcon,
      result.restricted,
      adjustedValuation,
      result.valuationEstimate
    );
    const position = { lat: parseFloat(result.latitude), lng: parseFloat(result.longitude) };

    if (!(position.lat && position.lng)) return;

    const advancedMarkerView = new google.maps.marker.AdvancedMarkerElement({
      map,
      position: position,
      content: markerElementContent
    });
    marker.current = advancedMarkerView;
    const element = advancedMarkerView.element;

    ['focus', 'pointerenter'].forEach((eventName) => {
      element.addEventListener(eventName, () => {
        setOffMarketHoverPropertyId(result.propertyId);
      });
    });

    ['blur', 'pointerleave'].forEach((eventName) => {
      element.addEventListener(eventName, () => {
        setOffMarketHoverPropertyId();
      });
    });

    advancedMarkerView.addListener('click', () => {
      setOffMarketHoverPropertyId();
      setOffMarketPropertyId(result.propertyId);
    });


    return () => {
      marker.current.map = null;
    };
  }, [map, result, adjustedValuation]);

  useEffect(() => {
    if (marker.current) {
      if (isHovering) {
        highlight(marker.current, result.restricted);
      } else {
        unhighlight(marker.current, result.restricted);
      }
    }
  }, [isHovering]);

  if (!result.parcelBoundary) return null;

  return (
    <Polygon
      options={{
        strokeColor: '#5A75D4',
        strokeOpacity: 0.85,
        strokeWeight: 2,
        fillOpacity: 0.2,
        fillColor: '#5A75D4'
      }}
      path={result.parcelBoundary}
    />
  );
}

OffMarketMapMarker.propTypes = {
  adjustedValuation: PropTypes.number,
  isHovering: PropTypes.bool.isRequired,
  largeIcon: PropTypes.bool.isRequired,
  result: PropTypes.shape({
    latitude: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    longitude: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    propertyId: PropTypes.string.isRequired,
    valuationEstimate: PropTypes.number,
  }).isRequired,
  setOffMarketHoverPropertyId: PropTypes.func.isRequired
};

const mapStateToProps = ({
  results: { totalOffMarketResults },
  ui: { offMarketHoverPropertyId, mapZoomReadonly },
  valuationAdjustments
}, ownProps) => ({
  isHovering: offMarketHoverPropertyId === ownProps.result.propertyId,
  largeIcon: mapZoomReadonly >= 15 || (totalOffMarketResults || 0) <= 250,
  adjustedValuation: valuationAdjustments[ownProps.result.propertyId]?.estimate
});

const mapActionsToProps = { setOffMarketHoverPropertyId };

export default connect(mapStateToProps, mapActionsToProps)(OffMarketMapMarker);
