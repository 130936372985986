import { Controller } from '@hotwired/stimulus';
import SimpleLightbox from 'simplelightbox';

export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.lightbox = new SimpleLightbox(this.element.querySelectorAll('a'), {
      history: false, // doesn't work well with turbo
      fileExt: false, // Our auto size urls dont use file extensions, set this to false to disable file ext checks
      overlayOpacity: 0.9
    });
  }

  disconnect() {
    this.lightbox.destroy();
  }
}

