import { propertyTypeMappings, propertySubtypeMappings } from 'helpers/property_types';
import { formatWithCommas, numberShorthand } from 'helpers/formatters/number_formats';
import { formatDate } from 'helpers/formatters/date_time_formats';
import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons';
import { getStateValue } from 'components/state_select';
import { isBoolean } from 'lodash';

const INVESTMENT_TYPES = {
  0: 'Core',
  1: 'Core Plus',
  2: 'Value Add',
  3: 'Opportunistic',
  4: 'Distressed'
};

const PROPERTY_USES = {
  1: 'Net Leased Investment (NNN)',
  2: 'Investment',
  3: 'Owner / User',
  4: 'Business for Sale',
  5: 'Development'
};

const TENANCIES = {
  1: 'Single',
  2: 'Multiple'
};

export const AIR_CONDITIONING_CODES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export const BUILDING_CONDITIONS = ['Excellent', 'Fair', 'Good', 'Poor', 'Unsound', 'Average'];
export const ROOF_TYPES = [
  'Gable',
  'Bowstring Truss',
  'Reinforced Concrete',
  'Dome',
  'Steel Frame/Truss',
  'Flat',
  'Gable or Hip',
  'Hip',
  'Irregular/Cathedral',
  'Gambrel',
  'Mansard',
  'Prestress Concrete',
  'Rigid Frame Bar Joist',
  'Shed',
  'Sawtooth',
  'Wood Truss'
];

const priceFormatter = (min, max) => {
  if (!min && !max) return;

  if (!max) return `Greater than $${numberShorthand(min)}`;
  if (!min) return `Less than $${numberShorthand(max)}`;

  return `$${numberShorthand(min)} - $${numberShorthand(max)}`;
};

const rangeFormatter = (min, max, units = '') => {
  if (!min && !max) return;

  if (!max) return `Greater than ${formatWithCommas(min)} ${units}`;
  if (!min) return `Less than ${formatWithCommas(max)} ${units}`;

  return `${formatWithCommas(min)} - ${formatWithCommas(max)} ${units}`;
};

const dateFormatter = (min, max, action) => {
  if (!min && !max) return;

  if (!max) return `${action} after ${min}`;
  if (!min) return `${action} before ${max}`;

  return `${action} ${min} - ${max}`;
};

const insightsPropertyTypeMappings = { ...propertyTypeMappings, ...{ 10: 'Residential' } };

const FILTERS = {
  salePrice: {
    label: 'Price',
    // type: 'integerRange',
    keys: ['minSalePrice', 'maxSalePrice'],
    formatted: priceFormatter
  },
  numberOfUnits: {
    label: 'Number of Units',
    // type: 'integerRange',
    keys: ['minNumberOfUnits', 'maxNumberOfUnits'],
    formatted: (min, max) => rangeFormatter(min, max, 'Units')
  },
  numberOfStories: {
    label: 'Number of Stories',
    // type: 'integerRange',
    keys: ['minNumberOfStories', 'maxNumberOfStories'],
    formatted: (min, max) => rangeFormatter(min, max, 'Stories')
  },
  numberOfBedrooms: {
    label: 'Number of Bedrooms',
    // type: 'integerRange',
    keys: ['minNumberOfBedrooms', 'maxNumberOfBedrooms'],
    formatted: (min, max) => rangeFormatter(min, max, 'Bedrooms')
  },
  propertyTypes: {
    label: 'Property Types',
    keys: ['assetClassIds'],
    formatted: (assetClassIds) => {
      if (!assetClassIds || assetClassIds.length < 1) return;

      const assetClassNames = assetClassIds.map((id) => {
        if (id < 100) {
          return insightsPropertyTypeMappings[id];
        } else {
          return propertySubtypeMappings[id];
        }
      });

      return assetClassNames.join(', ');
    }
  },
  recentPriceReduction: {
    label: 'Recent Price Reduction',
    keys: ['recentPriceReduction'],
    formatted: flag => (flag ? 'Recent Price Reduction' : null)
  },
  capRate: {
    label: 'Cap Rate',
    keys: ['minCapRate', 'maxCapRate'],
    formatted: (min, max) => rangeFormatter(min, max, '% (Cap Rate)')
  },
  investmentType: {
    label: 'Investment Type',
    keys: ['investmentTypeIds'],
    formatted: (investmentTypeIds) => {
      if (!investmentTypeIds || investmentTypeIds.length < 1) return;

      const investmentTypeNames = investmentTypeIds.map(id => INVESTMENT_TYPES[id]);

      return investmentTypeNames.join(', ');
    }
  },
  propertyUse: {
    label: 'Property Use',
    keys: ['propertyUse'],
    formatted: propertyUseId => PROPERTY_USES[propertyUseId]
  },
  tenancy: {
    label: 'Tenancy',
    keys: ['tenancyId'],
    formatted: tenancyId => TENANCIES[tenancyId]
  },
  opportunityZone: {
    label: 'Opportunity Zone',
    keys: ['opportunityZone'],
    formatted: flag => (flag ? 'Opportunity Zone' : null)
  },
  recentListings: {
    label: 'New Listings Only',
    keys: ['onMarketDuration'],
    formatted: onMarketDuration => (onMarketDuration ? 'New Listings Only' : null)
  },
  underContract: {
    label: 'Include Under Contract',
    keys: ['includeUnderContract'],
    formatted: includeUnderContract => (includeUnderContract ? 'Include Under Contract' : null)
  },
  buildingSize: {
    label: 'Building Size (SF)',
    keys: ['minBuildingSizeSf', 'maxBuildingSizeSf'],
    formatted: (min, max) => rangeFormatter(min, max, 'SF')
  },
  lotSize: {
    label: 'Lot Size (Acres)',
    keys: ['minLotSizeAcres', 'maxLotSizeAcres'],
    formatted: (min, max) => rangeFormatter(min, max, 'Acres')
  },
  yearBuilt: {
    label: 'Year Built',
    keys: ['minYearBuilt', 'maxYearBuilt'],
    formatted: (min, max) => dateFormatter(min, max, 'Built')
  },
  yearRenovated: {
    label: 'Year Renovated',
    keys: ['minYearRenovated', 'maxYearRenovated'],
    formatted: (min, max) => dateFormatter(min, max, 'Renovated')
  },
  dockHighDoors: {
    label: 'Dock High Doors',
    keys: ['minDockHighDoors', 'maxDockHighDoors'],
    formatted: (min, max) => rangeFormatter(min, max, 'Dock High Doors')
  },
  buildingClass: {
    label: 'Building Class',
    keys: ['buildingClassA', 'buildingClassB', 'buildingClassC'],
    formatted: (a, b, c) => {
      const arr = [];
      if (a) arr.push('A');
      if (b) arr.push('B');
      if (c) arr.push('C');

      if (arr.length == 0) return;

      return `Building Class: ${arr.join(', ')}`;
    }
  },
  maxVacancy: {
    label: 'Max Vacancy %',
    keys: ['maxVacancyPct'],
    formatted: (max) => {
      if (!max) return;

      return `Max Vacancy: ${max}%`;
    }
  },
  lotDepth: {
    label: 'Lot Depth (Feet)',
    keys: ['minLotDepth', 'maxLotDepth'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Lot Depth: ${rangeFormatter(min, max, 'Feet')}`;
    }
  },
  lotFrontage: {
    label: 'Lot Frontage (Feet)',
    keys: ['minLotFrontage', 'maxLotFrontage'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Lot Frontage: ${rangeFormatter(min, max, 'Feet')}`;
    }
  },
  floorAreaRatio: {
    label: 'Floor Area Ratio',
    keys: ['minFloorAreaRatio', 'maxFloorAreaRatio'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Floor Area Ratio: ${rangeFormatter(min, max)}`;
    }
  },
  lastTransactionAmount: {
    label: 'Last Transaction Amount',
    keys: ['minLastTransactionAmount', 'maxLastTransactionAmount'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Last Transaction: ${priceFormatter(min, max)}`;
    }
  },
  lastTransactionAmountPerSf: {
    label: 'Last Transaction Amount Per SF',
    keys: ['minLastTransactionAmountPerSf', 'maxLastTransactionAmountPerSf'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Last Transaction: ${priceFormatter(min, max)} per SF`;
    }
  },
  loanMaturityDate: {
    label: 'Loan Maturity Date',
    keys: ['minLoanMaturityDate', 'maxLoanMaturityDate'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return dateFormatter(formatDate(min), formatDate(max), 'Loan Matures');
    }
  },
  residentialUnits: {
    label: 'Number of Residential Units',
    keys: ['minResidentialUnits', 'maxResidentialUnits'],
    formatted: (min, max) => rangeFormatter(min, max, 'Residential Units')
  },
  commercialUnits: {
    label: 'Number of Commercial Units',
    keys: ['minCommercialUnits', 'maxCommercialUnits'],
    formatted: (min, max) => rangeFormatter(min, max, 'Commercial Units')
  },
  numberOfBuildings: {
    label: 'Number of Buildings',
    keys: ['minNumberOfBuildings', 'maxNumberOfBuildings'],
    formatted: (min, max) => rangeFormatter(min, max, 'Buildings')
  },
  zoning: {
    label: 'Zoning',
    keys: ['zoning'],
    formatted: zoning => (zoning ? `Zoning Code: ${zoning}` : null)
  },
  apn: {
    label: 'APN',
    keys: ['apn'],
    formatted: apn => (apn ? `APN: ${apn}` : null)
  },
  airConditioningId: {
    label: 'Air Conditioning Code',
    keys: ['airConditioningId'],
    formatted: airConditioningId => (
      AIR_CONDITIONING_CODES.includes(parseInt(airConditioningId)) ?
        `Air Conditioning Code: ${airConditioningId}` : null
    )
  },
  buildingCondition: {
    label: 'Building Condition',
    keys: ['buildingCondition'],
    formatted: buildingCondition => (
      BUILDING_CONDITIONS.includes(buildingCondition) ? `Building Condition: ${buildingCondition}` : null
    )
  },
  improvementAssessedValue: {
    label: 'Improvement Assessed Value',
    keys: ['minImprovementAssessedValue', 'maxImprovementAssessedValue'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Improvement Assessed Value: ${priceFormatter(min, max)}`;
    }
  },
  landAssessedValue: {
    label: 'Land Assessed Value',
    keys: ['minLandAssessedValue', 'maxLandAssessedValue'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Land Assessed Value: ${priceFormatter(min, max)}`;
    }
  },
  roofType: {
    label: 'Roof Typ',
    keys: ['roofType'],
    formatted: roofType => (
      ROOF_TYPES.includes(roofType) ? `Roof Type: ${roofType}` : null
    )
  },
  taxAmount: {
    label: 'Tax Amount',
    keys: ['minTaxAmount', 'maxTaxAmount'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Tax Amount: ${priceFormatter(min, max)}`;
    }
  },
  totalAssessedValue: {
    label: 'Total Assessed Value',
    keys: ['minTotalAssessedValue', 'maxTotalAssessedValue'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return `Total Assessed Value: ${priceFormatter(min, max)}`;
    }
  },
  transactionDate: {
    label: 'Last Sale Date',
    keys: ['minTransactionDate', 'maxTransactionDate'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return dateFormatter(formatDate(min), formatDate(max), 'Last Sale');
    }
  },
  loanBeginningDate: {
    label: 'Loan Starting Date',
    keys: ['minLoanBeginningDate', 'maxLoanBeginningDate'],
    formatted: (min, max) => {
      if (!min && !max) return;

      return dateFormatter(formatDate(min), formatDate(max), 'Loan Started');
    }
  },
  corporateOwned: {
    label: 'Corporate Owned',
    keys: ['corporateOwned'],
    formatted: corporateOwned => (
      isBoolean(corporateOwned) ? `Corporate Owned: ${corporateOwned ? 'Yes' : 'No'}` : null
    )
  },
  ownerOccupied: {
    label: 'Occupied by Owner',
    keys: ['ownerOccupied'],
    formatted: ownerOccupied => (
      isBoolean(ownerOccupied) ? `Occupied by Owner: ${ownerOccupied ? 'Yes' : 'No'}` : null
    )
  },
  ownerAddress: {
    label: 'Owner Address',
    keys: ['ownerAddress'],
    formatted: ownerAddress => (ownerAddress ? `Owner Address: ${ownerAddress}` : null)
  },
  ownerCity: {
    label: 'Owner City',
    keys: ['ownerCity'],
    formatted: ownerCity => (ownerCity ? `Owner City: ${ownerCity}` : null)
  },
  ownerState: {
    label: 'Owner State',
    keys: ['ownerState'],
    formatted: ownerState => (ownerState ? `Owner State: ${getStateValue(ownerState)}` : null)
  },
  ownerZip: {
    label: 'Owner Zip',
    keys: ['ownerZip'],
    formatted: ownerZip => (ownerZip ? `Owner Zip: ${ownerZip}` : null)
  },
  transactionDay: {
    label: 'Transaction Day',
    keys: ['transactionDay'],
    formatted: transactionDay => (transactionDay ? `Transaction Day: ${transactionDay}` : null)
  },
  transactionMonth: {
    label: 'Transaction Month',
    keys: ['transactionMonth'],
    formatted: transactionMonth => (transactionMonth ? `Transaction Month: ${transactionMonth}` : null)
  },
  transactionYear: {
    label: 'Transaction Year',
    keys: ['transactionYear'],
    formatted: transactionYear => (transactionYear ? `Transaction Year: ${transactionYear}` : null)
  },
  bookmarked: {
    label: 'Bookmarked',
    keys: ['bookmarked'],
    formatted: bookmarked => (bookmarked ? 'Bookmarked' : null)
  },
  qoz: {
    label: 'Opportunity Zone',
    keys: ['qoz'],
    formatted: qoz => (qoz ? 'Opportunity Zone' : null)
  },
  claimed: {
    label: 'Claimed',
    keys: ['claimed'],
    formatted: claimed => (claimed ? 'Claimed by Me' : null)
  },
  onMarket: {
    label: 'Active Listings',
    keys: ['onMarket'],
    formatted: onMarket => (onMarket ? 'Active Listings' : null)
  },
  interestedInvestors: {
    label: 'Interested Buyers',
    keys: ['interestedInvestors'],
    formatted: interestedInvestors => (interestedInvestors ? 'Interested Buyers' : null)
  },
  minLikelyToSellRank: {
    label: 'Likely to Sell',
    keys: ['minLikelyToSellRank'],
    formatted: minLikelyToSellRank => (minLikelyToSellRank === LIKELY_TO_SELL_RANKS[1] ? 'Likely To Sell' : null)
  },
};

export const EXCLUSIVE_LISTING_FILTERS = [
  FILTERS.buildingClass,
  FILTERS.buildingSize,
  FILTERS.capRate,
  FILTERS.dockHighDoors,
  FILTERS.investmentType,
  FILTERS.lotSize,
  FILTERS.maxVacancy,
  FILTERS.numberOfUnits,
  FILTERS.opportunityZone,
  FILTERS.propertyTypes,
  FILTERS.propertyUse,
  FILTERS.recentListings,
  FILTERS.recentPriceReduction,
  FILTERS.salePrice,
  FILTERS.tenancy,
  FILTERS.underContract,
  FILTERS.yearBuilt
];

export const OFF_MARKET_FILTERS = [
  FILTERS.airConditioningId,
  FILTERS.apn,
  FILTERS.bookmarked,
  FILTERS.buildingClass,
  FILTERS.buildingCondition,
  FILTERS.buildingSize,
  FILTERS.claimed,
  FILTERS.commercialUnits,
  FILTERS.corporateOwned,
  FILTERS.floorAreaRatio,
  FILTERS.interestedInvestors,
  FILTERS.improvementAssessedValue,
  FILTERS.landAssessedValue,
  FILTERS.lastTransactionAmount,
  FILTERS.lastTransactionAmountPerSf,
  FILTERS.loanBeginningDate,
  FILTERS.loanMaturityDate,
  FILTERS.lotDepth,
  FILTERS.lotFrontage,
  FILTERS.lotSize,
  FILTERS.minLikelyToSellRank,
  FILTERS.numberOfBedrooms,
  FILTERS.numberOfBuildings,
  FILTERS.numberOfStories,
  FILTERS.numberOfUnits,
  FILTERS.onMarket,
  FILTERS.ownerAddress,
  FILTERS.ownerCity,
  FILTERS.ownerOccupied,
  FILTERS.ownerState,
  FILTERS.ownerZip,
  FILTERS.propertyTypes,
  FILTERS.qoz,
  FILTERS.residentialUnits,
  FILTERS.roofType,
  FILTERS.taxAmount,
  FILTERS.totalAssessedValue,
  FILTERS.transactionDate,
  FILTERS.transactionDay,
  FILTERS.transactionMonth,
  FILTERS.transactionYear,
  FILTERS.yearBuilt,
  FILTERS.yearRenovated,
  FILTERS.zoning
];

export const PERSISTANT_ATTRIBUTES = ['locationsAttributes', 'mapBounds'];

export const ASSET_CLASSES = [
  {
    id: 1,
    label: 'Office',
    value: 1,
    subclasses: [
      {
        id: 101,
        label: 'Office Building',
        value: 101,
        parentId: 1
      },
      {
        id: 102,
        label: 'Creative/Loft',
        value: 102,
        parentId: 1
      },
      {
        id: 103,
        label: 'Executive Suites',
        value: 103,
        parentId: 1
      },
      {
        id: 104,
        label: 'Medical',
        value: 104,
        parentId: 1
      },
      {
        id: 105,
        label: 'Institutional/Governmental',
        value: 105,
        parentId: 1
      },
      {
        id: 106,
        label: 'Office Warehouse',
        value: 106,
        parentId: 1
      },
      {
        id: 107,
        label: 'Office Condo',
        value: 107,
        parentId: 1
      },
      {
        id: 108,
        label: 'Coworking',
        value: 108,
        parentId: 1
      },
      {
        id: 109,
        label: 'Lab',
        value: 109,
        parentId: 1
      }
    ]
  },
  {
    id: 2,
    label: 'Retail',
    value: 2,
    subclasses: [
      {
        id: 201,
        label: 'Street Retail',
        value: 201,
        parentId: 2
      },
      {
        id: 202,
        label: 'Strip Center',
        value: 202,
        parentId: 2
      },
      {
        id: 203,
        label: 'Free Standing Building',
        value: 203,
        parentId: 2
      },
      {
        id: 204,
        label: 'Regional Mall',
        value: 204,
        parentId: 2
      },
      {
        id: 205,
        label: 'Retail Pad',
        value: 205,
        parentId: 2
      },
      {
        id: 206,
        label: 'Vehicle Related',
        value: 206,
        parentId: 2
      },
      {
        id: 207,
        label: 'Outlet Center',
        value: 207,
        parentId: 2
      },
      {
        id: 208,
        label: 'Power Center',
        value: 208,
        parentId: 2
      },
      {
        id: 209,
        label: 'Neighborhood Center',
        value: 209,
        parentId: 2
      },
      {
        id: 210,
        label: 'Community Center',
        value: 210,
        parentId: 2
      },
      {
        id: 211,
        label: 'Specialty Center',
        value: 211,
        parentId: 2
      },
      {
        id: 212,
        label: 'Theme/Festival Center',
        value: 212,
        parentId: 2
      },
      {
        id: 213,
        label: 'Restaurant',
        value: 213,
        parentId: 2
      },
      {
        id: 214,
        label: 'Post Office',
        value: 214,
        parentId: 2
      },
      {
        id: 215,
        label: 'Retail Condo',
        value: 215,
        parentId: 2
      },
      {
        id: 216,
        label: 'Lifestyle Center',
        value: 216,
        parentId: 2
      }
    ]
  },
  {
    id: 3,
    label: 'Industrial',
    value: 3,
    subclasses: [
      {
        id: 301,
        label: 'Manufacturing',
        value: 301,
        parentId: 3
      },
      {
        id: 302,
        label: 'Warehouse/Distribution',
        value: 302,
        parentId: 3
      },
      {
        id: 303,
        label: 'Flex Space',
        value: 303,
        parentId: 3
      },
      {
        id: 304,
        label: 'Research & Development',
        value: 304,
        parentId: 3
      },
      {
        id: 305,
        label: 'Refrigerated/Cold Storage',
        value: 305,
        parentId: 3
      },
      {
        id: 306,
        label: 'Office Showroom',
        value: 306,
        parentId: 3
      },
      {
        id: 307,
        label: 'Truck Terminal/Hub/Transit',
        value: 307,
        parentId: 3
      },
      {
        id: 308,
        label: 'Self Storage',
        value: 308,
        parentId: 3
      },
      {
        id: 309,
        label: 'Industrial Condo',
        value: 309,
        parentId: 3
      },
      {
        id: 310,
        label: 'Data Center',
        value: 310,
        parentId: 3
      }
    ]
  },
  {
    id: 5,
    label: 'Land',
    value: 5,
    subclasses: [
      {
        id: 501,
        label: 'Office',
        value: 501,
        parentId: 5
      },
      {
        id: 502,
        label: 'Retail',
        value: 502,
        parentId: 5
      },
      {
        id: 503,
        label: 'Retail-Pad',
        value: 503,
        parentId: 5
      },
      {
        id: 504,
        label: 'Industrial',
        value: 504,
        parentId: 5
      },
      {
        id: 505,
        label: 'Residential',
        value: 505,
        parentId: 5
      },
      {
        id: 506,
        label: 'Multifamily',
        value: 506,
        parentId: 5
      },
      {
        id: 507,
        label: 'Other',
        value: 507,
        parentId: 5
      }
    ]
  },
  {
    id: 6,
    label: 'Multifamily',
    value: 6,
    subclasses: [
      {
        id: 601,
        label: 'High-Rise',
        value: 601,
        parentId: 6
      },
      {
        id: 602,
        label: 'Mid-Rise',
        value: 602,
        parentId: 6
      },
      {
        id: 603,
        label: 'Low-Rise/Garden',
        value: 603,
        parentId: 6
      },
      {
        id: 604,
        label: 'Government Subsidized',
        value: 604,
        parentId: 6
      },
      {
        id: 605,
        label: 'Mobile Home Park',
        value: 605,
        parentId: 6
      },
      {
        id: 606,
        label: 'Senior Living',
        value: 606,
        parentId: 6
      },
      {
        id: 607,
        label: 'Skilled Nursing',
        value: 607,
        parentId: 6
      },
      {
        id: 608,
        label: 'Single Family Rental Portfolio',
        value: 608,
        parentId: 6
      }
    ]
  },
  {
    id: 7,
    label: 'Special Purpose',
    value: 7,
    subclasses: [
      {
        id: 701,
        label: 'School',
        value: 701,
        parentId: 7
      },
      {
        id: 702,
        label: 'Marina',
        value: 702,
        parentId: 7
      },
      {
        id: 703,
        label: 'Other',
        value: 703,
        parentId: 7
      },
      {
        id: 704,
        label: 'Golf Course',
        value: 704,
        parentId: 7
      },
      {
        id: 705,
        label: 'Church',
        value: 705,
        parentId: 7
      }
    ]
  },
  {
    id: 8,
    label: 'Hospitality',
    value: 8,
    subclasses: [
      {
        id: 801,
        label: 'Full Service',
        value: 801,
        parentId: 8
      },
      {
        id: 802,
        label: 'Limited Service',
        value: 802,
        parentId: 8
      },
      {
        id: 803,
        label: 'Select Service',
        value: 803,
        parentId: 8
      },
      {
        id: 804,
        label: 'Resort',
        value: 804,
        parentId: 8
      },
      {
        id: 805,
        label: 'Economy',
        value: 805,
        parentId: 8
      },
      {
        id: 806,
        label: 'Extended Stay',
        value: 806,
        parentId: 8
      },
      {
        id: 807,
        label: 'Casino',
        value: 807,
        parentId: 8
      }
    ]
  },
  {
    id: 10,
    label: 'Residential',
    value: 10,
    subclasses: [],
    hint: 'Excluded by default'
  }
];

const ASSET_CLASS_LABELS = {};
ASSET_CLASSES.forEach((assetClass) => {
  ASSET_CLASS_LABELS[assetClass.id] = assetClass.label;
  if (assetClass.subclasses) {
    assetClass.subclasses.forEach(subClass => ASSET_CLASS_LABELS[subClass.id] = subClass.label);
  }
});
export { ASSET_CLASS_LABELS };

export const SORTING_OPTIONS = [
  { label: 'Sort By', value: '' },
  { label: 'Lot Size SF', value: 'lotSizeSf:desc', icon: faArrowDownWideShort },
  { label: 'Lot Size SF', value: 'lotSizeSf:asc', icon: faArrowUpWideShort },
  { label: 'Sq Ft', value: 'buildingSizeSf:desc', icon: faArrowDownWideShort },
  { label: 'Sq Ft', value: 'buildingSizeSf:asc', icon: faArrowUpWideShort },
  { label: 'Year Built', value: 'yearBuilt:desc', icon: faArrowDownWideShort },
  { label: 'Year Built', value: 'yearBuilt:asc', icon: faArrowUpWideShort },
  { label: 'Year Renovated', value: 'yearRenovated:desc', icon: faArrowDownWideShort },
  { label: 'Year Renovated', value: 'yearRenovated:asc', icon: faArrowUpWideShort },
  { label: 'Units', value: 'numberOfUnits:desc', icon: faArrowDownWideShort },
  { label: 'Units', value: 'numberOfUnits:asc', icon: faArrowUpWideShort }
];

export const LIKELY_TO_SELL_RANKS = [0, 1, 2, 3];
export const LIKELY_TO_SELL_RANK_TO_LIKELIHOOD = Object.freeze({
  1: 'Likely',
  2: 'Very Likely',
  3: 'Extremely Likely',
});
