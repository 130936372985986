import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faDownload,
  faFileExport,
  faPencil,
  faTrash,
  faTrashUndo
} from '@fortawesome/pro-solid-svg-icons';
import { Dropdown, MenuItem, Button } from 'react-bootstrap';

import confirm from 'components/confirm';
import { deleteDealRoom, deleteEntries, downloadEntries, restoreEntries, moveEntries } from '../actions';

export class ActionsDropdown extends React.Component {
  static propTypes = {
    contributor: PropTypes.bool.isRequired,
    currentFolderId: PropTypes.number,
    dealRoom: PropTypes.object,
    deleteDealRoom: PropTypes.func.isRequired,
    deleteEntries: PropTypes.func.isRequired,
    downloadEntries: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    entriesById: PropTypes.object.isRequired,
    fileSearchOpen: PropTypes.bool,
    inRecycleBin: PropTypes.bool,
    moveEntries: PropTypes.func.isRequired,
    restoreEntries: PropTypes.func.isRequired,
    selectedEntries: PropTypes.array.isRequired,
    onDealRoomClose: PropTypes.func.isRequired,
    onRenameDealRoom: PropTypes.func.isRequired
  }

  deleteDealRoom = () => {
    confirm('Are you sure you want to delete the deal room?', {
      buttonLabel: 'Delete',
      buttonStyle: 'danger',
      title: 'Delete Deal Room?'
    }).then(() => {
      this.props.deleteDealRoom(this.props.dealRoom, this.props.onDealRoomClose);
    }).catch(() => {});
  }

  handleDownload = () => {
    this.props.downloadEntries(this.props.selectedEntries);
  }

  handleRestore = () => {
    this.props.restoreEntries(this.props.selectedEntries);
  }

  handleMoveToParent = () => {
    const moveToParentId = this.moveToParentId();
    const { moveEntries, selectedEntries } = this.props;

    moveEntries(selectedEntries, moveToParentId);
  }

  handleDeleteEntries = () => {
    const { selectedEntries, inRecycleBin, deleteEntries } = this.props;

    deleteEntries(selectedEntries, !inRecycleBin);
  }

  moveToParentId = () => {
    const { entriesById, currentFolderId } = this.props;
    const currentFolder = entriesById[currentFolderId];

    if (currentFolder) return currentFolder.parentId;
  }

  renderMenuItem(action) {
    return (
      <MenuItem {...action.menuItemProps}>
        {action.icon && <FontAwesomeIcon className="mr-2" icon={action.icon} />}
        {action.text}
      </MenuItem>
    );
  }

  dealRoomActions() {
    const { contributor, inRecycleBin, fileSearchOpen, selectedEntries, editable } = this.props;
    const entriesSelected = selectedEntries.length > 0;
    const dealRoomActions = [];

    if (contributor && editable && !(inRecycleBin || fileSearchOpen)) {
      dealRoomActions.push({
        menuItemProps: {
          onClick: this.props.onRenameDealRoom,
          disabled: entriesSelected,
          name: 'rename-dr-button'
        },
        icon: faPencil,
        text: 'Rename Deal Room',
      });
      dealRoomActions.push({
        menuItemProps: {
          onClick: this.deleteDealRoom,
          disabled: entriesSelected,
          name: 'open-delete-dr-button'
        },
        icon: faTrash,
        text: 'Delete Deal Room',
      });
    }

    return dealRoomActions;
  }

  fileActions() {
    const { contributor, inRecycleBin, selectedEntries } = this.props;
    const moveToParentId = this.moveToParentId();
    const entriesSelected = selectedEntries.length > 0;
    const fileActions = [];

    if (entriesSelected) {
      if (inRecycleBin) {
        fileActions.push({
          menuItemProps: {
            onClick: this.handleRestore,
            name: 'restore-button'
          },
          icon: faTrashUndo,
          text: 'Restore',
        });
      } else {
        if (moveToParentId) {
          fileActions.push({
            menuItemProps: {
              onClick: this.handleMoveToParent,
              name: 'move-up-button'
            },
            icon: faFileExport,
            text: 'Move to Parent Folder',
          });
        }
        fileActions.push({
          menuItemProps: {
            onClick: this.handleDownload,
            name: 'download-button'
          },
          icon: faDownload,
          text: 'Download',
        });
      }
      if (contributor) {
        fileActions.push({
          menuItemProps: {
            onClick: this.handleDeleteEntries,
            name: 'delete-button'
          },
          text: 'Delete',
          icon: faTrash
        });
      }
    }

    return fileActions;
  }

  render() {
    const dealRoomActions = this.dealRoomActions();
    const fileActions = this.fileActions();

    if (dealRoomActions.length === 0 && fileActions.length === 0) return null;
  
    return (
      <Dropdown id="deal-room-dropdown" pullRight>
        <Button bsRole="toggle" className="btn btn-light mr-2">
          Actions
          <FontAwesomeIcon className="ml-2" icon={faCaretDown}/>
        </Button>
        <Dropdown.Menu>
          {dealRoomActions.map(this.renderMenuItem)}
          {dealRoomActions.length > 0 && fileActions.length > 0 && <MenuItem divider />}
          {fileActions.map(this.renderMenuItem)}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({
  selected: { entryIds },
  entriesById, ui: { inRecycleBin, currentFolderId },
  fileSearch: { open },
  dealRoom
}) => ({
  contributor: dealRoom.access.contributor,
  dealRoom,
  selectedEntries: entryIds.map(id => entriesById[id]),
  editable: dealRoom.editable,
  inRecycleBin,
  entriesById,
  currentFolderId,
  fileSearchOpen: open
});

const mapDispatchToProps = { deleteDealRoom, deleteEntries, downloadEntries, restoreEntries, moveEntries };

export default connect(mapStateToProps, mapDispatchToProps)(ActionsDropdown);
