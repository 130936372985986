import {
  MOVED_ENTRY,
  MOVING_ENTRIES,
  MOVE_CANCELLED,
  DONE_MOVING_ENTRIES,
  RESTORING_ENTRIES,
  RESTORED_ENTRY,
  RESTORE_CANCELLED,
  DONE_RESTORING_ENTRIES,
  ERROR_RESTORING_ENTRY,
  ERROR_MOVING_ENTRY
} from "../actions";

const defaultState = {
  pendingMoves: [],
  completedMoves: [],
  pendingRestores: [],
  completedRestores: []
};
export default (state = defaultState, action) => {
  if (action.fromRemote) {
    return state;
  }
  switch (action.type) {
  case MOVING_ENTRIES: {
    return {
      ...state,
      pendingMoves: [...state.pendingMoves, ...(action.entries.map(entry => (
        { id: entry.id, name: entry.name, destId: action.destination.id, destName: action.destination.name }
      )))]
    };
  }
  case MOVED_ENTRY: {
    const pendingMoves = [...(state.pendingMoves)].splice(1);
    const movedCopy = {};
    Object.assign(movedCopy, state.pendingMoves[0]);
    return {
      ...state,
      pendingMoves,
      completedMoves: [...state.completedMoves, movedCopy]
    };
  }
  case ERROR_MOVING_ENTRY:
  case MOVE_CANCELLED: {
    const pendingMoves = [...(state.pendingMoves)].splice(1);
    return { ...state, pendingMoves };
  }
  case DONE_MOVING_ENTRIES: {
    return { ...state, completedMoves: [] };
  }
  case RESTORING_ENTRIES: {
    return {
      ...state,
      pendingRestores: [...state.pendingRestores, ...(action.entries.map(entry => (
        { id: entry.id, name: entry.name }
      )))]
    };
  }
  case RESTORED_ENTRY: {
    const pendingRestores = [...(state.pendingRestores)].splice(1);
    const restoredCopy = {};
    Object.assign(restoredCopy, state.pendingRestores[0]);
    return {
      ...state,
      pendingRestores,
      completedRestores: [...state.completedRestores, restoredCopy]
    };
  }
  case ERROR_RESTORING_ENTRY:
  case RESTORE_CANCELLED: {
    const pendingRestores = [...(state.pendingRestores)].splice(1);
    return { ...state, pendingRestores };
  }
  case DONE_RESTORING_ENTRIES: {
    return { ...state, completedRestores: [] };
  }
  default:
    return state;
  }
};