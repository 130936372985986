// Date string is YYYY-MM-DD
export function formatDateString(date) {
  return (new Date(date))
    .toLocaleString('en-US', { timeZone: 'UTC', day: '2-digit', month: 'short', year: 'numeric', weekday: 'short' });
}

export function formatShortDate(dateString) {
  if (!dateString) return;

  const date = new Date(dateString);
  
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${month}/${day}/${year}`;
}
