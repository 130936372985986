import React from 'react';
import PropTypes from 'prop-types';
import { some } from 'lodash';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'styles/react-datepicker.scss';
import * as dateHelper from 'helpers/formatters/date_time_formats';

class ManagedDateInput extends React.Component {
  static propTypes = {
    'aria-label': PropTypes.string,
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    inputValue: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showTimeSelect: PropTypes.bool.isRequired,
    showYearPicker: PropTypes.bool,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showTimeSelect: false
  };

  state = this.parseValue(this.props.value);

  componentDidUpdate(previousProps) {
    if (previousProps.value !== this.props.value) {
      const parsedValues = this.parseValue(this.props.value);
      if (parsedValues.dateValue !== this.state.dateValue) {
        this.setState(parsedValues);
      }
    }
  }

  get format() {
    let format;

    format = this.props.showTimeSelect ? dateHelper.defaultFormatUnicode() : dateHelper.DATE_FORMAT_UNICODE;
    format = this.props.showYearPicker ? dateHelper.YEAR_ONLY_FORMAT_UNICODE : format;

    return format;
  }

  parseValue(value) {
    const parsedValues = {
      dateValue: this.parseDate(value),
    };

    return parsedValues;
  }

  parseDate(value) {
    // TODO: Should strip time information for date (i.e. non-datetime) inputs using ISO_DATE_FORMAT
    return value ? dateHelper.formatRawDateTime(value) : null;
  }

  matchSomeFormat = (date, value, formats) => {
    return some(formats, format => value.includes(date.format(format)));
  }

  isValidInputValue = (value, date) => {
    if (value === '') return true;

    const supportedFormats = this.props.showYearPicker
      ? ['YYYY']
      : ['l', 'M/D/YY', 'M/DD/YY', 'M/DD/YYYY'];

    return date.isValid() && this.matchSomeFormat(date, value, supportedFormats)
      && (
        !this.props.showTimeSelect || value.includes(date.format(dateHelper.TIME_FORMAT))
      );
  }

  handleChange = (date, event) => {
    const momentDate = moment(date);

    const isInputTarget = event && event.target.tagName.toUpperCase() === 'INPUT';
    const skipUpdate = isInputTarget && !this.isValidInputValue(event.target.value, momentDate);

    if (skipUpdate) return;

    this.setState(this.parseValue(date));
    this.updateDateValue(date);
  }

  updateDateValue = (date) => {
    const { name, onChange, onValueChange } = this.props;

    const value = this.parseDate(date);
    const event = { target: { name: this.props.name, value } };
    onChange({ [name]: value }, event);
    onValueChange(value, event);
  }

  render() {
    const { inputValue, placeholder, value, onChange, onValueChange, ...props } = this.props;
    const { dateValue } = this.state;
    const dateMoment = dateValue ? moment(dateValue) : null;
    if (props.startDate && props.selectsStart) props.startDate = dateMoment;
    if (props.endDate && props.selectsEnd) props.endDate = dateMoment;

    const date = dateMoment ? dateMoment.toDate() : null;
    // TODO: Should handle date strings here, not force all callers to parse
    const startDate = props.startDate ? props.startDate.toDate() : null;
    const endDate = props.endDate ? props.endDate.toDate() : null;

    return (
      <DatePicker
        autoComplete="off"
        customInput={<input aria-label={this.props['aria-label']} type="text" />}
        dateFormat={this.format}
        disabledKeyboardNavigation
        openToDate={date}
        placeholderText={placeholder}
        selected={date}
        timeFormat={dateHelper.TIME_FORMAT_UNICODE}
        value={inputValue}
        onChange={this.handleChange}
        {...props}
        endDate={endDate}
        startDate={startDate}
      />
    );
  }
}

export default ManagedDateInput;
