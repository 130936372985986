import React from 'react';
import PropTypes from 'prop-types';

import Accent from './accent';

export default function Cancel({ label = 'Cancel', ...props }) {
  return <Accent label={label} outlined {...props} />;
}

Cancel.propTypes = {
  label: PropTypes.string,
};
