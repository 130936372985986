import React from 'react';
import PropTypes from 'prop-types';
import { Col, ProgressBar, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';


export default function StepProgress({ labels, step, total }) {
  const progressPercent = (step / total) * 100;

  return (
    <div className="position-relative animate__animated animate__fadeIn mb-3 border rounded p-2 bg-light">
      <Row className="justify-content-between gy-4">
        {labels.map((label, index) => (
          <Col className="text-center" key={index}>
            <div className="d-flex justify-content-center flex-column">
              <div className={`d-flex ${index + 1 != step ? 'opacity-50' : ''}`}>
                <div
                  className={`
                    d-flex align-items-center fw-bolder border rounded-circle me-2 align-self-start
                    ${index + 1 == step ? 'text-light bg-dark' : ''}
                    ${index + 1 < step ? 'completed-step' : 'px-2'}
                  `}
                >
                  {step > index + 1 ? (
                    <FontAwesomeIcon className="p-2 text-light" icon={faCheckCircle} style={{ fontSize: '8px' }}/>
                  ) : (
                    index + 1
                  )}
                </div>
                <div>
                  <div className="text-start fw-bolder">{label.title}</div>
                  <small className="text-muted text-nowrap">{label.subTitle}</small>
                </div>
              </div>
            </div>
          </Col>
        ))}
        <Col xs={12}>
          <ProgressBar
            now={progressPercent}
            style={{
              backgroundColor: '#EEEEF2',
              border: '1px solid #D8DADF',
              height: '10px',
              width: '100%'
            }}
            variant="secondary"
          />
        </Col>
      </Row>
    </div>
  );
}

StepProgress.propTypes = {
  labels: PropTypes.array,
  saved: PropTypes.bool,
  step: PropTypes.number,
  total: PropTypes.number
};
