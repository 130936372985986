/*
  react-confirm interacts with the DOM in such a way that the pack must be deferred
  Example (.html.slim): `= javascript_pack_tag "application", defer: true`
*/
// createConfirmation has a poor interface: the promise rejects when the dialog is canceled, so you need to use `catch`,
// but that also swallows errors from buggy code. It should've just resolved with a boolean value.
// Workaround: if the error passed to `catch` isn't undefined, re-throw it.

import { createConfirmation } from "react-confirm";
import Confirm from "./confirm_dialog";

const confirm = createConfirmation(Confirm);

export default (message, options = {}) => confirm({ message, options });
