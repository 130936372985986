import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ListGroup, Button } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import ListItem from 'components/file_system/list_item';
import { fetchNavigation, handleNavigation, previewFile } from '../actions';
import VaultAccess from './vault_access';
import EmptyState from './empty_state';
import { faHandPointer, faLock } from '@fortawesome/pro-regular-svg-icons';
import parseNavigationVariables from '../helpers/parse_navigation_variables';
import { formatContainerClasses } from '../helpers/card_component_container_class';

export function MobileDocumentNavigation({
  currentFolder,
  fetchNavigation,
  handleNavigation,
  hasVaultGates,
  isEuRequest,
  parentFolder,
  previewFile,
  rootFolder
}) {
  const [showVaultAccess, setShowVaultAccess] = useState(false);
  const [clickedFile, setClickedFile] = useState();

  useEffect(() => {
    if (!rootFolder) fetchNavigation();
  }, [rootFolder]);

  if (!rootFolder) {
    return (
      <div className="text-center my-5 text-muted">
        <FontAwesomeIcon icon={faSpinner} size="2x" spin />
      </div>
    );
  }

  if (rootFolder.children.length === 0) {
    if (hasVaultGates) {
      return (
        <div className={formatContainerClasses}>
          <VaultAccess loginOnly={false} mobile noDocs onRefreshNavigation={vaultAccessComplete}/>
        </div>
      );
    }

    return <EmptyState/>;
  }

  const keyForFile = file => file.folder ? `folder${file.id}` : `file${file.id}`;

  const fileClicked = (file) => {
    // Mobile view doesn't do file previews, but we still need to call this to set the correct file in the redux
    // store so that the VaultAccess component knows which file we're accessing.
    previewFile(file);

    if (file.locked) {
      setClickedFile(file);
      setShowVaultAccess(file.locked);
    } else if (file.url) {
      window.open(file.url, '_blank');
    }
  };

  function vaultAccessComplete(result) {
    fetchNavigation();

    if (result?.unlocked !== false) {
      setShowVaultAccess(false);

      if (clickedFile.url) {
        window.open(clickedFile.url, '_blank');
      }
    }
  }

  const gainApprovalChild = currentFolder.children.find(({ accessIfApproved }) => accessIfApproved);
  const unlockedChildren = currentFolder.children.filter(({ locked, lostAccess }) => !locked || lostAccess);

  const handleParentFolderClick = () => parentFolder ? handleNavigation(parentFolder) : null;

  return (
    <div className="position-absolute w-100 h-100 overflow-auto" style={{ '--animate-duration': '0.3s' }}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          classNames={{
            enter: 'animate__animated',
            enterActive: showVaultAccess ? 'animate__fadeInRight' : 'animate__fadeInLeft',
            exit: 'animate__animated',
            exitActive: showVaultAccess ? 'animate__fadeOutRight' : 'animate__fadeOutLeft'
          }}
          key={showVaultAccess}
          timeout={500}
        >
          <div>
            {showVaultAccess && (
              <div className="px-3">
                <Button className="mt-2" variant="link" onClick={() => setShowVaultAccess(false)}>
                  <FontAwesomeIcon className="me-2" icon={faChevronLeft} size="lg"/>
                  Back
                </Button>
                <VaultAccess mobile={true} onRefreshNavigation={vaultAccessComplete} />
              </div>
            )}
            {!showVaultAccess && (
              <div>
                <div className="py-3 ">
                  <span className="clickable ps-3" onClick={handleParentFolderClick}>
                    {parentFolder && '< '}
                    {currentFolder.name}
                  </span>
                </div>
                <SwitchTransition mode="out-in">
                  <CSSTransition
                    classNames={{
                      enter: 'animate__animated',
                      enterActive: 'animate__fadeIn',
                      exit: 'animate__animated',
                      exitActive: 'animate__fadeOut'
                    }}
                    key={currentFolder.id}
                    timeout={500}
                  >
                    <div>
                      <ListGroup className="border-top border-bottom" variant="flush">
                        {unlockedChildren.map((item, idx) => (
                          <ListItem
                            file={item}
                            index={idx}
                            key={keyForFile(item)}
                            name={keyForFile(item)}
                            wrapper={<ListGroup.Item className="py-3" />}
                            onNavigation={handleNavigation}
                            onPreview={fileClicked}
                          />
                        ))}
                      </ListGroup>
                      {gainApprovalChild && !isEuRequest && (
                        <div className="my-4">
                          <div className="mb-2 ms-3 text-center">
                            <strong>
                              <FontAwesomeIcon className="me-1" icon={faLock} /> Additional Documents Available
                            </strong>
                          </div>
                          <div className="text-center px-4">
                            <Button
                              className="w-100 border bg-white"
                              name="unlock-btn"
                              variant="light"
                              onClick={() => fileClicked(gainApprovalChild)}
                            >
                              <FontAwesomeIcon className="me-2" icon={faHandPointer} />
                              <strong>Access secure documents</strong>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );

}

MobileDocumentNavigation.propTypes = {
  currentFolder: PropTypes.shape({
    children: PropTypes.array.isRequired
  }),
  fetchNavigation: PropTypes.func.isRequired,
  file: PropTypes.shape({
    accessIfApproved: PropTypes.bool,
    folder: PropTypes.bool,
    locked: PropTypes.bool,
    id: PropTypes.number.isRequired,
    pendingApproval: PropTypes.bool
  }),
  handleNavigation: PropTypes.func.isRequired,
  hasVaultGates: PropTypes.bool.isRequired,
  isEuRequest: PropTypes.bool.isRequired,
  navigation: PropTypes.array,
  parentFolder: PropTypes.object,
  previewFile: PropTypes.func.isRequired,
  rootFolder: PropTypes.shape({
    children: PropTypes.array.isRequired
  }),
};

export const mapStateToProps = ({ navigation, file, ui: { isEuRequest }, vault: { hasVaultGates } }) => (
  { ...parseNavigationVariables(navigation), file, hasVaultGates, isEuRequest }
);

const mapDispatchToProps = { handleNavigation, previewFile, fetchNavigation };

export default connect(mapStateToProps, mapDispatchToProps)(MobileDocumentNavigation);
