import Image from './image';
import CSV from './csv';
import PDF from './pdf';
import Video from './video';
import Text from './text';
import MsOffice from './ms_office';
import BODoc from './bo_doc';
import NoPreview from './no_preview';

export async function onDownload(file) {
  try {
    const response = await fetch(file && (file.downloadUrl || file.url));
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = file.name || file.fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (e) {
    alert('There was an error downloading your file! Please contact support');
  }
}

const Previews = {
  Image,
  CSV,
  PDF,
  Video,
  Text,
  MsOffice,
  BODoc,
  NoPreview
};

export default Previews;
