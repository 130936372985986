import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from './checkbox';
import CheckboxGroup from './checkbox_group';
import CurrencyInput from './currency_input';
import DateDropDownInput from './date_dropdown_input';
import DateInput from './date_input';
import NumberInput from './number_input';
import NumberWithCommas from './number_with_commas_input';
import PercentageInput from './percentage_input';
import RadioGroup from './radio_group';
import TextInput from './text_input';
import Select from './select';

const ManagedInput = React.forwardRef(({ helperText, error, addValidationsWrapper, ...props }, ref) => {
  let baseInput;
  switch (props.type) {
  case 'checkbox':
    baseInput = <Checkbox {...props} />;
    break;
  case 'checkboxGroup':
    baseInput = <CheckboxGroup {...props} />;
    break;
  case 'currency':
    baseInput = <CurrencyInput {...props} />;
    break;
  case 'date':
    baseInput = <DateInput {...props} />;
    break;
  case 'datetime':
    baseInput = <DateInput showTimeSelect {...props} />;
    break;
  case 'number':
    baseInput = <NumberInput {...props} />;
    break;
  case 'numberWithCommas':
    baseInput = <NumberWithCommas {...props} />;
    break;
  case 'radioGroup':
    baseInput = <RadioGroup {...props} />;
    break;
  case 'select':
    baseInput = <Select {...props} />;
    break;
  case 'dateDropdown':
    baseInput = <DateDropDownInput {...props} />;
    break;
  case 'percentage':
    baseInput = <PercentageInput {...props} />;
    break;
  default:
    baseInput = <TextInput {...props} ref={ref} />;
    break;
  }

  if (addValidationsWrapper) {
    const helperTextClass = error ? 'text-danger' : undefined;

    return <div className={'w-100 d-flex flex-column'}>
      {baseInput}
      {helperText && (
        <strong className={`${helperTextClass} ml-2 mt-2`}>{helperText}</strong>
      )}
    </div>;
  }

  return baseInput;
});

const VALID_TYPES = [
  'checkbox',
  'checkboxGroup',
  'currency',
  'date',
  'dateDropdown',
  'datetime',
  'number',
  'percentage',
  'radioGroup',
  'textarea',
  'text',
  'select'
];

ManagedInput.propTypes = {
  addValidationsWrapper: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  type: PropTypes.oneOf(VALID_TYPES),
  onChange: PropTypes.func, // function({ [name]: value }, event)
  onValueChange: PropTypes.func // function(value, event)
};

ManagedInput.defaultProps = {
  onChange: () => {},
  onValueChange: () => {}
};

export default ManagedInput;
