import React from 'react';
import PreviewPortal from './index';
import { Button } from 'react-bootstrap-5';
import PropTypes from 'prop-types';
import { PROFILE_OPTIONS, setProfileData } from '../../../actions/preview';
import { connect } from 'react-redux';
import { COMPLETE, CREATE_ACCOUNT } from '../../../helpers/vault_access_helper';
import { ACCESS_LEVEL_OPTIONS, accessLevelString } from './change_doc_access_portal';

function LoginPortal({ profile, setProfileData, onNext }) {
  function loginWithSpecifiedLeadAccess(accessLevel) {
    const updatedProfile = {
      ...profile,
      [PROFILE_OPTIONS.ACCESS_LEVEL]: accessLevel,
      [PROFILE_OPTIONS.EMAIL_VERIFIED]: true,
      [PROFILE_OPTIONS.CA_SIGNED]: true,
      [PROFILE_OPTIONS.WAITLISTED]: false,
      [PROFILE_OPTIONS.PROFILE_PRESENT]: true
    };

    setProfileData(updatedProfile);
    onNext(COMPLETE, false, { previewProfile: updatedProfile });
  }

  return (
    <PreviewPortal>
      {ACCESS_LEVEL_OPTIONS.map(accessLevel => (
        <Button key={accessLevel} variant="primary" onClick={() => loginWithSpecifiedLeadAccess(accessLevel)}>
          <span>Login with <span className="text-capitalize">{accessLevelString(accessLevel)}</span></span>
        </Button>
      ))}
      <Button variant="primary" onClick={() => onNext(CREATE_ACCOUNT)}>Create Account</Button>
    </PreviewPortal>
  );
}

LoginPortal.propTypes = {
  profile: PropTypes.object.isRequired,
  setProfileData: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ preview }) => ({ profile: preview.profile || {} });
const mapDispatchToProps = { setProfileData };
export default connect(mapStateToProps, mapDispatchToProps)(LoginPortal);
