import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const input = this.element.querySelector('input[autofocus]');
    if (input) {
      input.addEventListener('focus', function() {
        const end = this.value.length;
        this.setSelectionRange(end, end);
      });
    }
  }
}
