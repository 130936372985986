import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAlt,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp
} from '@fortawesome/pro-solid-svg-icons';

/*
 * Provides a taxonomy to access sorting icons
 */
export const sortIcons = {
  alpha: {
    asc: faSortAlphaDown,
    desc: faSortAlphaUp
  },
  amount: {
    asc: faSortAmountUp,
    desc: faSortAmountDown
  },
  numeric: {
    asc: faSortNumericDown,
    desc: faSortNumericUp
  },
  sort: faSortAlt
};

export const PROPERTY_TYPE_ICON = {
  0: 'fa-building',
  1: 'fa-chair-office',
  2: 'fa-store',
  3: 'fa-industry',
  5: 'fa-fence',
  6: 'fa-apartment',
  7: 'fa-flag',
  8: 'fa-hotel',
  10: 'fa-house'
};
