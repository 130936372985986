import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Badge } from 'react-bootstrap-5';
import { connect } from 'react-redux';

import { setHoverListingId } from '../../store/actions/ui';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarClock, faFileSignature, faMessage } from '@fortawesome/pro-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import ResultSummary from './result_summary';
import { setExclusiveListingId } from '../../helpers/navigation_helper';

function ResultCard({
  hoverSaleListingId,
  result,
  setHoverListingId,
}) {
  const isHovering = hoverSaleListingId === result.saleListingId;

  const onMouseEnter = () => {
    setHoverListingId(result.saleListingId);
  };

  const onMouseLeave = () => {
    setHoverListingId();
  };

  const onClick = () => {
    onMouseLeave();
    setExclusiveListingId(result.saleListingId);
  };

  const badge = (icon, text, iconClass) => {
    return (
      <Badge bg="" className="rounded-pill bg-light text-dark position-absolute top-0 end-0 px-2 py-1 m-2">
        <small>
          <FontAwesomeIcon className={`me-1 ${iconClass}`} icon={icon}/>
          {text}
        </small>
      </Badge>
    );
  };

  const overlay = () => {
    if (result.interested) return badge(faMessage, 'Contacted', 'text-secondary');
    if (result.saved) return badge(faBookmark, 'Saved', 'text-success');
  };

  const banners = [];

  if (result.recentlyUpdatedLabel) banners.push({
    value: result.recentlyUpdatedLabel,
    icon: faCalendarClock,
    bgClass: 'primary'
  });

  if (result.underContract) banners.push({
    value: 'Under Contract',
    icon: faFileSignature,
    bgClass: 'secondary'
  });

  const showBanners = banners.length > 0;

  return (
    <Card
      className={classNames('mb-3 clickable overflow-hidden', { shadow: isHovering, 'border-primary': isHovering })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Row className="g-0 align-items-stretch">
        <Col>
          <div className="h-100 d-block d-md-flex flex-column">
            {showBanners && (
              <Row className="m-0">
                {banners.map((banner, idx) => (
                  <Col
                    className={classNames(`text-xs text-white px-2 py-1 bg-${banner.bgClass}`, {
                      'rounded-bottom-end overflow-hidden': idx == banners.length - 1
                    })}
                    key={idx}
                    xs="auto"
                  >
                    <FontAwesomeIcon className="me-1" icon={banner.icon} />
                    {banner.value}
                  </Col>
                ))}
              </Row>
            )}
            <Card.Body className="p-2">
              <ResultSummary property={result} />
            </Card.Body>
          </div>
        </Col>
        <Col
          className="position-relative w-xs-100 text-center border-start-md map-result-thumb"
          md={{ order: 'last', span: 'auto' }}
          xs={{ order: 'first', span: '12' }}
        >
          <img
            className={classNames(
              'rounded-top d-md-none w-100',
              { 'placeholder-thumb': result.usingPlaceholderImage }
            )}
            src={result.thumbnail}
          />
          <img
            className={classNames(
              'rounded-end d-none d-md-block',
              { 'placeholder-thumb': result.usingPlaceholderImage }
            )}
            src={result.thumbnail}
          />
          {overlay()}
        </Col>
      </Row>
    </Card>
  );
}

ResultCard.propTypes = {
  hoverSaleListingId: PropTypes.number,
  result: PropTypes.shape({
    addressLineOne: PropTypes.string.isRequired,
    addressLineTwo: PropTypes.string.isRequired,
    attributesTable: PropTypes.array.isRequired,
    location: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    }).isRequired,
    saleListingId: PropTypes.number.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    usingPlaceholderImage: PropTypes.bool
  }).isRequired,
  setHoverListingId: PropTypes.func.isRequired,
};

const mapStateToProps = ({ ui }) => ({ hoverSaleListingId: ui.hoverSaleListingId });
const mapDispatchToProps = { setHoverListingId };
export default connect(mapStateToProps, mapDispatchToProps)(ResultCard);
