import moment from 'moment';
import 'moment-timezone';

const timezone = () => moment().tz(moment.tz.guess()).format('z');

export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT = 'M/D/YY';
export const DAY_MONTH_FORMAT = 'D MMM, YYYY';
export const MONTH_DAY_FORMAT = 'MMMM D';
export const YEAR_ONLY_FORMAT = 'YYYY';
export const TIME_FORMAT = 'h:mma';
export const FULL_FORMAT = `${DATE_FORMAT} [at] ${TIME_FORMAT}`;
export const defaultFormat = () => `${FULL_FORMAT} [${timezone()}]`;
export const timeFormat = () => `${TIME_FORMAT} [${timezone()}]`;

// UNICODE FORMATS
export const DATE_FORMAT_UNICODE = 'MM/dd/yyyy';
export const YEAR_ONLY_FORMAT_UNICODE = 'yyyy';
export const TIME_FORMAT_UNICODE = 'h:mmaaa';
export const FULL_FORMAT_UNICODE = `${DATE_FORMAT_UNICODE} 'at' ${TIME_FORMAT_UNICODE}`;
export const defaultFormatUnicode = () => `${FULL_FORMAT_UNICODE} '${timezone()}'`;

export function getDateTimeFormat(short) {
  if (short) {
    return `${SHORT_DATE_FORMAT} ${TIME_FORMAT} [${timezone()}]`;
  } else {
    return defaultFormat();
  }
}

export const formatRawDateTime = datetime => moment(datetime).toISOString();
export const formatTime = time => moment(time).format(`${TIME_FORMAT} [${timezone()}]`);

export function formatDateTime(datetime, short = false) {
  return moment(datetime).format(getDateTimeFormat(short));
}

// TODO: Clean up signature - format is a bit overloaded, support it being a string or a `{ short: true }` object
export function formatDate(date, format) {
  const parsedDate = moment(date);
  if (!parsedDate.isValid()) {
    return '';
  }

  if (format === true) {
    format = SHORT_DATE_FORMAT;
  } else {
    format = format || DATE_FORMAT;
  }

  return parsedDate.format(format);
}
