import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap-5';
import ContactForm from '../../contacts/form/contact_form';
import { createContact } from '../../../helpers/prospect_api';



export default function CreateModal({ onHide, onPushContact }) {

  const handleSaveContact = ({ contact }) => {
    createContact(contact).then((response) => {
      onPushContact(response['connect/person'] || response['connect/company']);
      onHide();
    });
  };

  return (
    <Modal show onHide={onHide}>
      <Modal.Header className="border-bottom-0" closeButton>
        <Modal.Title as="h5">New Contact</Modal.Title>
      </Modal.Header>
      <ContactForm
        type="Connect::Person"
        onCancel={onHide}
        onSave={handleSaveContact}
      />
    </Modal>
  );
}

CreateModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onPushContact: PropTypes.func.isRequired
};
