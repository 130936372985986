import { sortBy } from 'lodash';

export const findFileInFolder = (folder, fileId) => {
  if (!fileId) return null;

  let file = null;
  let parentFolderId = folder.id;

  folder.children.some((doc) => {
    if (doc.folder) {
      const result = findFileInFolder(doc, fileId);
      if (result && result.file) {
        file = result.file;
        parentFolderId = result.parentFolderId;
        return true;
      }
    } else if (doc.id == fileId) {
      file = doc;
      return true;
    }
  });

  return { file, parentFolderId };
};

export const getFirstFile = (folder) => {
  const files = folder.children.filter(doc => !doc.folder);
  const unlockedFile = files.find(file => !file.locked);

  if (unlockedFile) return unlockedFile;
  return sortBy(files, 'accessLevel')[0] || null;
};

export const findFolderInFolder = (folderToSearch, folderId) => {
  if (!folderId) return null;

  const recursiveSearch = (currentFolder, id) => {
    for (const doc of currentFolder.children) {
      if (!doc.folder) continue;

      if (doc.id == id) return { folder: doc, parentFolder: currentFolder };

      const result = recursiveSearch(doc, folderId);
      if (result?.folder) return result;
    }
  };

  return recursiveSearch(folderToSearch, folderId);
};

export const findEntryById = (folderToSearch, entryId, isFolder) => {
  if (!entryId || !folderToSearch) return null;

  const recursiveSearch = (currentFolder, id) => {
    for (const entry of currentFolder.children) {
      if (entry.id === id && entry.folder === isFolder) return entry;

      if (entry?.folder) {
        const result = recursiveSearch(entry, id);
        if (result) return result;
      }
    }
  };

  return recursiveSearch(folderToSearch, entryId);
};

export const findParentMostFolder = (rootFolder, folderId) => {
  // this will return a folder from the rootFolder.children that contains the folder nested x levels
  if (!folderId) return null;

  const recursiveSearch = (currentFolder, isRootChildren) => {
    for (const entry of currentFolder.children) {
      if (!entry.folder) continue;

      if (entry.id === folderId && entry.folder) {
        return isRootChildren ? entry : true;
      }

      if (entry?.folder) {
        const result = recursiveSearch(entry, false);
        if (result) {
          return isRootChildren ? entry : result;
        }
      }
    }
  };

  return recursiveSearch(rootFolder, true);
};
