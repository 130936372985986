import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';

import { toggleInvestmentType } from '../../../store/actions/criteria';


function InvestmentTypeInput({
  criteria,
  toggleInvestmentType
}) {
  const investmentTypeIds = criteria.investmentTypeIds || [];

  return (
    <div>
      <Form.Check
        checked={investmentTypeIds.includes(0)}
        id="type_core"
        label="Core"
        type="checkbox"
        onChange={() => toggleInvestmentType(0)}
      />
      <Form.Check
        checked={investmentTypeIds.includes(1)}
        id="type_core_plus"
        label="Core Plus"
        type="checkbox"
        onChange={() => toggleInvestmentType(1)}
      />
      <Form.Check
        checked={investmentTypeIds.includes(3)}
        id="type_oppurtunistic"
        label="Opportunistic"
        type="checkbox"
        onChange={() => toggleInvestmentType(3)}
      />
      <Form.Check
        checked={investmentTypeIds.includes(2)}
        id="type_value_add"
        label="Value Add"
        type="checkbox"
        onChange={() => toggleInvestmentType(2)}
      />
      <Form.Check
        checked={investmentTypeIds.includes(4)}
        id="type_distressed"
        label="Distressed"
        type="checkbox"
        onChange={() => toggleInvestmentType(4)}
      />
    </div>
  );
}

InvestmentTypeInput.propTypes = {
  criteria: PropTypes.object.isRequired,
  toggleInvestmentType: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { toggleInvestmentType })(InvestmentTypeInput);
