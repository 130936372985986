import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Nav } from 'react-bootstrap-5';

import ContactForm from './form/contact_form';
import { createContact } from '../../helpers/prospect_api';

const TAB_CONTACTS = 'contacts';
const TAB_COMPANIES = 'companies';

export default function CreateModal({ defaultTab, onPushContact, onHide }) {
  const [tab, setTab] = useState(defaultTab || TAB_CONTACTS);

  const handleSaveContact = ({ contact }) => {
    createContact(contact).then((response) => {
      onPushContact(response['connect/person'] || response['connect/company']);
      onHide();
    });
  };

  const contactType = useMemo(() => {
    if (tab == TAB_CONTACTS) return 'Connect::Person';
    if (tab == TAB_COMPANIES) return 'Connect::Company';
  }, [tab]);

  return (
    <Modal show onHide={onHide}>
      <Modal.Header className="border-bottom-0" closeButton>
        <Modal.Title as="h5">New Contact</Modal.Title>
      </Modal.Header>
      <Nav activeKey={tab} variant="tabs" onSelect={setTab}>
        <Nav.Item>
          <Nav.Link className="border-start-0" eventKey={TAB_CONTACTS}>
            Contact
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={TAB_COMPANIES}>
            Company
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <ContactForm
        type={contactType}
        onCancel={onHide}
        onSave={handleSaveContact}
      />
    </Modal>
  );
}

CreateModal.propTypes = {
  defaultTab: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onPushContact: PropTypes.func.isRequired
};
