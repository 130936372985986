import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'react-bootstrap-5';
import TaskForm from './task_form';

export default function EditTaskModal({ existingActivity, onHide, onUpdate }) {
  const [activity, setActivity] = useState();
  const [saving, setSaving] = useState(false);
  const isValid = activity && activity.name !== '';

  useEffect(() => {
    if (existingActivity) setActivity({ ...existingActivity });
  }, [existingActivity]);

  const handleSave = () => {
    setSaving(true);

    onUpdate(activity)
      .then(onHide)
      .catch(() => {
        // errors
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal show={!!existingActivity} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Edit Task</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        {activity && <TaskForm activity={activity} onChange={setActivity} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={saving || !isValid} variant="secondary" onClick={handleSave}>
          {saving && <Spinner animation="border" />}
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EditTaskModal.propTypes = {
  existingActivity: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
