import _ from 'lodash';

import {
  CLOSE_FLASH_MESSAGE,
  DONE_MOVING_ENTRIES,
  DONE_RESTORING_ENTRIES,
  SET_CURRENT_FOLDER_ID,
  ERROR_CREATING_FOLDER,
  ERROR_RENAMING_ENTRY,
  ERROR_DELETING_ENTRY,
  ERROR_RECYCLING_ENTRY,
  ERROR_RESTORING_ENTRY,
  ERROR_MOVING_ENTRY,
  ERROR_DELETING_DEAL_ROOM,
  ERROR_UPDATING_MEMBER,
  ERROR_DELETING_MEMBER,
  CLOSE_FILE_PREVIEW
} from "../actions";
import { isFolder } from '../helpers/file_system';

const entriesName = (entries) => {
  if (entries.length === 1) {
    return entries[0].name;
  } else if (_.every(entries, isFolder)) {
    return `${entries.length} folders`;
  } else if (_.every(entries, entry => !isFolder(entry))) {
    return `${entries.length} files`;
  } else {
    return `${entries.length} items`;
  }
};

const moveMessage = (completedMoves) => {
  const movedName = entriesName(completedMoves);
  if (_.every(completedMoves, { destName: completedMoves[0].destName })) {
    return `Moved ${movedName} to ${completedMoves[0].destName}`;
  }
  return `Moved ${movedName}`;
};

const restoreMessage = (completedRestores) => {
  return `Restored ${entriesName(completedRestores)}`;
};

const newMessageState = (state, messageText) => {
  return {
    ...state,
    show: true,
    message: messageText,
    id: state.id + 1,
    isErrorMessage: false
  };
};

const newErrorMessageState = (state, messageText) => {
  return {
    ...newMessageState(state, messageText),
    isErrorMessage: true
  };
};

export default (state = { show: false, movingEntries: [], movedEntries: [], id: 0 }, action) => {
  switch (action.type) {

  case CLOSE_FLASH_MESSAGE: {
    if (action.id !== state.id) {
      return state;
    }
    return { ...state, show: false };
  }
  case DONE_MOVING_ENTRIES:
    return newMessageState(state, moveMessage(action.completedMoves));
  case DONE_RESTORING_ENTRIES:
    return newMessageState(state, restoreMessage(action.completedRestores));
  case SET_CURRENT_FOLDER_ID: {
    if (action.remoteForcedBy) {
      return newMessageState(state, `${action.remoteForcedBy} removed the folder you were in.`);
    }
    return state;
  }
  case CLOSE_FILE_PREVIEW: {
    if (action.remoteForcedBy) {
      return newErrorMessageState(state, `${action.remoteForcedBy} removed the file you were viewing`);
    }
    return state;
  }
  case ERROR_CREATING_FOLDER:
    return newErrorMessageState(state, 'Could not create new folder');
  case ERROR_RENAMING_ENTRY:
    return newErrorMessageState(state, `Could not rename ${action.entry.name}`);
  case ERROR_DELETING_ENTRY:
  case ERROR_RECYCLING_ENTRY:
    return newErrorMessageState(state, `Could not delete ${action.entry.name}`);
  case ERROR_RESTORING_ENTRY:
    return newErrorMessageState(state, `Could not restore ${action.entry.name}`);
  case ERROR_MOVING_ENTRY:
    return newErrorMessageState(state, `Could not move ${action.entry.name}`);
  case ERROR_UPDATING_MEMBER:
    return newErrorMessageState(state, 'Could not update member');
  case ERROR_DELETING_MEMBER:
    return newErrorMessageState(state, 'Could not remove member');
  case ERROR_DELETING_DEAL_ROOM:
    return newErrorMessageState(state, 'Could not delete deal room');

  default:
    return state;
  }
};
