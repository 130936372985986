import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function TrixEditor({ id, initialValue, name, onChange }) {
  const trixInput = useRef();

  useEffect(() => {
    const fileHandler = e => e.preventDefault();
    trixInput.current.addEventListener('trix-file-accept', fileHandler);

    return () => trixInput.current.removeEventListener('trix-file-accept', fileHandler);
  }, []);

  useEffect(() => {
    const changeHandler = e => onChange(e.target.innerHTML);
    trixInput.current.addEventListener('trix-change', changeHandler);

    return () => trixInput.current.removeEventListener('trix-change', changeHandler);
  }, []);

  return (
    <React.Fragment>
      <input
        id={id}
        name={name}
        type="hidden"
        value={initialValue || ''}
      />
      <trix-editor
        class="trix-content"
        id={`editor-${id}`}
        input={id}
        ref={trixInput}
      />
    </React.Fragment>
  );
}

TrixEditor.propTypes = {
  id: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};
