export function clearUrlParam(key) {
  var url = new window.URL(document.location);
  url.searchParams.delete(key);
  history.replaceState(null, null, url);
}

export function getUrlParam(key) {
  const url = new window.URL(document.location);
  return url.searchParams.get(key);
}

export function getUrlParams() {
  const url = new window.URL(document.location);
  return Object.fromEntries(url.searchParams);
}

export function addUrlParam(key, id) {
  var url = new window.URL(document.location);
  url.searchParams.set(key, id);
  return url.toString();
}
