import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Spinner } from 'react-bootstrap-5';
import ConnectTagManager from '../tags/connect_tag_manager';
import { deleteCallListItem, fetchContact, updateContactRequest } from '../../helpers/prospect_api';
import NotesCard from '../call_list/notes';
import ContactInformation from '../call_list/contact_information.js';
import OwnedPropertiesCard from './owned_properties_card.js';
import ActivityHistoryCard from './activity_history_card.js';
import TasksCard from './tasks_card.js';
import { Provider } from 'react-redux';
import { setActivities } from '../call_list/store/actions/activities.js';
import configureStore from 'bundles/connect/components/call_list/store/configure';
import ActionsDropdown from './action_dropdown.js';
import { CONTACT_MODEL } from '../../constants.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import Lists from './lists.js';

const TAB_PROPERTIES = 'properties';
const TAB_LISTS = 'lists';
const TAB_TASKS = 'tasks';
const TAB_ACTIVITY_HISTORY = 'activity_history';
const TAB_NOTES = 'notes';

export default function ContactDetails({ id, onDelete, onUpdate }) {
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(TAB_TASKS);
  const store = useMemo(() => configureStore({ activities: [] }), []);

  const initContact = useCallback((performUpdate = true) => {
    if (!id) return;

    fetchContact(id)
      .then((res) => {
        setContact(res);
        store.dispatch(setActivities(res.activities));
        if (performUpdate) onUpdate(res);
      })
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    setLoading(true);
    initContact(false);
  }, [id]);

  const handleUpdate = (id, contact) => {
    return updateContactRequest(id, contact).then(() => {
      initContact();
    });
  };

  const handleAddToList = () => {
    initContact();
  };

  const handleCallListItemDelete = (callListItem) => {
    deleteCallListItem(callListItem.id)
      .then(() => {
        setContact(prevContact => ({
          ...prevContact,
          callListItems: prevContact.callListItems.filter(item => item.id !== callListItem.id),
        }));
      });
  };

  if (loading) {
    return (
      <Card.Body className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Card.Body>
    );
  }

  return (
    <Provider store={store}>
      <React.Fragment>
        <Card.Body className="d-flex flex-column gap-4">
          <Row className="align-items-center">
            <Col>
              {contact.jobTitle && (
                <div>{contact.jobTitle}</div>
              )}
              <h4 className="m-0 privacy-mask">{contact.name}</h4>
            </Col>

            {contact.company && (
              <Col xs="auto">
                <div className="d-flex gap-2 align-items-center rounded-2">
                  <FontAwesomeIcon className="text-secondary" icon={faBuilding} />
                  <div>
                    <div>{contact.company.name}</div>
                  </div>
                </div>
              </Col>
            )}
            <Col xs="auto">
              <ActionsDropdown
                contact={contact}
                onAddToList={handleAddToList}
                onDelete={() => onDelete(contact.id, contact.contactType)}
                onUpdate={handleUpdate}
              />
            </Col>
          </Row>
          <ContactInformation contact={contact} />
          <ConnectTagManager
            initialTags={contact.tags}
            recordId={contact.id}
            recordType={CONTACT_MODEL}
          />
        </Card.Body>
        <Nav activeKey={tab} variant="tabs" onSelect={setTab}>
          <Nav.Item>
            <Nav.Link className="border-start-0" eventKey={TAB_TASKS}>
              Tasks
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TAB_ACTIVITY_HISTORY}>
              Activity History
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TAB_PROPERTIES}>
              Portfolio
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TAB_LISTS}>
              Lists ({contact.callListItems.length})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TAB_NOTES}>
              Notes
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Card.Body>
          {tab === TAB_TASKS && <TasksCard contact={contact}/>}
          {tab === TAB_ACTIVITY_HISTORY && <ActivityHistoryCard />}
          {tab === TAB_PROPERTIES && <OwnedPropertiesCard contactId={id} />}
          {tab === TAB_LISTS && <Lists callListItems={contact.callListItems} onDelete={handleCallListItemDelete}/>}
          {tab === TAB_NOTES && <NotesCard recordId={id} recordType={CONTACT_MODEL}/> }
        </Card.Body>
      </React.Fragment>
    </Provider>
  );
}

ContactDetails.propTypes = {
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
