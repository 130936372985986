import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ButtonGroupRadio({ checked, name, options, onChange }) {
  return (
    <ButtonGroup className="d-flex z-0">
      {options.map(option => (
        <React.Fragment key={option.value}>
          <input
            checked={checked === option.value}
            className="btn-check"
            id={`${name}_${option.value}`}
            name={name}
            type="radio"
            onChange={() => onChange(option.value)}
          />
          <label className="btn btn-outline-primary btn-sm" htmlFor={`${name}_${option.value}`}>
            {option.icon && <FontAwesomeIcon icon={option.icon} />}
            {option.label}
          </label>
        </React.Fragment>
      ))}
    </ButtonGroup>
  );
}

ButtonGroupRadio.propTypes = {
  checked: PropTypes.any,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired
};
