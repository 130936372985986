import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faTrashUndo } from '@fortawesome/pro-solid-svg-icons';

import { openRecycleBin, deleteEntries, restoreEntries } from '../actions';
import FileSystem from 'components/file_system';

class RecycleBinRow extends React.Component {
  static propTypes = {
    deleteEntries: PropTypes.func.isRequired,
    dropdownComponent: PropTypes.elementType.isRequired,
    excludedColumns: PropTypes.arrayOf(PropTypes.string),
    index: PropTypes.number.isRequired,
    openRecycleBin: PropTypes.func.isRequired,
    recycleBinRootEntries: PropTypes.arrayOf(PropTypes.object).isRequired,
    restoreEntries: PropTypes.func.isRequired,
    onBeginDrag: PropTypes.func.isRequired,
    onEndDrag: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired
  }

  actionsFor = () => {
    const { recycleBinRootEntries } = this.props;
    const actions = [{
      label: "Delete All",
      key: "hardDelete",
      icon: faTrash,
      callback: () => this.props.deleteEntries(recycleBinRootEntries, false)
    }, {
      label: "Restore All",
      key: "restore",
      icon: faTrashUndo,
      callback: () => this.props.restoreEntries(recycleBinRootEntries)
    }];

    return actions;
  }

  file = () => {
    const { recycleBinRootEntries } = this.props;
    return {
      activities: [],
      createdBy: recycleBinRootEntries[0].createdBy,
      folder: true,
      icon: "trash",
      lastModified: recycleBinRootEntries[0].lastModified,
      name: "Recycle Bin",
      size: ""
    };
  }

  render() {
    const { excludedColumns, onBeginDrag, onEndDrag, onReorder, openRecycleBin } = this.props;
    const dndProps = { onBeginDrag, onEndDrag, onReorder };
    return (
      <React.Fragment>
        <FileSystem.Row
          {...dndProps}
          actions={this.actionsFor()}
          dropdownComponent={this.props.dropdownComponent}
          excludedColumns={excludedColumns}
          file={this.file()}
          index={this.props.index}
          key="recycle-bin"
          onNavigation={openRecycleBin}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  deleteEntries,
  restoreEntries,
  openRecycleBin
};
export default connect(null, mapDispatchToProps)(RecycleBinRow);
