import { DELETE_OWNED_PROPERTY, SET_OWNED_PROPERTIES } from '../actions/owned_properties';

export default function(state = { list: [], loading: true }, action) {
  switch (action.type) {
  case SET_OWNED_PROPERTIES: 
    return { ...state, loading: false, list: action.payload };
  case DELETE_OWNED_PROPERTY:
    return {
      ...state,
      loading: false,
      list: state.list.filter(ownedProperty => ownedProperty.ownershipId != action.payload.ownershipId)
    };
  default:
    return state;
  }
}
